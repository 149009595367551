angular.module('easybuild.component.eb.mediamanager')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebMediaManager
 * @description Upload and pick media from various sources
 * @restrict 'A'
 * @scope
 * @param  {number} cols Number of columns per row to show media in grid
 * @param  {Function&} toggleMediaManager Function to show/hide the media manager
**/
.directive('ebMediaManager',['APP_EVENTS', 'mediaManager', 'dialog', 'i18n', 'evBus', 'ebConfig' , 'registry', 'utilitiesService', function(APP_EVENTS, mediaManager, dialog, i18n, evBus, ebConfig, registry, utilitiesService) {
    return {
        restrict: 'A',
        replace: true,
        scope: {
            'cols': '@',
            'toggleMediaManager': '&',
            'touchScreen': '=',
            'customData': '='
        },
        templateUrl: 'partials/media_manager.html',
        link: function($scope) {
            $scope.toggleMediaManager({ toThis: false }); // Hide by default on each page
        },
        controller: ['$scope', function($scope) {
            var colCount = $scope.cols ? parseInt($scope.cols) : 1;

            $scope.i18n = i18n;
            $scope.mediaList = [];
            $scope.groupFilter = '';
            $scope.chosenField = null;
            $scope.clipartCategories = [];
            $scope.uploadDisabled =false;
            $scope.galleryDisabled = false;
            $scope.authorisedDisabled = false;

            var defaultGallerySource = ebConfig.get('easybuild.web.mediamanager.gallery.defaultsource');
            if(defaultGallerySource){
                $scope.$on(APP_EVENTS.mediaManagerGetMediaList, function(ev, data){
                    var group = data.group;
                    var groupFilter = data.groupFilter;
                    var selectAndClose = data.selectAndClose;
                    var mediaList = mediaManager._getMediaList(group, groupFilter).then(function(mediaList) {
                        evBus.broadcast(APP_EVENTS.mediaManagerListRecieved, {mediaList : mediaList, selectAndClose : selectAndClose});
                    });
                });
            }
            $scope.$on(APP_EVENTS.mediaManagerListRecieved, function(ev, evData){
                var allResults = evData.mediaList;
                var selectAndClose = evData.selectAndClose;

                // Filter out invalid media for chosen field
                var frameType = $scope.chosenField ? $scope.chosenField.fieldInfo.frameType : null;
                if ( frameType === "externalmedia"){
                    frameType = $scope.chosenField.fieldInfo.mediaType;
                }
                var results = [];
                angular.forEach(allResults, function(item) {
                    if (!frameType || frameType.indexOf(item.type) > -1) results.push(item);
                });

                // Chunk the result into multiple rows/columns
                var cols = ($scope.group === 'clipart' || $scope.group === 'shared') ? colCount - 1 : colCount;

                var rows = [];
                for (var i=0; i < results.length; i += cols) {
                    rows.push(results.slice(i, i + cols));
                }
                $scope.mediaList = rows;

                if (selectAndClose && $scope.chosenField) {
                    // Select the first item (hence closing the media manager)
                    $scope.selectMedia(results[0]);
                }
            });

            var useDefaultCategories = ebConfig.get('easybuild.web.mediamanager.gallery.defaultcategorysource');
            if(useDefaultCategories) {
                $scope.$on(APP_EVENTS.mediaManagerGetClipartCategories,function(ev){
                    mediaManager._getClipartCategories().then(function(clipartCategories){
                        var evData = {clipartCategories : clipartCategories};
                        evBus.broadcast(APP_EVENTS.mediaManagerClipartCategoriesRecieved, evData);
                    });
                });
            }
            $scope.$on(APP_EVENTS.mediaManagerClipartCategoriesRecieved, function(ev, evData){
                $scope.clipartCategories = evData.clipartCategories;
            });

            $scope.$on(Wave2.DocContent.FIELD_SELECT_START_EVENT,function(ev, data){
                if(data.fieldInfo){
                    $scope.galleryDisabled = !data.fieldInfo.imageGallery;
                    $scope.authorisedDisabled = !data.fieldInfo.authorised;
                    $scope.uploadDisabled = !data.fieldInfo.fileUpload;

                    if (data.fieldInfo.fileUpload){
                        $scope.changeGroup(ebConfig.get('easybuild.web.mediamanager.defaultgroup.when.upload.enabled'));
                    }
                    else {
                        if ( data.fieldInfo.authorised && data.fieldInfo.imageGallery ){
                            $scope.changeGroup(ebConfig.get('easybuild.web.mediamanager.defaultgroup.when.upload.disabled'));
                        }
                        else {
                            $scope.changeGroup((data.fieldInfo.authorised)?"template":"clipart");
                        }

                    }
                }
            });

            // Ensure job details are available in the registry before retrieving the categories to be displayed, as if
            // 'optionalgalleries' are to be used we will need to parse the metadata block to pick up gallery paths.
            var jobs = registry.get("jobs");
            var evData = { 'mediaManager' : mediaManager };
            if(jobs.length > 0) {
                mediaManager.getClipartCategories();
            }
            else {
                evBus.on(Wave2.ArticleManager.JOBS_LOADED_EVENT, function(ev, evData) {
                    mediaManager.getClipartCategories();
                }, this, evData)
            }

            $scope.userGroups = mediaManager.getUserGroups();

            function findSelectedFromMediaList(selectAll) {
                var result = [];
                angular.forEach($scope.mediaList, function(cols) {
                    angular.forEach(cols, function(item) {
                        if (item.selected || selectAll) {
                            result.push(item);
                        }
                    });
                });
                return result;
            }

            $scope.changeGroup = function(targetGroup) {
                $scope.group = targetGroup;
                doUpdateMediaList(false);
            };
            $scope.changeFilter = function(targetFilter) {
                $scope.groupFilter = targetFilter;
                doUpdateMediaList(false);
            };

            $scope.selectMedia = function(selectedMedia) {
                if (!selectedMedia) selectedMedia = findSelectedFromMediaList();
                if (angular.isArray(selectedMedia) && selectedMedia.length > 1) selectedMedia = selectedMedia[0];
                if (!selectedMedia || !$scope.chosenField || (angular.isArray(selectedMedia) && selectedMedia.length <= 1)) return;

                if (selectedMedia.path.indexOf('media/pasteboard/') > -1) {
                    // Copy the selected media into the tmp folder first
                    mediaManager.copyMediaTo(selectedMedia, 'temp', null, function(result) {
                        console.log('sean', result);
                        var evData = {
                            'type': $scope.chosenField.type,
                            'id': $scope.chosenField.id,
                            'content': result[0].path
                        }
                        evBus.broadcast(Wave2.DocContent.FIELD_SELECT_COMPLETE_EVENT, evData);
                    });
                }
                else {
                    var evData = {
                        'type': $scope.chosenField.type,
                        'id': $scope.chosenField.id,
                        'content': 'cms:/' + selectedMedia.path
                    }
                    evBus.broadcast(Wave2.DocContent.FIELD_SELECT_COMPLETE_EVENT, evData);
                }
            };

            $scope.$on(APP_EVENTS.mediaManagerChangeField, function(ev, fieldData) {
                // Change the target field, consequently FIELD_SELECT_START_EVENT will open the media manager
                $scope.chosenField = fieldData;

                // Remove any existing highlighting from fields
                var allFields = angular.element(document.querySelector('.eb-image-field-primary'));
                allFields.removeClass('eb-image-field-primary');

                if (fieldData && fieldData.id) {
                    // Highlight the chosen field on the build form
                    var field = angular.element(document.querySelector('#' + fieldData.id));
                    var fieldContainer = field.parent();
                    fieldContainer.addClass('eb-image-field-primary');
                }
            });

            $scope.deleteSelected = function(selectedMedia) {
                if ($scope.group !== 'pasteboard') return; // Prevent deleting outside of pasteboard

                if (!selectedMedia) selectedMedia = findSelectedFromMediaList();

                dialog.showDialog(dialog.TYPE.CONFIRM, '', _('media_manager.delete_media_check'), {
                    onCancelClicked: function() {
                        dialog.closeDialog();
                    },
                    onOkClicked: function() {
                        dialog.closeDialog();
                        mediaManager.deleteMedia(selectedMedia, function() {
                            evBus.broadcast(APP_EVENTS.mediaManagerRefresh, { group: 'pasteboard' });
                        });
                    }
                });
            };

            $scope.addSelectedToPasteboard = function(selectedMedia) {
                if ($scope.group === 'pasteboard') return; // Prevent pasteboard copying back to itself

                if (!selectedMedia) selectedMedia = findSelectedFromMediaList();

                mediaManager.copyMediaTo(selectedMedia, 'pasteboard', '', function() {
                    evBus.broadcast(APP_EVENTS.mediaManagerRefresh, { group: 'pasteboard' });
                });
            };

            $scope.clearPasteboard = function() {
                if ($scope.group !== 'pasteboard') return; // Prevent deleting outside of pasteboard

                dialog.showDialog(dialog.TYPE.CONFIRM, '', _('media_manager.pasteboard_clear_check'), {
                    onCancelClicked: function() {
                        dialog.closeDialog();
                    },
                    onOkClicked: function() {
                        dialog.closeDialog();
                        var items = findSelectedFromMediaList(true);
                        mediaManager.deleteMedia(items, function() {
                            evBus.broadcast(APP_EVENTS.mediaManagerRefresh, { group: 'pasteboard' });
                        });
                    }
                });
            };

            function doUpdateMediaList(selectAndClose) {
                $scope.mediaList.length = 0;

                var evData = {
                    group : $scope.group,
                    groupFilter : $scope.groupFilter,
                    selectAndClose : selectAndClose
                }

                evBus.broadcast(APP_EVENTS.mediaManagerGetMediaList, evData);
            };

            // Update the media manager panel
            $scope.$on(APP_EVENTS.mediaManagerRefresh, function(ev, target) {
                $scope.group = target.group;
                $scope.groupFilter = target.filter;
                doUpdateMediaList(target.selectAndClose);
            });

            $scope.copyLinkSelected = function(selectedMedia) {
                if (!selectedMedia) selectedMedia = findSelectedFromMediaList();
                if (angular.isArray(selectedMedia) && selectedMedia.length > 1) selectedMedia = selectedMedia[0];

                utilitiesService.copyTextToClipboard('cms:/' + selectedMedia.path.replace(/%20/g, " "));
            };
        }]
    }
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebDropZone
 * @description  Multi-part file upload control that allows drag-drop actions
 * @restrict 'A'
 **/
.directive('ebDropZone', ['APP_EVENTS', 'evBus', 'dialog', 'mediaService', 'mediaManager', 'i18n', 'ebConfig',
    function(APP_EVENTS, evBus, dialog, mediaService, mediaManager, i18n, ebConfig) {
    return {
        restrict: 'A',
        link : function(scope, element, attrs) {
            var previewTemplate = '<span></span>';
            if (attrs['ebDropZoneProgressTemplate']) {
                var templateEl = document.getElementById(attrs['ebDropZoneProgressTemplate']);
                previewTemplate = templateEl.innerHTML;
                templateEl.style.display = 'none';
            }

            var mediaId = mediaManager.getMediaId();

            var dropzoneHeaders = {};
            if (mediaId)
                dropzoneHeaders['x-wave2-media-owner'] = mediaId;

            element.dropzone({
                url: Wave2.WcService.BASE_SERVER_URL + 'media/',
                maxFilesize: 1024,
                paramName: 'uploadfile',
                uploadMultiple: true,
                showFileList: false,
                previewTemplate: previewTemplate,
                previewsContainer: attrs['ebDropZoneProgress'],
                headers: dropzoneHeaders,
                acceptedFiles: ebConfig.get('easybuild.web.mediamanager.accepted'),
                thumbnail: function(file, dataUrl) { /* do nothing */ },
                init: function() {
                    var dropZoneControl = this;
                    var processing = 0, isMultiple = false;
                    var transcodedVideos = [];

                    this.on('success', function(file, json) {
                        dropZoneControl.removeFile(file);
                        processing--;
                        var source = json.ref + file.name;
                        var destination = json.ref +file.name.substring(0, file.name.lastIndexOf(".")) + ".mp4";
                        if (processing <= 0) {
                            processing = 0;
                            isMultiple = false;
                            element.children().first().show();

                            if((mediaManager.calculateMediaType(file.name) == "video") && !(file.name.substring((file.name.lastIndexOf(".") + 1) )=="mp4")){
                                transcodedVideos.push(source);

                                mediaService.submitTranscodeVideo(source, destination).then(function(jobToken){
                                    var callbackEvent = {
                                        name: APP_EVENTS.transcodeComplete,
                                        data: { group: 'pasteboard', selectAndClose: !isMultiple, transcodedVideos: transcodedVideos}
                                    };
                                    Wave2.JobGenerator.attachRunningJob(jobToken,{formatterMode: true, finishEvent: callbackEvent});
                                    transcodedVideos = [];

                                }, function(reason){
                                    transcodedVideos = [];
                                    throw new Error(i18n.error.video_transcode_failed + reason );
                                });
                            }
                            else {
                                dialog.closeDialog();
                                evBus.broadcast(APP_EVENTS.mediaManagerRefresh, { group: 'pasteboard', selectAndClose: !isMultiple });
                            }
                        }
                        else if((mediaManager.calculateMediaType(file.name) == "video") &&!(file.name.substring((file.name.lastIndexOf(".") + 1) )=="mp4")) {
                            transcodedVideos.push(source);

                            mediaService.submitTranscodeVideo(source, destination).then(function(jobToken){
                                Wave2.JobGenerator.attachRunningJob(jobToken,{formatterMode: true});
                            }, function(reason){
                                throw new Error(i18n.error.video_transcode_failed + reason );
                            });
                        }
                    });
                    this.on('addedfile', function(file) {
                        // Hide the message
                        processing++;
                        if (processing == 1) {
                            dialog.showDialog(dialog.TYPE.PROGRESS, '', _('media_manager.upload_in_progress'));
                        }
                        else if (processing > 1) {
                            isMultiple = true;
                        }

                        element.children().first().hide();
                        scope.$apply();
                    });
                }
            });
        }
    }
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebDragDropContent
 * @description Form field control receives dragged content and updates the field value
 *              If attribute value is provided, the element has some content that can be dragged.
 * @restrict 'A'
 * @scope
 * @param  {array} ebDragDropContent - The content to make draggable, "text" or "image" followed by a CMS reference. e.g. "image:temp/myimage.png"
 **/
.directive('ebDragDropContent', ['buildService', function(buildService) {
    return {
        restrict: 'A',
        scope: {
            ebDragDropContent: '@'
        },
        compile: function(elm, attrs) {
            if (attrs.ebDragDropContent && attrs.ebDragDropContent.length > 0) {
                angular.element(elm).attr('draggable', true);
            }

            return {
                post: function(scope, elm, attrs){
                    if (attrs.ebDragDropContent && attrs.ebDragDropContent.length > 0) {
                        elm.bind('dragstart', function (e) {
                            var content = attrs.ebDragDropContent;
                            var dt = e.originalEvent.dataTransfer;
                            dt.setData('text/uri-list', content);
                            dt.setData('text/plain', content);
                        });
                    }
                    else {
                        elm.bind('dragover', function (e) {
                            e.preventDefault();
                        });
                        elm.bind('drop', function (e) {
                            e.stopPropagation();
                            e.preventDefault();

                            var dt = e.originalEvent.dataTransfer;
                            var content = dt.getData('text/plain');
                            buildService.importDraggedContent(angular.element(elm), content);
                        });
                    }
                }
            }
        }
    }
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebImportFromWebsite
 * @description Control to import resources from a chosen website
 * @restrict 'A'
 **/
.directive('ebImportFromWebsite',['APP_EVENTS', '$log', 'mediaService', 'dialog', function(APP_EVENTS, $log, mediaService, dialog) {
    return {
        restrict: 'A',
        link: function (scope, element) {
            element.on('click', function() {
                // Request the target web page URL
                var url = window.prompt(_('media_manager.url_prompt'), '');

                if (url) {
                    if((url.indexOf("http://") === -1) && (url.indexOf("https://") === -1 )){
                        url = "http://" + url;
                    }

                    // Execute the import
                    dialog.showDialog(dialog.TYPE.PROGRESS, '', _('media_manager.import_in_progess'));
                    mediaService.importFromWebPage(url).then(function (media) {
                        // Close the progress dialog
                        dialog.closeDialog();
                        // Update and switch to pasteboard
                        scope.$broadcast(APP_EVENTS.mediaManagerRefresh, { group: 'pasteboard' });
                    }, function (reason) {
                        // Close the progress dialog
                        dialog.closeDialog();
                        scope.$broadcast(APP_EVENTS.mediaManagerRefresh, { group: 'pasteboard' });
                        dialog.showDialog(dialog.TYPE.NOTIFY, '', _('media_manager.import_from_website_error'));
                    });
                }
            });
        }
    }
}]);
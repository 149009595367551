angular.module('easybuild.component.eb.mediamanager')

/**
 * Service operations for media manager component
 */
.service('mediaManager', ['$log', 'nodeService', 'userSession', 'registry', '$http', '$q', 'metadataService', 'ebConfig', 'evBus' ,'APP_EVENTS' ,
        function($log, nodeService, userSession, registry, $http, $q, metadataService, ebConfig, evBus, APP_EVENTS) {

    var mediaManager = {
        getClipartCategories: function() {
            evBus.broadcast(APP_EVENTS.mediaManagerGetClipartCategories);
        },

        _getClipartCategories: function () {
            return nodeService.getCMSDirectoryListing('inventory2gallery/').then(function (entries) {
                var categories = [];
                angular.forEach(entries, function (entry) {
                    if (entry.type == 'collection' || entry.type == 'directory') {
                        var filename = entry.name.substring(entry.name.lastIndexOf('/') + 1);
                        categories.push(filename);
                    }
                });
                return categories;
            });
        },

        getUserGroups: function () {
            var groups = [];
            angular.forEach(JSON.parse(localStorage.getItem("userGroups")), function (group) {
                groups.push(group.name);
            });
            return groups;
        },

        getMediaId: function() {
            var metaFieldKey = ebConfig.get('easybuild.web.mediamanager.owner.key');
            if (!metaFieldKey) return null;

            return metadataService.getMetaField(metaFieldKey);
        },

        _calculatePasteboardPath: function() {
            // Grab the media identifier in the metadata
            // Otherwise, fall back to the username
            var mediaId = this.getMediaId() || userSession.username;
            return 'media/pasteboard/' + encodeURI(mediaId) + '/';
        },

        _getMediaList : function(group, groupFilter) {
            var path = 'inventory2gallery/' + (groupFilter ? encodeURI(groupFilter) + '/' : '');
            if (group === 'pasteboard') {
                path = 'Easybuild/' + this._calculatePasteboardPath();
            }
            else if (group === 'shared') {
                path = 'Easybuild/media/shared/' + (groupFilter ? encodeURI(groupFilter) + '/' : '');
            }
            else if (group === 'template') {
                var template = [
                    encodeURI(registry.get('product').name),
                    encodeURI(registry.get('type').name),
                    encodeURI(registry.get('document').name)
                ].join('/');
                path = 'Easybuild/media/template/' + template.replace(/#/g, '%23') + '/';
            }

            return nodeService.getCMSDirectoryListing(path).then(function (entries) {
                var files = [];

                angular.forEach(entries, function (entry) {
                    if (entry.type == 'file') {
                        var type = mediaManager.calculateMediaType(entry.name);
                        var filename = entry.name.substring(entry.name.lastIndexOf('/') + 1);
                        files.push({
                            type: type,
                            path: entry.path,
                            modified: entry.modified,
                            filename: filename
                        });
                    }
                });

                // Sort the media by most recent first
                files.sort(function(a, b) {
                    return b.modified - a.modified;
                });

                return files;
            });
        },

        getMediaList: function (group, groupFilter) {
            var evData = {'group' : group, 'groupFilter' : groupFilter };
            evBus.broadcast(APP_EVENTS.mediaManagerGetMediaList, evData);
        },

        copyMediaTo: function(mediaIn, targetGroup, targetGroupFilter, updateCallback) {
            if (!angular.isArray(mediaIn)) mediaIn = [ mediaIn ];
            var media = angular.copy(mediaIn); // Don't mutate the input since this is a copy operation
            var promises = [];
            var mediaManager = this;
            var destinationPath = this._calculatePasteboardPath();
            if (targetGroup == 'temp') destinationPath = 'temp/pasteboard';
            angular.forEach(media, function(item) {
                promises.push(mediaManager._copyItem.call(mediaManager, item, destinationPath));
            });

            $q.all(promises).then(function() {
                if (updateCallback) updateCallback.call(this, media);
            });
        },

        _copyItem: function(item, destination, errorMsg, updateCallback, updateCallbackContext) {
            var deferred = $q.defer();
            if(!updateCallbackContext) updateCallbackContext = this;
            var req = {
                method: 'POST',
                url: Wave2.WcService.BASE_SERVER_URL + destination,
                headers: {
                    'x-wave2-copy-resource': item.path
                }
            }
            if (this.getMediaId()) {
                req.headers['x-wave2-media-owner'] = this.getMediaId();
            }

            $http(req).success(function(data, status) {
                if (status === 200) {
                    // Send back the path to the new resource

                    if(updateCallback) updateCallback.call(updateCallbackContext,item, data);

                    item.path = data.destination;
                    deferred.resolve(status);
                }
                else {
                    nodeService.errHandler(status, errorMsg, deferred);
                }
            }).error(function(data, status) {
                nodeService.errHandler(status, errorMsg, deferred);
            });
            return deferred.promise;
        },

        deleteMedia: function (media, updateCallback, updateCallbackContext) {
            var promises = [];
            if(!updateCallbackContext) updateCallbackContext = this;
            if (!angular.isArray(media)) media = [ media ];
            angular.forEach(media, function(item) {
                promises.push(mediaManager._deleteItem.call(updateCallbackContext, item.path, 'Failed to delete item'));
            });

            $q.all(promises).then(function() {
                // All deletes have finished
                if (updateCallback) updateCallback.call(media);
            }, function() {
                // All deletes have finished, but some failed
                $log.error('Some items failed to delete');
                if (updateCallback) updateCallback.call(media);
            });
        },

        _deleteItem: function(path, errorMsg) {
            var deferred = $q.defer();

            var req = {
                method: 'DELETE',
                url: Wave2.WcService.BASE_SERVER_URL + 'media/' + path,
                headers: {}
            }
            if (this.getMediaId()) {
                req.headers['x-wave2-media-owner'] = this.getMediaId();
            }


            $http(req).success(function(data, status) {
                if (status === 200) {
                    deferred.resolve(status);
                }
                else {
                    nodeService.errHandler(status, errorMsg, deferred);
                }
            }).error(function(data, status) {
                nodeService.errHandler(status, errorMsg, deferred);
            });
            return deferred.promise;
        },

        /*
         * Determine if some media is an image, video or text snippet from the file extension
         * @param {string} filename the filename of the media to check
         * @return 'text', 'image' or 'externalmedia'
         */
        calculateMediaType: function (filename) {
            var type = 'image';
            if (filename.indexOf('.txt') === filename.length - 4) type = 'text';
            else if (filename.indexOf('.mp4') === filename.length - 4) type = 'video';
            else if (filename.indexOf('.flv') === filename.length - 4) type = 'video';
            else if (filename.indexOf('.mpeg') === filename.length - 4) type = 'video';
            else if (filename.indexOf('.wmv') === filename.length - 4) type = 'video';
            else if (filename.indexOf('.avi') === filename.length - 4) type = 'video';
            else if (filename.indexOf('.mpg') === filename.length - 4) type = 'video';
            // audio
            else if (filename.indexOf('.acc') === filename.length - 4) type = 'audio';
            else if (filename.indexOf('.wav') === filename.length - 4) type = 'audio';
            else if (filename.indexOf('.mp3') === filename.length - 4) type = 'audio';
            else if (filename.indexOf('.ogg') === filename.length - 4) type = 'audio';
            else if (filename.indexOf('.flac') === filename.length - 5) type = 'audio';
            return type;
        }
    }

    return mediaManager;

}]);
angular.module('easybuild.component.eb.pagination')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebServerPagination
 * @description Allow server side pagination on a data table
 * @restrict 'A'
 **/
.directive('ebServerPagination', function() {
    return {
        templateUrl: 'partials/server_pagination.html',
        restrict: 'A',
        transclude: true,
        replace: true,
        scope: {
            pagination: '='
        }
    }
});
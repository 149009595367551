angular.module('easybuild.component.eb.pagination')

.filter('range', function() {
    return function(input, paging) {
        var total = paging.totalPages;
        var pageIndex = paging.pageIndex;
        var pageVariance = paging.variance;
        total = parseInt(total);

        var pageMin = pageIndex - pageVariance;
        var pageMax = pageIndex + pageVariance;

        if (pageMin <= 0) {
            pageMax += -pageMin;
            pageMin = 0;
        }

        if (pageMax >= total - 1) {
            pageMin -= (pageMax - (total-1));
            if (pageMin < 0) pageMin = 0;
            pageMax = total - 1;
        }

        for (var i=pageMin; i<=pageMax; i++)
            input.push(i+1);

        return input;
    };
})
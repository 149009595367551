angular.module('easybuild.page.jobmanager.store', [])

.service('jobStoreManager',['dialog','jobManagerService' , function(dialog, jobManagerService){
    return {
        //storeType maybe create or update
        store: function($scope, storeType){

            if(typeof($scope.storeJobSent) === 'undefined'){
                $scope.storeJobSent = false;
            }

            if(!$scope.jobDetails.label){
                dialog.showDialog(dialog.TYPE.NOTIFY, '', _('store_job.no_job_name'));
            }
            else if(($scope.jobDetails.shareType.type == "groups") && ($scope.shareGroup == null) ){
                dialog.showDialog(dialog.TYPE.NOTIFY, '', _('store_job.no_group_selected'));
            }
            else if(($scope.jobDetails.shareType.type == "users") && ($scope.shareUser == null) ){
                dialog.showDialog(dialog.TYPE.NOTIFY, '', _('store_job.no_user_selected'));
            }
            else{
                if(!$scope.storeJobSent){
                    $scope.storeJobSent = true;
                    jobManagerService.setSharingSettings($scope.jobDetails, $scope.status, $scope.shareGroup, $scope.shareUser, $scope.shareGroupList);
                    jobManagerService.storeJobs($scope.jobDetails, $scope.materialFiles, storeType, $scope.materialFilesDiscardList);
                }
            }
        }
    }
}])
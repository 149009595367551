angular.module('easybuild.component.wc.doccontentform')

/**
 * @ngdoc directive
 * @name easybuild.directive.webcomponents:ebDocContentForm
 * @description Draw the build form
 * @restrict 'A'
 **/
.directive('ebDocContentForm', ['$compile', 'ebConfig', 'registry', 'galleryService', 'userSession', 'APP_EVENTS', 'utilitiesService', 'evBus', 'metadataService',
                        function($compile, ebConfig, registry, galleryService, userSession, APP_EVENTS, utilitiesService, evBus, metadataService) {
    return {
        restrict: 'A',
        link: function ($scope, element, attrs) {
            var templates = {
                'formFrameViewTmpl': '#formFrameViewTmpl',
                'tabbedFrameViewTmpl': '#tabbedFrameViewTmpl',
                'textPanelViewTmpl': '#textPanelViewTmpl',
                'textPanelViewTranslateModeTmpl': '#textPanelViewTranslateModeTmpl',
                'textFieldTmpl': '#textFieldTmpl',
                'textFieldTranslateModeTmpl': '#textFieldTranslateModeTmpl',
                'richTextFieldTmpl': '#richTextFieldTmpl',
                'imagePanelViewTmpl': '#imagePanelViewTmpl',
                'imageFieldTmpl': '#imageFieldTmpl',
                'videoFieldTmpl': '#videoFieldTmpl',
                'videoPanelViewTmpl': '#videoPanelViewTmpl',
                'audioFieldTmpl': '#audioFieldTmpl',
                'audioPanelViewTmpl': '#audioPanelViewTmpl'
            };

            // Pick the presentation mode
            var TargetFrame = Wave2.DocContent.UI.FormFrame;
            if (attrs['preferredLayout']) TargetFrame = Wave2.DocContent.UI[attrs['preferredLayout']];

            var job = registry.get("job");
            var document = registry.get("document");
            var groupIndex = registry.get("groupIndex") || 0;
            var articleIndex = registry.get("articleIndex") || 0;

            if(job && job.index && typeof job.index === "number" && job.index > 0){
                document = job.document;
            }

            var updateDocumentErrors = function(){
                var job = registry.get("jobs")[utilitiesService.getJobIndex()];
                if (ebConfig.get('easybuild.web.pagepergroup.enabled')) {
                    var pageIndex = utilitiesService.getFirstPageIndex(job, Wave2.JobGenerator.groupIndex);
                    if (job.pagePreviewMapping && job.document.documentId === job.pagePreviewMapping["documentId"] && job.pagePreviewMapping[pageIndex] && job.pagePreviewMapping[pageIndex].output) {
                        evBus.broadcast(Wave2.WcService.DOCUMENT_ERRORS_EVENT, job.pagePreviewMapping[pageIndex].output.stats.documentErrors);
                    }
                } else if ( job.output ) {
                    evBus.broadcast(Wave2.WcService.DOCUMENT_ERRORS_EVENT, job.output.stats.documentErrors);
                }
                if ( $scope && $scope.isBuildType && $scope.isBuildType('form') ) {
                    utilitiesService.updateFormError(job);
                }
            }

            var jobs = registry.get("jobs");

            var jobIndex = utilitiesService.getJobIndex();

            var articleKey = jobs[jobIndex].groupArticleMapping[groupIndex][articleIndex];

            var dynamicPreviewEnabled = utilitiesService.isDynamicPreviewEnabled();
            var triggerPreviewOnStart = utilitiesService.isTriggerPreviewOnStart();
            if ( triggerPreviewOnStart ){
                var pageIndex = utilitiesService.getFirstPageIndex(jobs[jobIndex], Wave2.JobGenerator.groupIndex);
                var previewStatus = undefined;
                if ( jobs[jobIndex].pagePreviewMapping){
                    if ( jobs[jobIndex].document.documentId === jobs[jobIndex].pagePreviewMapping["documentId"] ) {
                        previewStatus = jobs[jobIndex].pagePreviewMapping[pageIndex];
                    }else {
                        jobs[jobIndex].pagePreviewMapping = {};
                        registry.put('jobs', jobs);
                    }
                }
                if ( previewStatus) {
                    triggerPreviewOnStart = false;
                    evBus.broadcast(Wave2.JobGenerator.JOB_STATUS_CHANGED_EVENT, jobs[jobIndex].pagePreviewMapping[pageIndex]);
                }
            }

            // Start the doccontent form
            var docContentForm = new TargetFrame($scope.currentGroup.groupIndex, $scope.currentGroup.articleIndex, registry, articleKey, {
                triggerPreviewOnStart: triggerPreviewOnStart,
                dynamicPreviewEnabled: dynamicPreviewEnabled,
                richTextEditorControls: ebConfig.get('easybuild.web.rte.controls'),
                ckEditorLicenseKey:  ebConfig.get('easybuild.web.rte.ckEditorLicenseKey'),
                ckEditorFontColor:  ebConfig.get('easybuild.web.rte.fontColor'),
                videoCroppingDestinationTmpl: 'cms://Easybuild/temp/'+encodeURIComponent(userSession.username)+'/{{uuid}}_'+(1+$scope.currentGroup.groupIndex) + '_' + (1+$scope.currentGroup.articleIndex),
                showTranslateFields: false,
                showDeleteFrame: document.format == 'indd',
                showMoveFrame: document.format !== 'flash' && document.format !== 'ae',
                useDatePicker: ebConfig.get('easybuild.web.datepicker'),
                groupOnly: ebConfig.get('easybuild.web.preview.currentgrouponly'),
                assocIdsOverride: metadataService.getMetaField('assocIdsOverride'),
                useDocumentPreview:  ebConfig.get('easybuild.web.buildmedia.useDocumentPreview')
            });
            var postPopulateElement = function (targetContainer) {
                targetContainer.html('');
                $compile(this)($scope).appendTo(targetContainer);
                // cropping not allow on html templates as we only put a url to the image.
                if ( document.format === 'html') {
                    targetContainer.find('.eb-option-btn.button.crop:not(.disabled)').addClass('disabled');
                }
            };
            if (docContentForm.initialise()) {
                docContentForm.populateElement(element, templates, postPopulateElement );
                updateDocumentErrors();
            }

            var otherFields = [];

            if ( ebConfig.get('easybuild.web.validate.all')) {
                function addItemsToValidate(itemsToAdd, type) {
                    if (itemsToAdd) {
                        jQuery.each(itemsToAdd, function (index, fieldData) {
                            fieldData.frameType = "externalmedia";
                        });
                        otherFields = otherFields.concat(itemsToAdd);
                    }
                }

                // validate all on first scan.
                jQuery.each(jobs[jobIndex].groupArticleMapping, function (gIndex, group) {
                    jQuery.each(group, function (aIndex, article) {
                        if (article !== articleKey) {
                            var tempArticle = registry.get(article);
                            addItemsToValidate(tempArticle.externalMediaItems, "externalmedia");
                            addItemsToValidate(tempArticle.imageItems, "image");
                            addItemsToValidate(tempArticle.flashImageItems, "flashimage");
                            addItemsToValidate(tempArticle.textItems, "text");
                            addItemsToValidate(tempArticle.flashTextItems, "flashtext");
                        }
                    });
                });
                Wave2.DocContent.Commands.validateAllFields(otherFields, docContentForm.eventBus, docContentForm.commandHandler, false, docContentForm.options);
            }


            $scope.$on(Wave2.ArticleManager.ARTICLE_READY_EVENT, function (ev, articleKey) {
                // Switching to another article, redraw the form...
                if (docContentForm.reload($scope.currentGroup.groupIndex, $scope.currentGroup.articleIndex, registry, articleKey)) {
                    docContentForm.populateElement(element, templates, postPopulateElement);
                    updateDocumentErrors();
                }
            });

            $scope.$on(APP_EVENTS.doccontentToggleTranslateFields, function (ev) {

                var areTranslateFieldsCurrentlyDisplayed = docContentForm.options['showTranslateFields'];

                if(areTranslateFieldsCurrentlyDisplayed) {
                    evBus.broadcast(APP_EVENTS.resetForm);
                } else {
                    docContentForm = new TargetFrame($scope.currentGroup.groupIndex, $scope.currentGroup.articleIndex, registry, articleKey, {
                        triggerPreviewOnStart: true,
                        dynamicPreviewEnabled: !ebConfig.get('easybuild.web.fieldhighlight.enabled') || registry.get('document').format !== 'indd' /* Turn off built-in preview triggers */,
                        richTextEditorControls: ebConfig.get('easybuild.web.rte.controls'),
                        richTextEditorTranslationControls: ebConfig.get('easybuild.web.rte.translation.controls'),
                        ckEditorLicenseKey:  ebConfig.get('easybuild.web.rte.ckEditorLicenseKey'),
                        ckEditorFontColor:  ebConfig.get('easybuild.web.rte.fontColor'),
                        videoCroppingDestinationTmpl: 'cms://Easybuild/temp/'+encodeURIComponent(userSession.username)+'/{{uuid}}_'+(1+$scope.currentGroup.groupIndex) + '_' + (1+$scope.currentGroup.articleIndex),
                        showTranslateFields: !areTranslateFieldsCurrentlyDisplayed,
                        useDatePicker: ebConfig.get('easybuild.web.datepicker'),
                        groupOnly: ebConfig.get('easybuild.web.preview.currentgrouponly'),
                        assocIdsOverride: metadataService.getMetaField('assocIdsOverride'),
                        useDocumentPreview:  ebConfig.get('easybuild.web.buildmedia.useDocumentPreview')
                    });

                    if (docContentForm.reload($scope.currentGroup.groupIndex, $scope.currentGroup.articleIndex, registry, articleKey)) {
                        docContentForm.populateElement(element, templates, function (targetContainer) {
                            targetContainer.html('');
                            $compile(this)($scope).appendTo(targetContainer);
                        });
                    }
                }
            });
        }
    }
}]);
angular.module('easybuild.component.eb.navbar')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebNavbarTop
 * @description Draw the EasyBuild top dynamic navigation toolbar
 * @restrict 'A'
 * @scope
 **/
.directive('ebNavbarTop', ['$location', '$timeout', 'i18n', 'evBus', 'APP_EVENTS', 'registry', 'utilitiesService', function($location, $timeout,i18n, evBus, APP_EVENTS, registry, utilitiesService) {
    return {
        restrict: 'A',
        replace: true,
        templateUrl: 'partials/navbar_top.html',
        scope: {
            'selection': '=',
            'products': '=',
            'types': '=',
            'documents': '=',
            'search': '=',
            'storeEnabled': '@',
            'retrieveEnabled': '@',
            'logout': '&',
            'newJob': '&',
            'updateTypes': '&',
            'updateDocs': '&',
            'updateBatchOutputGrid': '&',
            'format': '=',
            'toggleMediaManager': '&',
            'showMediaManager': '=',
            'easybuild' : '=',
            'job' : '=',
            'batches' : '=', //list of all batches
            'mode': '@'
        },
        link: function(scope) {

            scope.i18n = i18n;

            utilitiesService.getHasLightbox(scope);

            var query = $location.search();
            scope.isJump = query && query['jump'];

            // Make the navbar stick when the header disappears
            function hasScrolled() {
                var st = jQuery(window).scrollTop();

                var logoElement = angular.element(".eb-logo-header");

                if (st < 0) return;

                if ( (st < 90) && (logoElement.is(":visible")) ){
                    var pos = 90 - st;
                    jQuery('.navbar-fixed-top').css({'top' : pos + 'px'});
                }
                else {
                    jQuery('.navbar-fixed-top').css({'top' : '0px'});
                }
            }
            jQuery(window).scroll(hasScrolled);
            jQuery(window).resize(hasScrolled);
            $timeout(hasScrolled);

            scope.batchButtonClick = function(){
                if($location.path() == '/batch/new'){
                    evBus.broadcast(APP_EVENTS.resetBatch);
                }
                else {
                    $location.path('/batch/new');
                }
            }

            if ( scope.mode == 'build-design-lightbox' || scope.mode == 'build-form-lightbox'){
                var launchEvents = registry.get('launchEvents');
                if ( launchEvents && launchEvents.lightbox_batchname ){
                    scope.batchDetails = {name: launchEvents.lightbox_batchname,
                        job: launchEvents.lightbox_currentjob};
                }

            }

        }
    };
}]);
/**
 * Load and inject the messages component
 * This is injected as a provider so it is immediately available during page rendering
 */
angular.module('easybuild.shared.locale', [], ['$provide', function ($provide) {
    var getQueryString = function() {
        var key = false, res = {}, itm = null;
        // get the query string without the ?
        var qs = location.search.substring(1);
        // check for the key as an argument
        if (arguments.length > 0 && arguments[0].length > 1)
            key = arguments[0];
        // make a regex pattern to grab key/value
        var pattern = /([^&=]+)=([^&]*)/g;
        // loop the items in the query string, either
        // find a match to the argument, or build an object
        // with key/value pairs
        while (itm = pattern.exec(qs)) {
            if (key !== false && decodeURIComponent(itm[1]) === key)
                return decodeURIComponent(itm[2]);
            else if (key === false)
                res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
        }

        return key === false ? res : null;
    };

    var externalLaunch = getQueryString('externalLaunch');
    if (externalLaunch === "true") {
        var language = getQueryString('language');
        if (language && language.length > 0) {
            localStorage.setItem('language', language);
        } else {
            localStorage.removeItem('language');
        }

        var dateformat = getQueryString('dateformat');
        if (dateformat && dateformat.length > 0) {
            localStorage.setItem('dateformat', dateformat);
        } else {
            localStorage.removeItem('dateformat');
        }
    }

    language = localStorage.getItem('language');

    Wave2.Messages.initialise(language);
    var messages = Wave2.Messages.getAllMessages();
    $provide.value('i18n', messages);

}]);
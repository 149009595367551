angular.module('easybuild.page.build')

/**
 * Forms based build page
 */
.controller('BuildFormCtrl', ['APP_EVENTS', '$scope', '$log', '$compile', '$sce', 'ebConfig', 'evBus', 'registry', 'buildService', 'pluginScripts', 'galleryService', 'articleService', 'bundleService', 'i18n',
                    function (APP_EVENTS, $scope, $log, $compile, $sce, ebConfig, evBus, registry, buildService, pluginScripts, galleryService, articleService, bundleService, i18n) {

    // Draw the groups and articles selector
    $scope.groupsArticles.populateElement('#groupsarticles_container', {
        'groupsArticlesTmpl': '#groupsArticlesTmpl'
    }, function(targetContainer) {
        targetContainer.html('');
        $compile(this)($scope).appendTo(targetContainer);
    });

    if (ebConfig.get('easybuild.web.opi.enabled')) {
        // Enable the script-based OPI
        pluginScripts.setScript('W2_OPI.jsx', 'prePlaceImage', {
            hotswap: true
        });
    }

    PageValidator.start(registry, {}).requires("doccontent");
    DoccontentValidator.start(registry);

    var validationBox;
    validationBox = new ValidationMessages.ValidationBox(registry, PageValidator.SHOW_VALIDATIONS);
    if (validationBox.initialise()) {
        validationBox.populateElement(jQuery("#page_validation"));
    }

    validationBox = new ValidationMessages.ValidationBox(registry, DoccontentValidator.SHOW_VALIDATIONS);
    if (validationBox.initialise()) {
        var validationTemplates = {"validationTmpl" : "#validationTmpl"};
        validationBox.populateElement(jQuery("#content_validation"),validationTemplates);
    }

    $scope.$on(Wave2.GroupsArticles.ARTICLE_CHANGED_EVENT, function (ev, articleIndex) {
        $scope.currentGroup.articleIndex = parseInt(articleIndex);
        articleService.getManager().changeArticle(registry, $scope.currentGroup.groupIndex, articleIndex, $scope.jobIndex);
    });

    if(registry.get("job")){
        $scope.$on(Wave2.DocContent.FIELD_CHANGED_EVENT, function(ev, fieldData){
            bundleService.updateEditedFlag(fieldData);
        });
    }

    if (ebConfig.get('easybuild.web.mediamanager.enabled')) {
        $scope.$on(Wave2.DocContent.FIELD_SELECT_START_EVENT, function (ev, fieldData) {
            $scope.toggleMediaManager(true);
            evBus.broadcast(APP_EVENTS.mediaManagerChangeField, fieldData);
        });
        $scope.$on(Wave2.DocContent.FIELD_SELECT_COMPLETE_EVENT, function () {
            $scope.toggleMediaManager(false);
            evBus.broadcast(APP_EVENTS.mediaManagerChangeField, null);
        });
    }
    if (ebConfig.get('easybuild.web.externalassets.enabled')) {
        $scope.showAssetManager = false;

        // Bind to the postMessage event
        window.addEventListener("message", function(ev) {
            if (ev.data && ev.data.content) {
                // Redirect result through EasyBuild configured proxy
                var url = ev.data.content;

                $scope.$emit(Wave2.DocContent.FIELD_SELECT_COMPLETE_EVENT, {
                    type: $scope.targetFieldInfo.type,
                    id: $scope.targetFieldInfo.id,
                    content: url
                });
            }
        }, false);

        // Add our custom media picking action
        $scope.$on(Wave2.DocContent.FIELD_SELECT_START_EVENT, function (ev, fieldInfo) {
            $scope.targetFieldInfo = fieldInfo;

            // Show the iframe
            $scope.showAssetManager = true;

            // Load the assets page through the proxy (to avoid a mixed source content error)
            var targetUrl = '/assets/assets.html';
            $scope.assetManagerUrl = $sce.trustAsResourceUrl(targetUrl);
        });

        $scope.$on(Wave2.DocContent.FIELD_SELECT_COMPLETE_EVENT, function () {
            // Hide the asset manager iframe after selection
            $scope.showAssetManager = false;
        });
    }

    if (ebConfig.get('easybuild.web.fieldhighlight.enabled') && registry.get('document').format === 'indd') {

        // Feature to highlight the field in the preview that is focused in the form

        $scope.$on(Wave2.DocContent.FIELD_FOCUS_ENTERED_EVENT, function (ev, fieldData) {
            // Send all the available field identification to the plugin script
            pluginScripts.setScript('W2_HighlightSelection.jsx', 'postPlaceText', {
                'focus': 'true',
                'targettype': fieldData.frameType,
                'targetgroupid': $scope.currentGroup.groupIndex + 1,
                'targetarticleid': $scope.currentGroup.articleIndex + 1,
                'targetitemid': fieldData.item,
                'targetname': ""
            });
            // Kick off a generation so the selection is made visible
            Wave2.JobGenerator.start(registry, ebConfig.get('easybuild.web.preview.currentgrouponly'));
        });

        $scope.$on(Wave2.DocContent.FIELD_FOCUS_LEFT_EVENT, function (ev, fieldData) {
            // Send all the available field identification to the plugin script
            pluginScripts.setScript('W2_HighlightSelection.jsx', 'postPlaceText', {
                'focus': 'false',
                'targettype': fieldData.frameType,
                'targetgroupid': $scope.currentGroup.groupIndex + 1,
                'targetarticleid': $scope.currentGroup.articleIndex + 1,
                'targetitemid': fieldData.item,
                'targetname': ""
            });
            // Kick off a generation so the selection is made visible
            Wave2.JobGenerator.start(registry, ebConfig.get('easybuild.web.preview.currentgrouponly'));
        });


    }

    $scope.$on(Wave2.WcService.DOCUMENT_ERRORS_EVENT, function (ev, errors) {
        jQuery(".w2Errors").removeClass("w2Errors");
        jQuery(".w2-show-errors").html('');
        if (errors && errors.length > 0) {
            var jobIndex = Wave2.ArticleManager.getJobIndex();
            var groupIndex = registry.get("groupIndex") || 0;
            var articleIndex = registry.get("articleIndex") || 0;
            errors.forEach(function(error) {
                if ( error.location ) {
                    var field = error.location.split(":");
                    var errorGroupIndex = parseInt(field[1]) - 1;
                    var errorArticleIndex = parseInt(field[2]) - 1;
                    if (errorGroupIndex === groupIndex && articleIndex === errorArticleIndex) {
                        var articleKey = Wave2.ArticleManager.getArticleKey(registry, errorGroupIndex, errorArticleIndex, jobIndex);
                        if (articleKey) {
                            var article = registry.get(articleKey);
                            if (article) {
                                var itemName = field[0].toLowerCase();
                                switch (field[0].toLowerCase()) {
                                    case 'flashtext':
                                        itemName = 'flashText';
                                        break;
                                    case 'flashimage':
                                        itemName = 'flashImage';
                                        break;
                                    case 'externalmedia':
                                        itemName = 'externalMedia';
                                        break;
                                }
                                var items = article[itemName + "Items"];
                                if (items && items.length > 0) {
                                    items.forEach(function (item, index) {
                                        if (item.item === parseInt(field[3])) {
                                            var fieldType = item.frameType || item.type || item.mediaType || field[0];
                                            if ( fieldType ){
                                                fieldType = fieldType.toLowerCase();
                                            }
                                            var fieldId = item.id;
                                            if ( !fieldId || typeof fieldId == 'number'){
                                                fieldId = "field_" + fieldType + "_" + (index+1);
                                            }
                                            var parent = jQuery('#' + fieldId).closest("div." + fieldType.replace('flash', '') + "_field");
                                            parent.addClass('w2Errors');
                                            var w2Error = parent.find(".w2-show-errors");
                                            var text = w2Error.html();
                                            if (text && text.length > 0) {
                                                text += "<br>"
                                            }
                                            text += i18n.error[error.type];
                                            w2Error.html(text);
                                            item.errorText = text;
                                        }
                                    });
                                }
                            }
                        }
                    }
                }
            });
        }
    });
}]);
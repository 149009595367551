angular.module('easybuild.page.login')

/**
  * Authenticate with the backend and initialise a session
  */
.controller('LoginCtrl', ['$scope', '$rootScope', '$window', '$log', 'AUTH_EVENTS', 'evBus', 'authService', 'registry',
                    'i18n',
                    function ($scope, $rootScope, $window, $log, AUTH_EVENTS, evBus, authService, registry, i18n) {
    $rootScope.showProductLogo = false;
    $scope.credentials = {
        username: '',
        password: '',
        remember: false
    };

    $scope.selection = {
        product: registry.get('product'),
        type: registry.get('type'),
        document: registry.get('document')
    };

    $scope.message = {
        loginFailed: false
    };

            $scope.login = function (credentials) {

                authService.login(credentials)
                    .then(function () {
                        // Hide the login failed message and broadcast success
                        $scope.message.loginFailed = false;

                        if ($scope.credentials.remember) {
                            authService.rememberMe($scope.credentials);
                        }
                    })
                    .then(function () {
                        return authService.userUserGroups($scope.credentials.username, false);
                    })
                    .then(function (userGroups) {
                        localStorage.removeItem('userGroups');
                        localStorage.setItem('userGroups', JSON.stringify(userGroups));
                        evBus.broadcast(AUTH_EVENTS.loginSuccess);
                    })
                    .catch(function(reasonRejected) {
                        if(reasonRejected.config && reasonRejected.config.url === Wave2.WcService.BASE_SERVER_URL + 'user/login') {
                            // Show the login failed message and broadcast failure
                            $scope.message.loginFailed = true;
                            evBus.broadcast(AUTH_EVENTS.loginFailed);
                        } else {
                            // Error retrieving/setting user's usergroups
                            if(!$scope.message.loginFailed) {
                                authService.logout();
                            }
                            throw new Error(i18n.error.error + reasonRejected);
                        }
                    });

            };

    evBus.on(AUTH_EVENTS.loginSuccess, function() {
        // Redirect the user to the target page
        $window.location.reload();
    });

    authService.autoLogin().then(function (credentials) {
        $scope.credentials = credentials;
    });
}])


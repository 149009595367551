'use strict';

angular.module('easybuild.shared.utilities', [])

.service('utilitiesService', ['registry', 'i18n', 'ebConfig', '$cookieStore', 'userSession', 'dialog', 'evBus', '$http', function(registry, i18n, ebConfig, $cookieStore, userSession, dialog, evBus, $http) {
    this._getDynamicPreviewEnabled = function(document){
        var dynamicPreviewEnabled = ebConfig.get('easybuild.web.preview.dynamic');
        if  (!dynamicPreviewEnabled){
            dynamicPreviewEnabled = false;
        }
        switch(document.dynamicPreview){
            case 2:
            {
                dynamicPreviewEnabled = false;
                break;
            }
            case 1:
            {
                dynamicPreviewEnabled = true;
                break;
            }
            default:
            {
                break;
            }
        }
        return dynamicPreviewEnabled;
    };

    this.isDynamicPreviewEnabled = function(){
        var document = registry.get("document");
        var dynamicPreviewEnabled = this._getDynamicPreviewEnabled(document);
        if(ebConfig.get('easybuild.web.fieldhighlight.enabled') && document.format === 'indd') {
            dynamicPreviewEnabled = true;
        }
        return dynamicPreviewEnabled;
    };

    this.isTriggerPreviewOnStart = function(){
        return this._getDynamicPreviewEnabled(registry.get("document"));
    };

    this.round = function(value){
        return Math.round(value*100)/100;
    };

    this.getNewLocation = function(location){
        if (location.toLowerCase().indexOf("http://") === 0 || location.toLowerCase().indexOf("https://") === 0 || location.toLowerCase().indexOf("/") === 0 ){
            return location;
        }
        var asBaseUrls = document.head.getElementsByTagName('base');
        if ( asBaseUrls && asBaseUrls.length > 0){
            var asBaseUrl =  asBaseUrls[0];
            if ( asBaseUrl && asBaseUrl.href ){
                return asBaseUrl.href + location;
            }
        }
        return location;
    };

    this.setResizeUnitPrint = function(value){
        $cookieStore.put('units', value, userSession.getOptions());
    };

    this.getResizeUnitPrint = function(){
        var units = $cookieStore.get('units');
        if (!units ) {
            units = ebConfig.get('easybuild.web.resize.unit.print');
        }
        return units;
    };

    this.getJobIndex = function(job){
        if ( !job ){
            job = registry.get("job");
        }
        var jobIndex = 0;
        if(job && job.index && typeof job.index === "number"){
            jobIndex = job.index;
        }
        return jobIndex;
    };

    this.isLightboxBuild = function(){
        var launchEvents = registry.get("launchEvents");
        if (launchEvents && launchEvents.lightbox) {
            return true;
        }
        return false;
    };

    this.getDocumentIdFromSubJob = function(subJob){
        if ( subJob && subJob.builtJobXML) {
            var publication = $(jQuery.parseXML(subJob.builtJobXML)).find('publication');
            var docId = publication.attr('name');
            if (!docId || docId.length == 0) {
                if (publication.attr('product') && publication.attr('type') && publication.attr('document')) {
                    docId = publication.attr('product') + '@@' + publication.attr('type') + '@@' + publication.attr('document');
                }
            }
            return docId;
        }
        return undefined;
    };


    this.displayCopyText = function(text) {
        dialog.showDialog(dialog.TYPE.NOTIFY, '',  i18n.copy.title + text);
    };

    this.fallbackCopyTextToClipboard = function(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            if ( !successful ){
                this.displayCopyText(text);
            }
        } catch (err) {
            this.displayCopyText(text);
        }

        document.body.removeChild(textArea);
    };

    this.copyTextToClipboard = function(text) {
        var self = this;
        if (!navigator.clipboard) {
            self.fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
        }, function (err) {
            self.displayCopyText(text);
        });
    };

    this.getFirstPageIndex = function(job, groupId) {
        return this.getLowestItem(this.getPageIndexes(job, groupId));
    };

    this.getLowestItem = function(items){
        var self = this;
        if ( Array.isArray(items)){
            var ret = -1;
            items.forEach(function(item){
                var temp = self.getLowestItem(item);
                if (ret === -1 || ret > temp){
                    ret = temp;
                }
            });
            return ret;
        } else {
            return items;
        }
    }

    this.getPageIndexes = function(job, groupId) {
        var ret = 0;
        if ( job && job.document && job.document.groupPageMap && Array.isArray(job.document.groupPageMap)){
            job.document.groupPageMap.forEach(function(mapping){
                if ( mapping.group === groupId+1){
                    ret = mapping.pageIndexes;
                }
            });
        }
        if ( Array.isArray(ret)){
            return ret;
        }
        return [ret];
    };

    this.updateFormError = function(job){
        if ( ebConfig.get('easybuild.web.documentErrors.enabled')) {
            var documentErrors = [];
            if (ebConfig.get('easybuild.web.pagepergroup.enabled')) {
                if (job.pagePreviewMapping) {
                    Object.entries(job.pagePreviewMapping).forEach(function (pageStatus) {
                        if (pageStatus && pageStatus.length >= 2 && pageStatus[1] && pageStatus[1].output && pageStatus[1].output.stats && pageStatus[1].output.stats.documentErrors && pageStatus[1].output.stats.documentErrors.length > 0) {
                            documentErrors = documentErrors.concat(pageStatus[1].output.stats.documentErrors);
                        }
                    });
                }
            } else {
                if (job.output.stats && job.output.stats.documentErrors && job.output.stats.documentErrors.length > 0) {
                    documentErrors = documentErrors.concat(job.output.stats.documentErrors);
                }
            }
            var groups = registry.get('groups');
            if (groups && groups.length > 1) {
                groups.forEach(function (name, index) {
                    var valid = true;
                    if (documentErrors && documentErrors.length > 0) {
                        documentErrors.forEach(function (error) {
                            if (error.location) {
                                var field = error.location.split(":");
                                var errorGroupIndex = parseInt(field[1]) - 1;
                                if (errorGroupIndex === index) {
                                    valid = false;
                                }
                            }
                        });
                    }
                    evBus.broadcast(DoccontentValidator.FORM_VALIDATION_CHANGED_EVENT, {
                        "errors": [{
                            "code": "group_errors",
                            "field": name,
                            "valid": valid
                        }], "showErrors": false
                    });
                });
            } else {
                var valid = !(documentErrors && documentErrors.length > 0);
                evBus.broadcast(DoccontentValidator.FORM_VALIDATION_CHANGED_EVENT, {
                    "errors": [{
                        "code": "document_errors",
                        "valid": valid
                    }], "showErrors": false
                });
            }
        }
    };

    this.getTimeString = function(timeInMilliseconds){
        var waitTime = Math.round(timeInMilliseconds/1000);
        var minutes = Math.floor(waitTime/60);
        var seconds = waitTime%60;
        var mainTimeString = "minute";
        var secondTimeString = "seconds";
        if ( minutes && minutes >= 60 ) {
            mainTimeString = "hour";
            secondTimeString = "minutes";
            seconds = minutes % 60;
            minutes = Math.floor(minutes / 60);
        }

        var ret = "";
        if ( minutes && minutes > 0 ){
            if ( minutes > 1 ){
                mainTimeString += "s";
            }
            ret = _("time." + mainTimeString, minutes);
            if ( seconds){
                ret += _("time.and");
            }
        }

        if (seconds || !minutes || minutes === 0){
            ret += _("time." + secondTimeString, seconds);
        }

        return ret;
    };

    this.getTime = function(timeInMilliseconds){
        var waitTime = Math.round(timeInMilliseconds/1000);
        var minutes = Math.floor(waitTime/60);
        var seconds = waitTime%60;
        var hours = 0;
        if ( minutes && minutes >= 60 ) {
            minutes = minutes%60;
            hours = Math.floor(minutes / 60);
        }

        return _("time.time", String(hours).padStart(2, '0'), String(minutes).padStart(2, '0'), String(seconds).padStart(2, '0'));
    };

    this.getJobStatusString = function(status){
        var ret = _("job_status." + status);
        if ( !ret ){
            ret = status;
        }
        return ret;
    };

    this.getHasLightbox = function(scope){
        var self = this;
        if ( self.hasLightbox === undefined ) {
            $http.get(Wave2.WcService.BASE_SERVER_URL + 'api/lightbox').then(function (res) {
                self.hasLightbox = res.data && res.data.length > 0;
                scope.hasLightbox = self.hasLightbox;
            });
        }else {
            scope.hasLightbox = self.hasLightbox;
        }
    };

    return this;
}]);
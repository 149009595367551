angular.module('easybuild.page.batch')

.directive('ebBatchDetails', ['i18n', '$http', 'utilitiesService', 'ebConfig', 'evBus', 'APP_EVENTS',
    function(i18n, $http, utilitiesService, ebConfig, evBus, APP_EVENTS){
        return {
            restrict: 'A',
            scope: {
                ebBatchDetails: '@',
                ebLightbox: '@'
            },
            templateUrl: function(elem, attr) {
                var type = attr.ebBatchDetailsType;
                if (!type || type.length == 0) {
                    type = "details";
                }
                return 'partials/batch_' + type + '.html';
            },
            compile: function (element, attr) {
                var getNumber = function(item, def){
                    var ret = def;
                    if (!isNaN(item) && item > 0) {
                        ret = parseInt(item);
                    }
                    return ret;
                };

                return {
                    'pre': function (scope) {
                        scope.i18n = i18n;

                        scope.batchId = scope.ebBatchDetails;
                        if (scope.ebLightbox && scope.ebLightbox.length > 0) {
                            scope.hasLightbox = scope.ebLightbox.toLowerCase() == "true";
                        } else {
                            utilitiesService.getHasLightbox(scope);
                        }
                        scope.competed = 0;
                        scope.processEstimate = "";
                        scope.processEstimateTime = "";

                        scope.updateDetails = function () {
                            if (scope.batchId && scope.batchId.length > 0) {
                                var url = Wave2.WcService.BASE_SERVER_URL + 'api/batches/' + scope.batchId;
                                $http.get(url).success(function (batchStatus) {
                                    scope.batchStatus = batchStatus;
                                    if ( batchStatus) {
                                        scope.submittingJobs = !batchStatus.jobTokens || batchStatus.jobTokens.length == 0;
                                        scope.processEstimate = utilitiesService.getTimeString(batchStatus.processEstimate);
                                        scope.processEstimateTime = utilitiesService.getTime(batchStatus.processEstimate);
                                        scope.competed = batchStatus.cancelled + batchStatus.failed + batchStatus.finished;

                                        if (batchStatus.queued == 0 && batchStatus.processing == 0 && scope.timer && scope.competed != 0) {
                                            // stop timer
                                            clearInterval(scope.timer);
                                            scope.timer = null;
                                            evBus.broadcast(APP_EVENTS.batchFinished);
                                        }
                                    } else {
                                        scope.submittingJobs = true;
                                    }
                                }).error(function (error) {
                                    // stop timer
                                    clearInterval(scope.timer);
                                    scope.timer = null;
                                    // Show Error.
                                });
                            }
                        };
                        scope.updateDetails();

                    },
                    'post': function (scope) {
                        // Start timer.
                        scope.timer = setInterval(scope.updateDetails, ebConfig.get('easybuild.web.batch.poll'));
                    }
                }
            }
        }
    }])
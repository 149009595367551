/**
 * This directive handles the action overlay crop button click
 */
angular.module('pages.build.move.directive', [])
    .directive('ebMoveManager', ['i18n','$log', 'evBus', 'APP_EVENTS', 'ebConfig', 'utilitiesService','unitConvertor', 'registry',
        function(i18n,$log, evBus, APP_EVENTS, ebConfig, utilitiesService,unitConvertor, registry){
        return {
            restrict : 'A',
            scope: {
            },
            templateUrl: 'partials/move.html',



            controller: ['$scope', function($scope) {
                $scope.units = utilitiesService.getResizeUnitPrint();
                $scope.i18n = i18n;

                jQuery('#moveModal').on('hidden.bs.modal', function (e) {
                    if ( $scope.oldMove ) {
                        $scope.fireMove($scope.oldMove);
                    }
                });

                $scope.$on(Wave2.DocContent.FIELD_MOVE_START_EVENT,function(ev, data) {
                    if (i18n.move_tool.heading && i18n.move_tool.heading.length > 0) {
                        var heading = _("move_tool.heading", data.fieldInfo.name, data.fieldInfo.caption);
                        jQuery('#moveModal').find('.move-title').html(heading);
                    }
                    var movedLinked = jQuery('#moveModal').find('.move-linked');
                    var ul = movedLinked.find('ul');
                    ul.html("");
                    if  (data.linkedNames && data.linkedNames.length > 0){
                        data.linkedNames.forEach(function(item){ul.append($("<li>" + _("move_tool.linked_row", item.name, item.caption) + "</li>"))});
                        movedLinked.show();
                    }else{
                        movedLinked.hide();
                    }

                    jQuery('#moveModal #move_x').val(0);
                    jQuery('#moveModal #move_y').val(0);
                    jQuery('#moveModal #move_rotate').val(0);


                    var document = registry.get('document');
                    if ( document.format === 'indd') {
                        var unit = utilitiesService.getResizeUnitPrint();
                        var width = 0;
                        var height = 0;
                        var size = registry.get('size');
                        if (size && size.width > 0 && size.height > 0 ) {
                            width = size.width;
                            height = size.height;
                        }else {
                            if(document.scales && document.scales.length > 0) {
                                width = document.scales[0].width;
                                height = document.scales[0].height;
                            }
                        }
                        if (height) {
                            height = unitConvertor.getFormatter(unit).call(undefined, parseInt(height));
                        }

                        if (width) {
                            width = unitConvertor.getFormatter(unit).call(undefined, parseInt(width));
                        }


                        if ( height > 0 ){
                            jQuery('#moveModal #move_y').attr('max', height );
                            jQuery('#moveModal #move_y').attr('min', height*-1 );
                        }
                        if ( width > 0 ){
                            jQuery('#moveModal #move_x').attr('max', width );
                            jQuery('#moveModal #move_x').attr('min', width*-1 );
                        }
                    }


                    if ( data && data.fieldInfo) {
                        var unit = utilitiesService.getResizeUnitPrint();
                        $scope.oldMove = {
                            'movex' : 0,
                            'movey': 0,
                            'moverotate': 0
                        };
                        var isSet = false;
                        if (data.fieldInfo.movex !== null && data.fieldInfo.movex !== undefined) {
                            $scope.oldMove.movex = data.fieldInfo.movex;
                            jQuery('#moveModal #move_x').val(unitConvertor.getFormatter(unit).call(undefined, data.fieldInfo.movex));
                        }
                        if (data.fieldInfo.movey !== null && data.fieldInfo.movey !== undefined) {
                            $scope.oldMove.movey = data.fieldInfo.movey;
                            jQuery('#moveModal #move_y').val(unitConvertor.getFormatter(unit).call(undefined, data.fieldInfo.movey));
                        }
                        if (data.fieldInfo.moverotate !== null && data.fieldInfo.moverotate !== undefined) {
                            $scope.oldMove.moverotate = data.fieldInfo.moverotate;
                            jQuery('#moveModal #move_rotate').val(data.fieldInfo.moverotate);
                        }
                    }
                    jQuery('#moveModal').modal();
                    if (!$("#moveModal .modal-dialog").is(":ui-draggable") ) {
                        $("#moveModal .modal-dialog").draggable({
                            handle: ".modal-header"
                        });
                    }
                    $scope.FieldData = data;

                });

                $scope.fireMove = function(move){
                    $scope.FieldData.move = move;
                    evBus.broadcast(Wave2.DocContent.FIELD_MOVE_COMPLETE_EVENT, $scope.FieldData);
                };

                $scope.applyMove = function(){

                    $scope.doUpdate(false);
                    $scope.oldMove = undefined;
                    $scope.closeModal();
                };

                $scope.closeModal = function(){
                    jQuery('#moveModal').modal('hide');
                };

                $scope.doUpdate = function(preview){
                    var move = {};
                    var unit = utilitiesService.getResizeUnitPrint();
                    move.movex = unitConvertor.getParser(unit).call(undefined, jQuery('#moveModal #move_x').val());
                    move.movey = unitConvertor.getParser(unit).call(undefined, jQuery('#moveModal #move_y').val());
                    move.moverotate = jQuery('#moveModal #move_rotate').val();
                    if ( preview) {
                        move.preview = preview;
                    } else {
                        if ($scope.oldMove.movex != move.movex || $scope.oldMove.movey != move.movey || $scope.oldMove.moverotate != move.moverotate){
                            move.forceChange = true;
                        }
                    }
                    $scope.fireMove(move);
                };

                $scope.doPreview = function(){
                    $scope.doUpdate(true);
                };

                $scope.maxDecibels = function(first, second){
                    var countDecimals = function (value) {
                        if(Math.floor(value) === value) return 0;
                        return value.toString().split(".")[1].length || 0;
                    }
                    return Math.min(9,Math.max(countDecimals(first), countDecimals(second)));

                }

                $scope.stepUp = function(name){

                    var item = document.getElementById(name);
                    var step = Number(item.getAttribute("step"));
                    var max = item.getAttribute("max");
                    var oldValue = Number(item.value)
                    var newValue = (oldValue + step).toFixed(this.maxDecibels(oldValue, step));
                    if (max && max.length > 0 && newValue > Number(max) ){
                        newValue = max;
                    }
                    item.value = newValue;
                };

                $scope.stepDown = function(name){

                    var item = document.getElementById(name);
                    var step = Number(item.getAttribute("step"));
                    var min = item.getAttribute("min");
                    var oldValue = Number(item.value)
                    var newValue = (oldValue - step).toFixed(this.maxDecibels(oldValue, step));
                    if (min && min.length > 0 && newValue < Number(min) ){
                        newValue = min;
                    }
                    item.value = newValue;
                };

                $scope.itemBlur = function(name){
                    var item = document.getElementById(name);
                    var min = item.getAttribute("min");
                    var max = item.getAttribute("max");
                    var oldValue = Number(item.value);
                    var newValue = oldValue;
                    if (min && min.length > 0 && newValue < Number(min) ){
                        newValue = min;
                    } else if ( max && max.length > 0 && newValue > Number(max)){
                        newValue = max;
                    }
                    item.value = newValue;
                };

                evBus.on(APP_EVENTS.unitsChanged, function(ev, scope) {
                    $scope.units = utilitiesService.getResizeUnitPrint();
                });
            }]
        }
    }]);
angular.module('easybuild.component.eb.margins')

.directive('ebMargins', ['registry', 'i18n', 'evBus', 'utilitiesService', 'APP_EVENTS', 'metadataService', 'ebConfig',
    function(registry, i18n, evBus, utilitiesService, APP_EVENTS, metadataService, ebConfig){
        return {
            restrict : 'A',
            scope: {
            },
            templateUrl: 'partials/margins.html',


            controller: ['$scope', function ($scope) {
                var margins = metadataService.getMetadataBlock(utilitiesService.getJobIndex(), 'margins');

                $scope.updateCurrentMargin = function(){
                    $scope.currentMargins = {};
                    $scope.currentMargins.inside =  $scope.margins.inside;
                    $scope.currentMargins.outside =  $scope.margins.outside;
                    $scope.currentMargins.top =  $scope.margins.top;
                    $scope.currentMargins.bottom =  $scope.margins.bottom;
                    $scope.currentMargins.linked =  $scope.margins.linked;
                };

                $scope.units = utilitiesService.getResizeUnitPrint();
                $scope.i18n = i18n;
                $scope.margins = {};
                $scope.margins.inside;
                $scope.margins.outside;
                $scope.margins.top;
                $scope.margins.bottom;
                $scope.margins.linked = ebConfig.get("easybuild.web.margins.linked.default");

                if ( margins && margins.keyValuePairs ){
                    $scope.margins.linked = false;
                    for(var pairIndex = 0; pairIndex < margins.keyValuePairs.length ; pairIndex++){
                        var currentPair = margins.keyValuePairs[pairIndex];

                        $scope.margins[currentPair.name] = currentPair.value;
                    }
                }
                $scope.updateCurrentMargin();


                evBus.on(APP_EVENTS.unitsChanged, function(ev, scope) {
                    $scope.units = utilitiesService.getResizeUnitPrint();
                });

                $scope.updateMargins = function() {
                    if ( $scope.margins.linked )
                    {
                        $scope.margins.outside  = $scope.margins.top;
                        $scope.margins.inside = $scope.margins.top;
                        $scope.margins.bottom = $scope.margins.top;
                    }
                    console.log("margins = " + $scope.margins.inside + ", "+ $scope.margins.outside + ", "+ $scope.margins.top + ", "+ $scope.margins.bottom + ", " );
                    if ( $scope.currentMargins.inside !==  $scope.margins.inside ||
                        $scope.currentMargins.outside !==  $scope.margins.outside ||
                        $scope.currentMargins.top !==  $scope.margins.top ||
                        $scope.currentMargins.bottom !==  $scope.margins.bottom ||
                        $scope.currentMargins.linked !==  $scope.margins.linked ){

                        if (($scope.margins.inside === undefined || $scope.margins.inside === "") &&
                            ($scope.margins.outside === undefined || $scope.margins.outside === "") &&
                            ($scope.margins.top === undefined || $scope.margins.top === "") &&
                            ($scope.margins.bottom === undefined || $scope.margins.bottom === "")){
                            metadataService.removeMetadataBlock(utilitiesService.getJobIndex(), 'margins');
                        }else {
                            var keyValuePairs = [];

                            keyValuePairs.push({name: "top", value: $scope.margins.top, tagName: "parameter"});
                            keyValuePairs.push({name: "inside", value: $scope.margins.inside, tagName: "parameter"});
                            keyValuePairs.push({name: "outside", value: $scope.margins.outside, tagName: "parameter"});
                            keyValuePairs.push({name: "bottom", value: $scope.margins.bottom, tagName: "parameter"});
                            keyValuePairs.push({name: "linked", value: $scope.margins.linked, tagName: "parameter"});

                            metadataService.setMetadataBlock(utilitiesService.getJobIndex(), 'margins', keyValuePairs);
                        }

                        if (utilitiesService.isDynamicPreviewEnabled()) {
                            // Kick off a generation
                            Wave2.JobGenerator.start(registry, ebConfig.get('easybuild.web.preview.currentgrouponly'));
                        }
                        evBus.broadcast(Wave2.DocContent.FIELD_CHANGED_EVENT, {'name':''});
                        $scope.updateCurrentMargin();
                    }
                };
            }]
        }
    }])
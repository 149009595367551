angular.module('easybuild.page.jobmanager')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebMaterialFiles
 * @description Manage material files associated with a job
 * @restrict 'A'
 * @scope
 * @param  {expression=} i18n Localised messages
 * @param  {expression=} materialFiles The material file data to manage
 * @param  {expression=} materialFilesDiscardList Delete these stored files on the next save command
 **/
.directive('ebMaterialFiles',['APP_EVENTS', '$http', 'ngTableParams', 'mediaManager', 'i18n',
    function(APP_EVENTS, $http, ngTableParams, mediaManager, i18n){
    return {
        restrict: 'A',
        replace: true,
        scope: {
            i18n: '=',
            materialFiles: '=',
            materialFilesDiscardList: '='
        },
        templateUrl: 'partials/material_files.html',
        link: function(scope, elm){
            elm.on(APP_EVENTS.materialfilesReload, function(){
                scope.materialFilesTable.reload();
            });
            scope.deleteMaterialFile = function(){
                if(scope.selectedFile && scope.selectedFile.$selected){
                    var fileToDelete = "";
                    if (scope.materialFiles) {
                        for(var i=0; i<scope.materialFiles.length ;i++){
                            var materialFile = scope.materialFiles[i];
                            if(materialFile == scope.selectedFile){
                                fileToDelete = materialFile.location;
                                scope.materialFiles.splice(i,1);
                                break;
                            }
                        }
                    }
                    if( fileToDelete != "" ){
                        scope.materialFilesTable.reload();

                        if (fileToDelete.indexOf('/media/materialfiles/') == -1) {//don't delete item moved to persistent storage yet
                            mediaManager._deleteItem(fileToDelete.substring(6));
                        }
                        else {
                            scope.materialFilesDiscardList.push(fileToDelete);
                        }
                    }
                }
            };
            scope.onRowClick = function(materialFile){
                if(materialFile.name != "") {
                    materialFile.$selected = !materialFile.$selected;
                    if(scope.selectedFile && scope.selectedFile != materialFile ) {
                        scope.selectedFile.$selected = false;
                    }
                    scope.selectedFile = materialFile;
                }
            };
            scope.downloadMaterialFile = function(){
                if(scope.selectedFile && scope.selectedFile.$selected){
                    var location = Wave2.WcService.BASE_SERVER_URL + 'CMS/' + scope.selectedFile.location;
                    window.open(location,'_blank');
                }
            };

            scope.materialFilesTable = new ngTableParams({}, {
                total: (scope.materialFiles) ? scope.materialFiles.length : 0, // length of data
                getData: function($defer) {
                    $defer.resolve(scope.materialFiles);
                },
                $scope: { $data: {} }
            });

            scope.materialFilesTable.settings().$scope = scope; // https://github.com/esvit/ng-table/issues/297

            scope.isMaterialFilenameInUse = function(name) {
                if (scope.materialFiles) {
                    for (var i = 0; i < scope.materialFiles.length; i++) {
                        if (name === scope.materialFiles[i].name) {
                            return true;
                        }
                    }
                }
                return false;
            };

            scope.onFileSelect = function($files) {
                for (var i=0; i < $files.length ;i++) {
                    var file = $files[i];

                    scope.candidateName = file.name;
                    var nameInUse = scope.isMaterialFilenameInUse(scope.candidateName);
                    var renameNumber = 1;
                    while(nameInUse){
                        var dotPosition = file.name.lastIndexOf(".");
                        scope.candidateName = file.name.substring(0,dotPosition) + "(" + renameNumber + ")" + file.name.substring(dotPosition);
                        renameNumber++;
                        nameInUse = scope.isMaterialFilenameInUse(scope.candidateName);
                    }
                    var reader = new window.FileReader();
                    var url = Wave2.WcService.BASE_SERVER_URL + 'media/image/' + scope.candidateName + '?enableThumbnailGeneration=false';

                    reader.onload = function (ev) {
                        Wave2.WcService.waitingDialog(_('store_job.uploading_material_file'));
                        var fileData = new Uint8Array(ev.target.result);
                        $http.put(url, fileData, {
                            headers: {
                                "Content-Type": file.type
                            },
                            transformRequest : []
                        }).success(function(resp) {
                            Wave2.WcService.Dialog.Commands.closeDialogAction();
                            var location = resp.ref;
                            scope.materialFiles.push({name: scope.candidateName, location: location});
                            scope.materialFilesTable.reload();
                        }).error(function(error) {
                            Wave2.WcService.Dialog.Commands.closeDialogAction();
                            var codes = ['UNKNOWN_ERROR'];
                            if (error && error.codes) {
                                codes = error.errors.join(', ');
                            }
                            throw new Error(i18n.error.mat_file_upload_failed + codes + ')');
                        });
                    };
                    reader.readAsArrayBuffer(file);
                }
            }
            scope.$watch(scope.materialFiles, function() {
                scope.materialFilesTable.reload();
            });
        }
    }
}]);
angular.module('easybuild.component.wc.preview')

/**
 * Layout the job previews based on an ordering strategy
 */
.service('previewLayoutService', ['$log', function($log) {
    return {

        applyStrategy: function(jobs) {
            var banners = [];
            var skyscrapers = [];
            var boxes = [];

            angular.forEach(jobs, function(job, index) {

                job.index = index;//store the original order so that we can use it after reordering

                if (!job.output || !job.output.stats || job.output.stats.width == 0 || job.output.stats.height == 0) {
                    // Fall-through if the job is not fully resolved, treat as a banner
                    job.singleRow = true;
                    banners.push(job);
                }

                else if (job.output.stats.width > (job.output.stats.height * 2)) {
                    // Banners first, on their own row
                    job.singleRow = true;
                    banners.push(job);
                }

                else if (job.output.stats.height > (job.output.stats.width * 2)) {
                    // Skyscrapers next, on the left
                    skyscrapers.push(job);
                }

                else {
                    // Everything else is treated as boxes
                    boxes.push(job);
                }

                // Convert measurement format if necessary
                if (job.output && job.output.stats) {
                    var dpi = 72;
                    if (job.output.stats.widthUnits === 'millimeters') {
                        job.output.stats.width = (job.output.stats.width * dpi) / 25.4;
                    }
                    else if (job.output.stats.widthUnits === 'inches') {
                        job.output.stats.width = job.output.stats.width * dpi;
                    }
                    else if (job.output.stats.widthUnits === 'picas') {
                        job.output.stats.width = (job.output.stats.width * dpi) / 6;
                    }

                    if (job.output.stats.heightUnits === 'millimeters') {
                        job.output.stats.height = (job.output.stats.height * dpi) / 25.4;
                    }
                    else if (job.output.stats.heightUnits === 'inches') {
                        job.output.stats.height = job.output.stats.height * dpi;
                    }
                    else if (job.output.stats.heightUnits === 'picas'){
                        job.output.stats.height = (job.output.stats.height * dpi) / 6;
                    }

                    job.style = {"width" : Math.round(job.output.stats.width), "height" : Math.round(job.output.stats.height)}
                }
            });

            // Order the boxes from narrow to wide
            boxes.sort(function(a,b) {
                return a.output.stats.width - b.output.stats.width;
            });

            return banners.concat(skyscrapers, boxes);
        }

    };
}]);
angular.module('pages/build/reset-button.directive', [])
.directive('ebResetButton', ['evBus', 'APP_EVENTS', function(evBus, APP_EVENTS) {
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'partials/reset_button.html',
        link: function (scope, element, attrs) {
            scope.toggleResetAction = function () {
                evBus.broadcast(APP_EVENTS.resetForm);
            }
        }
    }
}])
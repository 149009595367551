angular.module('easybuild.page.batch')

.controller('BatchEditorCtrl',['$scope', '$rootScope','i18n', '$log', '$window', '$http', 'evBus' , 'APP_EVENTS', 'utilitiesService',
        function($scope, $rootScope, i18n, $log, $window, $http, evBus, APP_EVENTS, utilitiesService){

    evBus.on(APP_EVENTS.resetBatch, function(){
        $scope.batchStage = "UPLOAD";
    });

    $rootScope.showProductLogo = true;
    $scope.$on("$destroy", function(){
        $rootScope.showProductLogo = false;
    });

    utilitiesService.getHasLightbox($scope);

    /*
     * The batch creation process has 5 stages on the front end :
     * UPLOAD file input
     * UPLOADING spinner
     * START validation results, start button
     * COMPLETE displays the result of running the batch
     * ERROR
     */
    $scope.batchStage = "UPLOAD";

    /*
     * Upload the batch file to the Publishing Platform
     * If there are validation errors then we return 400
     *
     */

    $scope.onFileSelect = function($files){

        $log.info("Uploading batch configuration file");

        /*
         * TODO restrict the input to one file as the UI can't
         * display the results of multiple batch file scans
         */

        var reader = new $window.FileReader();

        var url = Wave2.WcService.BASE_SERVER_URL + 'api/batches';
        var file = $files[0];//May need to URL encode

        $log.info("MIME type : " + file.type);

        reader.onload = function(ev){
            $log.info("Batch file has been loaded");
            $scope.batchStage = "UPLOADING";
            var fileData = reader.result;

            $scope.fileName = file.name;//storing this for submit click

            /*
             * Stripping off characters prepending the base64 encoded data
             * We are something like data:application/octet-stream;base64,LCwsLA0KLEZp ...
             * or data:application/vnd.oasis.opendocument.spreadsheet;base64,UEsDBBQAAAgAAKWN ....
             */
            var base64 = "base64,";
            var base64pos = fileData.indexOf(base64) + base64.length;
            fileData = fileData.substr(base64pos);

            $http.put(url,fileData,{
                headers: {
                    "x-wave2-file-name" : file.name,
                    //"Content-Type": file.type
                    "Content-Type" : "application/octet-stream",
                    "Content-Transfer-Encoding" : "base64"
                },
                transformRequest : []
            }).success(function(jobBatchResult){

                $scope.jobBatchResult = jobBatchResult;

                $log.info("Response : " + jobBatchResult);
                angular.element("input.upload-batch").val("");

                $scope.batchStage = "START";

            }).error(function(error){
                $log.debug("There has been an error uploading the batch file");
                $log.debug("ERROR : " + error);
                angular.element("input.upload-batch").val("");

                $scope.error = "There has been an error uploading the batch file";
                $scope.batchStage = "ERROR";
            });
        }
        reader.readAsDataURL($files[0]);
    }

    $scope.onSubmitClick = function(){
        if($scope.jobBatchResult && $scope.jobBatchResult.Id){

            var batchId = $scope.jobBatchResult.Id;
            var url = Wave2.WcService.BASE_SERVER_URL + 'api/batches/' + batchId;

            $http.post(url).success(function(){
                $log.info("started batch : " + batchId);
                $scope.batchStage = "COMPLETE";
            }).error(function(){
                $scope.error = "problem occured when attempting to send batch to server. BatchId : " + batchId;
                $log.info($scope.error);
                $scope.batchStage = "ERROR";
            });
        }
        else {
            $scope.error = "Batch id not found";
            $log.debug($scope.error);
            $scope.batchStage = "ERROR";
        }
    }
}]);
angular.module('easybuild.page.jobmanager')

/**
 * Retrieve a stored job from the PP job store
 */
.controller('JobRetrieveCtrl',  ['$scope', '$rootScope', '$window', '$log', 'registry', 'ebConfig', 'jobManagerService', 'jobStoreService', 'userSession', '$location', 'dialog', 'authService','$controller',
                        function ($scope, $rootScope, $window, $log, registry, ebConfig, jobManagerService, jobStoreService, userSession , $location, dialog, authService, $controller) {
    if (!serverReady(ebConfig)) return;
    $rootScope.showProductLogo = true;
    $scope.$on("$destroy", function(){
        $rootScope.showProductLogo = false;
    });
    $scope.selection = {
        product: registry.get('product'),
        type: registry.get('type'),
        document: registry.get('document')
    };

    $scope.statuses = [];
    angular.copy(ebConfig.get('easybuild.web.job.store.statuses'), $scope.statuses);
    angular.forEach($scope.statuses, function(status) {
        status.label = _(status.label);
    });
    $scope.statuses.unshift({ label: 'Any', value: 'any'}); // Add the "Any" option as the default entry

    $scope.status = $scope.statuses[0];
    $scope.jobStoreList = [];

    $scope.shareTypes = [];
    angular.forEach(ebConfig.get('easybuild.web.job.retrieve.share'), function(shareType) {
        $scope.shareTypes.push({
            value: shareType.value,
            label: _(shareType.label)
        });
    });

    $scope.name = "";
    $scope.owner = "";
    $scope.comments = "";

    var userGroups = [];
    if(localStorage.getItem("userGroups")) {
        userGroups = JSON.parse(localStorage.getItem("userGroups"));
    }

    $scope.shareGroupList = userGroups;
    $scope.shareEnabled = false;
    $scope.shareType = "";

    $scope.username = userSession.username;

    var users = [];
    var allGroups = [];

    function getUserNames() {
        for (var userGroupIndex = 0; userGroupIndex < userGroups.length; userGroupIndex++) {
            var userGroup = userGroups[userGroupIndex];
            allGroups.push(userGroup.name);
            for (var memberIndex = 0; memberIndex < userGroup.members.length; memberIndex++) {
                if ($.inArray(userGroup.members[memberIndex], users) == -1) {
                    users.push(userGroup.members[memberIndex]);
                }
            }
        }
    }

    getUserNames();
    if ( users.length == 0 ){
        authService.userUserGroups($scope.username, true).then(function (newGroups) {
            userGroups = newGroups;
            getUserNames();
            if ( users.length > 0 ) {
                localStorage.removeItem('userGroups');
                localStorage.setItem('userGroups', JSON.stringify(newGroups));
                $scope.shareUserList = users;
            }
        });
    }

    $scope.shareUserList = users;
    $scope.headers = [];
    angular.forEach(ebConfig.get('easybuild.web.job.store.columns'), function(shareType) {
        $scope.headers.push({
            value: shareType.value,
            title: _(shareType.title)
        });
    });

    $scope.jobStoreSelected = null;

    $scope.filterCriteria = {
        startDate: undefined,
        endDate: undefined,
        pageIndex: 0,
        sortFields: {
            sortOrder: "descending",
            sortColumn: "modifiedDate"
        },
        label: $scope.label,
        owner: $scope.owner,
        status: $scope.status,
        comments: $scope.comments,
        shareType: $scope.shareTypes[0],
        shareGroup: $scope.shareGroupList[0],
        shareUser: users[0]
    };

    $scope.totalPages = 0;
    $scope.jobStoreCount = 0;
    $scope.boundaryLinks = true;
    $scope.directionLinks = true;

    function updateJobTable() {
        jobManagerService.listJobs($scope.filterCriteria, allGroups, users).then(function (results) {
            var jobStores = results.jobStores,
                totalPages = results.totalPages,
                jobCount = results.jobCount;
            $scope.jobStoreList = jobStores;
            $scope.totalPages = totalPages;
            $scope.jobStoreCount = jobCount;
        });
    }

    $scope.changeSelection = function(jobStore) {
        $scope.jobStoreSelected = jobStore;
    }

    // called when navigate to another page in the pagination
    $scope.selectPage = function (pageIndex) {
        $scope.filterCriteria.pageIndex = pageIndex;
        updateJobTable();
    };

    // Will be called when filtering the grid, will reset the page number to zero
    $scope.filterResult = function () {
        if ($scope.shareEnabled) {
            if ($scope.filterCriteria.shareType == $scope.shareTypes[0]) {
                $scope.filterCriteria.shareType = $scope.shareTypes[1];
            }
        } else {
            $scope.filterCriteria.shareType = $scope.shareTypes[0];
        }
        $scope.filterCriteria.pageIndex = 0;
        updateJobTable();
    };

    // call back function that we passed to our custom directive ebServerSortBy, will be called when clicking on any field to sort
    $scope.onSort = function (sortedby, sortdir) {
        $scope.filterCriteria.sortFields.sortOrder = sortdir;
        $scope.filterCriteria.sortFields.sortColumn = sortedby;
        $scope.filterCriteria.pageIndex = 0;
        updateJobTable();
    };

    // manually select a page to trigger an ajax request to populate the grid on page load
    $scope.selectPage(0);

    $scope.retrieve = function() {
        if(!$scope.jobStoreSelected) {
            dialog.showDialog(dialog.TYPE.NOTIFY, '', _('store_job.no_item_selected'));
            return;
        }

        var jobName = $scope.jobStoreSelected.name;
        var owner = $scope.jobStoreSelected.owner;
        jobManagerService.retrieveJobs(owner, jobName);
    }

    $scope.deleteJob = function() {
        if(!$scope.jobStoreSelected) {
            dialog.showDialog(dialog.TYPE.NOTIFY, '', _('store_job.no_item_selected'));
        }
        else {
            jobManagerService.deleteJob($scope.jobStoreSelected.name);
        }
    }

    $scope.details = function(selectedJob) {
        if (selectedJob && selectedJob.owner && selectedJob.name) {
            $location.path('/job/details/' + selectedJob.owner + '/' + selectedJob.name);
        }
        else {
            dialog.showDialog(dialog.TYPE.NOTIFY, '', _('retrieve_job.no_item_selected'))
        }
    }


    angular.extend(this, $controller('CustomJobRetrieveCtrl', {$scope: $scope}));
}]);
'use strict';

angular.module('easybuild.shared.registry', [])

/**
 * Create/attach to the active web component registry container
 */
.service('registry', ['$log', '$q', 'configService', 'pluginScripts', 'evBus', 'userSession', function($log, $q, configService, pluginScripts, evBus, userSession) {
    var registry;

    if (angular.isObject(window.DefaultRegistry)) {
        registry = window.DefaultRegistry;
        evBus.broadcast(Wave2.Registry.LOADED_EVENT);
    }
    else {
        registry = Wave2.Registry.create({'store': 'sessionstate', 'evBus': evBus});
    }

    /**
     * Handle jump strings by populating the registry using the URL segments
     * @param {*} routeParams - URL path segments to inspect (product, type, document, etc)
     * @param {*} registry - the live registry object
     * @returns {promise}
     */
    registry.jumpToDocument = function(routeParams, registry) {
        var urlProduct = null, urlType = null, urlDoc = null;
        if (routeParams) {
            urlProduct = routeParams.product; urlType = routeParams.type; urlDoc = routeParams.document;
        }
        var regProduct = registry.get('product'), regType = registry.get('type'), regDoc = registry.get('document');

        var promises = [];
        if ((regProduct == null || regProduct.name !== urlProduct) && urlProduct) {
            promises.push(configService.getProducts());
        }
        if ((regType == null || regType.name !== urlType) && urlType) {
            promises.push(configService.getTypes({name: urlProduct}));
        }
        if ((regDoc == null || regDoc.name !== urlDoc) && urlDoc) {
            promises.push(configService.getDocuments({name : urlProduct}, {name: urlType}));
        }

        var jumpDefer = $q.defer();
        $q.all(promises).then(function(results) {
            if (results.length > 0 && angular.isArray(results[0])) {
                angular.forEach(results[0], function (match) {
                    if (match.name === urlProduct) {
                        registry.put('product', match);
                    }
                });
            }

            if (results.length > 1 && angular.isArray(results[1])) {
                angular.forEach(results[1], function (match) {
                    if (match.name === urlType) {
                        registry.put('type', match);
                    }
                });
            }

            if (results.length > 2 && angular.isArray(results[2])) {
                angular.forEach(results[2], function (match) {
                    if (match.name === urlDoc) {
                        registry.put('document', match);
                    }
                });
            }

            jumpDefer.resolve();
        });
        return jumpDefer.promise;
    }

    registry.newJob = function() {
        window.DefaultRegistry = registry;
        this.empty();
        pluginScripts.clearScripts();
        registry.put('jobowner', userSession.username);
    }

    return registry;
}]);
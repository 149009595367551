angular.module('easybuild.component.eb.mediamanager')

    .directive('ebMediaManagerModal', ['i18n','$log', 'evBus', 'APP_EVENTS',
        function(i18n,$log, evBus , APP_EVENTS){
            return {
                restrict : 'A',
                scope: {
                },
                templateUrl: 'partials/media_manager_modal.html',

                controller: ['$scope', function($scope) {

                    $scope.$on(Wave2.DocContent.FIELD_SELECT_START_EVENT,function(ev, data){
                        jQuery('#mediaManagerModal').find('.modal-header').html(i18n.media_manager.header);
                        jQuery('#mediaManagerModal').find('.close-button-bottom').html(i18n.crop_tool.cancel);
                        jQuery('#mediaManagerModal').modal('show');
                        evBus.broadcast(APP_EVENTS.mediaManagerChangeField, data);
                    });

                    $scope.closeModal = function(){
                        jQuery('#mediaManagerModal').modal('hide');
                        evBus.broadcast(APP_EVENTS.mediaManagerChangeField, null);
                    };

                    $scope.$on(Wave2.DocContent.FIELD_SELECT_COMPLETE_EVENT, function () {
                        $scope.closeModal();
                    });

                    if (!$("#mediaManagerModal .modal-dialog").is(":ui-draggable") ) {
                        $("#mediaManagerModal .modal-dialog").draggable({
                            handle: ".modal-header"
                        });
                    }

                }]
            }
        }]);
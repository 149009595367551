angular.module('easybuild.component.eb.bind')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebRegistryBind
 * @description Display/modify a registry entry (for primitive values such as job name)
 * @restrict 'A'
 **/
.directive('ebRegistryBind', ['registry', function(registry) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var regKey = attrs.ebRegistryBind;
            if (regKey.indexOf('.') > -1) {
                regKey = regKey.split('.')[0];
            }

            var val = registry.get(regKey);
            if (element.is('input,textarea,select')) {
                element.on('blur', function() {
                    registry.put(regKey, element.val());
                });
                element.val(val);
            }
            else {
                element.text(val);
            }
        }
    }
}]);
angular.module('easybuild.page.batch')
.controller('BatchManagerCtrl',['$scope', '$rootScope', 'i18n', '$http', '$log', '$q', 'authService', 'jobStoreService', '$routeParams', '$window', 'registry', 'ebConfig', '$location', 'evBus', 'APP_EVENTS',
function($scope, $rootScope, i18n, $http, $log, $q, authService, jobStoreService, $routeParams, $window, registry, ebConfig, $location, evBus, APP_EVENTS) {
    $rootScope.showProductLogo = true;
    $scope.$on("$destroy", function () {
        $rootScope.showProductLogo = false;
    });
    $scope.current_job = 0;
    $scope.pick = {};
    $scope.pick.batch = null;
    if ($routeParams['batchid'] && $routeParams['batchid'].length > 0 ) {
        $scope.pick = {'batch': {'name': $routeParams['batchid']}};
        $location.search('batchid', null);
    }

    var launchEvents = registry.get('launchEvents');
    if ( launchEvents && launchEvents.lightbox_batchname ){
        $scope.pick = {'batch': {'name': launchEvents.lightbox_batchname }};
    }

    $scope.updateBatchOutputGrid = function(){
        if ($scope.pick.batch && $scope.pick.batch.name) {
            var batchId = $scope.pick.batch.name;
            $http.get(Wave2.WcService.BASE_SERVER_URL + 'api/lightbox/' + $scope.lightbox + '/' + encodeURIComponent(batchId)).then(function (res) {
                $scope.batchJobs = res.data;

                angular.forEach($scope.batchJobs, function (batchJob) {
                    var jobOutputFile = $scope.getOutputFileEndingWith(batchJob.jobOutputFiles, ".jpg")
                        || $scope.getOutputFileEndingWith(batchJob.jobOutputFiles, ".png");
                    if (jobOutputFile) {
                        var type = $scope.getType(jobOutputFile);
                        batchJob.gridImage = Wave2.WcService.BASE_SERVER_URL + $scope.getJobOutputFileUrl(batchJob.jobId, jobOutputFile, type);
                        return;
                    }
                    else if ($scope.getOutputFileEndingWith(batchJob.jobOutputFiles, ".zip") || $scope.getOutputFileEndingWith(batchJob.jobOutputFiles, ".html")) {
                        batchJob.gridImage = "img/html5-icon.png";
                    }
                    else if ($scope.getOutputFileEndingWith(batchJob.jobOutputFiles, ".pdf")) {
                        batchJob.gridImage = "img/pdf-icon.png";
                    }
                    else if ($scope.getOutputFileEndingWith(batchJob.jobOutputFiles, ".mp4")) {
                        batchJob.gridImage = "img/video-file-icon.svg";
                    }
                    else {
                        batchJob.gridImage = "img/no-doc-preview.png";
                    }
                });
                var launchEvents = registry.get('launchEvents');
                if ( launchEvents )
                {
                    if (launchEvents.lightbox_currentjob && launchEvents.lightbox_currentjob.length > 0) {
                        $scope.modalButtonClick(launchEvents.lightbox_currentjob);
                    }
                    registry.remove('launchEvents');

                }
            });
        }
    };

    $scope.getOutputFileEndingWith = function(jobOutputFiles, endsWith){
        return jobOutputFiles.find(function(jobOutputFile){return jobOutputFile.endsWith(endsWith)});
    }

    $scope.getJobOutputFileUrl = function(jobId,jobOutputFile, type){
        var batchId = $scope.pick.batch.name;
        var url = 'File/batch/' + encodeURIComponent($scope.lightbox) + '/' + encodeURIComponent(batchId) + '/' + jobId + '/' + type + '/' + jobOutputFile;

        return url;
    };

    $scope.getType = function(jobOutputFile){
        var type;
        var checkPreview = false;
        if(jobOutputFile.endsWith(".zip") || jobOutputFile.endsWith(".html")){
            type = "html5";
        }
        else if(jobOutputFile.endsWith(".jpg")){
            type = "jpeg";
            checkPreview = true;
        }
        else if(jobOutputFile.endsWith(".png")){
            type = "png";
            checkPreview = true;
        }
        else if(jobOutputFile.endsWith(".pdf")){
            type = "pdf";
            checkPreview = true;
        }
        else if(jobOutputFile.endsWith(".eps")){
            type = "eps";
        }
        else if(jobOutputFile.endsWith(".mp4")){
            type = "mp4";
        }

        if ( checkPreview ) {
            if (jobOutputFile.indexOf('_preview') > 0) {
                type += " preview";
            }
        }

        return type;
    };

    $scope.updateModal = function(jobId){
        var index = 0;
        for (index = 0; index<$scope.batchJobs.length;index++){
            if ($scope.batchJobs[index].jobId === jobId ){
                break;
            }
        }

        var currentJob = $scope.batchJobs[index].jobId;
        var jobOutputFiles = $scope.batchJobs[index].jobOutputFiles;



        var outputFiles = [];

        var addFileType = function(filename){
            if ( filename && filename.length > 0 ) {
                var type = $scope.getType(filename);

                if (type) {
                    var outputFile = {
                        type: type,
                        url: $scope.getJobOutputFileUrl(currentJob, filename, type)
                    };
                    if (type == 'html5' && outputFile.url.endsWith(".zip")) {

                        /*
                         * Replacing .zip with /
                         * This is to comply with the expectations of the code for unzipping of the zip file and returning
                         * its contents
                         */
                        var pos = outputFile.url.lastIndexOf(".");
                        outputFile.url = outputFile.url.substr(0, pos < 0 ? outputFile.url.length : pos) + "/";
                    }
                    outputFiles.push(outputFile);
                }
            }
        };

        var jobOutputFile = $scope.getOutputFileEndingWith($scope.batchJobs[index].jobOutputFiles, ".pdf")
            || $scope.getOutputFileEndingWith($scope.batchJobs[index].jobOutputFiles, ".html")
            || $scope.getOutputFileEndingWith($scope.batchJobs[index].jobOutputFiles, ".mp4")
            || $scope.getOutputFileEndingWith($scope.batchJobs[index].jobOutputFiles, ".zip");

        if (jobOutputFile && jobOutputFile.length > 0  ){
            addFileType(jobOutputFile);
        }else {
            for (var outputFileIndex = 0; outputFileIndex < jobOutputFiles.length; outputFileIndex++) {

                /*
                 * We are attempting to create output like this
                 * {"type":"html5","url":"File/batch/lightbox/batch1/1558346935065/html5/"}
                 *
                 * from input like this
                 * 1558346935065_HTML5.zip
                 */
                addFileType(jobOutputFiles[outputFileIndex]);

            }
        }

        $scope.current_job = currentJob;
        $scope.jobIndex = index;
        $scope.back_disabled = (index == 0);
        $scope.forward_disabled = ( index == ($scope.batchJobs.length - 1));

        var jobToken = {
            index : 0,
            jobToken : 'localhost/' + $scope.current_job
        };

        var container = angular.element('.output-container');

        container.empty();

        var maxWidth = container.width();
        var maxHeight = container.height();

        Wave2.PlayerHelper.renderPreviewForJob(outputFiles, jobToken, container, undefined, maxWidth, maxHeight);
    };

    $scope.modalButtonClick = function(jobId){
        jQuery('#batchModal').modal('show');//doesn't work in angular's jQlite
        $scope.updateModal(jobId);
    };

    $scope.previousJob = function(){
        $scope.updateModal($scope.batchJobs[$scope.jobIndex - 1].jobId);
    };

    $scope.nextJob = function(){
        $scope.updateModal($scope.batchJobs[$scope.jobIndex + 1].jobId);
    };

    $scope.getJobXML = function(){
        var batchId = $scope.pick.batch.name;

        var url = Wave2.WcService.BASE_SERVER_URL + 'File/batch/' + encodeURIComponent($scope.lightbox) + '/' + encodeURIComponent(batchId) + '/' + $scope.current_job
        + '/xml/' + $scope.current_job + '.xml';

        return $http.get(url)
    };

    $scope.updateBatches = function() {
        $http.get(Wave2.WcService.BASE_SERVER_URL + 'api/lightbox?batches=true').then(function (res) {
            $scope.batches = [];
            if (res.data && res.data.length > 0 ) {
                $scope.lightbox = res.data[0].name;
                for (var i = 0; i < res.data[0].batches.length; i++) {
                    $scope.batches.push({name: res.data[0].batches[i]});
                }
                if ($scope.pick.batch) {
                    $scope.updateBatchOutputGrid();
                }
            }
        });
    };

    $scope.updateBatches();

    evBus.on(APP_EVENTS.batchFinished, $scope.updateBatches);

    $scope.launch = function(){
        var promises = [];
        var username;
        var jobXML;

        promises.push(authService.userInfo().then(function(user){
            username = user.username;
        }));

        promises.push($http.get(Wave2.WcService.BASE_SERVER_URL + 'File/batch/' + encodeURIComponent($scope.lightbox) + '/' + encodeURIComponent($scope.pick.batch.name) + '/' + $scope.current_job
        + '/xml/' + $scope.current_job + '.xml').then(function sucessCallback(response){
            jobXML = response.data;
        }, function errorCallback(){
            $log.error('There was a problem when retrieving the job XML');
        }));

        $q.all(promises).then(function(){
            var jobLabel;

            var jobXMLDocument = jQuery.parseXML(jobXML);
            var $xml = jQuery(jobXMLDocument);
            var jobName = $xml.find("job").attr("name");

            if(jobName){
                jobLabel = jobName;
            }
            else {
                jobLabel = $scope.pick.batch.name + '~' + $scope.current_job;
            }

            var jobDetails = {
                hidden : true,
                creationDate : new Date(),
                instance : "",
                jobXml : jobXML,
                label : jobLabel,
                modifiedDate : new Date(),
                name : jobLabel,
                owner : username,
                shareType : {
                    groups : null,
                    type : 'private',
                    users : null
                },
                status : 'new'
            };

            jobStoreService.store(jobDetails.owner, jobDetails).then(function(){
                var action = "/launch?jobName=" + jobLabel + "&jobOwner=" + username +
                    "&deleteJob=true&lightbox=true&lightbox_batchname=" + $scope.pick.batch.name + "&lightbox_currentjob=" + $scope.current_job;
                var target = ebConfig.get('easybuild.web.lightbox.launch.open');
                if ( !target || target == ""){
                    target = "_self";
                }
                $window.open(action, "_self");

            });
        });
    };

    $scope.closeModal = function(){
        jQuery('#batchModal').find('.output-container').empty();
        jQuery('#batchModal').modal('hide');
    };

    $scope.downloadBatch = function(){
        $window.open(Wave2.WcService.BASE_SERVER_URL + "File?command=all&lightbox=" + $scope.lightbox + "&batch=" + $scope.pick.batch.name, "_blank");
    };

    $scope.timeout = false;

    var windowEl = angular.element(window);
    windowEl.resize(function() {
        clearTimeout($scope.timeout);
        $scope.timeout = setTimeout(function(){
            if ( ($scope.jobIndex !== undefined && $scope.jobIndex !== null) && $scope.batchJobs) {
                $scope.updateModal($scope.batchJobs[$scope.jobIndex].jobId);
            }
        }, 250);
    });

}]);
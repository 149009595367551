angular.module('easybuild.component.eb.queuedetails')

.directive('ebQueueDetails', ['i18n', 'registry', 'evBus', 'utilitiesService', 'ebConfig',
    function(i18n, registry, evBus, utilitiesService, ebConfig){
        return {
            restrict: 'A',
            scope: {
                'showQueueDetails': '=',
                ebQueueDetailsJobIndex: '@',
                ebQueueMinQueueLength: '@',
                ebQueueMinQueueWait: '@',
                ebQueueMinProcessEstimate: '@'
            },
            templateUrl: 'partials/queue_details.html',
            compile: function (element, attr) {
                var getNumber = function(item, def){
                    var ret = def;
                    if (!isNaN(item) && item > 0) {
                        ret = parseInt(item);
                    }
                    return ret;
                };

                var getJobIndex = function(scope){
                    return getNumber(scope.ebQueueDetailsJobIndex, 0);
                };

                var showMinQueueLength = function(scope, queueLength){
                    var val = getNumber(scope.ebQueueMinQueueLength, 0);
                    return !scope.ebQueueMinQueueLength || val <= 0 || val <= queueLength;

                };

                var showMinQueueWait = function(scope, queueWait){
                    var val = getNumber(scope.ebQueueMinQueueWait, 0);
                    return !scope.ebQueueMinQueueWait || val <= 0 || !queueWait || val <= queueWait;
                };

                var showMinProcessEstimate = function(scope){
                    var val = getNumber(scope.ebQueueMinProcessEstimate, 0);
                    return !scope.ebQueueMinProcessEstimate || val <= 0 || !scope.processEstimate || val <= scope.processEstimate;
                };

                return {
                    'pre': function (scope) {
                        var jobIndex = getJobIndex(scope);
                        scope.i18n = i18n;

                        scope.jobName = registry.get('jobname');

                        var job = registry.get('jobs')[jobIndex];

                        scope.templateType = job.document.format;
                        scope.templateTypeString = _("template." + job.document.format);
                        scope.processEstimate = job.document.processEstimate;
                        if ( job.document.processEstimate) {
                            scope.jobProcessEstimate = utilitiesService.getTimeString(job.document.processEstimate);
                            scope.jobProcessEstimateTime = utilitiesService.getTime(job.document.processEstimate);
                        } else {
                            scope.jobProcessEstimate = _("time.notCalculated");
                            scope.jobProcessEstimateTime = utilitiesService.getTime(0);
                        }

                        scope.displayimage = job.document.displayimage;

                        scope.getQueueDefaults = false;
                        if (ebConfig.get('easybuild.web.queue.enabled')) {
                            scope.getQueueDefaults = ebConfig.get('easybuild.web.queue.all') || ebConfig.get('easybuild.web.queue.' + job.document.format);
                        }
                    },
                    'post': function (scope) {
                        evBus.on(Wave2.JobGenerator.JOB_STATUS_CHANGED_EVENT, function (ev, status) {
                            if (scope.getQueueDefaults) {
                                if ( scope.jobToken && scope.jobToken !== status.output.jobToken.jobToken){
                                    scope.queueStartTime = undefined;
                                    scope.queueStartLength = undefined;
                                }
                                var outputStatus = status.output.status;
                                if (status && status.jobIndex == getJobIndex(scope) && status.output && outputStatus) {
                                    if (outputStatus === "PROCESSING" || outputStatus === "QUEUED" || outputStatus === "WAITING") {
                                        if ( !scope.showQueueDetails
                                         && (scope.queueStartLength !== undefined ||
                                            scope.templateType === "ae" ||
                                            (showMinQueueLength(scope, status.output.queueLength ) ||
                                                showMinQueueWait(scope, status.output.queueWait) ||
                                                showMinProcessEstimate(scope)))  ){
                                            scope.showQueueDetails = true;
                                        }
                                        if ( scope.showQueueDetails) {
                                            if (!scope.queueStartTime) {
                                                scope.queueStartTime = Date.now();
                                            }
                                            if (!scope.queueStartLength) {
                                                scope.queueStartLength = status.output.queueLength;
                                            }
                                        }
                                    } else {
                                        scope.showQueueDetails = false;
                                        scope.queueStartTime = undefined;
                                        scope.queueStartLength = undefined;
                                    }
                                }
                                scope.jobStatus = utilitiesService.getJobStatusString(outputStatus);
                                scope.jobSummary = status.output.summary;
                                if (status.output.jobToken && status.output.jobToken.jobToken) {
                                    scope.jobToken = status.output.jobToken.jobToken;
                                }
                                scope.queueLength = status.output.queueLength;
                                if (status.output.queueWait) {
                                    scope.queueWait = utilitiesService.getTimeString(status.output.queueWait);
                                    scope.queueWaitTime = utilitiesService.getTime(status.output.queueWait);
                                } else {
                                    scope.queueWait = _("time.notCalculated");
                                    scope.queueWaitTime = utilitiesService.getTime(0);
                                }
                                if (outputStatus === "PROCESSING" && status.output.progressElapsed){
                                    scope.queueStartTime = undefined;
                                    scope.progressElapsed = status.output.progressElapsed;
                                    scope.jobProcessElapsed = utilitiesService.getTimeString(status.output.progressElapsed);
                                    scope.jobProcessElapsedTime = utilitiesService.getTime(status.output.progressElapsed);
                                } else if ( scope.queueStartTime ){
                                    var elapsed = Date.now() - scope.queueStartTime;
                                    scope.queueElapsed = utilitiesService.getTimeString(elapsed);
                                    scope.queueElapsedTime = utilitiesService.getTime(elapsed);
                                }else {
                                    scope.queueElapsed = "";
                                    scope.queueElapsedTime = "";
                                    scope.jobProcessElapsed = "";
                                    scope.jobProcessElapsedTime = "";
                                }

                            }
                        });
                        evBus.on(Wave2.JobGenerator.ALL_JOBS_FINISHED_EVENT, function () {
                            scope.showQueueDetails = false;
                            scope.queueStartTime = undefined;
                            scope.queueStartLength = undefined;
                        });
                        evBus.on(Wave2.JobGenerator.ALL_JOBS_CANCELLED_EVENT, function () {
                            scope.showQueueDetails = false;
                            scope.queueStartTime = undefined;
                            scope.queueStartLength = undefined;
                        });

                        scope.cancelJob = function () {
                            Wave2.JobGenerator.cancel();
                            scope.showQueueDetails = false;
                        }
                    }
                }
            }
        }
    }])
angular.module('easybuild.component.eb.navbar')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebNavbarBottom
 * @description Draw the EasyBuild bottom dynamic navigation toolbar
 * @restrict 'A'
 * @scope
 **/
.directive('ebNavbarBottom', ['$location', 'APP_EVENTS', 'evBus', 'registry', 'ebConfig', 'i18n', 'utilitiesService', function($location, APP_EVENTS, evBus, registry, ebConfig, i18n, utilitiesService) {
    return {
        restrict: 'A',
        replace: true,
        templateUrl: 'partials/navbar_bottom.html',
        scope: {
            'jobStoreSelected' : '@',
            'mode': '@',
            'showMediaManager': '=',
            'toggleMediaManager': '&',
            'backAction': '@',
            'nextAction': '@',
            'onNextAction': '&',
            'onBackAction': '&',
            'deleteEnabled': '@',
            'downloadEnabled': '@',
            'downloadType': '@',
            'deleteAction': '&',
            'detailsAction': '&',
            'storeAction': '&',
            'storeEnabled' : '@',
            'retrieveAction': '&',
            'backDisabled' : '=',
            'jobIndex': '@', //Indicate generate data for download pdf ( edit bundled job )
            'customAction': '&',
            'selection': '='
        },
        link: function(scope) {
            scope.i18n = i18n;
            var query = $location.search();
            scope.isJump = query && query['jump'];

            scope.translateModeEnabled = ebConfig.get('easybuild.web.preview.translate.mode.enabled');

            scope.triggerPreviewGeneration = function () {
                if (!utilitiesService.isDynamicPreviewEnabled()) {
                    Wave2.JobGenerator.start(registry, ebConfig.get('easybuild.web.preview.currentgrouponly'));
                }
            }

            scope.toggleTranslateMode = function () {
                evBus.broadcast(APP_EVENTS.doccontentToggleTranslateFields);
            }

            scope.backButtonMode = scope.mode;

            var launchEvents = registry.get("launchEvents");
            if(launchEvents){
                console.log("Step Name : " + launchEvents.StepName );
                var stepName = launchEvents.StepName;
                if((scope.mode=="pick") && (stepName=="Styles")){
                    scope.backButtonMode = "launch-back";
                }
                else if(((scope.mode=="build-form")||(scope.mode=="build-design")) && (stepName=="Content")){
                    scope.backButtonMode = "launch-back";
                }
            }
        }
    };
}]);

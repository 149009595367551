angular.module('easybuild.component.eb.datasource-sort')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebServerSortBy
 * @description Allow server side sorting on a data table
 * @restrict 'A'
 * @scope
 * @param  {string=} sortdir Sort direction (ascending/descending)
 * @param  {string=} sortedby Table column to sort on
 * @param  {string} sortValue
 **/
.directive('ebServerSortBy', function() {
    return {
        templateUrl: 'partials/server_sort_by.html',
        restrict: 'A',
        transclude: true,
        replace: true,
        scope: {
            sortdir: '=',
            sortedby: '=',
            sortvalue: '@',
            onsort: '='
        },
        link: function (scope, element, attrs) {
            scope.sort = function () {
                if (scope.sortedby == scope.sortvalue) {
                    scope.sortdir = (scope.sortdir == 'ascending') ? 'descending' : 'ascending';
                } else {
                    scope.sortedby = scope.sortvalue;
                    scope.sortdir = 'ascending';
                }
                scope.onsort(scope.sortedby, scope.sortdir);
            }
        }
    }
});
'use strict';

angular.module('easybuild.shared.metadata', [])

/**
 * Helper service to manage the job metadata
 */
.service('metadataService', ['registry', 'i18n', function(registry, i18n) {

    /**
     * Fetch a generic metafield from the job metadata
     * @param {string} key - the metadata key to fetch
     * @return {string} the value as a string
     * @param {number} [jobIndex] - (optional) target a single job at this position, defaults to primary job
     */
    this.getMetaField = function(key, jobIndex) {
        if (!jobIndex) jobIndex = 0;

        var result = null;
        var jobs = registry.get('jobs');

        if(jobs && jobs[jobIndex] && jobs[jobIndex].metadata) {
            var metadata = jobs[jobIndex].metadata;
            angular.forEach(metadata.metadataPair, function (metaField) {
                if (metaField.name === key) result = metaField.value;
            });
        }

        return result;
    };

    /**
     * Store/replace a generic metafield in the job metadata
     * @param {string} key - the metadata key to create/update
     * @param {string} value - the new value
     * @param {number} [jobIndex] - (optional) target a single job at this position, if omitted applies to all jobs
     */
    this.setMetaField = function(key, value, jobIndex) {
        var jobs = registry.get('jobs');
        if (!jobs || jobs.length < 1) throw new Error(i18n.error.store_metadata_failed_missing_job);

        var job;
        if (jobIndex) {
            job = jobs[jobIndex];
            this._setMetaFieldInternal(job, key, value);
        }
        else {
            for (jobIndex = 0; jobIndex < jobs.length; jobIndex++) {
                job = jobs[jobIndex];
                this._setMetaFieldInternal(job, key, value);
            }
        }

        registry.put('jobs', jobs);
    };

    this._setMetaFieldInternal = function(jobObject, key, value) {
        if (!jobObject.metadata) jobObject.metadata = {};
        if (!jobObject.metadata.metadataPair) jobObject.metadata.metadataPair = [];

        var found = false;
        for (var metadataIndex = 0; metadataIndex < jobObject.metadata.metadataPair.length; metadataIndex++) {
            if (jobObject.metadata.metadataPair[metadataIndex].name == key) {
                jobObject.metadata.metadataPair[metadataIndex].value = value;
                found = true;
                break;
            }
        }
        if (!found) {
            jobObject.metadata.metadataPair.push({
                tagName: 'metafield',
                name: key,
                value: value
            });
        }
    };

    this.removeMetaField = function(metadata, key){
        var ret = false;
        if (metadata && metadata.metadataPair) {
            for (var metadataIndex = 0; metadataIndex < metadata.metadataPair.length; metadataIndex++) {
                var thisItem = metadata.metadataPair[metadataIndex];
                if (thisItem.name == key) {
                    metadata.metadataPair.splice(metadataIndex, 1);
                    if(metadata.metadataPair.length == 0){
                        delete metadata.metadataPair;
                    }
                    ret = true;
                    break;
                }
            }
        }
        return ret;
    };

    /**
     * When clicking the store button we check to see if any material files are held within the registry. These should be
     * used as the initial set of material files. If there are none start with an empty array.
     */
    this.getMaterialFileList = function() {
        var materialFiles = [];

        var jobs = registry.get("jobs");

        if(jobs && jobs[0]){
            var job0 = jobs[0];
            if(job0.metadata && job0.metadata.metadataBlock){
                for(var i=0; i < job0.metadata.metadataBlock.length ;i++){
                    var metadataBlock = job0.metadata.metadataBlock[i];
                    if(metadataBlock.tagName == "materialfiles"){
                        var keyValuePairs = metadataBlock.keyValuePairs;
                        if(keyValuePairs) {
                            for (var j = 0; j < keyValuePairs.length; j++) {
                                var materialFile = {'name' : keyValuePairs[j].name, 'location' : keyValuePairs[j].value};
                                materialFiles.push(materialFile);
                            }
                        }
                    }
                }
            }
        }

        return materialFiles;
    }

    /**
     * Get a metadata block from the jobXml associated with the job
     * @param {Integer} jobIndex - index of job with jobs array in the registry
     * @param {String} tagname - the tag name of the metadata block
     */
    this.getMetadataBlock = function(jobIndex, tagName, name, type){
        var result = null;
        var jobs = registry.get("jobs");

        if(jobs && jobs[jobIndex]){
            var jobObject = jobs[jobIndex];
            if(jobObject.metadata && jobObject.metadata.metadataBlock){
                for(var metadataBlockIndex = 0 ; metadataBlockIndex < jobObject.metadata.metadataBlock.length; metadataBlockIndex++ ){
                    var thisBlock = jobObject.metadata.metadataBlock[metadataBlockIndex];
                    if(thisBlock.tagName == tagName && thisBlock.name === name && thisBlock.type === type){
                        result = thisBlock;
                        break;
                    }
                }
            }
        }
        return result;
    };

        /**
         * @param jobIndex
         * @param tagName
         */
    this.removeMetadataBlock = function(jobIndex, tagName, name, type){
        var jobs = registry.get("jobs");

        if(jobs && jobs[jobIndex]) {
            var jobObject = jobs[jobIndex];
            if (jobObject.metadata && jobObject.metadata.metadataBlock) {
                for (var metadataBlockIndex = 0; metadataBlockIndex < jobObject.metadata.metadataBlock.length; metadataBlockIndex++) {
                    var thisBlock = jobObject.metadata.metadataBlock[metadataBlockIndex];
                    if (thisBlock.tagName == tagName && thisBlock.name === name && thisBlock.type === type) {
                        jobObject.metadata.metadataBlock.splice(metadataBlockIndex, 1);
                        if(jobObject.metadata.metadataBlock.length == 0){
                            delete jobObject.metadata.metadataBlock;
                        }
                        registry.put("jobs",jobs);
                        break;
                    }
                }
            }
        }
    };

    /*
     * Set the metadata block on the passed in jobObject
     * @param jobObject
     * @param tagName
     * @param keyValuePairs
     * @param name
     * @param type
     */
    this.setJobMetadataBlock = function(jobObject, tagName, keyValuePairs, name, type){
        if(!jobObject.metadata){jobObject.metadata = {};}
        if(!jobObject.metadata.metadataBlock){jobObject.metadata.metadataBlock = []};

        var found = false;
        for(var blockIndex = 0; blockIndex < jobObject.metadata.metadataBlock.length; blockIndex++){
            var metadataBlock = jobObject.metadata.metadataBlock[blockIndex];
            if(metadataBlock.tagName == tagName && metadataBlock.name === name && metadataBlock.type === type){
                if(keyValuePairs){metadataBlock['keyValuePairs'] = keyValuePairs};
                found = true;
            };
        }
        if(!found) {
            var metadataBlock = {};
            if(tagName){metadataBlock['tagName'] = tagName};
            if(keyValuePairs){metadataBlock['keyValuePairs'] = keyValuePairs};
            if(name){metadataBlock['name'] = name};
            if(type){metadataBlock['type'] = type};
            jobObject.metadata.metadataBlock.push(metadataBlock);
        }
    };

    /**
     * Set a metadata block
     * Currently replacing when matching tagName is found
     * @param{Integer} jobIndex
     * @param{String} tagName
     * @param{Array} keyValuePairs
     * @param{String} name
     * @param{String} type

     * @returns {boolean}
     */
    this.setMetadataBlock = function(jobIndex, tagName, keyValuePairs, name, type) {

        var result = false;
        var jobs = registry.get("jobs");

        if(jobs && jobs[jobIndex] && tagName){
            var jobObject = jobs[jobIndex];
            this.setJobMetadataBlock(jobObject, tagName, keyValuePairs, name, type);
            registry.put("jobs",jobs);
            result = true;
        }
        return result;
    };

    return this;
}]);
angular.module('easybuild.shared.generation')

.service('buildService', ['$window', 'ebConfig', 'registry', 'APP_EVENTS', 'jobService', 'configService',
                    'metadataService', 'dialog', 'evBus', '$q', 'docContentService','GENERATION_TYPE','unitConvertor',
                    'i18n', 'utilitiesService',
                    function($window, ebConfig, registry, APP_EVENTS, jobService, configService, metadataService,
                             dialog, evBus, $q, docContentService, GENERATION_TYPE,unitConvertor, i18n, utilitiesService) {

    // Implement callback function to generate a job name programatically
    this.jobNameGenerator = null;

    /**
     * Resize the layout grid so that it fills the available screen space
     */
    this.resizeGrid = function(grid, fullScreen) {

        var wcEl = angular.element('#grid');
        var containerEl = angular.element('.subGrid');
        var windowEl = angular.element(window);

        // if in forms mode then do not resize
        if (wcEl.size() == 0) return;

        // When the window is resized, remove the preview and resize the container
        if(fullScreen){
            containerEl.height(windowEl.height() - 10);
            containerEl.width(wcEl.parent().width() - 10);
        }
        else {
            containerEl.height(windowEl.height() - wcEl.offset().top - 80);
            containerEl.width(wcEl.parent().width() - 10);
        }
        containerEl.empty();

        var payload = {
            ctx: grid
        }

        evBus.broadcast(Wave2.LayoutGrid.LOADED_EVENT, payload);
    }

    this.attachGenerateEvents = function($scope, registry, generateType) {

        $scope.$on(APP_EVENTS.generateStart, function (ev, generateDataObject, callback, ctx, callbackArgs) {
            if (!ctx) { ctx = ev; }

            var generateData;
            if (angular.isString(generateDataObject)) {
                generateData = angular.fromJson(generateDataObject);
            } else {
                generateData = generateDataObject;
            }

            var jobs = registry.get('jobs');
            if (!jobs) return; // No job to generate

            var job = generateData.job;
            var jobObject = angular.fromJson(job);

            jobObject.channelName = ebConfig.get('easybuild.w2pp.channel.'+generateType);
            if(generateType == GENERATION_TYPE.proof){
                var proofChannelMetafield = metadataService.getMetaField("proofchannel");
                if (proofChannelMetafield != null) {
                    jobObject.channelName = proofChannelMetafield;
                }
            }

            var document = registry.get('document');
            if (document && document.multiJobDocuments && document.multiJobDocuments.length > 0) {
                // Flag each job as part of a multi-job, this is used later to ensure the primary job doesn't
                // kick off a new multi-job flow
                metadataService.setMetaField('primaryDoc', document.id, callbackArgs.index);
                metadataService.setMetaField('baseJobName', registry.get('jobname'));
            }

            // Get the target document for the job
            var productTypeDocument = jobObject.document.id.split("@@");
            var product = {"name" : productTypeDocument[0]};
            var type = {"name" : productTypeDocument[1]};
            var doc = {"name" : productTypeDocument[2]};

            jobService.generateJob(product, type, doc, angular.toJson(jobObject)).then(function (jobToken) {
                generateData.jobToken = jobToken;
                var jobs = registry.get('jobs');//Ensure that we are using a fresh copy before updating and placing back in the registry

                // Jobs array was purged mid-generate,
                // most likely this generate is about to be cancelled so skip
                if (!jobs || !callbackArgs || jobs.length < callbackArgs.index) return;
                jobs[callbackArgs.index].jobToken = jobToken;
                registry.put('jobs', jobs);

                if (callback) {
                    callback.call(ctx, jobToken, callbackArgs);
                }
            }, function(reason) {
                throw new Error(i18n.error.job_generation_failed + reason);
            });
        });

        $scope.$on(APP_EVENTS.generateStatus, function (ev, generateDataObject, callback, ctx, callbackArgs) {
            if (!ctx) { ctx = ev; }
            var generateData = angular.isString(generateDataObject) ? angular.fromJson(generateDataObject) : generateDataObject;

            var getQueueDefaults = false;
            if (ebConfig.get('easybuild.web.queue.enabled')){
                getQueueDefaults = ebConfig.get('easybuild.web.queue.all');
                if ( !getQueueDefaults) {
                    var jobs = registry.get('jobs');
                    if (jobs && jobs.length > 0) {
                        jobs.forEach(function (job, index) {
                            if (job.jobToken === generateData.jobToken) {
                                getQueueDefaults = ebConfig.get('easybuild.web.queue.' + job.document.format);
                            }
                        });
                    }
                }
            }

            jobService.getJobStatus(generateData.jobToken, getQueueDefaults).then(function (statusIn) {
                var tokenParts = generateData.jobToken.split('/');
                if (statusIn.code === 'FINISHED') {

                    // Get the output too
                    jobService.getJobOutput(generateData.jobToken).then(function (result) {
                        var outputFiles = [];
                        angular.forEach(result.output, function (item) {
                            if (item && item.pages > 0) {
                                // Calculate which page we should return, if "page per group" is enabled and we
                                // have the right number of groups then use the current group index
                                var page = 0;
                                if (ebConfig.get('easybuild.web.pagepergroup.enabled') && item.pages > 1) {
                                    var jobs = registry.get('jobs');
                                    page = utilitiesService.getFirstPageIndex(jobs[generateData.jobIndex], generateData.groupIndex);
                                }

                                // Default to 'indd', which tells the preview WC to show a JPEG
                                var outputUrl = ['File', 'job', tokenParts[0], tokenParts[1], item.type].join('/');

                                if (item.type === 'html5') {
                                    outputUrl += '/'; // Add the slash on the end to fetch the zip contents
                                } else {
                                    outputUrl += "/" + page; // Add the page number on the end
                                }

                                outputFiles.push({
                                    type: item.type,
                                    url: outputUrl
                                });
                            }
                        });

                        var status = {
                            'jobStatus': statusIn.code,
                            'jobSummary': statusIn.message,
                            'output': outputFiles,
                            'stats': result.stats
                        };
                        if (callback) {
                            callback.call(ctx, status, callbackArgs);
                        }
                    }, function(reason) {
                        throw new Error(i18n.error.failed_to_get_job_output + reason);
                    });
                }
                else if (statusIn.code === 'ERRORED') {
                    jobService.getJobInfo(generateData.jobToken).then(function (stats) {
                        var status = {
                            'jobStatus': statusIn.code,
                            'jobSummary': statusIn.message,
                            'output': [],
                            'stats': stats,
                            'queueLength': statusIn.queueLength,
                            'queueWait': statusIn.queueWait,
                            'progressElapsed': statusIn.progressElapsed,
                        };
                        if (callback) {
                            callback.call(ctx, status, callbackArgs);
                        }
                    }, function(reason) {
                        throw new Error(i18n.error.failed_to_get_job_output + reason);
                    });
                }
                else {
                    // No output if non-success status is returned
                    var status = {
                        'jobStatus': statusIn.code,
                        'jobSummary': statusIn.message,
                        'output': [],
                        'stats': null,
                        'queueLength': statusIn.queueLength,
                        'queueWait': statusIn.queueWait,
                        'progressElapsed': statusIn.progressElapsed,
                    };
                    if (callback) {
                        callback.call(ctx, status, callbackArgs);
                    }
                }
            }, function(reason) {
                throw new Error(i18n.error.failed_to_get_job_status + reason);
            });
        });

        $scope.$on(APP_EVENTS.generateCancel, function (ev, jobTokenObject, callback, ctx, callbackArgs) {
            if (!ctx) { ctx = ev; }

            var jobToken = jobTokenObject['jobToken'];
            try {
                jobService.cancelJob(jobToken);
            }
            catch (e) {
                // This is not a hard error, since we do not care if the cancel failed
            }
            if (callback) {
                callback.call(ctx, jobToken, callbackArgs);
            }
        });

        $scope.$on(APP_EVENTS.doccontentGetGroups, function(ev, s, callback, ctx, callbackArgs) {
            configService.getGroupNames(s.product, s.type, s.document).then(function(groups) {

                if(callback){
                    callback.call(ctx || ev, groups, callbackArgs);
                }
            }, function(reason) {
                // This is the first point of failure for a document that hasn't been calculated...
                throw new Error(i18n.error.form_load_failed);
            });
        });

        $scope.$on(APP_EVENTS.doccontentGetStructure, function(ev, s, callback, ctx, callbackArgs) {

            var getStructurePromises = [];
            getStructurePromises[0] = docContentService.getDocContent({}, s.groupIndex);
            getStructurePromises[1] = docContentService.getEffects();

            $q.all(getStructurePromises).then(function (results) {
                var res = results[0];

                // In regards to [0] Node Server returns an array however the SOAP API returns a single object
                var effects = results[1][0];
                res.effects = effects;
                var primaryDocument = s.document;

                var structureData = [
                    { docConfig : res, document : primaryDocument }
                ];

                var primaryDoc = metadataService.getMetaField('primaryDoc');

                // Initialise the other jobs for multi-job if we've not done this before
                if( !utilitiesService.isLightboxBuild() && primaryDocument.multiJobDocuments && primaryDocument.multiJobDocuments.length > 0
                    && (!primaryDoc || primaryDoc.length == 0)) {

                    var documentPromises = [];
                    angular.forEach(primaryDocument.multiJobDocuments, function (multiJobDocument){
                        documentPromises.push(configService.getDocument(
                            {"name" : multiJobDocument.product},
                            {"name" : multiJobDocument.type},
                            {"name" : multiJobDocument.document}));
                    });

                    $q.all(documentPromises).then(function(documents){

                        // Ensure the primary structure is returned first, then the other documents
                        // They can arrive in any order, we don't care because the multiPreview web component
                        // re-arranges the previews anyway
                        structureData[0] = {docConfig : res, document : primaryDocument};
                        angular.forEach(documents, function(doc, index) {
                            structureData[index + 1] = {docConfig : res, document : doc};
                        });

                        if(callback){
                            callback.call(ctx || ev, structureData, callbackArgs);
                        }
                    }, function(res){
                        throw new Error(i18n.error.assoc_doc_struct_failed + res );
                    });
                }
                else if(callback){
                    callback.call(ctx || ev, structureData, callbackArgs);
                }
            }, function() {
                // Always return success to ensure design mode works
                if(callback){
                    callback.call(ctx || ev, null, callbackArgs);
                }
            });
        });

        $scope.$on(APP_EVENTS.doccontentGetLibraries, function(ev, s, callback, ctx, callbackArgs ) {
            var groupIndex = 0;
            configService.getLibraryEntries(s.product, s.type, s.document, groupIndex).then(function (res) {
                var entry = {'libraries' : res };
                var output = [entry];
                if(callback){
                    callback.call(ctx || ev, output, callbackArgs);
                }
            }, function(reason) {
                throw new Error(i18n.error.failed_retrieving_libs + reason);
            });
        });
    }

    /**
     * Disable all skip flags in the job,
     * this is required for things like resize where the rules need to run on all dynamic content
     * @param registry - the active persistence context
     */
    this.disableAllSkipFlags = function(registry) {
        var jobs = registry.get('jobs');
        if (angular.isArray(jobs[0].groupArticleMapping)) {
            angular.forEach(jobs[0].groupArticleMapping, function(group) {
                angular.forEach(group, function(articleKey) {
                    var articleData = registry.get(articleKey);
                    var itemTypes = [ "textItems", "imageItems", "flashTextItems", "flashImageItems", "externalMediaItems"];
                    angular.forEach(itemTypes, function(itemType) {
                        angular.forEach(articleData[itemType], function (field) {
                            field.skip = false;
                        });
                    });
                    registry.put(articleKey, articleData);
                });
            });
        }
    }

    this.checkCustomSize = function(original, target, thresholds) {
        var originalWidth = parseFloat(original.width), originalHeight = parseFloat(original.height);
        target.width = parseFloat(target.width), target.height = parseFloat(target.height);

        // Positive values only (impossible to resize smaller than 1x1)
        if (originalWidth < 1) originalWidth = 1;
        if (originalHeight < 1) originalHeight = 1;

        var minWidth = 1, maxWidth = null;
        if (thresholds.widthPercentage) {
            minWidth = originalWidth - ((thresholds.widthPercentage / 100) * originalWidth);
            maxWidth = originalWidth + ((thresholds.widthPercentage / 100) * originalWidth);
            minWidth = Math.round(parseFloat(minWidth) * 100)/100; maxWidth = Math.round(parseFloat(maxWidth) * 100)/100;
        }
        var minHeight = 1, maxHeight = null;
        if (thresholds.heightPercentage) {
            minHeight = originalHeight - ((thresholds.heightPercentage / 100) * originalHeight);
            maxHeight = originalHeight + ((thresholds.heightPercentage / 100) * originalHeight);
            minHeight = Math.round(parseFloat(minHeight)*100)/100; maxHeight = Math.round(parseFloat(maxHeight)*100)/100;
        }

        if (minWidth && target.width < minWidth) target.width = minWidth;
        else if (maxWidth && target.width > maxWidth) target.width = maxWidth;
        if (minHeight && target.height < minHeight) target.height = minHeight;
        else if (maxHeight && target.height > maxHeight) target.height = maxHeight;
    }

    this.importDraggedContent = function(fieldEl, data) {
        // Find the field we have dropped into
        if (fieldEl[0].nodeName === 'IMG') {
            // Get the input control for this image thumbnail
            fieldEl = fieldEl.parent().parent().parent().parent().parent().find('input');
        }else {
            fieldEl = fieldEl.find('input');
        }
        var fieldType = fieldEl.attr('x-fieldtype');
        var mediaType = fieldType;
        if (mediaType === 'externalmedia'){
            mediaType = fieldEl.attr('x-mediatype');
        }
        var fieldId = fieldEl.attr('id');

        // Get the content
        var content = null;
        if (data.split(':').length > 1) {
            var type = data.split(':')[0].toLowerCase();
            content = data.split(':')[1];
            if (mediaType.toLowerCase().indexOf(type) === -1) {
                var typeString = 'text';
                if (type === 'image') typeString = 'images';
                else if (type === 'video' || type === 'externalmedia') typeString = 'videos';
                else if (type === 'audio') typeString = 'audios';
                dialog.showDialog(dialog.TYPE.NOTIFY, 'Wrong type of content', 'This field cannot accept ' + typeString + '.');
                return;
            }
            content = 'cms:/' + content;
        }
        else if (fieldType.toLowerCase() === 'text') {
            // Allow normal text to be dragged around
            content = data;
        }

        if (content != null) {
            // Update the content
            evBus.broadcast(Wave2.DocContent.FIELD_SELECT_COMPLETE_EVENT, {
                type: fieldType,
                id: fieldId,
                content: content
            });
        }
    }

    /**
     * Generate a name for a job
     * @param {number} jobIndex - the index of the target job in the jobs array
     * @return {string} the calculated name for this job
     */
    this.generateJobName = function(jobIndex) {
        var jobName = null;

        var jobs = registry.get('jobs');
        if (angular.isFunction(this.jobNameGenerator)) {
            jobName = this.jobNameGenerator.call(null, registry, jobIndex);
        }
        else {
            // Use the job label to generate the job name if present
            var baseJobName = metadataService.getMetaField('joblabel', 0);
            if (baseJobName){
                registry.put('jobname', baseJobName);
                metadataService.setMetaField('baseJobName', baseJobName);
                jobName = baseJobName;
            }else{
                jobName = registry.get('jobname');
            }

            var doc = jobs[jobIndex].document;
            if (jobs.length >= 2 && jobIndex >= 0 && jobIndex < jobs.length) {
                // New multi-job naming convention, append document name
                // Null or empty string is valid for job name, it gets generated in Wave2PP,
                // if so don't append.
                if(jobName) {
                    jobName = jobName + '_' + doc.name;
                }
            }
        }

        if(ebConfig.get('easybuild.w2pp.jobname.encode')&& jobName){
            jobName = encodeURIComponent(jobName);
            jobName = jobName.replace(/%/g, "@");
        }

        return jobName;
    }

    this.checkCustomSizelimits = function(target,originalScale) {

        var unit = utilitiesService.getResizeUnitPrint();

        if((target.minWidth < 1) && (target.minHeight < 1) && (target.maxWidth < 1) && (target.maxHeight < 1))
            return true;

        var changed = false;

        var widthInUnit = parseFloat(unitConvertor.getFormatter(unit).call('undefined',target.width));
        var heightInUnit = parseFloat(unitConvertor.getFormatter(unit).call('undefined',target.height));
        var minWidthInUnit = parseFloat(unitConvertor.getFormatter(unit).call('undefined',target.minWidth));
        var maxWidthInUnit = parseFloat(unitConvertor.getFormatter(unit).call('undefined',target.maxWidth));
        var minHeightInUnit = parseFloat(unitConvertor.getFormatter(unit).call('undefined',target.minHeight));
        var maxHeightInUnit = parseFloat(unitConvertor.getFormatter(unit).call('undefined',target.maxHeight));

        var limitMessage = ' [';

        if(minWidthInUnit >= 1){
            limitMessage += i18n.build.min_width + ' = '+ minWidthInUnit;
            if(widthInUnit < minWidthInUnit){
                target.width = target.minWidth;
                changed = true;
            }
        }
        if(maxWidthInUnit >= 1){
            limitMessage += '|' + i18n.build.max_width + ' = ' + maxWidthInUnit;
            if(widthInUnit > maxWidthInUnit){
                target.width = target.maxWidth;
                changed = true;
            }
        }
        if(minHeightInUnit >= 1){
            limitMessage += '|' + i18n.build.min_height + minHeightInUnit;
            if(heightInUnit < minHeightInUnit){
                target.height = target.minHeight;
                changed = true;
            }
        }
        if(maxHeightInUnit >= 1){
            limitMessage += '|' + i18n.build.max_height + maxHeightInUnit;
            if(heightInUnit > maxHeightInUnit){
                target.height = target.maxHeight;
                changed = true;
            }
        }

        limitMessage += ']'
        limitMessage = limitMessage.replace('[|','[');
        target.message = "";

        if(changed && target.name == 'Custom Size'){
            alert(i18n.build.out_of_range + " : " + limitMessage);
        }
        return true;
    }



    this.checkDimension = function(frame, template, tolerance){
        return this.checkDimensionScale(frame, template, template, tolerance);
    };

    this.checkDimensionScale = function(frame, templateMin, templateMax, tolerance){
        var minFrame = frame * ( 1 - tolerance );
        var maxFrame = frame * ( 1 + tolerance );

        if ( !templateMin ) templateMin = 0;
        if ( !templateMax ) templateMax = Infinity;

        return minFrame <= templateMax && templateMin <= maxFrame;
    };

    this.inddDocHasScaleWhichFitsBox = function(buildMediaDocument, frameHeight, frameWidth, tolerance){
        var matchingScaleFound = false;
        var buildService = this;


        angular.forEach(buildMediaDocument.scales,function(scale){
            var thisScaleFits = false;

            if(scale.name == "Custom Size"){
                //Check for restrictions at the document level
                if(buildMediaDocument.minHeight || buildMediaDocument.maxHeight || frameWidth,buildMediaDocument.minWidth || buildMediaDocument.maxWidth){
                    thisScaleFits = (buildService.checkDimensionScale(frameHeight, buildMediaDocument.minHeight, buildMediaDocument.maxHeight, tolerance)
                    && buildService.checkDimensionScale(frameWidth, buildMediaDocument.minWidth, buildMediaDocument.maxWidth, tolerance));
                }

                //Check for restriction on the scale if none exist at the document level
                else if(scale.minHeight || scale.maxHeight || scale.minWidth || scale.maxWidth){
                    thisScaleFits = (buildService.checkDimensionScale(frameHeight, scale.minHeight, scale.maxHeight, tolerance)
                    && buildService.checkDimensionScale(frameWidth, scale.minWidth, scale.maxWidth, tolerance));
                }

                //Looks like there aren't any restrictions at all so of course it can fit
                else thisScaleFits = true;
            }
            else {
                thisScaleFits = (buildService.checkDimension(frameHeight, scale.height, tolerance) &&
                    buildService.checkDimension(frameWidth, scale.width, tolerance));
            }

            if(thisScaleFits) matchingScaleFound = true;
        });
        return matchingScaleFound;
    },

    this._filterBuildMediaDocs = function(buildMediaDocuments, frameHeight, frameWidth) {
        var buildMediaAssociatedDocuments = [];
        var buildService = this;
        //convert from a percentage to out of 1
        var tolerance = (ebConfig.get('easybuild.web.buildmedia.filter.tolerance')) / 100;
        var filterEnabled = ebConfig.get('easybuild.web.buildmedia.filter.enabled');
        angular.forEach(buildMediaDocuments, function (buildMediaDocument) {
            if (!filterEnabled || frameHeight <= 0 && frameWidth <= 0) {
                // If we don't have both a frame height and frame width for the image box then we can't filter
                buildMediaAssociatedDocuments.push(buildMediaDocument);
                return;
            }
            if (buildMediaDocument.format == "indd") {
                if (buildService.inddDocHasScaleWhichFitsBox(buildMediaDocument, frameHeight, frameWidth, tolerance)) {
                    buildMediaAssociatedDocuments.push(buildMediaDocument);
                }
            }
            else {
                /*
                 * Document selection only applies for InDesign Documents otherwise auto select rules apply
                 */
                var documentScale;
                angular.forEach(buildMediaDocument.scales, function (scale) {
                    if (
                        scale.name == "Original") {
                        documentScale = scale;
                    }
                });
                if (!documentScale && buildMediaDocument.scales.length > 0) {
                    documentScale = buildMediaDocument.scales[0];
                }
                if (documentScale) {
                    if (buildService.checkDimension(frameHeight, documentScale.height, tolerance) &&
                        buildService.checkDimension(frameWidth, documentScale.width, tolerance)) {
                        buildMediaAssociatedDocuments.push(buildMediaDocument);
                    }
                }
            }
        });
        return buildMediaAssociatedDocuments;
    };

    this.getFilteredBuildMediaAssociatedDocuments = function(frameHeight, frameWidth){
        var deferred = $q.defer();
        var buildService = this;
        var doc = registry.get('document');
        if (doc.buildMediaDocuments && doc.buildMediaDocuments.length > 0 ){
            var buildMediaAssociatedDocuments = buildService._filterBuildMediaDocs(doc.buildMediaDocuments, frameHeight, frameWidth);
            deferred.resolve(buildMediaAssociatedDocuments);
        } else {
                configService.getBuildMediaDocuments(doc.fullId, doc.format).then(function (res) {
                    if ( res && res.length > 0 ) {
                        doc = registry.get('document');
                        doc.buildMediaDocuments = res;
                        registry.put('document', doc);

                    var buildMediaAssociatedDocuments = buildService._filterBuildMediaDocs(res, frameHeight, frameWidth);
                    deferred.resolve(buildMediaAssociatedDocuments);
                    }else{deferred.resolve();}
                }, function(reason) {
                    deferred.resolve();
                });
        }
        return deferred.promise;
    };

    return this;
}]);
angular.module('easybuild.component.wc.doccontentform')

.service('galleryService', ['$log', '$q', 'nodeService', 'registry', 'userSession', function($log, $q, nodeService, registry, userSession) {

    var GALLERY_ROOT = 'inventory2gallery/';

    /**
     * Retrieve a gallery listing for a given path
     * @param path - the relative path in the gallery
     * @return {*} the gallery folder listing
     */
    function getDirectories(path) {
        var deferred = $q.defer();
        var cmsPath = GALLERY_ROOT + path;
        nodeService.getCMSDirectoryListing(cmsPath).then(function(result) {
            deferred.resolve(result);
        }, function() {
            deferred.resolve([]);
        });
        return deferred.promise;
    }

    /**
     * Apply a filter to items within the image gallery
     *
     * We are given parent node and child node as input
     * We return a display policy object with the boolean variable members show and traverse which
     * determines the display policy for the child
     *
     * show : true, traverse : true - Default behaviour
     * show : true, traverse : false - Show files at this level but ignore folders
     * show : false, traverse : false - Ignore all items
     * show : false, traverse : true - Attach items within child folder to the parent
     */
    function filter(child, parent) {
        //Filter Documents Folder and sub-folders
        var product = registry.get("product").name;
        var type = registry.get("type").name;
        var document = registry.get("document").name;

        if(parent.path === "Documents") {
            return {"show" : false, "traverse" : child.name === product };
        }
        else if(parent.path === "Documents" + "/" + product ) {
            return {"show" : false, "traverse" : child.name === type };
        }
        else if(parent.path === "Documents" + "/" + product + "/" + type) {
            return {"show" : child.name === document, "traverse" : child.name === document };
        }

        //Filter Groups Folder
        var userGroups = [];
        if(localStorage.getItem("userGroups")) {
            userGroups = JSON.parse(localStorage.getItem("userGroups"));
        }

        if( parent.path === "Groups" ) {
            var show = false;
            for (var i=0; i < userGroups.length ;i++) {
                if( child.name === userGroups[i].name) {
                    show = true;
                    break;
                }
            }
            return {"show" : show, "traverse" : show };
        }

        //Otherwise return the default display policy
        return {"show" : true, "traverse" : true };
    }

    return {
        getDirectories: getDirectories,
        filter: filter
    }
}]);
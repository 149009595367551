angular.module('easybuild.component.eb.resizing')

.factory('unitConvertor', function() {

    function _toFixed(number, precision) {
        var multiplier = Math.pow(10, precision + 1);
        var whole = Math.floor(number * multiplier);
        var result = Math.round(whole / 10) * 10 / multiplier;
        return result;
    }

    function _appendIncompleteFraction(original, result) {
        // Allow incomplete fractions, these are normalised later
        var scanNumber = String(original);
        if (scanNumber.length > 1 && scanNumber.lastIndexOf('.') === scanNumber.length - 1) {
            result += '.';
        }
        else if (scanNumber.length > 2 && scanNumber.lastIndexOf('.0') === scanNumber.length - 2) {
            result += '.0';
        }
        return result;
    }

    return {

        ParserStrategy : {
            'mm': function (value) {
                if (isNaN(parseFloat(value)) || !isFinite(value)) return value;
                var result = _toFixed(value / 0.352777778, 2);
                return _appendIncompleteFraction(value, result);
            },
            'in': function (value) {
                if (isNaN(parseFloat(value)) || !isFinite(value)) return value;
                var result = _toFixed(value / 0.0138888889, 2);
                return _appendIncompleteFraction(value, result);
            },
            'cm': function (value) {
                if (isNaN(parseFloat(value)) || !isFinite(value)) return value;
                var result = _toFixed(value / 0.0352777778, 2);
                return _appendIncompleteFraction(value, result);
            },
            'pt': function (value) {
                if (isNaN(parseFloat(value)) || !isFinite(value)) return value;
                var result = _toFixed(value, 2);
                return _appendIncompleteFraction(value, result);
            }
        },

        FormatterStrategy : {
            'mm': function (value) {
                if (isNaN(parseFloat(value)) || !isFinite(value)) return null;
                return _toFixed(value * 0.352777778, 2);
            },
            'in': function (value) {
                if (isNaN(parseFloat(value)) || !isFinite(value)) return null;
                return _toFixed(value * 0.0138888889, 2);
            },
            'cm': function (value) {
                if (isNaN(parseFloat(value)) || !isFinite(value)) return null;
                return _toFixed(value * 0.0352777778, 2);
            },
            'pt': function (value) {
                if (isNaN(parseFloat(value)) || !isFinite(value)) return null;
                return _toFixed(value, 2);
            }
        },

        getParser: function(unit) {
            if (!unit) unit = 'pt';
            return this.ParserStrategy[unit];
        },
        getFormatter: function(unit) {
            if (!unit) unit = 'pt';
            return this.FormatterStrategy[unit];
        }
    }
});
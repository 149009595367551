angular.module('easybuild.component.wc.errors')

/**
 * @ngdoc directive
 * @name easybuild.directive.webcomponents:ebError
 * @description Render a generated document from the Publishing Platform, this might be a static URL (if provided), or the active job output.
 * @restrict 'A'
 * @scope
 **/
.directive('ebErrors', ['registry', 'utilitiesService', 'i18n', function(registry, utilitiesService, i18n) {
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'partials/errors.html',
        link: function (scope, element, attrs) {
            var getMessage = function(error){
                var message = i18n.error[error.type];
                if ( !message || message.length === 0){
                    message = error.message;
                }
                return message;
            };
            scope.i18n = i18n;
            scope.errors = [];
            var jobIndex = Wave2.ArticleManager.getJobIndex();
            var job = registry.get("jobs")[jobIndex];
            var groupNames = registry.get("groups");
            if (job.output.stats.documentErrors && job.output.stats.documentErrors.length > 0) {
                job.output.stats.documentErrors.forEach(function(error) {
                    var itemGroup;
                    var itemArticle;
                    var itemName;
                    if ( error.location ) {
                        var field = error.location.split(":");
                        var errorGroupIndex = parseInt(field[1]) - 1;
                        var errorArticleIndex = parseInt(field[2]) - 1;
                            var articleKey = Wave2.ArticleManager.getArticleKey(registry, errorGroupIndex, errorArticleIndex, jobIndex);
                            if (articleKey) {
                                var article = registry.get(articleKey);
                                if (article) {
                                    var itemName = field[0].toLowerCase();
                                    switch (field[0].toLowerCase()) {
                                        case 'flashtext':
                                            itemName = 'flashText';
                                            break;
                                        case 'flashimage':
                                            itemName = 'flashImage';
                                            break;
                                        case 'externalmedia':
                                            itemName = 'externalMedia';
                                            break;
                                    }
                                    var items = article[itemName + "Items"];
                                    if (items && items.length > 0) {
                                        items.forEach(function (item, index) {
                                            if (item.item === parseInt(field[3])) {
                                                itemName = item.name;
                                                if ( article.articleCount > 1){
                                                    itemArticle = errorArticleIndex+1;
                                                }
                                                if ( groupNames.length > 1 ){
                                                    itemGroup = groupNames[errorGroupIndex];
                                                }


                                            }
                                        });
                                    }
                                }
                            }
                        }
                    scope.errors.push({
                        type: "DOCUMENT",
                        hasName: (itemName && itemName.length > 0),
                        name: itemName,
                        hasGroup: (itemGroup && itemGroup.length > 0),
                        group: itemGroup,
                        hasArticle: (itemArticle && itemArticle.length > 0),
                        article: itemArticle,
                        message: getMessage(error)
                    })
                });
            }

            if (job.output.stats.systemErrors && job.output.stats.systemErrors.length > 0) {
                job.output.stats.systemErrors.forEach(function(error) {
                    scope.errors.push({
                        type: "SYSTEM",
                        message: getMessage(error.type)
                    })
                });
            }

        }
    }
}])

;

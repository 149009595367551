angular.module('easybuild.component.eb.action.invokegenerate', [])

.service('invokeGenerate', ['$log', '$q', 'jobService', 'authService', 'registry', 'ebConfig', 'articleService', 'buildService', 'metadataService',
                    function($log, $q, jobService, authService, registry, ebConfig, articleService, buildService, metadataService) {

    var jobMonitor = Wave2.JobMonitorCore;

    /*
     * Add any information to the job(s) for use in final production output only
     */
    this.updateJobsForFinalProductionOutput = function(jobs){
        var materialFiles = registry.get("materialFiles");
        if(materialFiles){
            var keyValuePairs = [];
            angular.forEach(materialFiles, function(materialFile) {
                if (materialFile.name == "") return;
                keyValuePairs.push({
                    tagName: 'materialfile',
                    name: materialFile.name,
                    value: materialFile.location
                });
            });
            metadataService.setJobMetadataBlock(jobs[0], 'materialfiles',keyValuePairs);
        }
    };

    /**
     * Start a new job generation using the specified channel
     *
     * @param {string} channelName - generate using this channel
     * @param {string} postAction - generate using this job post action flag (i.e. "preview")
     * @param {number|string} [jobIndex] - generate the job at this position in the jobs array
     * @return {string} - the monitor UUID for the job(s) being generated
     */
    this.startUsingChannelConfig = function (channelName, postAction, jobIndex) {
        jobMonitor.initialise(registry, articleService.getManager());
        var uuid = Wave2.WcService.createUUID();
        var jobs = registry.get("jobs");
        this.updateJobsForFinalProductionOutput(jobs);

        if (jobIndex !== undefined && jobIndex.length > 0) {
            // Start generation for just a single specified job
            jobIndex = parseInt(jobIndex);
            var job = jobs[jobIndex];
            this._startJobInternal(job, channelName, jobIndex, postAction, function(generateData) {
                jobMonitor.monitorJobs([generateData],uuid);
            });

        }
        else {
            // Start a new generation for all jobs in the array
            var generateDataForMonitor = [];
            angular.forEach(jobs, function(job, jobIndex) {
                this._startJobInternal(job, channelName, jobIndex, postAction, function(generateData) {
                    generateDataForMonitor.push(generateData);

                    if (generateDataForMonitor.length == jobs.length) {
                        jobMonitor.monitorJobs(generateDataForMonitor,uuid);
                    }
                });
            }, this);
        }

        return uuid;
    };

    this._startJobInternal = function(job, channelName, jobIndex, postAction, resultCallback) {
        var id;
        if (job.document.document) {
            id = job.document.document.id;
        } else {
            id = job.document.id;
        }
        var productTypeDocument = id.split("@@");
        var product = {"name" : productTypeDocument[0]};
        var type = {"name" : productTypeDocument[1]};
        var doc = {"name" : productTypeDocument[2]};

        var generateData = this.getGenerateData(job, channelName, jobIndex, postAction);
        jobService.generateJob(product, type, doc, angular.toJson(generateData)).then(function(jobToken) {
            generateData.jobToken = jobToken;
            resultCallback.call(this, generateData);
        });
    }

    this.getGenerateData = function(job, channelName, jobIndex, postAction) {

        var jobData = {};
        var generateData;

        jobData.articleKeys = job.articleKeys;
        jobData.groupArticleMapping = job.groupArticleMapping;
        jobData.metadata = job.metadata;
        jobData.channelName = channelName;
        jobData.document = job.document;

        if (channelName.indexOf('[') > -1) {
            channelName = channelName.substring(1, channelName.length - 1);
            jobData.channelName = ebConfig.get('easybuild.w2pp.channel.' + channelName);
        }

        var generateDataObject = jobMonitor.getGenerateData(jobIndex, jobData, registry);
        if (angular.isString(generateDataObject)) {
            generateData = angular.fromJson(generateDataObject);
        } else {
            generateData = generateDataObject;
        }

        generateData.effects = job.effects;
        generateData.colourChanges = job.colourChanges;
        Wave2.JobGenerator.updateFromMasterJob(jobIndex, job, generateData, registry);

        if (postAction !== 'preview') {
            // We're routing now, ensure the job name has been generated
            generateData.jobName = buildService.generateJobName(jobIndex);
            generateData.channelPostAction = postAction;
        }

        return generateData;
    };

    /**
    *
    * @param uuid
    * @param mediaType {String} html5 | indd ...
    * @param jobIndex
    * @param download {boolean} Applicatable to html5 media only
     * we may want a link to the html5 ad or we may want to download the zip file
     * true - we want the zip file
     * false - output should be unzipped so that we can display it
    * @returns {*}
    */
    this.getOutputUrl = function(uuid, mediaType, jobIndex, download) {
        if (!jobIndex) jobIndex = 0;
        var jobs = jobMonitor.getJobBatch(uuid);
        var mediaUrl = null;

        var job = jobs[jobIndex];
        for (var i = 0; i < job.output.length; i++) {
            if (job.output[i].type === mediaType || mediaType === "html5" && job.output[i].type === "html") {
                var command;
                if(download){
                    command = "download-job";
                }
                else {
                    command = "job";
                }
                mediaUrl = Wave2.WcService.BASE_SERVER_URL + "File/" + command + "/"+job.jobToken+"/"+job.output[i].type + "/";
                if(job.output[i].type !== "html5"){
                    mediaUrl += "0";
                }
            }
        }
        return mediaUrl;
    };

    this.getAllOutputZipUrl = function(uuid, includeSubjobs) {
        var jobs = jobMonitor.getJobBatch(uuid);
        var mediaUrl = Wave2.WcService.BASE_SERVER_URL + "File?command=all&jobs=";
        for (var jobIndex = 0; jobIndex < jobs.length; jobIndex++) {
            var job = jobs[jobIndex];
            mediaUrl += job.jobToken;
            mediaUrl += ';';
        }
        if ( includeSubjobs ){
            mediaUrl = mediaUrl + "&includeSubjobs=" + includeSubjobs;
        }
        return mediaUrl;
    };

    return this;
}]);
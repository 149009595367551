angular.module('easybuild.page.build')

/**
 * Design mode build page
 */
.controller('BuildDesignCtrl', ['$rootScope', '$scope', '$routeParams', '$compile', '$log', 'registry', 'APP_EVENTS',
    'ebConfig', 'configService', 'galleryService', 'designModeService', 'buildService' , '$timeout', 'evBus',
    'articleService', 'docContentService', 'i18n', 'metadataService',
    function ($rootScope, $scope, $routeParams, $compile, $log, registry, APP_EVENTS, ebConfig, configService,
              galleryService, designModeService, buildService, $timeout, evBus, articleService, docContentService,
              i18n, metadataService) {

    /*
     * scope variables declared are not visible on build_design.html they need to be declared within BuildCtrl
     */
    var jobData = registry.get('jobs');
    if (jobData == null) jobData = [{}];
    jobData[0]['document'] = $scope.selection;
    registry.put('jobs', jobData);

    $scope.showMaximise = true;

    var templates = {
        'canvasTmpl' : '#canvasTmpl',
        'libItemPanelTmpl' : '#libItemPanelTmpl',
        'controlsPanelTmpl' : '#controlsPanelTmpl',
        'articleEditDialogTmpl' : '#articleEditDialogTmpl',
        'textPanelViewTmpl' : '#textPanelViewTmpl',
        'textFieldTmpl' : '#textFieldTmpl',
        'imagePanelViewTmpl' : '#imagePanelViewTmpl',
        'imageFieldTmpl' : '#imageFieldTmpl',
        'libItemSelectorTmpl': '#libItemSelectorTmpl'
    };

    $scope.$on(APP_EVENTS.designModeBackgroundSolids, function(ev, payload,callback, ctx, callbackArgs ) {
        var path = $scope.selection.product.name + "/" + $scope.selection.type.name + "/" + $scope.selection.document.name;
        designModeService.listBackgroundSolids(path).then( function(res) {
            var output = res;

            if(callback){
                callback.call(ctx || ev, output, callbackArgs);
            }
        }, function(reason) {
            throw new Error(i18n.error.retrieving_background_solids + reason);
        });
    });

    $scope.$on(APP_EVENTS.designModeBorderClips, function(ev, payload,callback, ctx, callbackArgs ) {
        var path = $scope.selection.product.name + "/" + $scope.selection.type.name + "/" + $scope.selection.document.name;
        designModeService.listBorderClips(path).then( function(res) {
            var output = res;

            if(callback){
                callback.call(ctx || ev, output, callbackArgs);
            }
        }, function(reason) {
            throw new Error(i18n.error.retrieving_border_clips + reason);
        });
    });

    $scope.$on(APP_EVENTS.doccontentGetLibraryItem, function(ev, s, callback, ctx, callbackArgs ) {

        var options = {
            productName : s.product,
            typeName : s.type,
            documentName : s.document
        };

        docContentService.getDocContent(options, s.groupIndex, s.libItem).then(function (res) {

            var output = [];
            var entry = {'docConfig' : res };

            output.push(entry);

            if(callback){
                callback.call(ctx || ev, output, callbackArgs);
            }
        }, function(reason) {
            throw new Error(i18n.error.retrieving_doccontents + reason);
        });
    });

    $scope.$on(APP_EVENTS.doccontentSaveGridDefaults, function(ev, payload ,callback,ctx, callbackArgs ) {
        var docId = registry.get('document').id;
        configService.saveGridDefaults(payload.product, payload.type, payload.document, payload.groupIndex, payload.gridDefaults).then(function (res) {

            if(callback){
                callback.call(ctx || ev, null, callbackArgs);
            }
        }, function(reason) {
            throw new Error(i18n.error.saving_defaults + reason);
        });
    });

    $scope.$on(APP_EVENTS.doccontentLoadGridDefaults, function(ev, payload ,callback,ctx, callbackArgs ) {
        var docId = registry.get('document').id;
        configService.loadGridDefaults(payload.product, payload.type, payload.document, payload.groupIndex).then(function (res) {

            if(callback){
                callback.call(ctx || ev, res, callbackArgs);
            }
        }, function(reason) {
            throw new Error(i18n.error.loading_defaults + reason);
        });
    });

    $scope.$on(Wave2.LayoutGrid.LOADED_EVENT, function(ev, payload) {
        var ctx = payload.ctx;
        ctx.populateElement.call(ctx, '.subGrid', templates, function(targetContainer, ctx) {
            targetContainer.html('');
            $compile(this)($scope).appendTo(targetContainer);

            if(ctx){
                var validationBox;
                validationBox = new ValidationMessages.ValidationBox(ctx.registry, PageValidator.SHOW_VALIDATIONS);
                if (validationBox.initialise()) {
                    validationBox.populateElement(jQuery("#page_validation"));
                }

                validationBox = new ValidationMessages.ValidationBox(ctx.registry, DoccontentValidator.SHOW_VALIDATIONS);
                if (validationBox.initialise()) {
                    var validationTemplates = {"validationTmpl" : "#designValidationTmpl"};
                    validationBox.populateElement(jQuery("#design_content_validation"),validationTemplates);
                }
            }
        });
    });

    // Start the articles/groups component
    var groupsArticles = new Wave2.GroupsArticles.UI.PagedNavigator(registry);
    if (groupsArticles.initialise()) {
        groupsArticles.populateElement('#groupsarticles_container', {
            'groupsArticlesTmpl': '#groupsArticlesTmpl'
        }, function(targetContainer) {
            targetContainer.html('');
            $compile(this)($scope).appendTo(targetContainer);
        });
    }

    var windowEl = angular.element(window);
    var containerEl = angular.element('.subGrid');

    PageValidator.start(registry, {}).requires("doccontent");
    DoccontentValidator.start(registry);

    var grid = new Wave2.LayoutGrid.UI.Grid(registry, 0, articleService.getManager(), {
        'showGridOnStart' : false,
        richTextEditorControls: ebConfig.get('easybuild.web.rte.controls'),
        ckEditorLicenseKey:  ebConfig.get('easybuild.web.rte.ckEditorLicenseKey'),
        ckEditorFontColor:  ebConfig.get('easybuild.web.rte.fontColor'),
        backgroundOpacityMinimum: ebConfig.get('easybuild.web.opacity.background.min'),
        useDatePicker: ebConfig.get('easybuild.web.datepicker'),
        groupOnly: ebConfig.get('easybuild.web.preview.currentgrouponly'),
        assocIdsOverride: metadataService.getMetaField('assocIdsOverride'),
        useDocumentPreview:  ebConfig.get('easybuild.web.buildmedia.useDocumentPreview'),
        undoLimit: ebConfig.get('easybuild.web.buildmedia.undoLimit')
    });
    containerEl.height(windowEl.height() - jQuery("#grid").offset().top - 80);
    containerEl.width(jQuery("#grid").parent().width() - 10);

    grid.initialise($scope.selection.product, $scope.selection.type, $scope.selection.document, templates);

    windowEl.resize(function() {
        buildService.resizeGrid(grid);
    });

    $rootScope.$on(Wave2.WcService.TOGGLE_FULL_SCREEN_EVENT, function(ev, data){
        $rootScope.hideNavbars = data.maximise;
        $timeout(function(){
            buildService.resizeGrid(grid, $rootScope.hideNavbars);
        }, 0);
    });
}]);
angular.module('easybuild.component.wc.doccontentform')

.service('designModeService', ['$log', '$q', 'nodeService', function($log, $q, nodeService) {

    var BORDERS_ROOT = 'DesignMode/BorderClips/';
    var BACKGROUNDS_ROOT = 'DesignMode/BackgroundSolids/';

    function listBorderClips(path) {
        var deferred = $q.defer();
        var cmsPath = BORDERS_ROOT + path + '/';
        nodeService.getCMSDirectoryListing(cmsPath).then(function(result) {
            deferred.resolve(result);
        }, function() {
            deferred.resolve([]);
        });
        return deferred.promise;
    }

    function listBackgroundSolids(path) {
        var deferred = $q.defer();
        var cmsPath = BACKGROUNDS_ROOT + path + '/';
        nodeService.getCMSDirectoryListing(cmsPath).then(function(result) {
            deferred.resolve(result);
        }, function() {
            deferred.resolve([]);
        });
        return deferred.promise;
    }

    return {
        listBackgroundSolids: listBackgroundSolids,
        listBorderClips: listBorderClips
    }

}]);
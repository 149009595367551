'use strict';

require('./dialog/dialog.service');
require('./doccontentform/doccontentform.module');
require('./preview/preview.module');
require('./errors/errors.module');

angular.module('easybuild.component.wc', [
    'easybuild.component.wc.dialog',
    'easybuild.component.wc.doccontentform',
    'easybuild.component.wc.preview',
    'easybuild.component.wc.errors'
]);
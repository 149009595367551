angular.module('easybuild.shared.event')

/**
 * Modular event bus
 */
.service('evBus', ['$rootScope', '$window', 'PUBLISH_EVENTS', 'ebConfig', function($rootScope, $window, PUBLISH_EVENTS, ebConfig) {
    var evBusInstance = Wave2.EventBus.create({
        'ngScope': $rootScope,
        'forceAngular': true
    });

    evBusInstance._publishEvent = function (key, data) {
        var domain = data && data.domain ? data.domain : '*';
        var sender = $window.parent || $window;

        // Don't attempt to broadcast the scope object on application load
        var packet = {
            event: key,
            data: data && !data.$id ? data : undefined
        };
        sender.postMessage(angular.toJson(packet), domain);
    }

    // Monkey-patch the broadcast event to intercept and publish events to the caller
    evBusInstance._broadcast = evBusInstance.broadcast;
    evBusInstance.broadcast = function (key, data, callback, ctx) {
        this._broadcast(key, data, callback, ctx);

        var enableEventPublishing  = ebConfig.get('easybuild.web.event.publish.enabled');
        if (enableEventPublishing && PUBLISH_EVENTS.indexOf(key) > -1) {
            this._publishEvent(key, data);
        }
    }

    return evBusInstance;
}])
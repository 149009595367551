angular.module('easybuild.component.eb.autosave')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebAppVersion
 * @description Inserts the version of EasyBuild
 * @restrict 'AEC'
 **/
.directive('ebAutoSave', ['$location', 'APP_EVENTS', 'evBus', 'registry', 'jobStoreService', 'jobManagerService', 'userSession', 'buildService', 'jobBuilderService',
        function($location, APP_EVENTS, evBus, registry, jobStoreService, jobManagerService, userSession, buildService, jobBuilderService) {
        return {
            restrict: 'A',
            scope: {
                'i18n': '=',
                'jobIndex': '=',
                'listenOnAllFinished': '='
            },
            link: function(scope) {
                var doingSave=false;
                var saveFunction = function(){
                    var jobLabel = registry.get('jobname');
                    if (jobLabel && jobLabel.length > 0 && !doingSave ){
                        doingSave = true;
                        var jobDetails = {
                            label: jobLabel,
                            owner: userSession.username
                        };
                        var jobName = buildService.generateJobName(0)
                        jobStoreService.exists(jobDetails.owner, jobName).then(function (jobExists) {
                            var operation = 'create';
                            if ( jobExists){
                                operation = 'update';
                                jobManagerService.showJob(jobDetails.owner, jobName, []).then(function (result) {
                                    jobDetails = result.job;
                                    if ( !jobDetails.shareType || !jobDetails.shareType.type){
                                        jobDetails.shareType = {type: 'private'};
                                    }
                                    if ( !jobDetails.status){
                                        jobDetails.status = 'new';
                                    }
                                    var jobs = registry.get('jobs');
                                    if (jobs && jobs.length > 0 ) {
                                        jobManagerService.storeActiveJob(jobDetails, result.materialFiles, operation, true).then(function (error) {
                                            if (!error || error == '') {
                                                evBus.broadcast(APP_EVENTS.jobSaved, {
                                                    jobName: jobName,
                                                    jobLabel: jobLabel
                                                });
                                            }
                                            doingSave = false;
                                        });
                                    }
                                });
                            }else {
                                jobDetails.shareType = {type: 'private'};
                                jobDetails.status = 'new';
                                var jobs = registry.get('jobs');
                                if (jobs && jobs.length > 0 ) {
                                    jobManagerService.storeActiveJob(jobDetails, [], operation, true).then(function (error) {
                                        if (!error || error == '') {
                                            evBus.broadcast(APP_EVENTS.jobSaved, {
                                                jobName: jobName,
                                                jobLabel: jobLabel
                                            });
                                        }
                                        doingSave = false;
                                    });
                                }
                            }
                        });
                    }
                };

                saveFunction();
                evBus.on(Wave2.DocContent.FIELD_CHANGED_EVENT, function(ev, status){
                    saveFunction();
                });
            }
        };
}])


angular.module('easybuild.component.eb.bind')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebMetadataBind
 * @description Display/modify a metadata field (for primitive values such as job label)
 * @restrict 'A'
 **/
.directive('ebMetadataBind', ['metadataService', function(metadataService){
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var metaKey = attrs.ebMetadataBind;

            var val = metadataService.getMetaField(metaKey);
            if ( val ){
                val = val.trim();
            }
            if (element.is('input,textarea,select')) {
                element.on('blur', function () {
                    var newVal = element.val().trim();
                    element.val(newVal);
                    metadataService.setMetaField(metaKey, newVal);
                });
                element.val(val);
            }
            else {
                element.text(val);
            }
        }
    }
}]);
angular.module('easybuild.shared.ui-support')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebPartHeight
 * @description Ensure block container fills at least the height of the page, can pass in a element css selector to reduce its height.
 * @restrict 'A'
 **/
.directive('ebMultiPartHeight', function() {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            var windowEl = angular.element(window);

            windowEl.resize(function() {
                // Ensure preview is not hidden by the bottom nav bar, if present
                var previewElement = angular.element('#wave2_multipreview_proof');
                if (previewElement && previewElement.length > 0) {
                    var padding = 18;
                    if (angular.element('.navbar-fixed-bottom').height() > 0) {
                        padding += angular.element('.navbar-fixed-bottom').height();
                    }

                    // Ensure the element is exactly as high as the page
                    if (angular.element('#wave2_multipreview_thumbs_wrapper').height() > 0) {
                        padding += angular.element('#wave2_multipreview_thumbs_wrapper').height();
                    }

                    // Ensure the element is at least as high as the page
                    previewElement.css('height', function () {
                        return windowEl.height() - (element.offset().top + padding);
                    });
                }
            });
            setTimeout(function() {
                windowEl.resize();
            }, 100);
        }
    }
});
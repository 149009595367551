angular.module('easybuild.component.eb.generate')

.directive('ebGenerate', ['$window', 'utilitiesService', 'APP_EVENTS', 'evBus',
    function($window, utilitiesService, APP_EVENTS, evBus){
        return {
            restrict : 'A',
            scope: {
                ebGenerate: '@',
                ebGenerateType: '@',
                ebGenerateDocument: '@'
            },
            compile: function (element, attr) {
                return {
                    'post' : function(scope, element, attrs) {
                        element.on('click', function (ev) {
                            ev.preventDefault();
                            if ( scope.ebGenerateType && scope.ebGenerateType.toLowerCase() === "xlsx" ) {
                                if ( jQuery('#generateModal').length == 1 ){
                                    evBus.broadcast(APP_EVENTS.downloadExcel, {'documentId': scope.ebGenerate, 'document': JSON.parse(scope.ebGenerateDocument)});
                                }else {
                                    $window.open(Wave2.WcService.BASE_SERVER_URL + "generate?doc=" + scope.ebGenerate.replace("&", "%26") + "&indd=" + utilitiesService.getResizeUnitPrint(), "_blank");
                                }
                            } else {
                                $window.open(Wave2.WcService.BASE_SERVER_URL + "generatexml?doc=" + scope.ebGenerate.replace("&", "%26"), "_blank");
                            }
                        });
                    }
                }
            }
        }
    }]);


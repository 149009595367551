angular.module('easybuild.component.eb.generate')

.directive('ebGenerateModal', ['$window', 'i18n', 'utilitiesService', 'APP_EVENTS', 'evBus',
    function($window, i18n, utilitiesService, APP_EVENTS, evBus){
        return {
            restrict : 'A',
            scope: {
            },
            templateUrl: 'partials/generate_modal.html',
            compile: function (element, attr) {
                return {
                    'post' : function(scope, element, attrs) {
                        scope.i18n = i18n; //make i18n accessible
                        scope.showPrint = false;
                        scope.showDigital = false;

                        scope.unitsOptions = [
                            {name : "mm", id : "mm"},
                            {name : "cm", id : "cm"},
                            {name : "in", id : "in"},
                            {name : "pt", id : "pt"},
                            {name : "px", id : "px"}
                        ];


                        scope.printUnits = scope.unitsOptions.find(function(unit){return unit.name === utilitiesService.getResizeUnitPrint()});
                        scope.digitalUnits = scope.unitsOptions[4];


                        scope.closeModal = function(){
                            jQuery('#generateModal').modal('hide');
                        };

                        scope.checkFormat = function(format){
                            switch(format){
                                case "indd":{
                                    scope.showPrint = true;
                                    break;
                                }
                                case "html":
                                case "html5":{
                                    scope.showDigital = true;
                                    break;
                                }
                            }
                        };

                        scope.download = function(){
                            $window.open(Wave2.WcService.BASE_SERVER_URL + "generate?doc=" + scope.documentId.replace("&", "%26") + "&indd=" + scope.printUnits.id + "&html=" + scope.digitalUnits.id + "&html5=" + scope.digitalUnits.id, "_blank");
                            scope.closeModal();
                        };

                        evBus.on(APP_EVENTS.downloadExcel,function(ev, data){
                            scope.showPrint = false;
                            scope.showDigital = false;
                            scope.documentId = data.documentId;
                            if ( data.document ){
                                scope.checkFormat(data.document.format);
                                if ( data.document.multiJobDocuments && data.document.multiJobDocuments.length > 0 ){
                                    angular.forEach( data.document.multiJobDocuments, function(document){
                                        scope.checkFormat(document.format);
                                    });
                                }
                            }
                            if (scope.showPrint || scope.showDigital ) {
                                jQuery('#generateModal').modal('show');
                            }else {
                                scope.download();
                            }
                        });

                        if (!$("#generateModal .modal-dialog").is(":ui-draggable") ) {
                            $("#generateModal .modal-dialog").draggable({
                                handle: ".modal-header"
                            });
                        }
                    }
                }
            }
        }
    }]);


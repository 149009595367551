angular.module('pages.build.build-lightbox.directive', [])

  .directive('ebBuildLightboxNavButtons',['registry', 'i18n', 'configService', 'ebConfig', 'dialog', 'restService', '$window', '$q', 'metadataService', 'evBus', 'APP_EVENTS', 'utilitiesService',
        function(registry, i18n, configService, ebConfig, dialog, restService, $window, $q, metadataService, evBus, APP_EVENTS, utilitiesService){
        return {
            restrict : 'E',
            templateUrl: 'partials/launch_lightbox_buttons.html',
            link : {
                pre: function($scope) {
                    $scope.cancelLightboxEdit = function (packet) {
                        // Ask the user if they wish to lose their changes first
                        dialog.showDialog(dialog.TYPE.CONFIRM, '', _('build.reset_form_confirm'), {
                            onCancelClicked: function() {
                                dialog.closeDialog();
                            },
                            onOkClicked: function() {
                                dialog.closeDialog();
                                $window.location = utilitiesService.getNewLocation($scope.lightboxReturnUrl);
                            }
                        });
                    };

                    $scope.checkSaveStatus = function (packet) {
                        if(!packet.jobToken) {
                            evBus.broadcast(APP_EVENTS.scriptsOff);
                        } else {
                            if (packet.jobStatus === "FINISHED") {
                                return;
                            }
                            var msg = _("error.build_light_update_failed");
                            if (status == "QUEUED" || status === "PROCESSING" || status === "WAITING") {
                                msg = _("error.build_light_update_timedout");
                            } else if (status == "CANCELLED") {
                                msg = _("error.build_light_update_cancelled");
                            } else if (status == "ERRORED") {
                                msg = _("error.build_light_update_failed")
                                    + " "
                                    + _("error.see_log_for_details");
                            }
                            dialog.showDialog(dialog.TYPE.NOTIFY, '', msg);
                            return {abort: true};
                        }
                    };

                    $scope.lightboxChannel = 'Batch';
                    $scope.lightboxReturnUrl = 'lightbox';
                    var jobs = registry.get('jobs');
                    if (jobs && jobs.length > 0 ){
                        $scope.lightboxChannel = jobs[0].channelName;
                    }
                }

            }//end link
        }
  }]);

angular.module('easybuild.page.jobmanager')

/**
 * Store the current job in the PP job store
 */
.controller('JobStoreCtrl', ['$scope', '$rootScope', '$window', '$log', '$routeParams', 'ebConfig', 'registry', 'userSession', 'jobManagerService', 'dialog', 'metadataService', 'jobStoreManager', 'buildService', 'GENERATION_TYPE','$controller',
                    function ($scope, $rootScope, $window, $log, $routeParams, ebConfig, registry, userSession, jobManagerService, dialog, metadataService, jobStoreManager, buildService, GENERATION_TYPE, $controller) {

    if (!serverReady(ebConfig)) return;
    if ( !registry.get('jobowner') ) {
        registry.put('jobowner', userSession.username);
    }
    $rootScope.showProductLogo = true;
    $scope.$on("$destroy", function(){
        $rootScope.showProductLogo = false;
    });
    $scope.statuses = [];
    angular.copy(ebConfig.get('easybuild.web.job.store.statuses'), $scope.statuses);
    angular.forEach($scope.statuses, function (status) {
        status.label = _(status.label);
    });

    $scope.shareGroupList = [];
    $scope.shareUserList = [];
    $scope.storeEnabled = true;



    // If the primary job already exists in the store, redirect us to the details page for an update
    jobManagerService.gotoDetailsIfExists().then(function () {

        var status = $scope.statuses.filter(function (item) {
            return item['default'];
        });
        $scope.status = status.length > 0 ? status[0] : null;

        $scope.store = function () {
            jobStoreManager.store($scope, 'create');
        };

        $scope.jobDetails = {
            label: metadataService.getMetaField("joblabel"),
            owner: userSession.username,
            shareType: {
                type: 'private',
                groups: [],
                users: []
            }
        };

        $scope.materialFiles = metadataService.getMaterialFileList();
        $scope.materialFilesDiscardList = [];
    });
    buildService.attachGenerateEvents($scope, registry, GENERATION_TYPE.proof);
    angular.extend(this, $controller('CustomJobStoreCtrl', {$scope: $scope}));
}]);
require('./job-manager-store.service');

angular.module('easybuild.page.jobmanager', [
    'easybuild.page.jobmanager.store'
]);

require('./job-manager.service');

require('./material-files.directive');
require('./share-with.directive');

require('./job-manager-retrieve.controller');
require('./job-manager-store.controller');
require('./job-manager-update.controller');
angular.module('easybuild.page.login')

/**
  * Authenticate with the backend and initialise a session
  */
.controller('SsoCtrl', ['$scope', '$rootScope', '$window', '$log', 'AUTH_EVENTS', 'evBus', 'authService', 'registry',
                    'i18n', 'userSession','$http', '$location', "WORKFLOW_CONFIG", 'utilitiesService',
    function ($scope, $rootScope, $window, $log, AUTH_EVENTS, evBus, authService, registry, i18n, userSession, $http, $location, WORKFLOW_CONFIG, utilitiesService) {
    $rootScope.showProductLogo = false;

    $scope.selection = {
        product: registry.get('product'),
        type: registry.get('type'),
        document: registry.get('document')
    };

    $scope.message = {
        loginFailed: false
    };

            $scope.login = function () {
                $scope.message.loginFailed = false;
                $location.search('sso', null);
                $window.location.href = utilitiesService.getNewLocation(Wave2.WcService.BASE_SERVER_URL + 'user/login?RelayState=' + $location.absUrl());
            };

    evBus.on(AUTH_EVENTS.loginSuccess, function() {
        // Redirect the user to the target page
        $window.location.reload();
    });

    var searchObject = $location.search();
    if (searchObject && searchObject.sso === 'failed'  ){
        $scope.message.loginFailed = true;
    } else if (WORKFLOW_CONFIG.ssoAutoLogin && (searchObject.sso === undefined || searchObject.sso === null)){
        $scope.login();
    }
}])


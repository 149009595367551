'use strict';

angular.module('easybuild.shared.config')

    .constant('MEDIA_TYPE',{
        applicationJson : 'application/json',
        applicationXml : 'application/xml'
    })

    .constant('WORKFLOW_CONFIG', {
        useDefaultLoginPage: true,
        defaultHomePage: 'job/retrieve',
        defaultNotFoundPage: 'job/retrieve',
        defaultLogoutPage: 'job/retrieve',
        defaultJobStartPage: 'build',
        launchPageStepNameProducts: 'build',
        launchPageStepNameTypes: 'build/{{product}}',
        launchPageStepNameStyles: 'build/{{product}}/{{type}}',
        launchPageStepNameContent: 'build/{{product}}/{{type}}/{{document}}',
        launchPageStepNameLayout: 'build/{{product}}/{{type}}/{{document}}',
        launchPageStepNameChannels: 'build/{{product}}/{{type}}/{{document}}',
        launchPageStepNameEffects: 'build/{{product}}/{{type}}/{{document}}',
        launchPageStepNameGenerate: 'build/{{product}}/{{type}}/{{document}}/review',
        launchPageStepNameView: 'build/{{product}}/{{type}}/{{document}}/review',
        ssoEnabled: false,
        ssoAutoLogin: false
    })

    .constant('RESULT_FORMAT', {
        SINGLE: 'single',
        MULTIPLE: 'multiple',
        NONE: 'none'
    })

    .constant('USER_ROLES', {
        admin: 'admin',
        user: 'user'
    })

    .constant('GENERATION_TYPE', {
        preview: 'preview',
        proof: 'proof'
    });
require('./build-media.directive');
require('./build-lightbox.directive');
require('./html5-crop.directive');
require('./move.directive');
require('./reset-button.directive');
require('./download-choice.directive');
angular.module('easybuild.page.build', ['pages.build.build-media.directive','pages.build.html5-crop.directive', 'pages.build.move.directive', 'pages/build/reset-button.directive','pages.build.build-lightbox.directive', 'pages.build.download-choice.directive'])

require('./build-base.controller');
require('./build-design.controller');
require('./build-form.controller');
angular.module('easybuild.page.batch')

.directive('ebBatches', ['i18n', '$http', 'utilitiesService', 'ebConfig',
    function(i18n, $http, utilitiesService, ebConfig){
        return {
            restrict: 'A',
            scope: {
                ebBatches: '@'
            },
            templateUrl: 'partials/batches.html',
            compile: function (element, attr) {
                return {
                    'pre': function (scope) {
                        scope.i18n = i18n;
                        var url = Wave2.WcService.BASE_SERVER_URL + 'api/batches';

                        if (scope.ebBatches && scope.ebBatches.length > 0 && !isNaN(scope.ebBatches)) {
                            url += '?limit=' + scope.ebBatches;
                        }

                        $http.get(url).success(function (batches) {
                            scope.batches = batches;
                        }).error(function (error) {
                            // Show Error.
                        });
                    },
                    'post': function (scope) {
                    }
                }
            }
        }
    }])
angular.module('pages.build.download-choice.directive', [])
    /**
     * @ngdoc directive
     * @name easybuild.directive:ebDownloadChoice
     * @description  This directive handles the action download choice for media encoder jobs.
     *               A dialog is displayed that gives the option to download the existing
     *               generated preview document, or to generate again and download that instead.
     * @restrict 'A'
     * @scope
     * @param  {boolean} ebDownload type to download
     * @param  {int} ebDownloadJobIndex job index to download
     */
    .directive('ebDownloadChoiceManager', ['i18n','$log', 'evBus', 'APP_EVENTS', 'ebConfig', 'utilitiesService','unitConvertor', 'registry',
        function(i18n,$log, evBus, APP_EVENTS, ebConfig, utilitiesService,unitConvertor, registry){
        return {
            restrict : 'A',
            scope: {
            },
            templateUrl: 'partials/download_choice.html',

            controller: ['$scope', function($scope) {
                $scope.i18n = i18n;

                $scope.closeModal = function(){
                    jQuery('#downloadChoiceModal').modal('hide');
                };

                evBus.on(APP_EVENTS.downloadChoice, function(ev, data){
                    $scope.jobIndex = data.jobIndex;
                    $scope.downloadType = data.downloadType;
                    jQuery('#downloadChoiceModal').modal();
                    if (!$("#downloadChoiceModal .modal-dialog").is(":ui-draggable") ) {
                        $("#downloadChoiceModal .modal-dialog").draggable({
                            handle: ".modal-header"
                        });
                    }
                });


            }]
        }
    }])

    /**
     * @ngdoc directive
     * @name easybuild.directive:ebDownloadChoice
     * @description Add to button to show download choice dialog (for media encoder jobs)
     * @restrict 'A'
     * @scope
     * @param  {boolean} ebDownload type to download
     * @param  {int} ebDownloadJobIndex job index to download
     **/
    .directive('ebDownloadChoice', ['$log', 'registry', '$window', 'APP_EVENTS', 'evBus', 'dialog',
        function($log, registry, $window, APP_EVENTS, evBus, dialog) {
        return {
            restrict: 'A',
            scope: {
                ebDownloadChoice: '@',
                ebDownloadJobIndex: '@'
            },
            link: function (scope, element, attrs) {
                element.on('click', function (ev) {
                    ev.preventDefault();
                    evBus.broadcast(APP_EVENTS.downloadChoice, {'downloadType': scope.ebDownloadChoice, 'jobIndex': scope.ebDownloadJobIndex});
                });
            }
        }
    }])
;
'use strict';

require('./locale.service');
require('./config/config.module');
require('./event/event.module');
require('./generation/generation.module');
require('./ui-support/ui-support.module');
require('./auth.service');
require('./command.service');
require('./metadata.service');
require('./registry.service');
require('./utilities.service');
require('./pollyfill');
require('./restrictor.directive');

angular.module('easybuild.shared', [
    'easybuild.shared.locale',
    'easybuild.shared.event',
    'easybuild.shared.config',
    'easybuild.shared.generation',
    'easybuild.shared.ui-support',
    'easybuild.shared.auth',
    'easybuild.shared.command',
    'easybuild.shared.metadata',
    'easybuild.shared.registry',
    'easybuild.shared.utilities',
    'easybuild.shared.pollyfill',
    'easybuild.shared.restrictor'
]);
angular.module('easybuild.shared.ui-support')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebFullHeight
 * @description Ensure block container fills at least the height of the page, but can be taller and will scroll with page as normal
 * @restrict 'A'
 **/
.directive('ebFullHeight', function() {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            var windowEl = angular.element(window);

            windowEl.resize(function() {
                // Ensure preview is not hidden by the bottom nav bar, if present
                var padding = 18;
                if (angular.element('.navbar-fixed-bottom').height() > 0) {
                    padding += angular.element('.navbar-fixed-bottom').height();
                }

                if (attrs['ebFullHeight'] === 'strict') {
                    // Ensure the element is exactly as high as the page
                    element.css('height', function () {
                        return windowEl.height() - (element.offset().top + padding);
                    });
                }
                else {
                    // Ensure the element is at least as high as the page
                    element.css('min-height', function () {
                        return windowEl.height() - (element.offset().top + padding);
                    });
                }
            });
            setTimeout(function() {
                windowEl.resize();
            }, 100);
        }
    }
});
'use strict';
/**
 * Created by sean on 20/07/16.
 */

/* Services */

angular.module('easybuild.component.eb.action.service', [])

    /**
     * Runs a chain of actions defined by the eb-action directive
     */
    .service('actionHandler',['$q', '$window', '$http', '$location', 'APP_EVENTS', 'ebConfig', 'evBus', 'registry', 'dialog', 'actionHandlerUtils', 'invokeGenerate', 'utilitiesService',
        function($q, $window, $http, $location, APP_EVENTS, ebConfig, evBus, registry, dialog, actionHandlerUtils, invokeGenerate, utilitiesService) {
        return {

            actionGenerate: function(packet) {
                var channelName = packet.generate,
                    postAction = packet.postAction || 'route',
                    jobIndex = packet.jobindex || packet.jobIndex || undefined;

                if (packet.download) {
                    // If we're later downloading the output then we implicitly don't route it now
                    packet.postAction = 'preview';
                }

                if (!channelName) channelName = registry.get('jobs') ? registry.get('jobs')[jobIndex!==undefined && jobIndex.length > 0?parseInt(jobIndex):0].channelName : null;
                if (!channelName) channelName = ebConfig.get('easybuild.w2pp.channel.preview');

                var defer = $q.defer();
                var promise = defer.promise;

                var runningJobs = [];
                var uuid = invokeGenerate.startUsingChannelConfig(channelName, postAction, jobIndex);
                evBus.on(Wave2.JobMonitorCore.JOB_STATUS_CHANGED_EVENT, function (ev, data) {

                    packet.jobToken = data.output.jobToken;
                    packet.jobStatus = data.output.status;

                    runningJobs[data.jobIndex] = true;

                    var allRunning = true;
                    for (var i = 0; i < runningJobs[i]; i++) {
                        if (!runningJobs[i]) {
                            allRunning = false;
                        }
                    }

                    if (allRunning) {
                        packet.uuid = uuid;
                        defer.resolve(packet);
                    }
                });

                return promise;
            },

            actionWait: function(packet) {
                var msgKey = packet.wait,
                    waitTimeout = packet.waitTimeout,
                    uuid = packet.uuid;

                var defer = $q.defer();
                var promise = defer.promise;

                if(actionHandlerUtils.isJobBatchFinished(uuid)){
                    defer.resolve(packet);
                }
                else {
                    var finished = false;
                    var poll;

                    dialog.showDialog(dialog.TYPE.PROGRESS, '', _(msgKey));
                    evBus.on(Wave2.JobMonitorCore.JOB_BATCH_FINISHED_EVENT, function (ev, data) {
                        if ( data === uuid) {
                            finished = true;
                            if (poll) clearTimeout(poll);

                            dialog.closeDialog();
                            defer.resolve(packet);
                        }
                    });

                    if (waitTimeout) {
                        poll = setTimeout(function() {
                            if (!finished) {
                                dialog.closeDialog();
                                defer.resolve(packet);
                            }
                        }, parseInt(waitTimeout));
                    }
                }
                return promise;
            },

            actionNotify: function(packet) {
                var msgKey = packet.notify;

                var defer = $q.defer();
                var promise = defer.promise;

                dialog.showDialog(dialog.TYPE.NOTIFY, '', _(msgKey));

                defer.resolve(packet);
                return promise;
            },

            actionPush: function(packet) {
                var href = packet.redirectFinish,
                    uuid = packet.uuid;

                var defer = $q.defer();
                var promise = defer.promise;

                var jobMonitor = Wave2.JobMonitorCore;
                var jobs = jobMonitor.getJobBatch(uuid);
                var finishUrl = actionHandlerUtils.expandVariables(href, jobs);

                var url = Wave2.WcService.BASE_SERVER_URL + 'push';

                var packetOut = angular.copy(packet);
                packetOut.redirect = finishUrl;
                delete packetOut.redirectBack;
                delete packetOut.redirectFinish;
                packetOut.jobs = angular.copy(jobs);
                var data = {
                    command: packet.push,
                    data: packetOut
                }

                var config = {
                    cache: false,
                    timeout: 60000
                }
                $http.post(url, data, config).then(function(res) {
                    if (res.data) {
                        packet.pushResponse = res.data;
                    }
                    defer.resolve(packet);
                }, function(res) {
                    var message = JSON.parse(res.data);
                    if(message == "Error writing temporary file"){
                        defer.reject("error.write_temp_file_error");
                    }
                    else {
                        defer.reject('error.cannot_push_command');
                    }
                });

                return promise;
            },

            actionJavascript: function(packet) {
                var defer = $q.defer();
                var promise = defer.promise;

                if(packet.javascript){
                    /*
                     * Description of the two set of round brackets technique used below can be found here
                     * https://weblogs.asp.net/dwahlin/creating-custom-angularjs-directives-part-3-isolate-scope-and-function-parameters
                     * https://stackoverflow.com/questions/18234491/two-sets-of-parentheses-after-function-call
                     */
                    var jsInitialResponse = packet.javascript();
                    var jsResponse;
                    if(angular.isFunction( jsInitialResponse )){
                        jsResponse = jsInitialResponse(packet);// eb-action-javascript="function1"
                    }
                    else {
                        jsResponse = jsInitialResponse;// eb-action-javascript="function1()"
                    }
                    packet.javascriptActionIndex = (packet.javascriptActionIndex + 1);

                    packet.jsResponse = jsResponse;
                }

                defer.resolve(packet);
                return promise;
            },

            actionPostMessageBack: function(packet) {
                var href = packet.redirectBack,
                    domain = packet.domain;
                if (domain == null) domain = '*';

                var defer = $q.defer();
                var promise = defer.promise;

                var backUrl = actionHandlerUtils.expandVariables(href, null);

                var packetOut = angular.copy(packet);
                packetOut.redirect = backUrl;
                delete packetOut.redirectBack;
                delete packetOut.redirectFinish;
                packetOut.navCurrentPage = $location.path();
                packetOut.navDirection = 'back';

                // Pass this to the event bus to be posted
                packetOut.domain = domain;
                console.log(APP_EVENTS.actionHandlerStatus, packetOut);
                evBus.broadcast(APP_EVENTS.actionHandlerStatus, packetOut);
                defer.resolve(packet);

                return promise;
            },

            actionPostMessageFinish: function(packet) {
                var href = packet.redirectFinish,
                    uuid = packet.uuid,
                    domain = packet.domain;
                if (domain == null) domain = '*';

                var defer = $q.defer();
                var promise = defer.promise;

                var jobMonitor = Wave2.JobMonitorCore;
                var jobs = jobMonitor.getJobBatch(uuid);
                var finishUrl = actionHandlerUtils.expandVariables(href, jobs);

                var packetOut = angular.copy(packet);
                packetOut.redirect = finishUrl;
                delete packetOut.redirectBack;
                delete packetOut.redirectFinish;
                packetOut.jobs = angular.copy(jobs);
                packetOut.navCurrentPage = $location.path();
                packetOut.navDirection = 'forward';

                // Copy in the job stats
                var registryJobs = registry.get('jobs');
                if (packetOut.jobs && registryJobs) {
                    angular.forEach(packetOut.jobs, function(jobInfoJob){
                        angular.forEach(registryJobs, function (registryJob, index) {
                            if((jobInfoJob.document.id == registryJob.document.id) && registryJob.output){
                                jobInfoJob.stats = registryJob.output.stats;
                            }
                        });
                    });
                }

                // We'd like a top-level multi-job status, so parse the jobs for the most appropriate one
                packetOut.status = 'PROCESSING';
                angular.forEach(packetOut.jobs, function(job) {
                    if (packetOut.status == 'PROCESSING' || packetOut.status == 'FINISHED') {
                        // Stop at the first final state
                        if (job.status == 'CANCELLED') packetOut.status = 'CANCELLED';
                        else if (job.status == 'ERRORED') packetOut.status = 'ERRORED';
                        else if (job.status == 'FINISHED') packetOut.status = 'FINISHED';
                    }
                });

                // Pass this to the event bus to be posted
                packetOut.domain = domain;
                evBus.broadcast(APP_EVENTS.actionHandlerStatus, packetOut);
                defer.resolve(packet);

                return promise;
            },

            actionDownload: function(packet) {
                var mediaType = packet.download,
                    uuid = packet.uuid;

                var defer = $q.defer();
                var promise = defer.promise;
                dialog.closeDialog();
                var mediaUrl;
                if (mediaType) {
                    mediaUrl = invokeGenerate.getOutputUrl(uuid, mediaType,undefined,true);
                } else {
                    mediaUrl = invokeGenerate.getAllOutputZipUrl(uuid, packet.includeSubjobs);
                }
                if (mediaUrl) {
                    $window.open(mediaUrl, "_blank");
                    defer.resolve(packet);
                }
                else {
                    defer.reject('error.cannot_download_route');
                }
                return promise;
            },

            actionRedirectBack: function(packet) {
                var href = packet.redirectBack;

                var defer = $q.defer();
                var promise = defer.promise;

                var jobMonitor = Wave2.JobMonitorCore;
                // Suspend all active job monitoring since we are due to navigate away from this page
                jobMonitor.suspendMonitors(true);

                // Chain stops here since we are redirecting. No need for a promise.
                $window.location = utilitiesService.getNewLocation(href);

                defer.resolve(packet);
                return promise;
            },

            actionRedirectFinish: function(packet) {
                var uuid = packet.uuid,
                    href = packet.redirectFinish;

                var defer = $q.defer();
                var promise = defer.promise;

                if(!(packet && packet.jsResponse && packet.jsResponse.abort)){
                    var jobMonitor = Wave2.JobMonitorCore;
                    // Suspend all active job monitoring since we are due to navigate away from this page
                    jobMonitor.suspendMonitors(true);

                    var jobs = jobMonitor.getJobBatch(uuid);
                    var finishUrl = actionHandlerUtils.expandVariables(href, jobs);

                    // Chain stops here since we are redirecting. No need for a promise.
                    $window.location = utilitiesService.getNewLocation(finishUrl);
                }

                defer.resolve(packet);
                return promise;
            },

            /**
             * Register the actions for this
             * @param actions
             * @return {Array}
             */
            registerActions: function(actions) {
                var actionChain = [];
                angular.forEach(actions, angular.bind(this, function(action) {
                    if (action == 'generate') actionChain.push(this.actionGenerate);
                    if (action == 'wait') actionChain.push(this.actionWait);
                    if (action == 'notify') actionChain.push(this.actionNotify);
                    if (action == 'push') actionChain.push(this.actionPush);
                    if (action == 'download') actionChain.push(this.actionDownload);
                    if (action == 'redirect-finish' || action == 'redirect') actionChain.push(this.actionRedirectFinish);
                    if (action == 'redirect-back') actionChain.push(this.actionRedirectBack);
                    if (action == 'postmessage-finish' || action == 'postmessage') actionChain.push(this.actionPostMessageFinish);
                    if (action == 'postmessage-back') actionChain.push(this.actionPostMessageBack);
                    if (action == 'javascript') actionChain.push(this.actionJavascript);
                }));
                return actionChain;
            },

            /**
             * Run the actions with the provided initial parameters
             * @param {Array} actions - the list of action names, defined in registerActions()
             * @param {Object} params - initial set of parameters to pass to the actions
             */
            execute: function (actions, params) {
                var defer = $q.defer();
                var promise = defer.promise;

                var actionChain = this.registerActions(actions);

                actionChain.reduce(function(promise, item) {
                    return promise.then(item, function(e) {
                        defer.reject(e);
                    });
                }, $q.when(params)).then(function(packet) {
                    defer.resolve(packet);
                }, function(e) {
                    defer.reject(e);
                });

                return promise;
            }

        }
    }])


    /**
     * Utility functions for the action handler
     */
    .service('actionHandlerUtils',['registry', 'buildService', function(registry, buildService) {
        return {

            buildAggregateJobName: function () {

                var jobs = registry.get("jobs");
                var aggregateJobName = "";
                angular.forEach(jobs, function (job, index) {
                    aggregateJobName += buildService.generateJobName(index);
                    if (index < jobs.length - 1) aggregateJobName += ",";
                });

                return aggregateJobName;
            },

            buildAggregateJobToken: function (jobs) {
                var aggregateJobToken = '';
                angular.forEach(jobs, function (job, index) {
                    if (job && job.output && job.output.jobToken) {
                        aggregateJobToken += job.output.jobToken;
                        if (index < jobs.length - 1) aggregateJobToken += ",";
                    } else if (job && job.jobToken) {
                        aggregateJobToken += job.jobToken;
                        if (index < jobs.length - 1) aggregateJobToken += ",";
                    }
                });
                return aggregateJobToken;
            },

            buildJobLabel: function (jobData) {
                var jobLabel = '';
                // Grab the label from the metadata of the first job
                // TODO: use metadataService.getMetaField()
                if(jobData && jobData[0] && jobData[0].metadata && jobData[0].metadata.metadataPair){
                    angular.forEach(jobData[0].metadata && jobData[0].metadata.metadataPair, function(metadataPair) {
                        if(metadataPair.name == 'joblabel') {
                            jobLabel = metadataPair.value;
                        }
                    });
                }
                return jobLabel;
            },

            expandVariables: function (url, jobData) {
                // Pull these values out of the registry and job data and pass them to the
                // destination URL as query params
                if (!url) return url;

                var enc = encodeURIComponent; // Encode strings for the URL

                if (jobData) url = url.replace(/{jobtoken}/g, this.buildAggregateJobToken(jobData));
                if (jobData) url = url.replace(/{joblabel}/g, enc(this.buildJobLabel(jobData)));
                if (registry.get('jobname')) url = url.replace(/{jobname}/g, enc(this.buildAggregateJobName(registry)));
                if (registry.get('product')) url = url.replace(/{product}/g, enc(registry.get('product').name));
                if (registry.get('type')) url = url.replace(/{type}/g, enc(registry.get('type').name));
                if (registry.get('document')) url = url.replace(/{document}/g, enc(registry.get('document').name));
                if (registry.get('document')) url = url.replace(/{docid}/g, enc(registry.get('document').id));
                if (registry.get('document')) url = url.replace(/{format}/g, enc(registry.get('document').format));
                if (registry.get('size')) url = url.replace(/{scale}/g, enc(registry.get('size').name));
                if (registry.get('size')) url = url.replace(/{width}/g, registry.get('size').width);
                if (registry.get('size')) url = url.replace(/{height}/g, registry.get('size').height);
                return url;
            },

            /*
             * Before displaying the wait dialog we should check to see if any jobs are still running.
             * Some HTML5 jobs can complete very quickly and the JOB_BATCH_FINISHED_EVENT is fired before the
             * waiting dialog is displayed.
             */
            isJobBatchFinished: function(uuid){
                var finished = true;
                if ( !Wave2.JobMonitorCore.jobBatches[uuid]) {
                    console.log("job not found.");
                    finished = false;

                } else {
                    for (var i = 0; i < Wave2.JobMonitorCore.jobBatches[uuid].length; i++) {
                        if (Wave2.JobMonitorCore.jobBatches[uuid][i].status != "FINISHED") {
                            finished = false;
                        }
                    }
                }
                return finished;
            }
        }
    }])

;

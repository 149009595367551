angular.module('easybuild.page.pick', [])

/**
 * Combined product/type/document selection page
 */
.controller('PickLevelsCtrl', ['$scope', '$rootScope', '$location', '$log', 'registry', 'configService', 'ebConfig', 'dialog',
                        'metadataService', 'i18n', 'userSession', '$compile',
                        function ($scope, $rootScope, $location, $log, registry, configService, ebConfig, dialog, metadataService,
                                  i18n, userSession, $compile) {

    if (!serverReady(ebConfig)) return;

    $rootScope.showProductLogo = true;
    $scope.$on("$destroy", function(){
        $rootScope.showProductLogo = false;
    });

    if ( !registry.get('jobowner') ) {
        registry.put('jobowner', userSession.username);
    }

    $scope.products = [];
    $scope.types = [];
    $scope.documents = [];
    registry.remove("size");

    $scope.pick = {
        'product': registry.get('product'),
        'type': registry.get('type'),
        'document': registry.get('document'),
        'size' : registry.get('size')
    };

    $scope.search = { name : '' };

    $scope.documentPageSize = ebConfig.get('easybuild.web.pick.document.pagesize');

    // Document click actions
    $scope.selectDoc = function (row) {
        // Store the selected document but don't jump to another page
        $scope.pick.document = row;
        registry.put('product', $scope.pick.product);
        registry.put('type', $scope.pick.type);
        registry.put('document', $scope.pick.document);
        registry.remove("groupIndex");
        registry.remove("articleIndex");

        // Flush the primary document metadata if we switch documents
        var jobs = registry.get("jobs");
        if(jobs && jobs.length >0) {
            metadataService.setMetaField('primaryDoc', null);
            metadataService.setMetaField('baseJobName', null);
        }
    };
    $scope.selectDocAndNext = function (row) {
        // Store the selected document and jump to the next page
        $scope.selectDoc(row);
        $location.path('/build/' + $scope.pick.product.name + '/' + $scope.pick.type.name + '/' + $scope.pick.document.name);
    };

    $scope.zoomPreview = function (row) {
        if (row.hiresimage && row.hiresimage !== "") {
            dialog.showDialog(dialog.TYPE.DISPLAY_IMAGE, "", row.hiresimage, {$compile: $compile, $scope: $scope});
        }
    };

    $scope.onNextAction = function (ev) {
        if (!$scope.pick.document || !$scope.pick.document.name) {
            dialog.showDialog(dialog.TYPE.NOTIFY, _('pick.headline'), _('pick.no_document_selected'));
            ev.preventDefault();
        }
    };

    // Update docs when type is changed
    $scope.updateDocs = function (retainLastSelection) {
        $scope.documents.length = 0;
        if ($scope.pick.product && $scope.pick.type) {
            configService.getDocuments($scope.pick.product, $scope.pick.type).then(function (documents) {
                angular.forEach(documents, function (value) {
                    if (!value.displayimage || value.displayimage === "") {
                        value.displayimage = 'img/no-doc-preview.png';
                    }
                   // else if (value.displayimage.indexOf('/') !== 0) {
                    //    // Prepend the slash
                    //    value.displayimage = '/' + value.displayimage;
                    //}
                    // HTML5 previews must be passed through the /File servlet to play properly
                    if (value.displayimage.indexOf(Wave2.WcService.BASE_SERVER_URL + 'File/webFile') == -1 && value.displayimage.indexOf('.zip') > 0) {
                        value.displayimage = Wave2.WcService.BASE_SERVER_URL + 'File/webFile' + value.displayimage + '/';
                    }
                });
                $scope.documents = documents;
                if (!retainLastSelection && documents && documents.length > 0) {
                    var autoSelectDoc = ebConfig.get('easybuild.web.pick.document.autoselect') || documents.length === 1
                    if (autoSelectDoc) $scope.pick.document = documents[0];
                    else $scope.pick.document = null;
                }
                $scope.documentCurrentPage = 1;
            });
        }
    }

    // Update types when product is changed
    $scope.updateTypes = function(retainLastSelection) {
        $scope.types.length = 0;
        $scope.documents.length = 0;
        if ($scope.pick.product) {
            configService.getTypes($scope.pick.product).then(function (types) {
                $scope.types = types;
                if (!retainLastSelection && types && types.length > 0) {
                    var autoSelectType = (ebConfig.get('easybuild.web.pick.document.autoselect') || types.length === 1);
                    if (autoSelectType) $scope.pick.type = types[0];
                    else $scope.pick.type = null;
                }
                $scope.updateDocs(retainLastSelection);
            });
        }
    };

    // Get the products once
    configService.getProducts().then(function (products) {
        if (!products || products.length === 0) {
            // No products returned from PP
            $scope.products = [];
            Wave2.WcService.fireError();
        }
        else {
            $scope.products = products;
            var retainLastSelection = true;
            var autoSelectProduct = (ebConfig.get('easybuild.web.pick.document.autoselect') || $scope.products.length === 1);
            if ($scope.products && !$scope.pick.product && autoSelectProduct) {
                // Pick the first product and type when we start a new job
                $scope.pick.product = products[0];
                retainLastSelection = false;
            }
            if ($scope.pick.product) {
                $scope.updateTypes(retainLastSelection);
            }
        }
    }, function(reason) {
        throw new Error(i18n.error.failed_to_retrieve_products + reason);
    });

    window.movieClicked = function(elId) {
        if (elId) {
            var container = angular.element('#' + elId).parent();
            container.click();
        }
    }
}]);
angular.module('easybuild.component.eb.pagerange')

.directive('ebPageRange', ['registry', 'i18n', 'evBus', 'utilitiesService', 'APP_EVENTS', 'metadataService',
    function(registry, i18n, evBus, utilitiesService, APP_EVENTS, metadataService){
        return {
            restrict : 'A',
            scope: {
                ebPageRange: '@',
            },
            templateUrl: 'partials/page_range.html',

            controller: ['$scope', function ($scope) {
                $scope.i18n = i18n;
                $scope.pageRange = {};
                $scope.pageRange.selected = false;
                $scope.pageRange.pages = "";
                $scope.pageRange.show = registry.get("jobs")[0].document.format == "indd";

                $scope.updatePageRange = function(){
                    var tagname = 'outputConfiguration';
                    var jobIndex = 0;



                    var key = "pagerange";

                    var keyValuePairs = [];
                    var outputConfiguration = metadataService.getMetadataBlock(jobIndex, tagname, undefined, $scope.ebPageRange);
                    if (outputConfiguration && outputConfiguration.keyValuePairs ){
                        keyValuePairs = outputConfiguration.keyValuePairs;
                    }
                    for (var index = 0; index < keyValuePairs.length; index++) {
                        var keyValue = keyValuePairs[index];
                        if (keyValue.name == key) {
                            keyValuePairs.splice(index, 1);
                            break;
                        }
                    }

                    if ( $scope.pageRange.selected && $scope.pageRange.pages && $scope.pageRange.pages.length > 0){
                        keyValuePairs.push({name: key, value: $scope.pageRange.pages, tagName: "parameter"});
                    }

                    if ( keyValuePairs.length > 0) {
                        metadataService.setMetadataBlock(jobIndex, tagname, keyValuePairs, undefined, $scope.ebPageRange);
                    } else {
                        metadataService.removeMetadataBlock(jobIndex, tagname, undefined, $scope.ebPageRange);
                    }
                };
                $scope.updatePageRange();
            }]
        }
    }])
angular.module('easybuild.shared.config')

/**
 * Easybuild configuration provider, pulls the config from the server
 */
.provider('ebConfig', ['WORKFLOW_CONFIG', function(WORKFLOW_CONFIG) {
    this._config = null;

    this.load = function() {
        // Load the config file synchronously using jQuery
        var q;
        if (window._W2_CONFIG_DEBUG) {
            q = {};
        }
        else {
            q = jQuery.ajax({
                type: 'GET', url: Wave2.WcService.BASE_SERVER_URL + 'version', cache: false, async: false,
                contentType: 'application/json', dataType: 'json', timeout: 5000
            });
        }
        var config = null;
        if (q.status === 200) {
            config = angular.fromJson(q.responseText);
            angular.forEach(config, function(value, key) {
                var constant = null;
                // Search for specific keys to inject into the constants on initialisation
                if (key === 'easybuild.navigation.page.home') constant = 'defaultHomePage';
                else if (key === 'easybuild.navigation.page.notfound') constant = 'defaultNotFoundPage';
                else if (key === 'easybuild.navigation.page.jobstart') constant = 'defaultJobStartPage';
                else if (key === 'easybuild.navigation.login.enabled') constant = 'useDefaultLoginPage';
                else if (key === 'easybuild.navigation.page.logout') constant = 'defaultLogoutPage';
                else if (key === 'easybuild.web.sso.enabled') constant = 'ssoEnabled';
                else if (key === 'easybuild.web.sso.autologin') constant = 'ssoAutoLogin';
                if (constant) {
                    WORKFLOW_CONFIG[constant] = value;
                }
            });
            angular.extend(WORKFLOW_CONFIG, config);
        }
        this._config = config;
    };

    this._INSTANCE = function(config) {
        this._config = config;

        this.isInitialised = function () {
            return (this._config != null);
        }

        this.getConfigAsObject = function () {
            var config = this._config;
            if (!config) return null;

            var asJson = {};
            angular.forEach(config, function (i, key) {
                if (!key || !config.hasOwnProperty(key)) return;

                var keyParts = key.toString().split(".");
                var level = asJson;
                for (var j = 0; j < keyParts.length; j++) {
                    if (j == keyParts.length - 1) {
                        level[keyParts[j]] = config[key];
                    }
                    else if (level[keyParts[j]] == undefined) {
                        level[keyParts[j]] = {};
                        level = level[keyParts[j]];
                    }
                    else {
                        level = level[keyParts[j]];
                    }
                }
            });

            return asJson.easybuild ? asJson.easybuild : null;
        };

        this.get = function (key) {
            if (!this._config || !this._config[key]) return null;
            if (key === 'easybuild.web.rte.controls' || key === 'easybuild.web.rte.translation.controls') {
                return this.resolveRTEFlags(this._config[key]);
            }
            return this._config[key];
        }

        this.resolveRTEFlags = function (flags) {
            var values = [];
            angular.forEach(flags, function (flagString) {
                var parts = flagString.split('.');
                var flag = window;
                angular.forEach(parts, function (part) {
                    flag = flag[part];
                    if (!flag) return false;
                });
                if (flag) values.push(flag);
            });
            return values;
        }

        return this;
    }

    this.$get = function() {
        var instance = this._INSTANCE(this._config);
        return instance;
    };
}]);
'use strict';

angular.module('easybuild.shared.event')

    .constant('APP_EVENTS', {
        applicationLoaded: 'eb.application.loaded',
        applicationErrored: 'eb.application.errored',

        materialfilesReload: 'eb.materialfiles.reload',
        actionoverlayClear: 'eb.actionoverlay.clear',

        doccontentValidateFields: 'validate',
        doccontentGetGroups: 'doccontent.groups',
        doccontentGetStructure: 'doccontent.structure',
        doccontentLoadGalleryTree: 'gallery',
        doccontentGetLibraries: 'doccontent.libraries',
        doccontentGetLibraryItem: 'doccontent.libraryitemstructure',
        doccontentSaveGridDefaults: 'doccontent.saveGridDefaults',
        doccontentLoadGridDefaults: 'doccontent.loadGridDefaults',
        doccontentToggleTranslateFields: 'doccontent.toggleTranslateFields',
        designModeBackgroundSolids: 'doccontent.backgrounds',
        designModeBorderClips: 'doccontent.borders',

        generateStart: 'generate.start',//Event triggered from JobGenerator._generateJob
        generateStatus: 'generate.status',
        generateCancel: 'generate.cancel',

        zoomToggle: 'eb.zoom.toggle',
        mediaManagerRefresh: 'eb.mediamanager.refresh',
        mediaManagerChangeField: 'eb.mediamanager.changefield',
        mediaManagerGetMediaList: 'eb.mediamanager.getMediaList',
        mediaManagerListRecieved: 'eb.mediamanager.mediaListRecieved',
        mediaManagerGetClipartCategories: 'eb.mediamanager.getClipartCategories',
        mediaManagerClipartCategoriesRecieved: 'eb.mediamanager.clipartCategoriesRecieved',
        previewLoaded: 'preview.loaded',

        transcodeComplete: 'eb.transcode.complete',

        actionHandlerStatus: 'eb.actionhandler.status',
        resetForm: 'eb.reset.form',
        resetBatch: 'eb.batch.reset',

        jobSaved: 'eb.job.saved',

        jobXmlUpdated: 'eb.job.xml.updated',

        cropLoaded: 'eb.crop.loaded',
        cropReset: 'eb.crop.reset',
        unitsChanged: 'eb.units.changed',

        downloadExcel: 'eb.download.excel',

        batchFinished: 'eb.batch.finished',

        scriptsOff: 'eb.scripts.off',
        validationXMLSummary: 'validation.xml.summary'
    })

    .factory('PUBLISH_EVENTS', ['APP_EVENTS', 'AUTH_EVENTS', function (APP_EVENTS, AUTH_EVENTS) {
        return [
            /* Application lifecycle events */
            AUTH_EVENTS.loginSuccess,
            AUTH_EVENTS.loginFailed,
            AUTH_EVENTS.logoutSuccess,
            AUTH_EVENTS.sessionTimeout,
            AUTH_EVENTS.loginTimeout,
            AUTH_EVENTS.forceLogout,
            APP_EVENTS.applicationLoaded,
            APP_EVENTS.applicationErrored,
            APP_EVENTS.validationXMLSummary,

            /* Media manager events */
            APP_EVENTS.mediaManagerChangeField,
            Wave2.DocContent.FIELD_SELECT_COMPLETE_EVENT,

            APP_EVENTS.actionHandlerStatus,
            APP_EVENTS.jobSaved
        ];
    }])

    .constant('AUTH_EVENTS', {
        loginSuccess: 'eb.auth.login.success',
        loginFailed: 'eb.auth.login.failed',
        logoutSuccess: 'eb.auth.logout.success',
        sessionTimeout: 'eb.auth.session.timeout',
        notAuthenticated: 'eb.auth.not.authenticated',
        notAuthorised: 'eb.auth.not.authorised',
        loginTimeout: 'eb.auth.login.timeout',
        forceLogout: 'eb.auth.force.logout'
    });


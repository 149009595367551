'use strict';

require('./shared/shared.module');
require('./pages/pages.module');
require('./components/components.module');

// Declare app level module which depends on filters, and services
var modules = [
    'ngRoute',
    'ngCookies',
    'ngTable',
    'angularFileUpload',
    'mgcrea.ngStrap',

    'easybuild.shared',
    'easybuild.page',
    'easybuild.component',
    'easybuild.custom',

    'easybuild.pagination',
    'ui.select'
];

angular.module('easybuild', modules).config(['$provide', '$routeProvider', '$locationProvider', '$httpProvider',
    'USER_ROLES', 'WORKFLOW_CONFIG','$datepickerProvider', 'ebConfigProvider',
    function($provide, $routeProvider, $locationProvider, $httpProvider, USER_ROLES, WORKFLOW_CONFIG, $datepickerProvider, ebConfigProvider) {
        Wave2.WcService.BASE_SERVER_URL = 'service/';

    // Read the config file on initialisation
    ebConfigProvider.load();

    // Exception handling
    $provide.decorator('$exceptionHandler', ['$delegate', '$injector', 'APP_EVENTS', 'dialog', function($delegate, $injector, APP_EVENTS, dialog) {
        return function(exception, cause) {
            $delegate(exception, cause);
            dialog.showExceptionDialog(exception, cause);

            // This is a somewhat more comprehensive event. Unlike Wave2.WC.COMPONENT_ERRORED, which
            // only delivers caught errors within the EasyBuild/WC code, this event also captures
            // unexpected JS errors within the EasyBuild/Angular framework
            var evBus = $injector.get('evBus');
            evBus.broadcast(APP_EVENTS.applicationErrored, {
                exception: exception,
                cause: cause
            });
        };
    }]);

    if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
    }
    // Disable IE Ajax request caching
    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
    $httpProvider.defaults.withCredentials = true;

    $routeProvider.when('/', {redirectTo: WORKFLOW_CONFIG.defaultHomePage});

    $routeProvider.when('/build', {templateUrl: 'partials/pick.html', controller: 'PickLevelsCtrl',
        label: 'Product', authorisedRoles: [ USER_ROLES.user ]});

    $routeProvider.when('/launch', {templateUrl: 'partials/launch.html', controller: 'LaunchCtrl',
        label: 'Launch', authorisedRoles: [ ]});

    $routeProvider.when('/build/:product', {templateUrl: 'partials/pick.html', controller: 'PickLevelsCtrl',
        label: 'Type', authorisedRoles: [USER_ROLES.user]});

    $routeProvider.when('/build/:product/:type', {templateUrl: 'partials/pick.html', controller: 'PickLevelsCtrl',
        label: 'Document', authorisedRoles: [ USER_ROLES.user ]});

    $routeProvider.when('/build/:product/:type/:document', {templateUrl: 'partials/build.html', controller: 'BuildCtrl',
        label: 'Build', authorisedRoles: [ USER_ROLES.user ]});

    $routeProvider.when('/build/:product/:type/:document/' + _('build.edit_bundled_document') + '/:associatedDocument', {templateUrl: 'partials/build.html', controller: 'BuildCtrl',
        label: 'Build', authorisedRoles: [ USER_ROLES.user ]});

    $routeProvider.when('/build/:product/:type/:document/review', {templateUrl: 'partials/proof.html', controller: 'ProofCtrl',
        label: 'Review', authorisedRoles: [ USER_ROLES.user ]});

    $routeProvider.when('/job/retrieve', {templateUrl: 'partials/job_retrieve.html', controller: 'JobRetrieveCtrl' ,
        label: 'Retrieve Job', authorisedRoles: [ USER_ROLES.user ]});

    $routeProvider.when('/job/store', {templateUrl: 'partials/job_store.html', controller: 'JobStoreCtrl',
        label: 'Store Job', authorisedRoles: [ USER_ROLES.user ]});

    $routeProvider.when('/job/details/:owner/:name', {templateUrl: 'partials/job_store.html', controller: 'JobUpdateCtrl',
        label: 'Update Job', authorisedRoles: [ USER_ROLES.user ]});

    $routeProvider.when('/batch/new', {templateUrl: 'partials/batch_editor.html', controller: 'BatchEditorCtrl',
        label: 'Batch Editor', authorisedRoles: [ USER_ROLES.user ]});

        $routeProvider.when('/lightbox', {templateUrl: 'partials/batch_management.html', controller: 'BatchManagerCtrl',
            label: 'Lightbox', authorisedRoles: [ USER_ROLES.user ], reloadOnSearch: false});

        $routeProvider.when('/lightbox/:batchid', {templateUrl: 'partials/lightbox.html', controller: 'BatchManagerCtrl',
            label: 'Lightbox', authorisedRoles: [ USER_ROLES.user ]});

    $routeProvider.otherwise({redirectTo: WORKFLOW_CONFIG.defaultNotFoundPage});

    // use the HTML5 History API where available
    $locationProvider.html5Mode(true);

    // Initialise the default data picker UI component settings
    angular.extend($datepickerProvider.defaults, {
        dateFormat: 'dd/MM/yyyy',
        startWeek: 1,
        dateType: 'iso'
    });

}]).run(["$rootScope", "$window", "$log", "WORKFLOW_CONFIG", "AUTH_EVENTS", "authService", "configService", "registry", "ebConfig", "evBus", "$location", '$http', 'userSession',
    function ($rootScope, $window, $log, WORKFLOW_CONFIG, AUTH_EVENTS, authService, configService, registry, ebConfig, evBus, $location, $http, userSession) {

    var routeParams = {};

    // Register a listener to check authentication and perform any jump actions before route change
    $rootScope.$on('$routeChangeStart', function(event, next) {
        routeParams = next.params;

        // Disable any SWF click actions added by another page
        window.movieClicked = function() {};

        // Flush the event bus for the new page
        evBus.clear();
        var searchObject = $location.search();

        if (WORKFLOW_CONFIG.ssoEnabled && searchObject && searchObject.sso === 'success' ){
            $http.get(Wave2.WcService.BASE_SERVER_URL + 'user/info').then(function (res) {
                if (res && res.data) {
                    userSession.create(res.data.username, res.data.roles);
                    authService.userUserGroups(userSession.username, false).then(function (userGroups) {
                        localStorage.removeItem('userGroups');
                        localStorage.setItem('userGroups', JSON.stringify(userGroups));
                        $location.search('sso', null);
                        // Inject the jump action into the router resolve dependencies,
                        // to ensure we jump before loading the page
                        next.resolve = {
                            '__jumpToDocument__': function() {
                                return registry.jumpToDocument(routeParams, registry);
                            }
                        };
                    });
                }
            });
        } else {

            // Check the user is authorised
            var authorisedRoles = next.authorisedRoles;
            if (!authService.isAuthorised(authorisedRoles)) {
                event.preventDefault();

                if (!authService.isAuthenticated() && next.$$route.templateUrl !== 'partials/login.html') {
                    // User is not logged in, show the login form
                    if (WORKFLOW_CONFIG.ssoEnabled) {
                        next.$$route.templateUrl = 'partials/sso.html';
                        next.$$route.controller = 'SsoCtrl';
                    } else if (WORKFLOW_CONFIG.useDefaultLoginPage) {
                        next.$$route.templateUrl = 'partials/login.html';
                        next.$$route.controller = 'LoginCtrl';
                    }
                    localStorage.removeItem("userGroups");
                    evBus.broadcast(AUTH_EVENTS.notAuthenticated);
                    return;
                } else {
                    // user is not allowed
                    evBus.broadcast(AUTH_EVENTS.notAuthorised);
                    return;
                }
            }
        }

        // Inject the jump action into the router resolve dependencies,
        // to ensure we jump before loading the page
        next.resolve = {
            '__jumpToDocument__': function() {
                return registry.jumpToDocument(routeParams, registry);
            }
        };
    });
}]);

angular.module('easybuild.component.eb.resizing')

.filter('scales', ['ebConfig', function(ebConfig) {
    return function(scales) {
        var result = [];
        angular.forEach(scales, function (scale) {
            // Ensure the width and height values are strings for the drop-down
            if (angular.isNumber(scale.width)) {
                scale.width = scale.width.toFixed(2);
            }
            if (angular.isNumber(scale.height)) {
                scale.height = scale.height.toFixed(2);
            }

            // Ensure the default scale is the first in the list
            if (scale.name == ebConfig.get('easybuild.w2pp.scale.default')) {
                result.unshift(scale);
            }
            else {
                result.push(scale);
            }
        });

        return result;
    }
}]);
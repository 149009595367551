angular.module('easybuild.shared.generation')

.service('bundleService', ['$location', '$window', '$timeout', 'registry', 'dialog', 'ebConfig', 'metadataService', 'configService', '$q',
    function($location, $window, $timeout, registry, dialog, ebConfig, metadataService, configService, $q) {

    this.updateEditedFlag = function(fieldData){
        var job = registry.get('job');

        var tagName = "easybuildedits";
        var editDate = new Date();
        var editDateISOString = editDate.toISOString();

        var metadataBlock = metadataService.getMetadataBlock(job.index,tagName);
        var keyValuePairs;
        if(!metadataBlock){
            keyValuePairs = [];
        }
        else {
            keyValuePairs = metadataBlock.keyValuePairs;
        }

        var found = false;

        for(var pairIndex = 0 ; pairIndex < keyValuePairs.length ; pairIndex++){
            var currentPair = keyValuePairs[pairIndex];

            if(currentPair.name == fieldData.name){
                found = true;
                break;//No action required the field has already been marked as edited
            }
        }
        if(!found){
            keyValuePairs.push({ tagName : 'metafield', name : fieldData.name, value : editDateISOString});
            metadataService.setMetadataBlock(job.index, tagName, keyValuePairs);
        }
    };

    /**
     * Initiate an edit of a job (in multi-job workflows)
     * @param jobIndex - the job to edit in the registry jobs array
     */
    this.editJob = function(jobIndex) {

        var jobs = registry.get("jobs");
        var currentArticleKey = jobs[jobIndex].groupArticleMapping[0][0];

        var editCallback = function(){
            var job = registry.get("job");
            var currentLocation = $window.location.href;
            var lastSlashPos = currentLocation.lastIndexOf('/');
            $window.location = currentLocation.substring(0, lastSlashPos) + "/" + _('build.edit_bundled_document') + "/" + job.document.name;
        }

        var job;

        if(registry.contains("job") && currentArticleKey && (currentArticleKey != registry.get("job").bundledGroupArticleMapping[0][0])) {
            job = jobs[jobIndex];
            job.groupArticleMapping = jobs[jobIndex].groupArticleMapping;
            job.index = jobIndex;
            job.bundledGroupArticleMapping = registry.get("job").bundledGroupArticleMapping;
            registry.put("job",job);
            editCallback();
        }
        else {
            this.createBundledEdit(jobIndex, editCallback);
        }
    };

    /**
     * Separates the first article from the specified job in the jobs array
     * Use editJob() to initialise an new edit workflow, this is only the initialisation bit
     * @param jobIndex - job to target
     */
    this.createBundledEdit = function(jobIndex, editCallback) {
        Wave2.ArticleManager.detachMultiJobArticles(registry, jobIndex);
        this.copyColourChanges();
        this.updateSizes(jobIndex, editCallback);

    };

    this.copyColourChanges = function(){
        var jobs = registry.get('jobs');
        var masterJob = registry.get('masterJob');
        var colourChanges = jobs[0].colourChanges;
        var effects = jobs[0].effects;
        if ( masterJob === undefined || masterJob === null ){
            masterJob = {};
            masterJob.colourChanges = colourChanges;
        } else if ( masterJob.colourChanges !== undefined){
            colourChanges = masterJob.colourChanges;
            effects = masterJob.effects;
        }

        for(var i=1; i<jobs.length;i++){
            if ( jobs[i].colourChanges === undefined){
                jobs[i].colourChanges = colourChanges;
                jobs[i].effects = effects
            }
        }
        registry.put('masterJob', masterJob);
        registry.put('jobs', jobs);
    };

    this.updateSizes = function(jobIndex, editCallback){
        var jobs = registry.get('jobs');
        if ( !jobs || jobs.length < jobIndex){
            if ( editCallback ) {
                editCallback();
            }
            return;
        }
        var job = jobs[jobIndex];
        if ( !job || !job.groupArticleMapping || !job.document ){
            if ( editCallback ) {
                editCallback();
            }
            return;
        }
        var groupArticleMapping = job.groupArticleMapping;

        var groups = groupArticleMapping.length;

        // uuid for jobIndex
        var documentId = job.document.id;

        // loop through each group add a get doc
        var docContentPromises = [];
        for (var groupIndex =0; groupIndex<groups; groupIndex++){
            docContentPromises[groupIndex] = configService.getDocContent(documentId, groupIndex);
        }

        var findItemWithMatchingName = function(itemList, name){
            var matchingItem = null;
            angular.forEach(itemList, function(item){
                if(item.name === name){
                    matchingItem = item;
                }
            });
            return matchingItem;
        };

        var updateImageItem = function(itemList, currentItem){
            var matchingItem = findItemWithMatchingName(itemList, currentItem.name);
            if ( matchingItem ) {
                currentItem.frameHeight = matchingItem.frameHeight;
                currentItem.frameWidth = matchingItem.frameWidth;
                currentItem.cropHeight = matchingItem.cropHeight;
                currentItem.cropWidth = matchingItem.cropWidth;
            }
        };

        // wait for all and update frame sizes
        $q.all(docContentPromises).then(function(results){
            if (!Array.isArray(results) ){
                results = [results];
            }

            for (var groupIndex =0; groupIndex<groups; groupIndex++){
                for  (var articleIndex =0; articleIndex<groupArticleMapping[groupIndex].length; articleIndex++){
                    var articleKey = groupArticleMapping[groupIndex][articleIndex];
                    var article = registry.get(articleKey);

                    if (article.imageItems) {
                        angular.forEach(article.imageItems, function (item, pos) {
                            updateImageItem(results[groupIndex].imageItems, item);
                        });
                    }
                    if (article.flashImageItems) {
                        angular.forEach(article.flashImageItems, function (item, pos) {
                            updateImageItem(results[groupIndex].flashImageItems, item);
                        });
                    }
                    registry.put(articleKey, article);
                }
            }
            if ( editCallback ) {
                editCallback();
            }
        });
    };

    this.haveBundledEditsBeenMade = function(){
        var bundledEditsExist = false;
        var jobs = registry.get("jobs");

        for(var jobIndex = 0; jobIndex < jobs.length ; jobIndex++){
            if(this.hasBundledEditBeenMade(jobIndex)){
                bundledEditsExist = true;
            }
        }
        return bundledEditsExist;
    };

    this.hasBundledEditBeenMade = function(jobIndex) {
        var bundledEditExists = false;
        var block = metadataService.getMetadataBlock(jobIndex, "easybuildedits");
        if(block && block.keyValuePairs && (block.keyValuePairs.length > 0) ) bundledEditExists = true;
        return bundledEditExists;
    };

    this.clearBundledEdits = function(){
        var jobs = registry.get("jobs");
        var masterJob = registry.get('masterJob');
        if (masterJob){
            jobs[0].colourChanges = masterJob.colourChanges;
            jobs[0].effects = masterJob.effects;
        }
        registry.remove('masterJob');
        for(var i=1; i<jobs.length;i++) {
            jobs[i].colourChanges = undefined;
            jobs[i].effects = undefined;
        }
        registry.put('jobs', jobs);
        for(var jobIndex = 0 ; jobIndex < jobs.length ; jobIndex++ ){
            metadataService.setMetadataBlock(jobIndex, 'easybuildedits', []);
        }
    };

    /**
     * Cancels all job edits and reverts to the original bundled generation behaviour
     */
    this.cancelAllBundledEdits = function(ev, showWarning) {
        var jobCount = registry.get('jobs').length;
        if (jobCount >= 2 && Wave2.ArticleManager.areMultiJobArticlesDetached(registry)) {
            if (ev) ev.preventDefault();
            if (showWarning === false || !this.haveBundledEditsBeenMade()) {
                Wave2.ArticleManager.mergeMultiJobArticles(registry);

                if(showWarning === false && this.haveBundledEditsBeenMade()){
                    this.clearBundledEdits();
                }

                var el = ev ? angular.element(ev.target) : null;
                if (ev && el && el.attr('href')) {
                    // Ensure the redirect is always invoked on the next digest
                    $timeout(function() {
                        $location.url(el.attr('href'));
                    }, 1);
                }
                return;
            }

            var bundledService = this;
            dialog.showDialog(dialog.TYPE.CONFIRM, '', _('global.abandon_edits_message'), {
                onCancelClicked: function() {
                    dialog.closeDialog();
                },
                onOkClicked: function() {
                    dialog.closeDialog();
                    bundledService.clearBundledEdits();
                    Wave2.ArticleManager.mergeMultiJobArticles(registry);
                    var el = angular.element(ev.target);
                    if (ev && el.attr('href')) {
                        // Ensure the redirect is always invoked on the next digest
                        $timeout(function() {
                            $location.url(el.attr('href'));
                        }, 1);
                    }
                }
            });
        }
    };

    return this;
}]);
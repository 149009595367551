angular.module('pages.build.build-media.directive', [])


  /*
   * Changing action overlay to allow 4 buttons
   */
  .directive('ebMakeRoomIfRequired', ['registry',function(registry){
    return {
      restrict: 'A',
      link: function($scope, element, attrs){
        var buildMedia = attrs.buildmedia == "true";
        var deleteFrame = attrs.deleteframe == "true";
        if(buildMedia && deleteFrame){
           element.css('height','28px');
        }
      }
    };
  }])

    .directive('ebBuildMediaCreate',['registry', '$window', '$location' , function(registry,$window,$location){
        return {
            restrict : 'A',
            link : function($scope, element){
                var jobxml = $scope.getImageSubJobXml(element);
                if  (!jobxml) {
                    element.css('width', '100%');

                }
                element.closest('.image_field').on("deleteImage", function(){
                    element.css('width', '100%');
                });
            }
        }
    }])

    .directive('ebBuildMediaEdit',['registry', '$window', '$location', 'evBus', 'APP_EVENTS', 'ebConfig' , function(registry,$window,$location, evBus, APP_EVENTS, ebConfig){
        return {
            restrict : 'A',
            link : function($scope, element){
                var jobxml = $scope.getImageSubJobXml(element);

                if  (!jobxml) {
                    element.css('display','none');
                }else {
                    if ( $scope.needsSubJobPreview(element)){
                        var imageFieldDiv = angular.element(element).parents(".image_field");
                        var image = angular.element(imageFieldDiv).find("img.image_preview");
                        var fieldName = imageFieldDiv.data('name');
                        var jobIndex = Wave2.ArticleManager.getJobIndex();
                        var groupIndex = registry.get("groupIndex") || 0;
                        var articleIndex = registry.get("articleIndex") || 0;

                        var getItemItem = function(){
                            var articleKey = Wave2.ArticleManager.getArticleKey(registry, groupIndex, articleIndex, jobIndex);
                            var article = registry.get(articleKey);
                            var imageItem = undefined;
                            if (article.imageItems) imageItem = article.imageItems.find(function (image) {
                                return image.name == fieldName
                            });
                            if (!imageItem && article.flashImageItems) imageItem = article.flashImageItems.find(function (image) {
                                return image.name == fieldName
                            });
                            return {articleKey: articleKey, article: article, imageItem: imageItem};
                        };

                        image[0].src = '';
                        if ( ebConfig.get('easybuild.web.buildmedia.useDocumentPreview') ){
                            var __ret = getItemItem();
                            if (__ret.imageItem && __ret.imageItem.previewUrl ) {
                                image[0].src = __ret.imageItem.previewUrl;
                            }
                        }

                        var updatepreview = function(){
                            if ( image.attr('src') == '' || $scope.needsSubJobPreview(element)) {
                                var jobs = registry.get('jobs');
                                var jobXml = jobs[jobIndex].jobxml;
                                if (jobXml) {
                                    var groupxmlIndex = parseInt(groupIndex) + 1;
                                    var articlexmlIndex = parseInt(articleIndex) + 1;
                                    var articlexml = $(jobXml).find('articlegroup[id="' + groupxmlIndex + '"]>article[id="' + articlexmlIndex + '"]');
                                    var items;
                                    var index = 0;
                                    for (index = 0; index < articlexml.length; index++) {
                                        if ((!items || items.length < 1) && $(articlexml[index]).parents('subJob').length == 0 ) {
                                            items = $(articlexml[index]).find('image[name="' + fieldName + '"]');
                                            if (items.length < 1) {
                                                items = $(articlexml[index]).find('flashimage[name="' + fieldName + '"]');
                                            }
                                            if (items.length < 1) {
                                                items = $(articlexml[index]).find('img[name="' + fieldName + '"]');
                                            }
                                            if ( items.length >= 1){
                                                break;
                                            }
                                        }
                                    }
                                    if (items && items.length >= 1) {
                                        var newUrl = items.attr('url');
                                        if (newUrl) {
                                            var __ret = getItemItem();
                                            var articleKey = __ret.articleKey;
                                            var article = __ret.article;
                                            var imageItem = __ret.imageItem;
                                            if (imageItem) {
                                                imageItem.value = newUrl;
                                                if (!ebConfig.get('easybuild.web.buildmedia.useDocumentPreview')) {
                                                    imageItem.previewUrl = '';
                                                }
                                                registry.put(articleKey, article);
                                                var fieldEl = imageFieldDiv.find("input");
                                                fieldEl.val(newUrl);
                                                if (ebConfig.get('easybuild.web.buildmedia.useDocumentPreview') && imageItem.previewUrl) {
                                                    image[0].src = imageItem.previewUrl;
                                                }else {
                                                    image[0].src = Wave2.WcService.BASE_SERVER_URL + 'Thumbnail?command=getThumbnail&width=159&height=105&path=' + newUrl;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        };
                        updatepreview();
                        evBus.on(APP_EVENTS.jobXmlUpdated, updatepreview);
                    }
                }

                element.closest('.image_field').on("deleteImage", function(){
                    element.css('display','none');
                });
            }
        }
    }])


  /*
   * After build media has been clicked the following widgets can be placed on the bottom navbar
   * TEXT INPUT - Choose the name of the output (typical an image which will form an image in the parent)
   * Cancel Button - Redirect back to the parent document
   * Save Button -
   *  Upon the “Save” button being pressed, the following actions will be undertaken.
   *  1). A validation check is performed to check that the image name field has been entered is if hasn't then the save action will
   *  not proceed further
   *  2). A Validation check is performed to check that the name contains only alphanumeric characters if it does not then the save action will
   *  not proceed further
   *  3). The resulting image will be saved, with the name will be a prefix followed by a prefix based on the output type e.g _page1_Wave2JpegRoute.jpg
   *  when the output type is jpeg.
   *  4). The Design Mode template will be closed, and the user presented back with the main template they were working on.
   */
  .directive('ebBuildMediaNavButtons',['registry', 'i18n', 'configService', 'ebConfig', 'dialog', 'restService', '$window', '$q', 'metadataService', 'evBus', 'APP_EVENTS', 'utilitiesService', 'jobService',
        function(registry, i18n, configService, ebConfig, dialog, restService, $window, $q, metadataService, evBus, APP_EVENTS, utilitiesService, jobService){
        return {
            restrict : 'E',
            templateUrl: 'partials/buildmedia.html',
            link : {
                pre: function(scope){
                    //Need to have this function declaration first
                    scope.copyMetadataFromMasterToSubsidiary= function(){
                        var jobs = registry.get("jobs");
                        var buildMediaAssociation = registry.get("buildMediaAssociation");
                        scope.buildMediaReturnUrl = buildMediaAssociation.buildMediaReturnUrl;
                        if (!jobs[0].metadata) {
                            // only set the metadata if the job does not have one. Also need to not set the joblabel, baseJobName or primaryDoc
                            var metadata = buildMediaAssociation.metadata;
                            if (metadata && metadata.metadataPair && Array.isArray(metadata.metadataPair)) {
                                metadataService.removeMetaField(metadata, "joblabel");
                                metadataService.removeMetaField(metadata, "baseJobName");
                                metadataService.removeMetaField(metadata, "primaryDoc");
                            }
                            jobs[0].metadata = metadata;
                            registry.put("jobs", jobs);
                        }
                    }

                    scope.i18n = i18n;
                    if(registry.contains("jobs")){
                        scope.copyMetadataFromMasterToSubsidiary();
                    }
                    else {
                        evBus.on(Wave2.ArticleManager.JOBS_LOADED_EVENT, function(ev, scope){
                            scope.copyMetadataFromMasterToSubsidiary();
                        });
                    };

                    getChannelData();

                    ////////////////////////////////////////////////////////////////////////////////////////////////////
                    ///                                    Initial Setup                                             ///
                    ////////////////////////////////////////////////////////////////////////////////////////////////////

                    function getChannelData(){
                        var buildChannelName = ebConfig.get('easybuild.w2pp.channel.buildmedia');
                        restService.getChannelData().then(function(res){
                            var channels = res.data;
                            angular.forEach(channels, function(channel){
                               if(channel.name == buildChannelName){
                                   scope.channel = channel;
                               }
                            });
                        });
                    }


                    ////////////////////////////////////////////////////////////////////////////////////////////////////
                    ////                            Called from buildmedia.html                                     ////
                    ////////////////////////////////////////////////////////////////////////////////////////////////////

                    scope.cancelBuildMedia = function() {
                        var buildMediaAssociation = registry.get("buildMediaAssociation");
                        registry.restore(buildMediaAssociation.originalRegistry);
                    };
                    scope.saveBuildMedia = function(packet){
                        if(!packet.jobToken){
                            evBus.broadcast(APP_EVENTS.scriptsOff);
                            //We haven't done the generation yet. At this stage we can just save the jobLabel
                            var jobLabel = 'SUBJOB' + Wave2.WcService.createUUID();

                            var buildMediaAssociation = registry.get("buildMediaAssociation");

                            if ( buildMediaAssociation.jobLabel ) {
                                jobLabel = buildMediaAssociation.jobLabel;
                            }

                            var jobIndex = buildMediaAssociation.jobIndex;

                            metadataService.setMetaField('joblabel',jobLabel,jobIndex);
                        }
                        else {
                            scope.jobId = packet.jobToken.split('/').pop();
                            if ( packet.jobStatus === "FINISHED") {
                                jobService.getJobOutput(packet.jobToken).then(function (jobOutputs) {
                                    var outputType = scope.getOutputType(jobOutputs.output); // 2
                                    $q.all([scope.getOutputURL(packet.jobToken, outputType), scope.getJobXML(packet.jobToken)])
                                        .then(
                                            scope.setOutputAndResetRegistry, // 4
                                            scope.showGenericError
                                        );
                                }, function(reason) {
                                    throw new Error(i18n.error.failed_to_get_job_output + reason);
                                });
                            } else {
                                scope.showStatusError(packet.jobStatus);
                            }
                        }
                    };

                     ///////////////////////////////////////////////////////////////////////////////////////////////////
                     ////                            Save Build Media Helpers                                        ///
                     ///////////////////////////////////////////////////////////////////////////////////////////////////

                    scope.getJobXML = function(jobToken){
                        var jobId = jobToken.split('/').pop();
                        return restService.getJobBridge(jobId).then(function(response){
                            return response.data.xml;
                        });
                    };

                    scope.getOutputType = function(outputs){ // 2
                         var document = registry.get("document");
                         var format = document.format;

                         /*
                          * Format is one of the constant on DocumentFormatConstants including
                          * indd, as, flash, html5, html5_w2, html5_gwd, html5_edge, animated_gif and
                          * html
                          */
                         var priorityArray = ebConfig.get("easybuild.web.buildmedia.output.priority." + format);

                         var acceptedOutputTypes;//It must be possible to display the output in the image box
                         if(format == "indd"){
                             acceptedOutputTypes = ["pdf","jpeg","eps","png"];
                         }
                         else if(format == "html"){
                             acceptedOutputTypes = ["html5","html"];
                         }
                         else if(format == "html5"){
                             acceptedOutputTypes = ["pdf","jpeg","eps","png", "html5"];
                         }
                        else {

                             var errorMessage = _("error.build_media_unsupported_subsidiary_format",
                                 format);

                            throw new Error(errorMessage);
                        }

                        //Full list of possible output types in OutputType.java


                         if(priorityArray && outputs) {

                             for (var index = 0; index < priorityArray.length; index++) {

                                 var priority = priorityArray[index].toLowerCase();
                                 var acceptable = acceptedOutputTypes.includes(priority);
                                 if (!acceptable) continue;

                                 for (var outputIndex = 0; outputIndex < outputs.length; outputIndex++) {

                                     var output = outputs[outputIndex];

                                     if (output.type == priority && output.pages > 0) {
                                         return priority;
                                     }
                                 }
                             }
                         }
                        var errorMessage = _("error.build_media_no_outputs")
                         if ( outputs) {
                             var errorMessage = _("error.build_media_cannot_determine_output_type",
                                 JSON.stringify(scope.channel), JSON.stringify(priorityArray));
                         }
                         throw new Error(errorMessage);
                    };

                    /*
                     * @param jobToken e.g. localhost/1574691529054
                     */
                    scope.getOutputURL = function(jobToken,outputType){ // 3
                        var jobTokenParts = jobToken.split('/');
                        var client = jobTokenParts[0];
                        var jobId = jobTokenParts[1];

                        var page = 0;//Build Media always displays the first page of the output
                        return restService.getDocumentURL(client, jobId, outputType, page);
                    };

                    scope.setOutputAndResetRegistry = function(res){
                        var originalOutputPath = res[0].data;
                        var fileName = originalOutputPath.split('/').pop();

                        //defaults to cms:/Defaults
                        var imageWebEndPoint = ebConfig.get("easybuild.web.buildmedia.web.endpoint");

                        var outputPath = imageWebEndPoint + "/" + fileName;

                        var buildMediaAssociation = registry.get("buildMediaAssociation");
                        var originalRegistry = buildMediaAssociation.originalRegistry;
                        var jobIndex = buildMediaAssociation.jobIndex;
                        var groupIndex = buildMediaAssociation.groupIndex;
                        var articleIndex = buildMediaAssociation.articleIndex;

                        var articleKey = originalRegistry.jobs[jobIndex].groupArticleMapping[groupIndex][articleIndex];

                        if ( originalRegistry.jobs[jobIndex].pagePreviewMapping ){
                            angular.forEach(utilitiesService.getPageIndexes(originalRegistry.jobs[jobIndex], groupIndex), function (pageIndex) {
                                originalRegistry.jobs[jobIndex].pagePreviewMapping[pageIndex] = undefined;
                            });
                        }

                        var imageName = buildMediaAssociation.fieldName;
                        var imageItems = originalRegistry[articleKey].imageItems;
                        var flashImageItems = originalRegistry[articleKey].flashImageItems;

                        var previewUrl = "";
                        if ( ebConfig.get('easybuild.web.buildmedia.useDocumentPreview') ){
                            var document = registry.get("document");
                            previewUrl = document.displayimage;
                        }

                        var setImageItem = function(imageItem){
                            if(imageItem.name == imageName){
                                imageItem.value = outputPath;
                                imageItem.skip = false;
                                imageItem.subJob = {builtJobXML : res[1]};
                                imageItem.previewUrl = previewUrl;
                                imageItem.delete = false;
                                imageItem.delete_frame = false;
                            }
                        };

                        if(imageItems){
                            angular.forEach(imageItems, setImageItem);
                        }

                        if(flashImageItems){
                            angular.forEach(flashImageItems, setImageItem);
                        }

                        registry.restore(originalRegistry);
                        $window.location = utilitiesService.getNewLocation(scope.buildMediaReturnUrl);
                    };

                    scope.showGenericError = function(reason) {
                        console.log("Build Media merge failed: " + reason.toString());
                        console.log("Job id: " + scope.jobId);
                        scope.showStatusError("ERRORED");
                    };

                    scope.showStatusError = function(status) {
                        var msg = _("error.build_media_update_failed");
                        if (status == "QUEUED" || status === "PROCESSING" || status === "WAITING"){
                            msg = _("error.build_media_update_timedout");
                        } else if  (status == "CANCELLED"){
                            msg = _("error.build_media_update_cancelled");
                        } else if (status == "ERRORED") {
                            msg = _("error.build_media_update_failed")
                                + " "
                                + _("error.see_log_for_details");
                        }
                        dialog.showDialog(dialog.TYPE.NOTIFY, '', msg);
                    };
                }
            }//end link
        }
  }]);

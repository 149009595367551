angular.module('easybuild.component.wc.preview')

/**
 * @ngdoc directive
 * @name easybuild.directive.webcomponents:ebPreview
 * @description Render a generated document from the Publishing Platform, this might be a static URL (if provided), or the active job output.
 * @restrict 'A'
 * @scope
 * @param  {number} ebPreviewJobIndex Target index of job to show preview for (job mode)
 * @param  {string} ebPreview Target URL for preview to display (static mode)
 * @param  {string} ebPreviewUrl Target URL for preview to display (static mode)
 * @param  {boolean} ebPreviewMulti Display the multi-preview web component when applicable
 * @param  {boolean} ebPreviewIsProof This is a final proof preview (some WC switches are changed for these)
 **/
.directive('ebPreview', ['registry', '$compile', 'evBus', 'bundleService', 'ebConfig', function(registry, $compile, evBus, bundleService, ebConfig) {
    return {
        restrict: 'A',
        scope: {
            ebPreviewJobIndex: '@',
            ebPreviewUrl: '@',
            ebPreview: '@',
            ebPreviewMulti: '@',
            ebPreviewIsProof: '@'
        },
        link: function (scope, element) {
            scope.editDocument = function(jobIndex) {
                bundleService.editJob(jobIndex);
            };
            scope.showEditIcon = function(jobIndex){
                var jobs = registry.get("jobs");
                if(jobs && jobIndex < jobs.length){
                    var job = jobs[jobIndex];
                    return bundleService.hasBundledEditBeenMade(jobIndex);
                }
                else false;
            };
            var html5Scrollbars = ebConfig.get('easybuild.web.preview.html5.scrollbars');

            var document = registry.get("document");

            var job = registry.get("job");
            if(job && job.index && typeof job.index === "number" && job.index > 0){
                document = job.document;
            }

            if ( document ) {
                switch (document.zoomMode) {
                    case 2: //scrollbars
                    {
                        html5Scrollbars = true;
                        break;
                    }
                    case 1: //zoom
                    {
                        html5Scrollbars = false;
                        break;
                    }
                }
            }

            if ( html5Scrollbars) {
                element.addClass('html_scrollbars');
            }else{
                element.removeClass('html_scrollbars');
            }
            var url = scope.ebPreviewUrl || scope.ebPreview;
            if (url && url.length > 0) {
                // This is a document preview (URL)
                var width = element.width(), height = element.height();
                if ( width == 0){
                    width = element.parent().width();
                }
                if ( height == 0) {
                    height = element.parent().height();
                }
                Wave2.PlayerHelper.renderPreviewForUrl(url, element, width, height);
            }
            else if (scope.ebPreviewMulti === 'true') {
                var multiPreview = new Wave2.MultiPreview({
                    eventBus: evBus,
                    showControls: false,
                    highlightDoneProof: false,
                    html5NoSize: html5Scrollbars
                });
                if (multiPreview.initialise(registry)) {
                    var templates = {
                        generatingTmpl: '#generatingTmpl',
                        finishedTmpl: '#finishedTmpl',
                        finishedThumbTmpl: '#finishedThumbTmpl'
                    };
                    multiPreview.populateElement(element, templates, function (targetContainer) {
                        targetContainer.html('');
                        var dom = this;
                        if (dom && targetContainer) {
                            if (dom instanceof String) dom = dom.trim();
                            $compile(dom)(scope).appendTo(targetContainer);
                        }
                    });
                }
            }
            else {
                // This is a job preview
                var jobIndexNumber = 0;
                if (!isNaN(scope.ebPreviewJobIndex) && scope.ebPreviewJobIndex > 0) {
                    jobIndexNumber = parseInt(scope.ebPreviewJobIndex);
                }

                var preview = new Wave2.Preview(jobIndexNumber, {
                    eventBus: evBus,
                    showControls: false,
                    hideGeneratingPreview: false,
                    hideInitialPreview: (scope.ebPreviewIsProof),
                    hideThrobber: (!scope.ebPreviewIsProof),
                    html5NoSize: html5Scrollbars
                });
                if (preview.initialise(registry)) {
                    preview.populateElement(element, null, function (targetContainer) {
                        targetContainer.html('');
                        var dom = this;
                        if (dom && targetContainer) {
                            // Trim whitespace from the input. For some reason one of the templates is arriving
                            // corrupted on the Miles 33 server
                            if (dom instanceof String) dom = dom.trim();
                            $compile(dom)(scope).appendTo(targetContainer);
                        }
                    });
                }
            }
        }
    }
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebJobSpinner
 * @description Draw a busy indicator when a job is running
 * @restrict 'A'
 * @scope
 * @param  {expression=} i18n Localisation messages
 * @param  {number=} jobIndex Index of job to watch
 * @param  {boolean=} listenOnAllFinished Only hide this spinner when all jobs have finished
 **/
.directive('ebJobSpinner', ['$location', 'APP_EVENTS', 'evBus', 'registry', 'utilitiesService', function($location, APP_EVENTS, evBus, registry, utilitiesService) {
    return {
        restrict: 'A',
        replace: true,
        templateUrl: 'partials/job_spinner.html',
        scope: {
            'i18n': '=',
            'jobIndex': '=',
            'listenOnAllFinished': '='
        },
        link: function(scope) {
            var jobIndex = scope.jobIndex || 0;
            var listenOnAllFinished = scope.listenOnAllFinished || false;
            evBus.on(Wave2.JobGenerator.JOB_STATUS_CHANGED_EVENT, function(ev, status){
                var outputStatus = status.output.status;
                if (status && status.jobIndex == jobIndex && status.output && outputStatus) {
                    if (outputStatus === "PROCESSING" || outputStatus === "QUEUED" || outputStatus === "WAITING" ) {
                        scope.showJobSpinner = true;
                    } else if (!listenOnAllFinished) {
                        scope.showJobSpinner = false;
                    }
                }
                scope.jobStatus = utilitiesService.getJobStatusString(outputStatus);
                scope.jobSummary = status.output.summary;
                if ( status.output.queueLength && status.output.queueLength > 0 ){
                    scope.jobSummary = _("queue.length", status.output.queueLength);
                    if ( status.output.queueWait ) {
                        scope.jobSummary = scope.jobSummary + _("queue.wait") + utilitiesService.getTimeString(status.output.queueWait);
                    }
                }
            });
            if (listenOnAllFinished) {
                evBus.on(Wave2.JobGenerator.ALL_JOBS_FINISHED_EVENT, function () {
                    scope.showJobSpinner = false;
                    scope.jobStatus = "";
                    scope.jobSummary = "";
                });
            }
            evBus.on(Wave2.JobGenerator.ALL_JOBS_CANCELLED_EVENT, function () {
                scope.showJobSpinner = false;
                scope.jobStatus = "";
                scope.jobSummary = "";
            });
        }
    };
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebZoom
 * @description Image zoom control, often used with job previews
 * @restrict 'A'
 * @scope
 * @param  {function&} toggleZoom Function to toggle zoom mode on/off
 * @param  {boolean} zoomEnabled flag to enable/disable zoom control
 **/
.directive('ebZoom', ['$log', 'registry', 'evBus', 'APP_EVENTS', function($log, registry, evBus, APP_EVENTS) {
    return {
        restrict: 'A',
        transclude: true,
        templateUrl: 'partials/zoom.html',
        link: function (scope, element, attrs) {
            scope.zoomShow = 'false';
            scope.rotateShow = attrs.ebZoomRotate;
            scope.visible = attrs.ebZoomVisible == "true";
            scope.degrees = 0;

            scope.zoomVisible = function() {
                if ( panzoom ) {
                    panzoom.setOptions({
                        disableZoom: false,
                        disablePan: false,
                        cursor: 'move'
                    });
                }
            };

            scope.zoomHide = function() {
                if ( panzoom ) {
                    panzoom.setOptions({
                        disableZoom: true,
                        disablePan: true,
                        cursor: ''
                    });
                }
            };

            scope.zoomPlus = function() {
                if ( panzoom ) {
                    panzoom.zoomIn();
                }
            };

            scope.zoomMinus = function() {
                if ( panzoom ) {
                    panzoom.zoomOut();
                }
            };

            scope.zoomFit = function() {
                if ( panzoom ) {
                    panzoom.reset();
                }
                scope.degrees = 0;
                doRotate(scope.degrees)
            };

            scope.rotate = function(degrees) {
                scope.degrees += degrees;
                doRotate(scope.degrees);
            };

            var zoomEl = jQuery(attrs.ebZoom);
            var doRotate = function(deg){
                zoomEl.find(".w2Rotate").css({'-webkit-transform' : 'rotate('+ deg +'deg)',
                    '-moz-transform' : 'rotate('+ deg +'deg)',
                    '-ms-transform' : 'rotate('+ deg +'deg)',
                    'transform' : 'rotate('+ deg +'deg)',
                    'transition': 'transform 500ms ease-in-out 0s'});
            };

            var panzoom = undefined;
            if ( zoomEl && zoomEl.length > 0 ) {
                panzoom = Panzoom(zoomEl[0], {
                    step: 0.2,
                    minScale: 0.8,
                    maxScale: 4,
                    duration: 500
                });

                scope.zoomHide();
            }

            evBus.off(APP_EVENTS.zoomToggle);
            if ( scope.visible ) {
                scope.zoomShow = 'true';
                scope.zoomVisible();
            } else {
                evBus.on(APP_EVENTS.zoomToggle, function (ev, override) {
                    try {
                        if (angular.isDefined(override)) {
                            // Set to the opposite of the override so we force the correct outcome below
                            scope.zoomShow = override ? 'false' : 'true';
                        }

                        if (scope.zoomShow == 'false') {
                            scope.zoomShow = 'true';
                            scope.zoomVisible();
                        } else {
                            scope.zoomShow = 'false';
                            scope.zoomHide();
                        }
                    } catch (e) {
                        // Sometimes the panzoom raises an exception but initialises correctly
                        // TODO: handle this more gracefully
                        $log.error('Panzoom error occurred during initialisation', e);
                    }
                });
            }

            scope.$on('$destroy', function(){
                evBus.off(APP_EVENTS.zoomToggle);
                if ( panzoom ){
                    panzoom.destroy();
                }
            });
        }
    }
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebZoomButton
 * @description Button that shows and hides the Image Zoom Control
 * @restrict 'E'
 * @scope
 * @param  {function&} toggleZoom Function to toggle zoom mode on/off
 * @param  {boolean} zoomEnabled flag to enable/disable zoom control
 **/
    .directive('ebZoomButton', ['$log', 'registry', 'evBus', 'ebConfig', 'APP_EVENTS', function($log, registry, evBus, ebConfig, APP_EVENTS) {
        return {
            restrict: 'E',
            transclude: true,
            templateUrl: 'partials/zoom_button.html',
            link: function (scope, element, attrs) {
                var document = registry.get("document");

                var job = registry.get("job");
                if(job && job.index && typeof job.index === "number" && job.index > 0){
                    document = job.document;
                }

                if (document && (document.format === 'html5' ||document.format === 'html') ) {
                    var zoomEnabled = 'true';

                    switch(document.zoomMode){
                        case 2: //scrollbars
                        {
                            zoomEnabled = 'false';
                            break;
                        }
                        case 1: // Zoom
                        {
                            zoomEnabled = 'true';
                            break;
                        }
                        default:
                        {
                            if ( ebConfig.get('easybuild.web.preview.html5.scrollbars')) {
                                zoomEnabled = 'false';
                            }
                            break;
                        }
                    }

                    scope.zoomEnabled = zoomEnabled;
                } else if (document && document.format !== 'flash') {
                    scope.zoomEnabled = 'true';
                } else {
                    scope.zoomEnabled = 'false';
                }

                scope.toggleZoomAction = function () {
                    evBus.broadcast(APP_EVENTS.zoomToggle);
                }

            }
        }
    }])


    /**
     * @ngdoc directive
     * @name easybuild.directive:ebDownload
     * @description Add to button to show download of preview
     * @restrict 'A'
     * @scope
     * @param  {boolean} ebDownload type to download
     * @param  {int} ebDownloadJobIndex job index to download
     **/
    .directive('ebDownload', ['$log', 'registry', '$window', 'APP_EVENTS', 'evBus', 'dialog', function($log, registry, $window, APP_EVENTS, evBus, dialog) {
        return {
            restrict: 'A',
            scope: {
                ebDownload: '@',
                ebDownloadJobIndex: '@'
            },
            link: function (scope, element, attrs) {
                var jobIndex = scope.ebDownloadJobIndex || 0;

                element.on('click', function (ev) {
                    ev.preventDefault();
                    var mediaType = scope.ebDownload;
                    var jobs = registry.get('jobs');
                    if (jobs && jobs[jobIndex] && jobs[jobIndex].output && jobs[jobIndex].output.status === "FINISHED") {
                        var mediaUrl = null;
                        var job = jobs[jobIndex];
                        for (var i = 0; i < job.output.outputFiles.length; i++) {
                            if (job.output.outputFiles[i].type == mediaType) {
                                var command;
                                mediaUrl = Wave2.WcService.BASE_SERVER_URL + "File/download-job/"+job.jobToken+"/"+job.output.outputFiles[i].type + "/";
                                if(mediaType != "html5"){
                                    mediaUrl += "0";
                                }
                                break;
                            }
                        }
                        if (mediaUrl) {
                            $window.open(mediaUrl, "_blank");
                        }else {
                            dialog.showDialog(dialog.TYPE.NOTIFY, '', _('error.cannot_download_preview', mediaType));
                        }
                    } else {
                        dialog.showDialog(dialog.TYPE.NOTIFY, '', _('error.preview_not_finished'));
                    }
                });

                var jobs = registry.get('jobs');
                element[0].disabled = !(jobs && jobs[jobIndex] && jobs[jobIndex].output && jobs[jobIndex].output.jobToken && jobs[jobIndex].output.jobToken.jobToken === jobs[jobIndex].jobToken && jobs[jobIndex].output.status === "FINISHED");

                evBus.on(Wave2.JobGenerator.JOB_STATUS_CHANGED_EVENT, function(ev, status){
                    if (status && status.jobIndex == jobIndex && status.output && status.output.status === "FINISHED" ) {
                        element[0].disabled = false;
                    } else {
                        element[0].disabled = true;
                    }
                });
            }
        }
    }])
;

'use strict';

require('./action/action.module');
require('./bind/bind.module');
require('./datasource-sort/datasource-sort.module');
require('./mediamanager/mediamanager.module');
require('./navbar/navbar.module');
require('./pagination/pagination.module');
require('./resizing/resizing.module');
require('./margins/margins.module');
require('./version/version.module');
require('./autosave/autosave.module');
require('./units/units.module');
require('./generate/generate.module');
require('./queuedetails/queuedetails.module');
require('./pagerange/pagerange.module');

angular.module('easybuild.component.eb', [
    'easybuild.component.eb.action',
    'easybuild.component.eb.bind',
    'easybuild.component.eb.datasource-sort',
    'easybuild.component.eb.mediamanager',
    'easybuild.component.eb.navbar',
    'easybuild.component.eb.pagination',
    'easybuild.component.eb.resizing',
    'easybuild.component.eb.margins',
    'easybuild.component.eb.version',
    'easybuild.component.eb.autosave',
    'easybuild.component.eb.units',
    'easybuild.component.eb.generate',
    'easybuild.component.eb.queuedetails',
    'easybuild.component.eb.pagerange'
]);
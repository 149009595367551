angular.module('easybuild.shared.generation')

.service('articleService', ['$log', 'registry', 'ebConfig', 'bundleService', function($log, registry, ebConfig, bundleService) {

    this.articleMgr = Wave2.ArticleManager;

    this.initialise = function(options) {
        if (!options) options = {}
        options.skipFlagEnabled = ebConfig.get('easybuild.web.skip.enabled');
        options.skipFlagTypes = ebConfig.get('easybuild.web.skip.types');
        this.articleMgr.initialise(registry, options);
    }

    this.clearArticles = function () {
        var groupIndex;
        var groupCount = this.articleMgr.getGroupCount(registry);
        if(registry.contains("job")){
            // Clear articles for bundled edit
            var job = registry.get("job");
            for (groupIndex = 0; groupIndex < groupCount; groupIndex++) {
                this.articleMgr.removeAllArticles(registry, groupIndex, job.index);
            }
            bundleService.createBundledEdit(job.index);
        }
        else {
            // Clear articles for primary job
            for (groupIndex = 0; groupIndex < groupCount; groupIndex++) {
                this.articleMgr.removeAllArticles(registry, groupIndex, 0);
            }
            registry.remove('jobs');
            registry.remove("size");
        }
    }

    this.getManager = function() {
        return this.articleMgr;
    }

}]).run()
angular.module('easybuild.page.login')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebAutoFill
 * @description Fix browser auto-complete helpers in this form (http://stackoverflow.com/a/20149259/43662)
 * @restrict 'AEC'
 * @requires ngModel
 * @scope
 **/
.directive('ebAutoFill', ['$timeout', function ($timeout) {
    return {
        require: 'ngModel',
        scope: {},
        link: function (scope, elem, attrs, ctrl) {
            scope.check = function(){
                var val = elem[0].value;
                if(ctrl.$viewValue !== val){
                    var isPristine = false;
                    if(ctrl.$pristine) isPristine = true;
                    ctrl.$setViewValue(val);
                    // If the form control was originally pristine, set it back to pristine
                    ctrl.$pristine = isPristine;
                }
                $timeout(scope.check, 300);
            };
            scope.check();
        }
    }
}]);
angular.module('easybuild.page.launch')

    .controller('LaunchCtrl', ['$scope', '$location', '$window', '$log', 'AUTH_EVENTS', 'authService', 'userSession',
        'registry', 'launchService', 'jobStoreService', 'i18n',
        function ($scope, $location, $window, $log, AUTH_EVENTS, authService, userSession, registry, launchService,
                  jobStoreService, i18n) {

            var jobName = $location.search().jobName;
            var jobOwner = $location.search().jobOwner;
            var stepName = $location.search().StepName;
            var username;
            var loginFailed = true;

            authService.userInfo()
                .then(function (user) {
                    userSession.create(user.username, user.roles);
                    username = user.username;
                    loginFailed = false;
                    return username;
                })
                .then(function (username) {
                    return authService.userUserGroups(username, false);
                }, function(rejectReason) {
                    if(!loginFailed) {
                        authService.logout();
                    }
                    throw new Error(i18n.error.getting_launch_usergroup_info + rejectReason);
                })
                .then(function (userGroups) {
                    localStorage.removeItem('userGroups');
                    localStorage.setItem('userGroups', JSON.stringify(userGroups));
                })
                .then(function () {
                    username = jobOwner || username;

                    if (jobName == undefined || jobName.trim().length == 0) {
                        // Don't have a job name passed in so we'll just launch to the 'job retrieve' page
                        $location.path('job/retrieve');
                    } else {
                        jobStoreService.exists(username, jobName)
                            .then(function (jobExists) {
                                if (jobExists) {
                                    launchService.launchJob(username, jobName, stepName);
                                } else {
                                    throw new Error(i18n.error.launch_job_not_exists + jobName);
                                }
                            }, function (reason) {
                                throw new Error(i18n.error.launch_job_check + reason);
                            });
                    }
                });
        }])
'use strict';

/* Services */

angular.module('easybuild.shared.auth', [])

    .value('keepaliveRef', { ref: null })

    .factory('keepaliveService', ['$http', '$interval', 'AUTH_EVENTS', 'evBus', 'ebConfig', 'keepaliveRef',
        function ($http, $interval, AUTH_EVENTS, evBus, ebConfig, keepaliveRef) {
        return {
            start: function () {
                if (this.isActive()) return;

                var keepaliveService = this;
                var pingFreq = ebConfig.get('easybuild.http.session.ping');
                keepaliveRef.ref = $interval(function() {
                    $http.get(Wave2.WcService.BASE_SERVER_URL + 'ping').then(function (res) {
                        if (!res || !res.data || res.data.result !== 'pong') {
                            keepaliveService._handleFailure(res);
                        }
                    }, function(res) {
                        keepaliveService._handleFailure(res);
                    });
                }, pingFreq);
            },

            isActive: function() {
                return keepaliveRef && keepaliveRef.ref;
            },

            stop: function() {
                if (this.isActive()) {
                    $interval.cancel(keepaliveRef.ref);
                    keepaliveRef.ref = null;
                }
            },

            _handleFailure: function(res) {
                // We've lost connection with the server, always treat as a session timeout
                evBus.broadcast(AUTH_EVENTS.sessionTimeout);
            }
        }
    }])

    .factory('authService', ['$http', '$log', '$q', 'AUTH_EVENTS', 'userSession', 'dialog', 'ebConfig', 'evBus',
        'nodeService', 'RESULT_FORMAT', 'i18n',
        function ($http, $log, $q, AUTH_EVENTS, userSession, dialog, ebConfig, evBus, nodeService, RESULT_FORMAT,
                  i18n) {
            return {
            login: function (credentials) {
                if (!credentials.rememberme) {
                    this.rememberMe(null);
                }
                var loggedIn = function (res) {
                    if (res && res.data) {
                        var user = res.data;
                        userSession.create(user.username, user.roles);
                    }
                };
                if ( ebConfig.get('easybuild.web.hmac') ) {
                    var mstime = Date.now();
                    var base = jQuery("head base");
                    var extra = "/";
                    if ( base && base.length > 0 ){
                        extra = base.attr("href");
                    }
                    var hashInBase64 = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA512("POST+" + extra + Wave2.WcService.BASE_SERVER_URL + "user/login+" + mstime, credentials.password));
                    return $http.post(Wave2.WcService.BASE_SERVER_URL + 'user/login', {}, {'headers': {'Authorization': "hmac " + credentials.username + ":" + mstime + ":" + hashInBase64}}).then(loggedIn);
                } else {
                    return $http.post(Wave2.WcService.BASE_SERVER_URL + 'user/login', credentials).then(loggedIn);
                }
            },

            timeout: function () {
                // Suppress exception dialogs until the logout is complete
                dialog.suppressExceptionDialogs = true;
                return $http.post(Wave2.WcService.BASE_SERVER_URL + 'user/logout').then(function () {
                    dialog.suppressExceptionDialogs = false;
                    userSession.destroy();
                    localStorage.removeItem('userGroups');
                    evBus.broadcast(AUTH_EVENTS.sessionTimeout);
                }, function() {
                    dialog.suppressExceptionDialogs = false;
                    throw new Error(i18n.error.logout_failed_check_backend);
                });
            },

            logout: function () {
                // Suppress exception dialogs until the logout is complete
                dialog.suppressExceptionDialogs = true;
                return $http.post(Wave2.WcService.BASE_SERVER_URL + 'user/logout').then(function () {
                    dialog.suppressExceptionDialogs = false;
                    // Forget the user...
                    userSession.destroy();
                    localStorage.removeItem('userGroups');
                    evBus.broadcast(AUTH_EVENTS.logoutSuccess);
                }, function() {
                    dialog.suppressExceptionDialogs = false;
                    throw new Error(i18n.error.logout_failed_check_backend);
                });
            },

            forceLogout: function () {
                if (userSession && userSession.username) {
                    userSession.destroy();
                    localStorage.removeItem('userGroups');
                    evBus.broadcast(AUTH_EVENTS.sessionTimeout);
                }
            },

            rememberMe: function(user) {
                var cookie = 'rememberme' + '=';
                if(angular.isObject(user) && user.password) {
                    cookie += angular.toJson(user) + ';';

                    // Remember user for two weeks, do this the old fashion way
                    // since we need to set the expiration date
                    var date = new Date();
                    date.setTime( date.getTime() + (14 * 24 * 60 * 60 * 1000));
                    var expires = date.toGMTString();
                    cookie += 'expires=' + expires + '; path=/ ; secure;';
                } else {
                    cookie += '; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/ ; secure;';
                }
                document.cookie = cookie;
            },

            autoLogin: function() {
                var deferred = $q.defer();

                var credentials = null;
                var cookie = document.cookie.split("; "), i;
                for (i = 0; i < cookie.length; i++) {
                    var crumb = cookie[i].split("=");
                    if ('rememberme' === crumb[0]) {
                        // Login with credentials
                        credentials = angular.fromJson(crumb[1]);
                    }
                }

                if (credentials) {
                    deferred.resolve(credentials);
                }
                else {
                    // No credentials in cookie
                    deferred.reject();
                }

                return deferred.promise;
            },

            isAuthenticated: function () {
                // Server not available
                if (!ebConfig.isInitialised()) return false;

                return !!userSession.username;
            },

            isAuthorised: function (authorisedRoles) {
                // Server not available
                if (!ebConfig.isInitialised()) return false;

                // No authorisation required
                if (!authorisedRoles || authorisedRoles.length == 0) return true;

                // Check if user fulfils restriction
                var result = false;
                if (!angular.isArray(authorisedRoles)) {
                    authorisedRoles = [authorisedRoles];
                }
                if (!angular.isArray(userSession.roles)) {
                    userSession.roles = [userSession.roles];
                }
                if (this.isAuthenticated()) {
                    angular.forEach(userSession.roles, function(role) {
                        if (authorisedRoles.indexOf(role) !== -1) {
                            result = true;
                        }
                    });
                }
                return result;
            },

            /**
             * @returns {"roles":["user"],"username":"Administrator"}
             */
            userInfo: function () {
                return $http.get(Wave2.WcService.BASE_SERVER_URL + 'user/info').then(function (res) {
                    if (res && res.data) {
                        var user = res.data;
                        return user;
                    }
                }, function(reason) {
                    throw new Error('Could not get user information : ' + reason);
                });
            },

                /**
                 * Get user's usergroup information
                 * @param {String} username - username for which to retrieve usergroup information
                 * @param {Boolean} includeMembers - Boolean flag indicating whether a list of members
                 * associated with the usergroup are to be returned.
                 * @returns {Array} a list of usergroups sorted alphabetically
                 */
                userUserGroups: function(username, includeMembers) {
                    return nodeService.sendCommand('AuthService', 'getRoles',
                        [username, includeMembers] , 'Unable to retrieve usergroup information', RESULT_FORMAT.NONE)
                        .then(function(roles){
                            var userGroups = [];
                            for(var roleIndex = 0; roleIndex < roles.length; roleIndex++) {
                                var role = roles[roleIndex];
                                var members = [];
                                if ( role.members ) {
                                    for (var memberIndex = 0; memberIndex < role.members.length; memberIndex++) {
                                        members.push(role.members[memberIndex].userName);
                                    }

                                    members.sort(function (item1, item2) {
                                        return item1.toLowerCase().localeCompare(item2.toLowerCase());
                                    });
                                }

                                userGroups.push({ name: role.name, members: members });
                            }
                            return userGroups;
                        });
                }

        };
    }])

    .service('userSession', ['$log', '$cookieStore', 'keepaliveService', 'ebConfig',
            function ($log, $cookieStore, keepaliveService, ebConfig) {
        this.create = function (username, roles) {
            this.username = username;
            this.roles = roles;
            $cookieStore.put('user', this, this.getOptions());
            keepaliveService.start();
        };
        this.destroy = function () {
            this.username = null;
            this.roles = null;
            $cookieStore.put('user', null, this.getOptions());
            keepaliveService.stop();
        };
        this.restore = function () {
            var user = $cookieStore.get('user');
            if (user && user.username) {
                this.username = user.username;
                this.roles = user.roles;
                keepaliveService.start();
            }
            else {
                keepaliveService.stop();
            }
        };

        this.getOptions=function(){
            var options = {};
            if (ebConfig.get('easybuild.web.session.secure')) {
                options.secure = true;
            }
            var samesite = ebConfig.get('easybuild.web.session.samesite');
            if (samesite) {
                if (samesite.toString().toLowerCase() === 'true') {
                    options.samesite = 'strict';
                } else {
                    options.samesite = samesite;
                }
            }
            return options;
        }

        this.restore();
        return this;
    }]);
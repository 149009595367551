/**
 * This directive handles the changing of the units
 */
angular.module('easybuild.component.eb.units', [])
    .directive('ebUnits', ['i18n', 'evBus', 'utilitiesService', 'APP_EVENTS',
        function(i18n, evBus, utilitiesService, APP_EVENTS){
            return {
                restrict : 'A',
                scope: {
                },
                templateUrl: 'partials/units.html',


                controller: ['$scope', function ($scope) {
                    $scope.selectedUnits = {};
                    $scope.selectedUnits.units = utilitiesService.getResizeUnitPrint();
                    $scope.i18n = i18n;

                    $scope.updateUnits = function() {
                        utilitiesService.setResizeUnitPrint($scope.selectedUnits.units);
                        evBus.broadcast(APP_EVENTS.unitsChanged);
                    };
                }]
            }
        }]);
/**
 * This directive handles the action overlay crop button click
 */
angular.module('pages.build.html5-crop.directive', [])
    .directive('ebCropManager', ['i18n','$log', 'evBus', 'APP_EVENTS', 'ebConfig', 'utilitiesService',
        function(i18n,$log, evBus , APP_EVENTS, ebConfig, utilitiesService){
        return {
            restrict : 'A',
            scope: {
            },
            templateUrl: 'partials/crop.html',

            controller: ['$scope', function($scope) {
                $scope.cropCount = 0;
                $scope.i18n = i18n; //make i18n accessible from crop.html
                $scope.alignmentMenusEnabled = true;
                $scope.frameFittingMenusEnabled = true;

                var cropControls = ebConfig.get('easybuild.web.crop.controls');

                $scope.showFrameFitting = cropControls && cropControls.indexOf('frameFitting') > -1;
                $scope.showImageFitting = cropControls && cropControls.indexOf('imageFitting') > -1;
                $scope.showVAlign = cropControls && cropControls.indexOf('vAlign') > -1;
                $scope.showHAlign = cropControls && cropControls.indexOf('hAlign') > -1;

                $scope.cropperJsOptions = ebConfig.get('easybuild.web.cropperjs.options');

                $scope.zoomable = ($scope.cropperJsOptions && ("zoomable" in $scope.cropperJsOptions))
                ? $scope.cropperJsOptions.zoomable : true;

                $scope.movable = ($scope.cropperJsOptions && ("movable" in $scope.cropperJsOptions))
                    ? $scope.cropperJsOptions.movable : true;

                $scope.scalable = ($scope.cropperJsOptions && ("scalable" in $scope.cropperJsOptions))
                    ? $scope.cropperJsOptions.scalable : true;

                $scope.rotatable = ($scope.cropperJsOptions && ("rotatable" in $scope.cropperJsOptions))
                    ? $scope.cropperJsOptions.rotatable : true;

                /*
                 * The drop down options listed below match the constants found in :
                 * ..\wave2pp\src\com\wave2\xstream\dbobject\rules\ImagePlacement.java
                 */
                $scope.frameFittingOptions = [
                    {name : i18n.crop_tool.frame_fitting_default, id : "Default"},
                    {name : i18n.crop_tool.frame_fitting_none, id : "None"},
                    {name : i18n.crop_tool.frame_fitting_image, id : "Image"}
                ];
                $scope.selectedFrameFitting = $scope.frameFittingOptions[0];

                $scope.imageFittingOptions = [
                    {name : i18n.crop_tool.image_fitting_default, id : "Default"},
                    {name : i18n.crop_tool.image_fitting_none, id : "None"},
                    {name : i18n.crop_tool.image_fitting_proportional, id : "Proportional"},
                    {name : i18n.crop_tool.image_fitting_proportional_max, id : "Proportional Max"},
                    {name : i18n.crop_tool.image_fitting_fill, id : "Fill"},
                    {name : i18n.crop_tool.image_fitting_template, id : "Template"}
                ];
                $scope.selectedImageFitting = $scope.imageFittingOptions[0];

                $scope.horizontalAlignments = [
                    {name : i18n.crop_tool.horizontal_alignment_default, id : "Default"},
                    {name : i18n.crop_tool.horizontal_alignment_left, id : "Left"},
                    {name : i18n.crop_tool.horizontal_alignment_centre, id : "Centre"},
                    {name : i18n.crop_tool.horizontal_alignment_right, id : "Right"}
                ];
                $scope.selectedHorizontalAlignment = $scope.horizontalAlignments[0];

                $scope.verticalAlignments = [
                    {name : i18n.crop_tool.vertical_alignment_default, id : "Default"},
                    {name : i18n.crop_tool.vertical_alignment_top, id : "Top"},
                    {name : i18n.crop_tool.vertical_alignment_centre, id : "Centre"},
                    {name : i18n.crop_tool.vertical_alignment_bottom, id : "Bottom"}
                ];
                $scope.selectedVerticalAlignment = $scope.verticalAlignments[0];

                /**
                 * Convert from cropper js format to Wave2 crop fromat
                 *
                 * @param {Object} data - The Cropper js data where
                 * @Default { x : 0 , y : 0, width : 0, height : 0, rotate : 0, scaleX : 1, scaleY : 1}
                 *
                 * @prop data.x - the offset left of the cropped area
                 * @prop data.y - the offset top of the cropped area
                 * @prop data.width - the width of the cropped area
                 * @prop data.height - the height of the cropped area
                 * @prop data.rotate - the rotated degrees of the image
                 * @prop data.scaleX - the scaling factor to apply on the abscissa of the image
                 * @prop data.scaleY - the scaling factor to apply on the ordinate of the image
                 *
                 * @return {Object} crop - The Wave2 crop data
                 * @Default by Default all properties are undefined
                 *
                 * @prop crop.hflip {boolean} - true indicates the image has been horizontally flipped
                 * @prop crop.vflip {boolean} - true indicates the image has been vertically flipped
                 * @prop crop.x1 {int} - css left position relative to source image
                 * @prop crop.x2 {int} - css right position relative to source image
                 * @prop crop.y1 {int} - css top position relative to source image
                 * @prop crop.y2 {int} - css bottom position relative to source image
                 */
                $scope.cropperJStoW2Crop = function(data){
                    var imageData = $scope.cropper.getImageData();

                    var hflip = (data.scaleX === -1);
                    var vflip = (data.scaleY === -1);

                    var width = parseInt(imageData.naturalWidth);
                    var height = parseInt(imageData.naturalHeight);

                    var x1, x2;

                    x1 = data.x;
                    x2 = data.x + data.width;

                    var y1, y2;
                    y1 = data.y;
                    y2 = data.y + data.height;

                    var crop = {
                        hflip : hflip,
                        vflip : vflip,
                        x1 : x1,
                        x2 : x2,
                        y1 : y1,
                        y2 : y2,
                        w : width,
                        h : height,
                        rotate : data.rotate
                    };
                    $log.info("----------------------Setting W2 Crop Data-------------------------\n" +
                    "hflip : " + hflip + "\n" +
                    "vflip : " + vflip + "\n" +
                    "x1 : " + x1 + "\n" +
                    "x2 : " + x2 + "\n" +
                    "y1 : " + y1 + "\n" +
                    "y2 : " + y2 + "\n" +
                    "w : " + width + "\n" +
                    "h : " + height + "\n" +
                    "rotate : " + data.rotate + "\n" +
                    "");

                    return crop;
                };

                /**
                 * Convert from Wave2 crop format to cropper js format
                 * see cropperJStoW2Crop for more details
                 */
                $scope.w2cropDataToCropperJSData = function(fieldInfo, naturalWidth, naturalHeight){
                    var scaleX, scaleY;
                    var x, y, width, height;
                    var data;
                    var rotate = 0;

                    if ( fieldInfo.right !== -1 && fieldInfo.left !== -1 && fieldInfo.top !== -1 && fieldInfo.bottom !== -1 ) {

                        $log.info("----------------------Setting W2 Crop Data-------------------------\n" +
                            "vflip : " + fieldInfo.vflip + "\n" +
                            "hflip : " + fieldInfo.hflip + "\n" +
                            "top : " + fieldInfo.top + "\n" +
                            "bottom : " + fieldInfo.bottom + "\n" +
                            "left : " + fieldInfo.left + "\n" +
                            "right : " + fieldInfo.right + "\n" +
                            "rotate : " + fieldInfo.rotate + "\n" +
                            "");

                        scaleY = 1;
                        y = fieldInfo.top;
                        height = fieldInfo.bottom - fieldInfo.top;
                        if (fieldInfo.vflip === true) {
                            scaleY = -1;
                        }

                        scaleX = 1;
                        x = fieldInfo.left;
                        width = fieldInfo.right - fieldInfo.left;
                        if (fieldInfo.hflip === true) {
                            scaleX = -1;
                        }

                        if ( fieldInfo.rotate !== undefined ){
                            rotate = fieldInfo.rotate;
                        }

                        $log.info("----------------------Set JS Cropper Data-------------------------\n" +
                            "scaleX : " + scaleX + "\n" +
                            "scaleY : " + scaleY + "\n" +
                            "x : " + x + "\n" +
                            "y : " + y + "\n" +
                            "width : " + width + "\n" +
                            "height : " + height + "\n" +
                            "rotate : " + rotate + "\n" +
                            "");

                        data = {
                            scaleX: scaleX, scaleY: scaleY, rotate: rotate,
                            x: x, y: y, width: width, height: height
                        };
                    }
                    return data;
                };

                $scope.setupDropdowns = function(fieldInfo){

                    $scope.showFrameFitting = fieldInfo.enableFittingControls && fieldInfo.enableFrameFitting && cropControls && cropControls.indexOf('frameFitting') > -1;
                    $scope.showImageFitting = fieldInfo.enableFittingControls && fieldInfo.enableImageFitting && cropControls && cropControls.indexOf('imageFitting') > -1;
                    $scope.showVAlign = fieldInfo.enableFittingControls && fieldInfo.enableVerticalAlignment && cropControls && cropControls.indexOf('vAlign') > -1;
                    $scope.showHAlign = fieldInfo.enableFittingControls && fieldInfo.enableHorizontalAlignment && cropControls && cropControls.indexOf('hAlign') > -1;

                    var framefitting = fieldInfo.framefitting;
                    var fitting = fieldInfo.fitting;
                    var valign = fieldInfo.valign;
                    var halign = fieldInfo.halign;

                    if(!framefitting) framefitting = "default";
                    framefitting = framefitting.toLowerCase();

                    angular.forEach($scope.frameFittingOptions, function(frameFittingOption, arrayIndex){
                        if(frameFittingOption.id.toLowerCase() == framefitting){
                            $scope.selectedFrameFitting = $scope.frameFittingOptions[arrayIndex];
                        }
                    });

                    if(!fitting) fitting = "default";
                    fitting = fitting.toLowerCase();

                    angular.forEach($scope.imageFittingOptions,function(imageFittingOption, arrayIndex){
                        if(imageFittingOption.id.toLowerCase() == fitting){
                            $scope.selectedImageFitting = $scope.imageFittingOptions[arrayIndex];
                        }
                    });

                    if(!halign) halign = "default";
                    halign = halign.toLowerCase();

                    angular.forEach($scope.horizontalAlignments,function(horizontalAlignment, arrayIndex){
                        if(horizontalAlignment.id.toLowerCase() == halign){
                            $scope.selectedHorizontalAlignment = $scope.horizontalAlignments[arrayIndex];
                        }
                    });

                    if(!valign) valign = "default";
                    valign = valign.toLowerCase();

                    angular.forEach($scope.verticalAlignments,function(verticalAlignment, arrayIndex){
                        if(verticalAlignment.id.toLowerCase() == valign){
                            $scope.selectedVerticalAlignment = $scope.verticalAlignments[arrayIndex];
                        }
                    });
                    $scope.imageFittingChanged();
                };

                $scope.horizontalFlip = function(){
                    var data = $scope.cropper.getData();
                    var horizontallyFlipped = data.scaleX == -1;

                    if(horizontallyFlipped){
                        $scope.cropper.scaleX(1);
                    }
                    else {
                        $scope.cropper.scaleX(-1);
                    }
                };

                $scope.verticalFlip = function(){
                    var data = $scope.cropper.getData();
                    var verticallyFlipped = data.scaleY == -1;

                    if(verticallyFlipped){
                        $scope.cropper.scaleY(1);
                    }
                    else {
                        $scope.cropper.scaleY(-1);
                    }
                };

                $scope.setCropAspectRatio = function(){
                    if ( $scope.cropper ) {
                        var data = $scope.cropper.getData(true);
                        if ($scope.cropAspectRatioLocked) {
                            $scope.cropAspectRatioLocked = false;
                            $scope.cropper.setAspectRatio(NaN);
                        } else {
                            $scope.cropAspectRatioLocked = true;
                            $scope.cropper.setAspectRatio($scope.cropAspectRatio);
                        }
                        $scope.cropper.setData(data);
                        $scope.setCropAspectLockIcon();
                    }
                };
                $scope.setCropAspectLockIcon = function(){
                    var item = jQuery('#cropModal').find(".crop-lock");
                    item.removeClass("fa-lock fa-unlock");
                    item.addClass($scope.cropAspectRatioLocked?"fa-lock":"fa-unlock");
                };

                $scope.$on(Wave2.DocContent.FIELD_FIT_START_EVENT,function(ev, data){
                    $scope.startLoading(i18n.crop_tool.fit_heading);
                    $scope.cropping = false;

                    jQuery('#cropModal').find(".cropping").hide();
                    jQuery('#cropModal').modal('show');
                    $scope.FieldData = data;
                    $scope.setupDropdowns(data.fieldInfo);
                    $scope.doneLoading();
                });

                $scope.$on(Wave2.DocContent.FIELD_CROP_START_EVENT,function(ev, data){
                    $scope.startLoading(i18n.crop_tool.heading);
                    $scope.cropping = true;

                    jQuery('#cropModal').find(".cropping").show();
                    jQuery('#cropModal').modal('show');
                    $scope.FieldData = data;
                    $scope.setupDropdowns(data.fieldInfo);

                    var cropImageUrl = ebConfig.get("easybuild.web.crop.image.url");
                    var imageSrc = data.fieldInfo.value;
                    if ( imageSrc.indexOf("cms:") === 0 ) {
                    //    var fieldValue = (imageSrc).replace("cms:", "");
                        imageSrc = cropImageUrl + imageSrc;
                    }else{
                        imageSrc = cropImageUrl + encodeURIComponent(imageSrc);
                    }

                    $log.info("Using the following URL for the crop image : " + imageSrc);

                    var image = angular.element("#crop-image");


                    image.one( "load", function(ev) {

                        if (data.fieldInfo.cropWidth > 0 && data.fieldInfo.cropHeight > 0) {
                            $scope.cropAspectRatio = data.fieldInfo.cropWidth / data.fieldInfo.cropHeight;
                        } else if (data.fieldInfo.frameWidth > 0 && data.fieldInfo.frameHeight > 0) {
                            $scope.cropAspectRatio = data.fieldInfo.frameWidth / data.fieldInfo.frameHeight;
                        } else {
                            $scope.cropAspectRatio = image[0].naturalWidth / image[0].naturalHeight;
                        }
                        $scope.cropAspectRatioLocked = true;
                        if(typeof data.fieldInfo.left == 'number'){
                            var naturalHeight = parseInt(image[0].naturalHeight);
                            var naturalWidth = parseInt(image[0].naturalWidth);

                            $scope.cropperJScropData = $scope.w2cropDataToCropperJSData(data.fieldInfo, naturalWidth, naturalHeight);
                        } else {
                            $scope.cropperJScropData = undefined;
                        }
                        if($scope.cropper){
                            /*
                             * If the image has been cropped then we would expect left, right, top, bottom, vflip, hflip to
                             * be defined otherwise these values are expected to be undefined
                             */
                            if($scope.cropperJScropData){
                                image.one("ready", function(ev){
                                    $scope.resetCrop();
                                    $scope.doneLoading();
                                });
                            }
                            $scope.cropper.replace(imageSrc);
                             if (!$scope.cropperJScropData) {
                                 $scope.resetCrop();
                                 $scope.doneLoading();
                             }
                        } else {
                            image.one("ready", function(ev){
                                /*
                                 * Resetting now after we have just created new jscropper instance may seem unnecessary
                                 * but the crop box does not initally display unless we do this for some images such as
                                 * ../app/img/dd_logo_small.jpg
                                 */
                                $scope.resetCrop();
                                $scope.doneLoading();
                            });

                            var cropperJsOptions = $scope.cropperJsOptions;
                            if(!cropperJsOptions) cropperJsOptions = {};
                            if ( $scope.cropperJScropData) {
                                cropperJsOptions.data = $scope.cropperJScropData;
                            }
                            if ( $scope.cropperJScropData && utilitiesService.round($scope.cropAspectRatio) !== utilitiesService.round($scope.cropperJScropData.width / $scope.cropperJScropData.height) ) {
                                $scope.cropAspectRatioLocked = false;
                            }
                            if ( $scope.cropAspectRatioLocked ) {
                                cropperJsOptions.aspectRatio = $scope.cropAspectRatio;
                            } else {
                                cropperJsOptions.aspectRatio = NaN;
                            }
                            $scope.setCropAspectLockIcon();
                            $scope.cropper = new Cropper(image[0], cropperJsOptions);
                        }
                    });
                    image.attr("src",imageSrc);
                });

                $scope.applyCrop = function(){
                    var crop = {};
                    if ( $scope.cropper && $scope.cropping ) {
                        crop = $scope.cropperJStoW2Crop($scope.cropper.getData(true));
                    }
                    crop.framefitting = $scope.selectedFrameFitting.id;
                    crop.fitting = $scope.selectedImageFitting.id;
                    crop.valign = $scope.selectedVerticalAlignment.id;
                    crop.halign = $scope.selectedHorizontalAlignment.id;

                    $scope.FieldData.crop = crop;

                    evBus.broadcast(Wave2.DocContent.FIELD_CROP_COMPLETE_EVENT, $scope.FieldData);

                    $scope.closeModal();
                };

                $scope.resetCrop = function() {
                    $scope.cropper.reset();
                    $scope.setupDropdowns($scope.FieldData.fieldInfo);
                    $scope.cropAspectRatioLocked = true;
                    if ($scope.cropperJScropData) {
                        var fittImage=function(){
                            var tempData = $scope.cropper.getData();
                            if (Math.round(tempData.height) != $scope.cropperJScropData.height ||
                                Math.round(tempData.width) != $scope.cropperJScropData.width) {
                                $scope.cropper.zoomTo(Math.min(tempData.width / $scope.cropperJScropData.width, tempData.height / $scope.cropperJScropData.height));
                                $scope.cropper.setData($scope.cropperJScropData);
                            }

                            var tempData = $scope.cropper.getData();
                            if (Math.round(tempData.x) != $scope.cropperJScropData.x ||
                                Math.round(tempData.y) != $scope.cropperJScropData.y ){
                                $scope.cropper.moveTo($scope.cropperJScropData.x*-1, tempData.y-$scope.cropperJScropData.y*-1);
                                $scope.cropper.setData($scope.cropperJScropData);
                            }
                        }

                        if ( utilitiesService.round($scope.cropAspectRatio) !== utilitiesService.round($scope.cropperJScropData.width / $scope.cropperJScropData.height) ) {
                            $scope.cropAspectRatioLocked = false;
                            $scope.cropper.setAspectRatio(NaN);
                        } else {
                            $scope.cropper.setAspectRatio($scope.cropAspectRatio);
                        }
                        $scope.cropper.setData($scope.cropperJScropData);
                        if (($scope.cropper.options && $scope.cropper.options.viewMode === 0) || $scope.cropperJScropData.rotate !== 0 ) {
                            fittImage();
                        }

                    } else {
                        $scope.cropper.setAspectRatio($scope.cropAspectRatio);
                    }
                    $scope.setCropAspectLockIcon();
                    evBus.broadcast(APP_EVENTS.cropReset);
                };

                $scope.closeModal = function(){
                    jQuery('#cropModal').modal('hide');
                };

                $scope.imageFittingChanged = function(){
                    $scope.alignmentMenusEnabled = !($scope.selectedImageFitting.id.toLowerCase() == "fill") && !($scope.selectedImageFitting.id.toLowerCase() == "template");
                    $scope.frameFittingMenusEnabled = !($scope.selectedImageFitting.id.toLowerCase() == "template");
                };

                $scope.startLoading = function(title){
                    jQuery('#cropModal').find('.crop-loading').show();
                    if ( title && title.length > 0 ) {
                        jQuery('#cropModal').find('.crop-title').html(title);
                    }
                };

                $scope.doneLoading = function(){
                    jQuery('#cropModal').find('.crop-loading').hide();
                    evBus.broadcast(APP_EVENTS.cropLoaded);
                };

                if (!$("#cropModal .modal-dialog").is(":ui-draggable") ) {
                    $("#cropModal .modal-dialog").draggable({
                        handle: ".modal-header"
                    });
                }

            }]
        }
    }]);
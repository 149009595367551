angular.module('easybuild.component.eb.version')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebAppVersion
 * @description Inserts the version of EasyBuild
 * @restrict 'AEC'
 **/
.directive('ebAppVersion', ['nodeService', 'i18n', function (nodeService, i18n) {
    return function(scope, elm) {
        nodeService.getProductInfo().then(function(info) {
            if (info && info['easybuild.product.version'])
                elm.text('v' + info['easybuild.product.version']);
        }, function(reason) {
            throw new Error(i18n.error.eb_version + reason);
        });
    };
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebPlatformVersion
 * @description Inserts the version of the running Publishing Platform
 * @restrict 'AEC'
 **/
.directive('ebPlatformVersion', ['nodeService', 'i18n', function (nodeService, i18n) {
    return function(scope, elm) {
        nodeService.getProductInfo().then(function(info) {
            if (info && info['easybuild.w2pp.version']) {
                elm.text('v' + info['easybuild.w2pp.version']);
            }
        }, function(reason) {
            throw new Error(i18n.error.pp_version + reason);
        });
    };
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebRequirePlatform
 * @description Disable this control if the Publishing Platform is not running
 * @restrict 'AEC'
 **/
.directive('ebRequirePlatform', ['nodeService', function (nodeService) {
    return function(scope, elm, attrs) {
        attrs.$set('disabled', 'disabled')
        nodeService.getProductInfo().then(function(info) {
            var running = info && info['easybuild.w2pp.version'];
            if (running) elm.removeAttr('disabled');
        });
    };
}]);
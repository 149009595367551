angular.module('easybuild.component.eb.resizing')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebDocumentSizeControls
 * @description UI controls for handling the document resize operations. The default template assumes to be placed in a bootstrap toolbar
 * @restrict 'A'
 * @scope
 * @param  {expression=} i18n Localisation messages
 * @param  {expression=} selection selected document with scale information
 * @param  {number} thresholdWidth Maximum bounds for resizing
 * @param  {number} thresholdHeight Maximum bounds for resizing
 * @param  {string} units Measurement units
 **/
.directive('ebDocumentSizeControls', ['registry', 'buildService' , 'ebConfig', 'evBus', 'utilitiesService', 'APP_EVENTS', function(registry, buildService, ebConfig, evBus, utilitiesService, APP_EVENTS) {
    return {
        restrict: 'A',
        replace: 'true',
        scope: {
            'i18n': '=',
            'selection': '=',
            'thresholdWidth': '@',
            'thresholdHeight': '@'
        },
        templateUrl: 'partials/size_controls.html',
        controller: ['$scope', function ($scope) {
            $scope.units = utilitiesService.getResizeUnitPrint();

            evBus.on(APP_EVENTS.unitsChanged, function(ev, scope) {
                $scope.units = utilitiesService.getResizeUnitPrint();
            });

            var thresholds = {
                widthPercentage: null,
                heightPercentage: null
            };

            if($scope.thresholdWidth && $scope.thresholdWidth.indexOf('%') > 0 ){
                thresholds.widthPercentage = parseInt($scope.thresholdWidth.substring(0, $scope.thresholdWidth.length - 1));
            }

            if ($scope.thresholdHeight && $scope.thresholdHeight.indexOf('%') > 0) {
                thresholds.heightPercentage = parseInt($scope.thresholdHeight.substring(0, $scope.thresholdHeight.length - 1));
            }

            $scope.isSizeUpdated = function() {
                var oldSize = registry.get('size');

                if ( $scope.selection.size && oldSize &&
                    $scope.selection.size.height == oldSize.height &&
                    $scope.selection.size.width == oldSize.width &&
                    $scope.selection.size.name == oldSize.name &&
                    $scope.selection.size.maxHeight == oldSize.maxHeight &&
                    $scope.selection.size.maxWidth == oldSize.maxWidth &&
                    $scope.selection.size.minHeight == oldSize.minHeight &&
                    $scope.selection.size.minWidth == oldSize.minWidth){
                    return false;
                }
                return true;
            };

            $scope.updateSize = function(custom) {
                if ( $scope.isSizeUpdated()) {
                    // Update the size info and trigger a preview
                    registry.put('size', $scope.selection.size);

                    // Added to check custom size is within the limits
                    if ($scope.selection.size.name === 'Custom Size' || custom) {
                        var originalScale = $scope.selection.document.scales[0];
                        buildService.checkCustomSize(originalScale, $scope.selection.size, thresholds);
                        if (buildService.checkCustomSizelimits($scope.selection.size, originalScale) == false)
                            return false;
                    }
                    if (ebConfig.get('easybuild.web.skip.enabled')) {
                        // Ensure the skip flags are all turned off
                        buildService.disableAllSkipFlags(registry);
                    }

                    if (utilitiesService.isDynamicPreviewEnabled()) {
                        // Kick off a generation
                        Wave2.JobGenerator.start(registry, ebConfig.get('easybuild.web.preview.currentgrouponly'));
                    }
                    evBus.broadcast(Wave2.DocContent.FIELD_CHANGED_EVENT, {'name': ''});
                }
            };

            $scope.switchToCustomSize = function() {
                // Find the custom scale
                var customScale = null;
                angular.forEach($scope.selection.document.scales, function(scale) {
                    if (scale.name === _('build.size_custom')) {
                        customScale = scale;
                    }
                });

                // Remember the last dimensions, and set the custom scale with these values
                var lastSize = $scope.selection.size;
                $scope.selection.size = customScale;
                if (lastSize && lastSize.width > 0 && lastSize.height > 0) {

                    if(!$scope.selection.size){
                        $scope.selection.size = {}
                    }

                    $scope.selection.size.width = lastSize.width;
                    $scope.selection.size.height = lastSize.height;
                }
            };

            var size = registry.get("size");

            if(!size && $scope.selection.document.scales && $scope.selection.document.scales.length > 0) {
                size = $scope.selection.document.scales[0];
                $scope.selection.size = size;
            }

            $scope.customScaleExists = false;
            angular.forEach($scope.selection.document.scales, function (scale) {
                if (scale.name === _('build.size_custom')) {
                    $scope.customScaleExists = true;
                }
                else if (size && size.name === scale.name) {
                    // Update the size of the selected scale
                    // Unless it is custom - we don't want to revert to original in that case
                    size.height = scale.height;
                    size.width = scale.width;
                    $scope.selection.size.height = scale.height;
                    $scope.selection.size.width = scale.width;
                    registry.put('size', size);
                }
            });
        }]
    };
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebConvertToUnit
 * @description Convert the measurement value of this text field using the unitConvertor service
 *              This is a directive rather than a filter because we want to interact with ngModel directly
 * @restrict 'A'
 **/
.directive('ebConvertToUnit', ['unitConvertor', 'evBus', 'APP_EVENTS', 'utilitiesService', function (unitConvertor, evBus, APP_EVENTS, utilitiesService) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            var unit = attrs.ebConvertToUnit;
            ngModel.$formatters.push(unitConvertor.getFormatter(unit));
            ngModel.$parsers.push(unitConvertor.getParser(unit));

            evBus.on(APP_EVENTS.unitsChanged, function(ev, scope) {
                unit = utilitiesService.getResizeUnitPrint();
                if ( ngModel.$formatters.length == 1){
                    ngModel.$formatters[0] = unitConvertor.getFormatter(unit);
                }else {
                    ngModel.$formatters.push(unitConvertor.getFormatter(unit));
                }
                if ( ngModel.$parsers.length == 1){
                    ngModel.$parsers[0] = unitConvertor.getParser(unit);
                }else {
                    ngModel.$parsers.push(unitConvertor.getParser(unit));
                }
                jQuery(element).val(unitConvertor.getFormatter(unit).call(undefined, ngModel.$modelValue));
            });

            element.on('change', function (e) {
                var element = e.target;
                element.value = unitConvertor.getFormatter(unit).call(undefined, ngModel.$modelValue);
            });
        }
    };

}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebScaleControl
 * @description Calculate and write a scale entry as a string
 * @restrict 'A'
 * @scope
 * @param  {expression=} ebScaleControl Contains the scale data in JSON to parse
 * @param  {boolean=} last If set to false then the scale is comma separated
 **/
.directive('ebScaleControl', function () {
    return {
        restrict: 'A',
        scope: {
            ebScaleControl: '=',
            last: '@'
        },
        link: function (scope, elm) {
            var text = scope.ebScaleControl.name;
            if (text !== 'Original' && (!text || text.length <= 10)) {
                try {
                    text += ' (' + parseInt(scope.ebScaleControl.width) + ' x ' + parseInt(scope.ebScaleControl.height) + ')';
                }
                catch (e) {}
            }
            if (scope.last === 'false') {
                text += ', ';
            }
            elm.text(text);
        }
    }
})
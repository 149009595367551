'use strict';

angular.module('easybuild.shared.command', [])

    .service('nodeService', ['$log', '$http', '$q', 'RESULT_FORMAT', 'evBus', 'AUTH_EVENTS', 'i18n', 'dialog',
        function($log, $http, $q, RESULT_FORMAT, evBus, AUTH_EVENTS, i18n, dialog) {

        /**
         * Cached copy of the product info, since this does not change and can be called frequently
         * @type {Object}
         */
        this.productInfo = null;

        /**
         * Retrieve the product information from the node server
         * @returns {*} the product name, version number, etc
         */
        this.getProductInfo = function() {
            if (this.productInfo) {
                var deferred = $q.defer();
                deferred.resolve(this.productInfo);
                return deferred.promise;
            }

            var path = Wave2.WcService.BASE_SERVER_URL + 'version';
            var ns = this;
            return $http.get(path).then(function(res) {
                if (ns.productInfo) return ns.productInfo;
                ns.productInfo = angular.fromJson(res.data);

                // Check the server version if we can
                var confVersion = res.headers('x-eb-version') || res.headers('server');
                if (confVersion && confVersion.indexOf(ns.productInfo['easybuild.product.version']) < 0) {
                    // Oops. The Apache and EB2 installation no longer match
                    throw i18n.error.apache_easybuild_config_mismatch;
                }

                return ns.productInfo;
            }, function() {
                ns.productInfo = null;
                evBus.broadcast(AUTH_EVENTS.forceLogout);
                return null;
            });
        }

        /**
         * Send a WS command to the server and parse the response as JSON
         * @param {string} serviceName - the name of the service to invoke (i.e. AuthService)
         * @param {string} commandName - the name of the operation to invoke (i.e. getRoles)
         * @param {Array} [args] - additional arguments for the operation
         * @param {string} [rejectMsg] - if provided, this command will reject the promise with the given error string
         * @param {boolean} [resultFormat] - one of the EXPECTED_RESULT flags. If omitted, MULTIPLE is assumed
         * @returns {promise}
         */
        this.sendCommand = function(serviceName, commandName, args, rejectMsg, resultFormat) {
            if (angular.isUndefined(args) || args == null) args = null;
            else if (!angular.isArray(args) && !angular.isObject(args)) args = [args];

            var url = Wave2.WcService.BASE_SERVER_URL + 'ws/' + serviceName + '/' + commandName;
            var deferred = $q.defer();
            var nodeService = this;
            $http.post(url, args)
                .success(function(data) {
                    if ((!data || data.length == 0) && rejectMsg && resultFormat !== RESULT_FORMAT.NONE) {
                        deferred.reject(rejectMsg);
                    }
                    if (resultFormat === RESULT_FORMAT.SINGLE) data = data[0];

                    deferred.resolve(data);
                }).error(function(data, status) {
                    nodeService.errHandler(status, data, deferred);
                });
            return deferred.promise;
        }

        /**
         * Get a directory listing from the CMS
         * @param cmsPath - the relative CMS path (e.g. 'inventory2gallery/Cars') Any URL encoding should be done
         * by the calling code
         * @return {promise}
         */
        this.getCMSDirectoryListing = function(cmsPath) {
            var url = Wave2.WcService.BASE_SERVER_URL + cmsPath;
            var deferred = $q.defer();
            var nodeService = this;
            $http.get(url)
                .success(function (data) {
                    var x2js = new X2JS();
                    var result = [];
                    if (data) {
                        result = x2js.xml_str2json(data);
                        result = nodeService._processDirectories(result, cmsPath);
                    }
                    deferred.resolve(result);
                }).error(function (data, status) {
                    nodeService.errHandler(status, data, deferred);
                });
            return deferred.promise;
        }

        /**
         * Process the CMS listing and filter out things we don't want to show
         * @private
         */
        this._processDirectories = function(jsonObj, cmsPath) {

            // The gallery web component is expecting an array with of objects
            // Where the object properties are [name, type (directory or file)]
            var output = [];
            var resource = (jsonObj && jsonObj.cms) ? jsonObj.cms.resource : null;
            var isArray = angular.isArray(resource);

            if (resource) {
                if(!isArray){
                    resource = [resource];
                }

                for (var i = 0; i < resource.length; i++) {
                    var current = resource[i];
                    if ((current._value === 'Thumbs.db') || (current._value === '.DS_Store')) {
                        continue;
                    }

                    var indexOfThumb = current._value.indexOf('-thumb.jpg');
                    if (indexOfThumb != -1) continue;
                    var indexOfThumbReg = current._value.search(/-thumb_.+x.+\.jpg/);
                    if (indexOfThumbReg > -1) continue;

                    var outputObj = {};
                    if (current._isCollection === 'true') {
                        outputObj['type'] = 'directory';
                        outputObj['name'] = current._value;
                        outputObj['path'] = this._encodeForUrl(current._value);
                    }
                    else {
                        outputObj['type'] = 'file';
                        outputObj['modified'] = new Date(parseInt(current._mtime));
                        outputObj['name'] = cmsPath + current._value;
                        outputObj['path'] = cmsPath + this._encodeForUrl(current._value);
                    }

                    output.push(outputObj);
                }
            }

            return output;
        };

        this._encodeForUrl = function(rawPath) {
            var result = '';
            var rawParts = rawPath.split('/');
            angular.forEach(rawParts, function(rawPart, index) {
                result += encodeURIComponent(rawPart);
                if (index < rawParts.length - 1) result += '/';
            });
            return result;
        };

        this._decodeForUrl = function(rawPath) {
            var result = '';
            var rawParts = rawPath.split('/');
            angular.forEach(rawParts, function(rawPart, index) {
                result += decodeURIComponent(rawPart);
                if (index < rawParts.length - 1) result += '/';
            });
            return result;
        };

        /**
         * Handle an error from the Node server
         * @param {string} status - HTTP status in response
         * @param {string} text - error message from the server
         * @param [deferred] - the promise deferred object to reject if provided
         */
        this.errHandler = function(status, text, deferred) {
            var msg = 'Unknown error occurred in web service handler';
            if (status && status.length > 0) {
                msg = 'Error with status ' + status + ' occurred: ' + text;
            }
            if (status == 401) {
                evBus.broadcast(AUTH_EVENTS.loginTimeout);
            }
            else if (status == 503) {
                evBus.broadcast(AUTH_EVENTS.forceLogout);
                if (deferred) deferred.reject('Server was not available');
            }
            else if (status == 403) {
                evBus.broadcast(AUTH_EVENTS.forceLogout);
                return;
            }
            else if ( status == 405){
                dialog.showDialog(dialog.TYPE.ERROR, '', msg);
            }
            if (deferred) deferred.reject(msg);
            else throw [status, text];
        }
    }])

    .service('restService',['$http', function($http){
        /*
         * Get channels for a document
         */
        this.getChannelData = function(docId){
            var getChannelsURL = Wave2.WcService.BASE_SERVER_URL + 'api/channels/';
            if(docId){
                getChannelsURL += docId;
            }
            return $http.get(getChannelsURL);
        }

        /**
         * This endpoint brings back URLs for the job output files
         */
        this.getDocumentURL = function(client, jobId, outputType, page){
            var outputCallURL = Wave2.WcService.BASE_SERVER_URL + 'api/file/getDocumentURL';
            outputCallURL += '/' + client + '/' + jobId  + '/' + outputType + '/' + page;
            return $http.get(outputCallURL, {responseType : "text"});
        }

        /**
         * Call to the JobBuilder RESTful service - Brings back jobXML structure in either JSON or XML format
         * The JSON format is a direct conversion of the JobModel object. This is similar to the a job in the jobs
         * array found in the registry but this one is structured differently and has different property names due
         * to customisations on the SOAP interface.
         *
         *  @builderToken - The Job Builder token
         *  @param mediaType - Legal values include application/xml and application/json
         */
        this.getJob = function(builderToken, mediaType){
            var jobBuilderRestURL = Wave2.WcService.BASE_SERVER_URL + 'api/jobbuilder/' + builderToken;
            return $http.get(jobBuilderRestURL, {responseType : mediaType});
        };

        /**
         * Brings back the JobBridge object which contains all the job info we see in the Admin Tool
         *
         * @param jobId - This is the second part of jobToken (localhost/1575628620760) when you separate it with a
         * slash you also see it in the admin tool
         */
        this.getJobBridge = function(jobId){
            var getJobBridgeURL = Wave2.WcService.BASE_SERVER_URL + 'api/jobs/' + jobId;
            return $http.get(getJobBridgeURL);
        };
    }])

    .service('configService', ['i18n', '$q', '$log', '$http', 'nodeService', 'RESULT_FORMAT',
        function (i18n, $q, $log, $http, nodeService, RESULT_FORMAT) {

            /**
             * Get the available products
             * @returns {Array} the list of products
             */
            this.getProducts = function() {
                return nodeService.sendCommand('ConfigService', 'getProducts',
                    null, 'No products available');
            };

            /**
             * Get the available types for a product
             * @param {Object} product - a product entry from getProducts
             * @returns {Array} the list of types
             */
            this.getTypes = function(product) {
                var uuid = product.name;
                return nodeService.sendCommand('ConfigService', 'getTypes',
                    uuid, 'No types available');
            };

            /**
             * Get the available documents for a type
             * @param {Object} product - a product entry from getProducts
             * @param {Object} type - a type entry from getTypes
             * @returns {Array} the list of documents
             */
            this.getDocuments = function(product, type) {
                var uuid = [product.name, type.name].join('/');
                return nodeService.sendCommand('ConfigService', 'getDocuments',
                    uuid, 'No documents available');
            };

            /**
             * Get info on a specific document
             * @param {Object} product - a product entry from getProducts
             * @param {Object} type - a type entry from getTypes
             * @param {Object} doc - a document entry from getDocuments
             * @returns {Object} the document details
             */
            this.getDocument = function(product, type, doc, assocIdsOverride) {
                var data = [product.name, type.name, doc.name].join('@@');
                if (assocIdsOverride ){
                    data = [ data, JSON.parse(assocIdsOverride) ];
                }
                return nodeService.sendCommand('ConfigService', 'getDocument',
                    data, 'Document not found', RESULT_FORMAT.SINGLE);
            };

            /**
             * Get the group info for this document
             * @param {Object} product - a product entry from getProducts
             * @param {Object} type - a type entry from getTypes
             * @param {Object} doc - a document entry from getDocuments
             * @returns {Array} a list of group names
             */
            this.getGroupNames = function(product, type, doc) {
                var uuid = [product.name, type.name, doc.name].join('@@');
                return nodeService.sendCommand('ConfigService', 'getGroupNames',
                    uuid, 'Group names not available for document')
                    .then(function(groups){// Make up group names if missing
                        angular.forEach(groups, function(group, index) {
                            if (!group) groups[index] = i18n.groups_articles.group_init_cap + ' ' + (index + 1);
                        });
                        return groups;
                    });
            };

            /**
             * Get the library info for this document
             * @param {Object} product - a product entry from getProducts
             * @param {Object} type - a type entry from getTypes
             * @param {Object} doc - a document entry from getDocuments
             * @param {number} groupIndex - the selected group index
             * @returns {Array} a list of libraries, or null if no library info is available for this group
             */
            this.getLibraryEntries = function(product, type, doc, groupIndex, size) {
                var uuid = [product.name, type.name, doc.name].join('@@');
                if ( !size ){
                    size = {name: 'Original'};
                }
                return nodeService.sendCommand('ConfigService', 'getLibraryConfig',
                    [ uuid, groupIndex, size ], null, RESULT_FORMAT.SINGLE);
            };

            /**
             * Get the config for this document / library item
             * docContent should be obtained via docContentService
             * @param {string} uud - product name, type name and document name joined with @@
             * @param {number} groupIndex - the selected group index
             * @param {string} [libItem] - get the selected library item config (leave empty for basic document config)
             * @returns {Array} a list of library items, or null if no library info is available for this group
             */
            this.getDocContent = function(uuid, groupIndex, libItem, assocIdsOverride) {
                var docContent;
                if (libItem !== undefined) {
                    docContent = nodeService.sendCommand('ConfigService', 'getLibraryEntryConfig',
                        [ uuid, groupIndex, libItem, 'Original' ], 'Library item has no content', RESULT_FORMAT.SINGLE);
                }
                else {
                    var data = [ uuid, groupIndex, 'Original' ];
                    if (assocIdsOverride ){
                        data = [ uuid, groupIndex, 'Original', JSON.parse(assocIdsOverride) ];
                    }
                    docContent = nodeService.sendCommand('ConfigService', 'getBasicContentConfig',
                        data, 'Document has no content', RESULT_FORMAT.SINGLE);
                }
                return docContent;
            };

            this.getEffects = function(uuid, assocIdsOverride){
                var data = [uuid, 'Original'];
                if (assocIdsOverride ){
                    data = [ uuid, 'Original', JSON.parse(assocIdsOverride) ];
                }
                var effects = nodeService.sendCommand('ConfigService','getEffects', data);
                return effects;
            };

            this.getPaths = function(docId) {
                var uuid = docId.join('/');
                return nodeService.sendCommand('ConfigService', 'getPaths',
                    uuid, 'Paths not available');
            };

            /**
             * Save the current grid defaults to the CMS
             * @param {string} product - the active product name
             * @param {string} type - the active type name
             * @param {string} doc - the active document name
             * @param {number} groupIndex - the selected group index
             * @param {Object} gridDefaults - the current grid information to store
             */
            this.saveGridDefaults = function(product, type, doc, groupIndex, gridDefaults) {
                var url = [ "service", "gridDefaults", product, type, doc, "gridDefaults.json" ].join("/");
                var deferred = $q.defer();
                $http.put(url, gridDefaults)
                    .success(function() {
                        deferred.resolve();
                    }).error(function(data, status) {
                        nodeService.errHandler(status, data, deferred);
                    });
                return deferred.promise;
            };

            /**
             * Load the grid defaults from the CMS
             * @param {string} product - the active product name
             * @param {string} type - the active type name
             * @param {string} doc - the active document name
             * @returns {Object} the default grid information, or null if no grid defaults available for this doc
             */
            this.loadGridDefaults = function(product, type, doc) {
                var url = [ "service", "gridDefaults", product, type, doc, "gridDefaults.json" ].join("/");
                var deferred = $q.defer();
                $http.get(url)
                    .success(function(data) {
                        deferred.resolve(data);
                    }).error(function() {
                        // There are no grid defaults for this document
                        deferred.resolve(null);
                    });
                return deferred.promise;
            };

            /**
             * Get a list of channel which relate to a particular document
             * @param {string} product - the active product name
             * @param {string} type - the active type name
             * @param {string} doc - the active document name
             */
            this.getChannels = function(product, type, doc) {
                var uuid = [product.name, type.name, doc.name].join('@@');
                return nodeService.sendCommand('ConfigService','getChannels',
                    uuid, 'Channels not available');
            }

            /**
             * Get a list of channel which relate to a particular document
             * @param {string} product - the active product name
             * @param {string} type - the active type name
             * @param {string} doc - the active document name
             */
            this.getPointSizes = function(product, type, doc) {
                var uuid = [product.name, type.name, doc.name].join('@@');
                return nodeService.sendCommand('ConfigService','getPointSizes', uuid, 'Point sizes not available');
            };

            /**
             * Get the available documents for a type
             * @param {Object} product - a product entry from getProducts
             * @param {Object} type - a type entry from getTypes
             * @returns {Array} the list of documents
             */
            this.getBuildMediaDocuments = function(fullId, format) {
                return nodeService.sendCommand('ConfigService', 'getBuildMediaDocuments',
                    [fullId, format]);
            };
        }
    ])

    .service('jobStoreService', ['$q', '$log', 'APP_EVENTS', 'RESULT_FORMAT', 'nodeService',
        function ($q, $log, APP_EVENTS, RESULT_FORMAT, nodeService) {
            var jobStoreService = {

                retrieve: function (filterCriteria, maxPageSize, allGroups, users) {
                    var query = angular.copy(filterCriteria, {});

                    query.status = filterCriteria.status.value;
                    query.pageIndex = filterCriteria.pageIndex || 0;
                    query.pageSize = maxPageSize;

                    if (filterCriteria.shareType) {
                        var shareType = {
                            type: filterCriteria.shareType.value,
                            groups: []
                        }
                        if (filterCriteria.shareType.value === 'users') {
                            shareType.users = [filterCriteria.shareUser];
                        }
                        else if (filterCriteria.shareType.value === 'groups') {
                            shareType.groups = [filterCriteria.shareGroup.name];
                        }
                        else if (filterCriteria.shareType.value === 'allgroups') {
                            shareType.type = "groups";
                            shareType.groups = allGroups;
                        }
                        else {
                            shareType.groups = allGroups;
                            shareType.users = users;
                        }
                        query.shareType = shareType;
                    }
                    delete query.shareGroup;
                    delete query.shareUser;

                    return nodeService.sendCommand('JobStoreService', 'listJobs',
                        { jobStoreSearch: query }, 'Could not fetch jobs from job store', RESULT_FORMAT.SINGLE);
                },

                /**
                 * Store/update a job on the server
                 * @param {string} owner - the owner of the job to create
                 * @param {Object} jobDetails - the updated job details e.g.
                 * {
                 *    childJobNames: null
                 *    comments: null
                 *    creationDate: "2019-06-05T15:16:01.000+01:00"
                 *    groups: null
                 *    hidden : false
                 *    instance: "14ef19de-5fba-4371-80b6-636899cd9499"
                 *    jobXml: "<?xml version="1.0" encoding="UTF-8"?><job client="localhost" id="" name="" prod
                 *    label : 'jobLabel',
                 *    modifiedDate: "2019-06-05T15:16:01.000+01:00"
                 *    name : 'jobLabel',
                 *    owner : 'Administrator',
                 *    shareType : {
                 *       groups : null,
                 *       type : 'private',
                 *       users : null
                 *    },
                 *    status : 'new'
                 * }
                 */
                store: function (owner, jobDetails) {
                    var deferred = $q.defer();
                    if ( jobDetails.shareType && !jobDetails.shareType.type){
                        delete jobDetails.shareType;
                    }
                    var jobName = jobDetails.name;
                    nodeService.sendCommand('JobStoreService', 'store',
                        { 'owner' : owner, 'name' : jobName, 'jobDetails' : jobDetails }, 'Failed to store job details', RESULT_FORMAT.SINGLE)
                        .then(function(jobKey) {
                            deferred.resolve(jobKey);
                        });
                    return deferred.promise;
                },

                /**
                 * Retrieve the details of a particular job in the store
                 * @param {string} owner - the owner of the job to retrieve
                 * @param {string} name - the name of the job
                 * @returns {Object} the job details
                 */
                details: function (owner, name) {
                    return nodeService.sendCommand('JobStoreService', 'getJobDetails',
                        [ owner, name ], 'Failed to retrieve job details', RESULT_FORMAT.SINGLE);
                },

                /**
                 * Check if a job name exists in the store already
                 * @param {string} owner - the owner of the job to check
                 * @param {string} name - the name of the job
                 * @returns {string} the instance ID of the existing job, or empty string if it doesn't exist
                 */
                exists: function (owner, name) {
                    var deferred = $q.defer();
                    nodeService.sendCommand('JobStoreService', 'getJobDetails',
                        [ owner, name ], null, RESULT_FORMAT.SINGLE)
                        .then(function(job) {
                            var exists = (job && job.instance) ? job.instance : "";
                            deferred.resolve(exists);
                        });
                    return deferred.promise;
                },

                /**
                 * Check if a job name exists in the store already
                 * @param {string} owner - the owner of the job to check
                 * @param {string} name - the name of the job
                 * @returns {boolean} true if the job was deleted successfully
                 */
                deleteJob: function (owner, name) {
                    var deferred = $q.defer();
                    nodeService.sendCommand('JobStoreService', 'delete',
                        [ name ], 'Failed to delete job', RESULT_FORMAT.SINGLE)
                        .then(function(instanceId) {
                            var success = (!instanceId);
                            deferred.resolve(success);
                        });
                    return deferred.promise;
                }
            }

            return jobStoreService;
        }
    ])

    .service('jobBuilderService', ['$q', '$log', 'APP_EVENTS', 'RESULT_FORMAT', 'MEDIA_TYPE', 'ebConfig', 'nodeService',
        'pluginScripts', 'registry', 'docContentService', 'restService',
        function ($q, $log, APP_EVENTS, RESULT_FORMAT, MEDIA_TYPE, ebConfig, nodeService, pluginScripts, registry, docContentService, restService) {

            var jobBuilderService = {

                /**
                 * Build the publication data for this job
                 * @param generateData - the data passed in for building
                 * @param materialFiles - the material files
                 * @private
                 */
                _buildPubData: function(generateData) {
                    var pubData = {
                        jobName: generateData.jobName || '',
                        channelName: generateData.channelName || ebConfig.get('easybuild.w2pp.channel.preview'),
                        channelPostAction: generateData.channelPostAction || 'preview',
                        isMasterJob: generateData.isMasterJob,
                        metadata: generateData.metadata
                    }
                    if (generateData.effects) {
                        pubData.effects = [];
                        angular.forEach(generateData.effects, function(data, key) {
                            var effectName = key;
                            if(data.name){
                                effectName = data.name;//effects coming from launch job have the effect name as a property
                            }
                            else {
                                effectName = key;//effects just added have names as properties of the effects object
                            }
                            pubData.effects.push({
                                name: effectName,
                                objectStyles: data.objectStyles,
                                swatches: data.swatches
                            });
                        });
                    }
                    if(generateData.currentGroup){
                        pubData.group = generateData.currentGroup;
                    }

                    this._buildScaleAndSizeData(pubData, generateData);
                    this._buildScriptConfiguration(pubData);
                    this._buildColourChanges(pubData, generateData.colourChanges);

                    return pubData;
                },

                /**
                 * Build the scale/size data for this job
                 * @param pubData - target publication object
                 * @param generateData - the data passed in for building
                 * @private
                 */
                _buildScaleAndSizeData: function(pubData, generateData) {
                    if (generateData.size && (!generateData.size.name || generateData.size.name === _('build.size_custom'))) {
                        pubData.scaleName = '';
                        pubData.width = generateData.size.width;
                        pubData.height = generateData.size.height;
                    }
                    else if (generateData.size && generateData.size.name) {
                        pubData.scaleName = generateData.size.name;
                    }
                    else {
                        pubData.scaleName = ebConfig.get('easybuild.w2pp.scale.default');
                    }
                },

                /**
                 * Send the plugin script configuration
                 * @param pubData - target publication to add these scripts to
                 * @private
                 */
                _buildScriptConfiguration: function(pubData) {
                    var scripts = pluginScripts.getScripts();

                    var scriptConfiguration = [];
                    angular.forEach(scripts, function (params, name) {

                        // Create the script command
                        var scriptConfig = {
                            name: name,
                            type: params.__type__
                        }

                        // Add the parameters
                        var scriptData = [];
                        angular.forEach(params, function (value, name) {
                            if (name !== '__type__') {
                                var entry = {
                                    name: name,
                                    value: value
                                }
                                scriptData.push(entry);
                            }
                        });
                        if (scriptData.length > 0) scriptConfig.parameters = scriptData;
                        scriptConfiguration.push(scriptConfig);
                    });
                    if (scriptConfiguration.length > 0) {
                        pubData.scriptConfiguration = scriptConfiguration;
                    }
                },

                /**
                 * Add the swatch options, the WS format is slightly different to the source data
                 * @param pubData - target publication to apply effects settings to
                 * @param colourChangeParams - list of colour swatches
                 * @private
                 */
                _buildColourChanges: function(pubData, colourChangeParams) {
                    // Build the new colour change list
                    var colourChanges = [];
                    if (angular.isArray(colourChangeParams)) {
                        angular.forEach(colourChangeParams, function (colourChangeParam) {
                            var colourChange = {
                                colour: {
                                    black: colourChangeParam.black,
                                    cyan: colourChangeParam.cyan,
                                    yellow: colourChangeParam.yellow,
                                    magenta: colourChangeParam.magenta
                                },
                                name: colourChangeParam.name
                            }
                            colourChanges.push(colourChange);
                        });
                    }
                    if (colourChanges.length > 0) {
                        pubData.colourChanges = colourChanges;
                    }
                },

                /**
                 * Build the data for the articles in the job
                 * @param generateData - the article data to convert
                 * @private
                 */
                _buildArticleData: function(generateData) {
                    var groupArticles;
                    if (!generateData.groupArticles) {
                        groupArticles = [];
                        if (generateData.groupArticleMapping) {
                            for (var groupIndex = 0; groupIndex < generateData.groupArticleMapping.length; groupIndex++) {
                                var mapping = generateData.groupArticleMapping[groupIndex];
                                var articles = [];
                                for (var index = 0; index < mapping.length; index++) {
                                    var article = registry.get(mapping[index]);
                                    var articleCopy = angular.copy(article);

                                    // Create empty arrays if fields missing
                                    if (!articleCopy.textItems) articleCopy.textItems = [];
                                    if (!articleCopy.imageItems) articleCopy.imageItems = [];
                                    if (!articleCopy.flashTextItems) articleCopy.flashTextItems = [];
                                    if (!articleCopy.flashImageItems) articleCopy.flashImageItems = [];
                                    if (!articleCopy.externalMediaItems) articleCopy.externalMediaItems = [];

                                    articles.push(articleCopy);
                                }
                                groupArticles.push(articles);
                            }
                        }
                    } else {
                        groupArticles = angular.copy(generateData.groupArticles);
                    }

                    angular.forEach(groupArticles, function(articles, groupIndex) {
                        var articleId = 1;
                        angular.forEach(articles, function(article) {
                            // Set the article ID
                            article.id = article.name = articleId;

                            // Rename these properties
                            article.layoutRowSpan = article.rows; delete article.rows;
                            article.layoutColumnSpan = article.cols; delete article.cols;
                            article.layoutRowPosition = article.y; delete article.y;
                            article.layoutColumnPosition = article.x; delete article.x;
                            article.layoutLibraryItemName = article.libraryEntryName; delete article.libraryEntryName;

                            // Now iterate the content fields, some properties need a jns0 namespace to set properly
                            // All fields need an appropriate ID

                            if (article.textItems) {
                                angular.forEach(article.textItems, function (text, index) {
                                    jobBuilderService._buildFieldData(text, index);
                                });
                                if (article.textItems.length > 0) article['dom:textItems'] = article.textItems;
                                delete article.textItems;
                            }

                            if (article.imageItems) {
                                angular.forEach(article.imageItems, function (image, index) {
                                    jobBuilderService._buildFieldData(image, index, true);
                                });
                                if (article.imageItems.length > 0) article['dom:imageItems'] = article.imageItems;
                                delete article.imageItems;
                            }

                            if (article.flashTextItems) {
                                angular.forEach(article.flashTextItems, function (text, index) {
                                    jobBuilderService._buildFieldData(text, index);
                                });
                                if (article.flashTextItems.length > 0) article['dom:flashTextItems'] = article.flashTextItems;
                                delete article.flashTextItems;
                            }

                            if (article.flashImageItems) {
                                angular.forEach(article.flashImageItems, function (image, index) {
                                    jobBuilderService._buildFieldData(image, index, true);
                                });
                                if (article.flashImageItems.length > 0) article['dom:flashImageItems'] = article.flashImageItems;
                                delete article.flashImageItems;
                            }

                            if (article.externalMediaItems) {
                                angular.forEach(article.externalMediaItems, function (exMedia, index) {
                                    jobBuilderService._buildFieldData(exMedia, index, true);
                                });
                                if (article.externalMediaItems.length > 0) article['dom:externalMediaItems'] = article.externalMediaItems;
                                delete article.externalMediaItems;
                            }

                            articleId++;
                        });
                    });

                    return groupArticles;
                },

                _buildFieldData : function(field, index, isImageOrExternalMedia) {
                    field['dom:id'] = index + 1;
                    if (field.fitting || field.framefitting || field.valign || field.halign || field.clipping) {
                        field['imagefit'] = {
                            'dom:fitting': field.fitting,
                            'dom:framefitting': field.framefitting,
                            'dom:valign': field.valign,
                            'dom:halign': field.halign,
                            'dom:clipping': field.clipping
                        }
                    }
                    if (isImageOrExternalMedia) {
                        field['dom:value'] = field.value;
                        if(field.delete_frame != undefined) {
                            field['delete'] = field.delete_frame;
                        }
                        delete field['value'];
                    }

                    // Reduce field data we don't need to send to the job builder
                    // This reduces the total packet size dramatically
                    angular.forEach([
                        'fieldParagraphStyles', 'fieldCharacterStyles', 'spellChecker', 'fieldRestrictions', 'selectionList', 'type',
                        'defaultValue', 'caption', 'inputCharacters', 'minCharCount', 'maxCharCount', 'allowOnlyCharacters',
                        'fileUpload', 'imageCropping', 'imageGallery',
                        'prefix', 'prefixCharacterStyle', 'prefixAlwaysUse', 'postfix', 'postfixCharacterStyle', 'postfixAlwaysUse'
                    ], function(propName) {
                        delete field[propName];
                    });
                },

                /**
                 * Create a new job builder session
                 * @private
                 */
                _createBaseJob: function(product, type, document) {
                    var docPath = [product.name, type.name, document.name].join('@@');
                    return nodeService.sendCommand('JobBuilderService', 'createBaseJob',
                        ['', docPath], 'Failed to create base job', RESULT_FORMAT.SINGLE).then(function(jobBuilderToken) {
                            return jobBuilderToken;
                        });
                },

                /**
                 * Create a new job builder session using an XML fragment
                 * @private
                 */
                _createBaseJobWithXml: function(jobXml) {
                    return nodeService.sendCommand('JobBuilderService', 'createBaseJob',
                        [jobXml, ''], 'Failed to create base job', RESULT_FORMAT.SINGLE).then(function(jobBuilderToken) {
                            return jobBuilderToken;
                        });
                },

                /**
                 * Retrieve the job model currently in the builder
                 * @private
                 */
                _getJobModel: function(jobBuilderToken) {
                    return nodeService.sendCommand('JobBuilderService', 'getJobModel',
                        jobBuilderToken, 'Failed to get job model', RESULT_FORMAT.SINGLE);
                },

                /**
                 * Build all the articles for the job
                 * @private
                 */
                _buildArticles: function(jobBuilderToken, groupArticles) {
                    var deferred = $q.defer();
                    var promise = deferred.promise;
                    var anyArticles = false;
                    angular.forEach(groupArticles, function (articles) {
                        if (articles.length > 0) anyArticles = true;
                    });
                    if (!anyArticles) deferred.resolve(jobBuilderToken);

                    angular.forEach(groupArticles, function (articles, groupIndex) {
                        angular.forEach(articles, function (article, articleIndex) {
                            // These must be invoked in sequence to avoid concurrent execution errors from JBoss
                            promise = promise.then(function () {
                                return nodeService.sendCommand('JobBuilderService', 'buildArticle',
                                    [jobBuilderToken, groupIndex + 1, 0, article], 'Failed to build article', RESULT_FORMAT.NONE);
                            });
                            if (groupIndex >= groupArticles.length - 1 && articleIndex >= articles.length - 1) {
                                deferred.resolve(jobBuilderToken);
                            }
                        });
                    });
                    return promise;
                },

                /**
                 * Build an entire job and return the XML
                 * @param {object} product - the chosen product
                 * @param {object} type - the chosen type
                 * @param {object} document - the chosen document
                 * @param generateDataString - the job data
                 * @param materialFiles - material file info to add in the metadata
                 * @return {promise}
                 */
                generateJobXML : function(product, type, document, generateDataString) {
                    var deferred = $q.defer();
                    var promise = deferred.promise;

                    var generateData = angular.fromJson(generateDataString);
                    var pubData = jobBuilderService._buildPubData(generateData);
                    var groupArticles = jobBuilderService._buildArticleData(generateData);

                    var chain = {

                        // Send the publication info
                        buildPublication: function (jobBuilderToken) {
                            return nodeService.sendCommand('JobBuilderService', 'buildPublication',
                                [jobBuilderToken, pubData], 'Failed to build publication', RESULT_FORMAT.NONE).then(function() {
                                    return jobBuilderToken;
                                });
                        },

                        // Send all the articles sequentially
                        buildArticles: function (jobBuilderToken) {
                            return jobBuilderService._buildArticles(jobBuilderToken, groupArticles).then(function () {
                                return jobBuilderToken;
                            });
                        },

                        // Retrieve the final job XML
                        getJobXml: function (jobBuilderToken) {
                            return nodeService.sendCommand('JobBuilderService', 'getJobXml',
                                jobBuilderToken, 'Failed to get job XML', RESULT_FORMAT.SINGLE).then(function(jobXml) {
                                    return jobXml;
                                });
                        }

                    };

                    jobBuilderService._createBaseJob(product, type, document)
                        .then(chain.buildPublication)
                        .then(chain.buildArticles)
                        .then(chain.getJobXml)
                        .then(function(jobXml) {
                            deferred.resolve(jobXml);
                        }, function(e) {
                            deferred.reject(e);
                        });

                    return promise;
                },

                /**
                 * Load a job XML into the builder and retrieve the job model
                 * @param {string} jobXml - the job XML to send to the builder
                 * @return {promise}
                 */
                getJobModel : function(jobXml) {
                    var deferred = $q.defer();
                    var promise = deferred.promise;

                    jobBuilderService._createBaseJobWithXml(jobXml).then(
                        function (jobBuilderToken) {
                            jobBuilderService._getJobModel(jobBuilderToken).then(function(jobModel) {
                                // Ensure the group names are returned as an array (TODO: fix the underlying wsclient bug)
                                if (jobModel.groupNames && !Array.isArray(jobModel.groupNames)) {
                                    jobModel.groupNames = [jobModel.groupNames];
                                }

                                // Strip out external media pages, we don't want these in EasyBuild
                                var articleDataMap = jobModel.articleDataMap;
                                var dataPairs = articleDataMap.articleDataPair;
                                if (dataPairs) {
                                    for (var dataPairIndex = 0; dataPairIndex < dataPairs.length; dataPairIndex++) {
                                        var dataPair = dataPairs[dataPairIndex];
                                        dataPair.articleData = docContentService.filterExternalMediaItems(dataPair.articleData);
                                    }
                                }

                                deferred.resolve(jobModel);
                            });
                        },function () {
                            Wave2.WcService.fireError();
                        }
                    );

                    return promise;
                }
            }

            return jobBuilderService;
        }
    ])

    .service('jobService', ['$q', '$log', 'APP_EVENTS', 'RESULT_FORMAT', 'nodeService', 'jobBuilderService',
        function ($q, $log, APP_EVENTS, RESULT_FORMAT, nodeService, jobBuilderService) {
            var jobService = {

                generateJob: function(product, type, document, generateDataString) {
                    var deferred = $q.defer();
                    jobBuilderService.generateJobXML(product, type, document, generateDataString).then(function(jobXml) {
                        nodeService.sendCommand('JobService', 'submitJobXml',
                            { arg0: jobXml }, 'Failed to generate job', RESULT_FORMAT.SINGLE).then(function(jobToken) {

                            deferred.resolve(jobToken);
                        });
                    })
                    return deferred.promise;
                },

                submitJobXml: function(jobXml) {
                    return nodeService.sendCommand('JobService', 'submitJobXml',
                        { arg0: jobXml }, 'Failed to submit job', RESULT_FORMAT.SINGLE);
                },

                getJobStatus: function(jobToken, getQueueDetials) {
                    var args = jobToken;
                    if ( getQueueDetials){
                        args = [jobToken, getQueueDetials]
                    }

                    return nodeService.sendCommand('JobService', 'getJobStatus',
                        args, 'Failed to get job output', RESULT_FORMAT.SINGLE);
                },

                getJobXml: function(jobToken) {
                    return nodeService.sendCommand('JobService', 'getJobXml',
                        jobToken, 'Failed to get job xml', RESULT_FORMAT.SINGLE);
                },

                getJobOutput: function(jobToken) {
                    var deferred = $q.defer();
                    nodeService.sendCommand('JobService', 'getJobOutput',
                        jobToken, 'Failed to get job output').then(function(output) {
                            var result = {
                                output: output
                            }

                            // Grab the job stats too...
                            nodeService.sendCommand('JobService', 'getJobInfo',
                                jobToken, 'Failed to get job statistics', RESULT_FORMAT.SINGLE).then(function(jobInfo) {
                                    result.stats = jobInfo.stats;
                                    deferred.resolve(result);
                                }, function(err, info) {
                                    deferred.reject(info);
                                });
                        }, function(err, info) {
                            deferred.reject(info);
                        });

                    return deferred.promise;
                },

                getJobInfo: function(jobToken) {
                    var deferred = $q.defer();
                    // Grab the job stats too...
                    nodeService.sendCommand('JobService', 'getJobInfo',
                        jobToken, 'Failed to get job statistics', RESULT_FORMAT.SINGLE).then(function(jobInfo) {
                        deferred.resolve(jobInfo.stats);
                    }, function(err, info) {
                        deferred.reject(info);
                    });

                    return deferred.promise;
                },

                isErrored: function(jobToken) {
                    return nodeService.sendCommand('JobService', 'isErrored',
                        jobToken, 'Errored check failed', RESULT_FORMAT.SINGLE);
                },

                cancelJob: function(jobToken) {
                    // Ignore the response for this, since we don't care if a cancel operation failed
                    nodeService.sendCommand('JobService', 'cancelJob', jobToken);
                }
        	}

            return jobService;
        }
    ])

    .service('mediaService', ['$q', '$log', 'RESULT_FORMAT', 'nodeService', function ($q, $log, RESULT_FORMAT, nodeService) {
            var mediaService = {

                /**
                 * Submit a video splice command to "crop" the start
                 * @param source - the original video to crop
                 * @param destination - the target video path
                 * @param cropStartSeconds - new start position
                 */
                submitCropVideo: function(source, destination, cropStartSeconds) {
                    var params = {
                        parameters : [{ key: 'cropStartSeconds', value: cropStartSeconds }]
                    };
                    //{ key: 'cropStartSeconds', value: cropStartSeconds }
                    var deferred = $q.defer();
                    nodeService.sendCommand('MediaService', 'submitCropStartVideo',
                        [ source, destination, params ], 'Video crop failed', RESULT_FORMAT.SINGLE).then(function(jobToken) {
                        deferred.resolve(jobToken);
                    }, function(reason) {
                        deferred.reject(reason);
                    });
                    return deferred.promise;
                },

                /**
                 * We convert any video's which are not flv to flv
                 * @param source - the original video to transcode
                 * @param destination - the target video path
                 */
                submitTranscodeVideo : function(source, destination){
                    var params = {
                        parameters : [{ key: 'targetFormat', value: 'mp4'}]
                    };
                    var deferred = $q.defer();
                    nodeService.sendCommand('MediaService', 'submitTranscodeVideo',
                        [ nodeService._decodeForUrl(source), nodeService._decodeForUrl(destination), params], 'Video transcode failed', RESULT_FORMAT.SINGLE).then(function(jobToken) {
                            deferred.resolve(jobToken);
                        }, function(reason){
                           deferred.reject(reason);
                        });
                    return deferred.promise;
                },

                /**
                 * Scrape text/images/videos from a website
                 * @param url - the website URL to scrape
                 */
                importFromWebPage: function(url) {
                    return nodeService.sendCommand('MediaService', 'importFromWebPage',
                        [ url, 'Easybuild/media/pasteboard/' ], 'Media import failed', RESULT_FORMAT.SINGLE);
                }
            };

            return mediaService;
        }
    ]);
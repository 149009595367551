angular.module('easybuild.page.jobmanager')

.service('jobManagerService', ['$window', '$q', '$location', '$timeout', 'APP_EVENTS', 'ebConfig', 'registry',
    'jobBuilderService', 'jobStoreService', 'userSession', 'dialog', 'authService', 'buildService', 'launchService',
    'configService', 'metadataService', 'mediaManager', 'evBus', 'i18n', 'utilitiesService',
    function($window, $q, $location, $timeout, APP_EVENTS, ebConfig, registry, jobBuilderService, jobStoreService,
             userSession, dialog, authService, buildService, launchService, configService, metadataService,
             mediaManager, evBus, i18n, utilitiesService) {

    return {

        listJobs: function(filterCriteria, allGroups, users) {
            var deferred = $q.defer();
            var promise = deferred.promise;

            jobStoreService.retrieve(filterCriteria, ebConfig.get('easybuild.web.job.retrieve.pagesize'), allGroups, users).then(function(jobStoresPagedResult) {
                var statuses = ebConfig.get('easybuild.web.job.store.statuses');
                if (!statuses) statuses = [];

                var jobStores = jobStoresPagedResult.jobStores;
                for (var index = 0; index < jobStores.length; index++) {
                    var valueStatus = jobStores[index].status;
                    for (var statusIndex = 0; statusIndex < statuses.length; statusIndex++) {
                        if (statuses[statusIndex].value == valueStatus) {
                            jobStores[index].status = _(statuses[statusIndex].label);
                            break;
                        }
                    }
                }

                var totalJobs = jobStoresPagedResult.maxResults;
                var totalPages = Math.ceil(totalJobs / ebConfig.get('easybuild.web.job.retrieve.pagesize'));
                deferred.resolve({jobStores: jobStores, totalPages: totalPages, totalJobs: totalJobs});
            }, function(reason) {
                throw new Error(i18n.error.fetch_jobs_failed + reason);
            });

            return promise;
        },

        /**
         * Return the details of a selected job
         * @param owner - the current user
         * @param jobName - the target job name
         * @param statuses - available statuses loaded from the config
         * @return {promise}
         */
        showJob: function(owner, jobName, statuses) {
            var deferred = $q.defer();
            var promise = deferred.promise;

            jobStoreService.details(owner, jobName).then(function(job) {
                var storeEnabled = true;
                authService.userInfo().then(function(user){
                    var x2js = new X2JS();
                    var jobXMLjson = x2js.xml_str2json(job.jobXml);

                    var materialFiles = [];
                    if(jobXMLjson.job.metadata && jobXMLjson.job.metadata.materialfiles && jobXMLjson.job.metadata.materialfiles.materialfile  ){
                        var materialFileList = jobXMLjson.job.metadata.materialfiles.materialfile;

                        if(materialFileList.constructor === Array){
                            for(var i=0; i < materialFileList.length; i++ ){
                                if (materialFileList[i] && materialFileList[i].__cdata) {
                                    materialFiles[i] = {"name": materialFileList[i]._name, "location": materialFileList[i].__cdata };
                                }
                            }
                        }
                        else if(typeof materialFileList === 'object'){
                            if (materialFileList && materialFileList.__cdata) {
                                materialFiles[0] = {"name": materialFileList._name, "location": materialFileList.__cdata };
                            }
                        }

                        $timeout(function() {
                            angular.element("#materialFiles").triggerHandler(APP_EVENTS.materialfilesReload, null);
                        }, 100);
                    }

                    var statusMatches = statuses.filter(function(item) {
                        return item.value === job.status;
                    });
                    var status = statusMatches.length > 0 ? statusMatches[0] : null;

                    deferred.resolve({storeEnabled: storeEnabled, job: job, status: status, materialFiles: materialFiles});
                },function(reason){
                    throw new Error(i18n.error.getting_user_info + reason);
                });
            }, function(reason) {
                throw new Error(i18n.error.getting_job_details + reason);
            });

            return promise;
        },

        /**
         * Calculate appropriate share settings for the job
         * @param jobDetails
         * @param status
         * @param shareGroup
         * @param shareUser
         * @param shareGroupList
         */
        setSharingSettings: function(jobDetails, status, shareGroup, shareUser, shareGroupList) {
            jobDetails.status = status ? status.value : null;
            jobDetails.shareType.groups = shareGroup ? shareGroup.name : null;
            jobDetails.shareType.users = shareUser;
            if (jobDetails.shareType.type === 'all_groups') {
                // Set all groups mode
                jobDetails.shareType.type = 'groups';
                jobDetails.shareType.groups = [];
                angular.forEach(shareGroupList, function(group) {
                    this.push(group.name);
                }, jobDetails.shareType.groups);
            }
        },

        _materialFileCopyCompleted : function(item, data){
            mediaManager._deleteItem(item.path);
            this.materialFiles[item.materialFileIndex].location = data.destination;
        },

        _calculateMaterialFilePath: function(name) {
            // Grab the media identifier in the metadata
            // Otherwise, fall back to the username
            var mediaId = metadataService.getMetaField('mediaIdentifier');

            var materialPathId = 'media/materialfiles/';
            materialPathId += mediaId ? mediaId  : userSession.username;
            materialPathId += '/';
            if ( name && name.length > 0 ){
                materialPathId += name.replace(/[\W_]+/g,"_");
                materialPathId += '/';
            }

            return materialPathId;
        },

        updateMaterialFilesForStoredJob: function(materialFiles, name){

            var defer = $q.defer();
            var promise = defer.promise;

            var items = [];

            angular.forEach(materialFiles,function(materialFile, materialFileIndex){
                if(materialFile.location.indexOf('materialfiles') == -1 ){
                    var path = materialFile.location.substring(6);
                    var item = { 'path' : path, type: 'image', filename: materialFile.location.substring(materialFile.location.lastIndexOf('/')+1), materialFileIndex: materialFileIndex};
                    items.push(item);
                }
            });

            var promises = [];

            var destinationPath = this._calculateMaterialFilePath(name);
            var moveFail = i18n.error.mat_files_to_persistent_storage;

            var jobManagerService = this;

            angular.forEach(items, function(item){
                promises.push(mediaManager._copyItem.call(mediaManager, item, destinationPath, moveFail, jobManagerService._materialFileCopyCompleted, {materialFiles : materialFiles}));
            });

            if(promises.length > 0){
                $q.all(promises).then(function(){
                    defer.resolve();
                });
            }
            else {
                defer.resolve();
            }

            return promise;
        },

        /**
         * Store/update a job/multi-job in the job store
         * @param jobDetails - the details of the job to create/update e.g
         * { 
         *    label : "test190605-1047",
         *    owner : "Administrator"
         *    shareType : {
         *                   groups : null,
         *                   type : "private",
         *                   users : null
         *                },
         *    status : "new"
         *    jobToken: "localhost/1234567890123"
         * }
         * @param materialFiles - the material files associated with this job
         * @param {string} operation - 'store' or 'update' (used for validation)
         * @param materialFilesDiscardList - the material files to delete
         */
        storeJobs: function(jobDetails, materialFiles, operation, materialFilesDiscardList) {

            // Include the jobToken if this is not a multi-job
            // Enables job output to be reused if desired when
            // retrieving the job from the job store
            let jobs = registry.get('jobs');
            if (jobs && jobs.length === 1) {
                let jobToken = jobs[0].jobToken;
                if (jobToken && jobToken.trim().length > 0) {
                    jobDetails.jobToken = jobs[0].jobToken.trim();
                }
            }

            var jobManagerService = this;
            angular.forEach(materialFilesDiscardList, function(fileToDelete){
                mediaManager._deleteItem(fileToDelete.substring(6));
            });

            this.updateMaterialFilesForStoredJob(materialFiles, jobDetails.label).then(function(){
                var jobs = registry.get('jobs');
                var joblabel = metadataService.getMetaField('joblabel');
                if (jobs && jobs.length > 0 && (!jobDetails.label || joblabel === jobDetails.label)) {
                    // Perform a full create/update of the job in the session
                    jobManagerService.storeActiveJob(jobDetails, materialFiles, operation).then(function (error) {
                        if (!error || error == '') {
                            evBus.broadcast(APP_EVENTS.jobSaved, {jobName: jobDetails.name, jobLabel: jobDetails.label});
                            // Take the user back to the last page
                            $window.history.back();
                        }
                        else if (error === 'EXISTS') {
                            // The job already exists
                            dialog.showDialog(dialog.TYPE.NOTIFY, '', _('store_job.job_name_already_exists', jobDetails.name));
                        }
                        else if (error === 'NOT_FOUND') {
                            // The job no longer exists
                            dialog.showDialog(dialog.TYPE.NOTIFY, '', _('store_job.no_matching_job'));
                        }
                        else {
                            Wave2.WcService.fireError();
                        }
                    }, function (reason) {
                        throw new Error(i18n.error.store_job_failed + reason);
                    });
                }
                else {
                    // Perform a "light" update of a job that is not currently loaded into the session
                    jobManagerService.storeInactiveJob(jobDetails, materialFiles).then(function(error) {
                        if (!error || error == '') {
                            evBus.broadcast(APP_EVENTS.jobSaved, {jobName: jobDetails.name, jobLabel: jobDetails.label});
                            // Take the user back to the last page
                            $window.history.back();
                        }
                        else if (error === 'NOT_FOUND') {
                            // The job no longer exists
                            dialog.showDialog(dialog.TYPE.NOTIFY, '', _('store_job.no_matching_job'));
                        }
                        else {
                            Wave2.WcService.fireError();
                        }
                    }, function (reason) {
                        throw new Error(i18n.error.update_job_failed + reason);
                    });
                }
            });
        },

        /**
         * Store/update a job on the server
         * @param {Object} jobDetails - the updated job info (comments, etc)
         * @param {Object} materialFiles - name and location of uploaded files
         * @param {string} operation - 'store' or 'update' (used for validation)
         */
        storeActiveJob: function (jobDetails, materialFiles, operation, dontCancelJobs) {
            var deferred = $q.defer();
            var stored = 0;
            var jobs = registry.get('jobs');

            if(materialFiles){
                registry.put("materialFiles", materialFiles);
                var keyValuePairs = [];
                angular.forEach(materialFiles, function(materialFile) {
                    if (materialFile.name == "") return;
                    keyValuePairs.push({
                        tagName: 'materialfile',
                        name: materialFile.name,
                        value: materialFile.location
                    });
                });
                metadataService.setJobMetadataBlock(jobs[0], 'materialfiles',keyValuePairs);
            }

            var childJobNames = [];
            angular.forEach(jobs, function(job, jobIndex) {
                childJobNames.push(buildService.generateJobName(jobIndex));
            });
            var primaryJobName = childJobNames.shift();

            angular.forEach(jobs, function(job, jobIndex) {
                var jobName = jobIndex == 0 ? primaryJobName : childJobNames[jobIndex - 1];
                jobStoreService.exists(jobDetails.owner, jobName).then(function (jobExists) {
                    if (operation == 'create' && jobExists) {
                        // This job name already exists
                        deferred.resolve('EXISTS');
                    }
                    else if (operation == 'update' && !jobExists) {
                        // This existing job name was not found
                        deferred.resolve('NOT_FOUND');
                    }
                    else {
                        Wave2.JobGenerator.initialise(registry, undefined, undefined, dontCancelJobs);
                        var generateData = Wave2.JobGenerator.getGenerateData(jobIndex, job, registry);
                        var document;
                        if (job.document.document)
                            document = job.document.document;
                        else
                            document = job.document;
                        var product = { name: document.id.split('@@')[0] };
                        var type = { name: document.id.split('@@')[1] };
                        jobBuilderService.generateJobXML(product, type, document, generateData).then(function(jobXml) {
                            jobDetails.name = jobName;
                            jobDetails.jobXml = jobXml;
                            jobDetails.hidden = (jobIndex != 0); // Hide any child jobs
                            if (jobIndex == 0 && childJobNames.length > 0) {
                                // Reference the child jobs in the primary job
                                jobDetails.childJobNames = childJobNames.join(',');
                            }
                            jobStoreService.store(jobDetails.owner, jobDetails).then(function() {
                                if (++stored == jobs.length) {
                                    deferred.resolve();
                                }
                            });
                        });
                    }
                });
            });

            return deferred.promise;
        },

        /**
         * Update the EasyBuild metadata of a job that hasn't been loaded into the session
         * @param {*} jobDetails - the details of the job to update
         * @param {Object} materialFiles - name and location of uploaded files
         */
        storeInactiveJob: function (jobDetails, materialFiles) {
            var deferred = $q.defer();

            jobStoreService.exists(jobDetails.owner, jobDetails.name).then(function (jobExists) {
                if (!jobExists) {
                    // This existing job name was not found
                    deferred.resolve('NOT_FOUND');
                }
                else {
                    // Load the job into a transient registry object
                    var transientRegistry = Wave2.Registry.create({ store : 'transient' });
                    jobBuilderService.getJobModel(jobDetails.jobXml).then(function(jobModel) {
                        var productQuery = {'name' : jobModel.productName };
                        var typeQuery = {'name' : jobModel.typeName };
                        var docQuery = {'name' : jobModel.documentName };
                        configService.getDocument(productQuery, typeQuery, docQuery).then(function(doc) {
                            launchService.populateJobModelIntoRegistryAtPosition(jobDetails.name, jobModel, doc, 0, transientRegistry);
                            var job = transientRegistry.get('jobs')[0];

                            if(materialFiles){
                                var keyValuePairs = [];
                                angular.forEach(materialFiles, function(materialFile) {
                                    if (materialFile.name == "") return;
                                    keyValuePairs.push({
                                        tagName: 'materialfile',
                                        name: materialFile.name,
                                        value: materialFile.location
                                    });
                                });
                                metadataService.setJobMetadataBlock(job, 'materialfiles',keyValuePairs);
                            }

                            // Process the job and update the metadata
                            Wave2.JobGenerator.initialise(transientRegistry);
                            var generateData = Wave2.JobGenerator.getGenerateData(0, job, transientRegistry);
                            var document = job.document;
                            var product = { name: document.id.split('@@')[0] };
                            var type = { name: document.id.split('@@')[1] };
                            jobBuilderService.generateJobXML(product, type, document, generateData).then(function (jobXml) {
                                // Only update the job XML of the primary job for the metadata
                                jobDetails.jobXml = jobXml;
                                jobStoreService.store(jobDetails.owner, jobDetails).then(function() {
                                    deferred.resolve();
                                });
                            });
                        });
                    });
                }
            });

            return deferred.promise;
        },

        /**
         * Retrieve a stored job/multi-job via the launch API
         * @param owner - the owner of the job to launch
         * @param jobName - the name of the job to launch
         */
        retrieveJobs: function(owner, jobName, StepName) {
            if (!jobName) throw new Error(i18n.error.job_has_no_name);
            var url = 'launch?jobName=' + jobName + '&jobOwner=' + owner;
            if ( StepName && StepName.length > 0){
                url += '&StepName=' + StepName;
            }
            $window.location = utilitiesService.getNewLocation(url);
        },

        /**
         * Delete a job matching the given job name
         * @param jobName - the name of the job to delete
         */
        deleteJob: function(jobName) {
            dialog.showDialog(dialog.TYPE.CONFIRM, '', _('retrieve_job.delete_confirm'), {
                onCancelClicked: function() {
                    dialog.closeDialog();
                },
                onOkClicked: function() {
                    jobStoreService.deleteJob(userSession.username, jobName).then(function() {
                        $window.location.reload();
                    }, function(reason) {
                        throw new Error(i18n.error.job_delete_failed + reason);
                    });
                }
            });
        },

        /**
         * Redirect to the details page if the job exists,
         * this prevents the "job name doesn't exist" error on the store page
         */
        gotoDetailsIfExists: function() {
            var defer = $q.defer();
            var promise = defer.promise;

            var owner = registry.get('jobowner') || userSession.username;
            var jobName = buildService.generateJobName(0);
            if (jobName) {
                jobStoreService.exists(owner, jobName).then(function (jobExists) {
                    if (jobExists) {
                        var url = '/job/details/' + owner + '/' + jobName;
                        $location.path(url).replace();
                        defer.reject();
                    } else {
                        defer.resolve();
                    }
                });
            } else
                defer.resolve();
            return promise;
        }

    }

}]);
angular.module('easybuild.page.jobmanager')

/**
 * Update an existing job in the PP job store
 */
.controller('JobUpdateCtrl', ['$scope', '$rootScope', '$window', '$log', '$routeParams', 'ebConfig', 'registry', 'userSession', 'jobStoreService', 'jobManagerService', '$timeout', 'APP_EVENTS', 'dialog', 'metadataService' , 'authService', 'jobBuilderService', 'jobStoreManager', 'buildService', 'GENERATION_TYPE','$controller' ,
                    function ($scope, $rootScope, $window, $log, $routeParams, ebConfig, registry, userSession, jobStoreService, jobManagerService, $timeout, APP_EVENTS, dialog, metadataService, authService, jobBuilderService, jobStoreManager, buildService, GENERATION_TYPE, $controller) {

    if (!serverReady(ebConfig)) return;
    $rootScope.showProductLogo = true;
    $scope.$on("$destroy", function(){
        $rootScope.showProductLogo = false;
    });
    $scope.isUpdate = true;

    $scope.statuses = [];
    angular.copy(ebConfig.get('easybuild.web.job.store.statuses'), $scope.statuses);
    angular.forEach($scope.statuses, function(status) {
        status.label = _(status.label);
    });

    $scope.shareGroupList = [];
    $scope.shareUserList = [];
    $scope.storeEnabled = true;

    $scope.materialFiles = metadataService.getMaterialFileList();
    $scope.materialFilesDiscardList = [];

    jobManagerService.showJob($routeParams.owner, $routeParams.name, $scope.statuses).then(function(result) {
        var storeEnabled = result.storeEnabled,
            job = result.job,
            status = result.status,
            materialFiles = result.materialFiles;
        $scope.storeEnabled = storeEnabled;
        $scope.jobDetails = job;
        $scope.status = status;
        $scope.materialFiles = materialFiles;
    });

    $scope.store = function() {
        jobStoreManager.store($scope, 'update');
    };
    buildService.attachGenerateEvents($scope, registry, GENERATION_TYPE.proof);
    angular.extend(this, $controller('CustomJobUpdateCtrl', {$scope: $scope}));
}])
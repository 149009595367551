angular.module('easybuild.page.launch')

.service('launchService', ['$q', '$log', 'WORKFLOW_CONFIG', '$location', 'registry', 'jobStoreService',
    'jobBuilderService', 'ebConfig', 'configService', 'metadataService', 'docContentService', 'i18n', 'bundleService', 'utilitiesService', 'dialog', 'APP_EVENTS', 'evBus',
    function($q, $log, WORKFLOW_CONFIG, $location, registry, jobStoreService, jobBuilderService, ebConfig,
             configService, metadataService, docContentService, i18n, bundleService, utilitiesService, dialog, APP_EVENTS, evBus) {

    function handleRedirect(product, type, doc, stepName, multiJob) {
        var path = 'build/{{product}}/{{type}}/{{document}}';  // Default to build page

        if (multiJob) {
            // Override the target page for multi-job, we must always navigate to the finish page
            path = 'build/{{product}}/{{type}}/{{document}}/review';
        }
        else if (stepName != undefined && stepName.length > 0) {
            // Grab the launched destination
            if (WORKFLOW_CONFIG.hasOwnProperty('launchPageStepName' + stepName)) {
                path = WORKFLOW_CONFIG['launchPageStepName' + stepName];
            } else {
                path = stepName;
                if (path.indexOf('/') == 0) path = path.substring(1);
            }
        }

        path = path.replace('{{product}}', product);
        path = path.replace('{{type}}', type);
        path = path.replace('{{document}}', doc);

        $location.path(path);
    }

    return {

        launchJob: function(username, jobName, stepName) {
            var buildMediaAssociation = undefined;
            if ($location.search().buildMedia && registry.contains('buildMediaAssociation')){
                buildMediaAssociation = registry.get('buildMediaAssociation');
            }
            // Start a new job
            registry.newJob();
            if ( buildMediaAssociation){
                registry.put('buildMediaAssociation', buildMediaAssociation);
            }

            // Populate the launch events
            var launchEvents = {
                'FinishLabel' : $location.search().FinishLabel,
                'BackLabel' : $location.search().BackLabel,
                'FinishURL' : $location.search().FinishURL,
                'BackURL' : $location.search().BackURL,
                'StepName' : stepName
            };



            var customJsonStr = $location.search().customJson;
            if(customJsonStr)   launchEvents['customJson'] = JSON.parse(decodeURIComponent(customJsonStr));

            angular.forEach($location.search(), function(value, key) {
                if ( this[key] === undefined ){
                    this[key] = value;
                }
            }, launchEvents);

            
            registry.put('launchEvents', launchEvents);

            var launchService = this;

            launchService._processJob(username, jobName, 0).then(function(jobZeroData) {
                var options = {
                    productName : jobZeroData.jobModel.productName,
                    typeName : jobZeroData.jobModel.typeName,
                    documentName : jobZeroData.jobModel.documentName
                }

                configService.getLibraryEntries({name:options.productName}, {name:options.typeName}, {name:options.documentName}, 0, registry.get('size')).then(function (libraries) {
                    // Store the primary job name and owner
                    registry.put('jobowner', username);

                    var childJobNames = null;
                    if (jobZeroData.jobDetails.childJobNames && jobZeroData.jobDetails.childJobNames.length > 0) {
                        childJobNames = jobZeroData.jobDetails.childJobNames.split(',');
                    }

                    var materialFiles = metadataService.getMaterialFileList();

                    if (materialFiles.length > 0) {
                        registry.put('materialFiles', materialFiles);
                        metadataService.removeMetadataBlock(0, 'materialfiles');
                    }

                    var jobs = registry.get("jobs");
                    if (libraries != null)
                        registry.put("libraries",libraries);
                    else
                        registry.remove("libraries");

                    if (childJobNames && childJobNames.length > 0) {
                        // Launch the child jobs into the session

                        launchService._processChildJobs(username, childJobNames).then(function () {
                            $log.debug('All child jobs processed');

                            // Create the "job" object if we are launching a multi-job,
                            // This prevents us immediately merging the articles back together
                            // when the articleService initialises...
                            var jobs = registry.get("jobs");
                            var primaryJob = angular.copy(jobs[0]);
                            primaryJob.index = 0;
                            primaryJob.bundledGroupArticleMapping = primaryJob.groupArticleMapping;
                            registry.put("job", primaryJob);

                            // Merge the bonded content field information to all jobs
                            launchService._updateJobModelWithDocContent(libraries, options, jobZeroData, stepName, true);
                        }, function (reason) {
                            throw new Error(i18n.error.launch_job_failed + reason);
                        });
                    }
                    else {
                        // Single job launched successfully

                        if (jobs && jobs.length > 0 && jobs[0].document.multiJobDocuments && jobs[0].document.multiJobDocuments.length > 0) {
                            // Force documentHasChanged to be triggered so that all
                            // jobs are loaded out of a single multi-job loaded job
                            jobs[0].document.id = "";
                            registry.put("jobs", jobs);
                        }

                        // Merge the bonded content field information to all jobs
                        launchService._updateJobModelWithDocContent(libraries, options, jobZeroData, stepName, false);
                    }
                    // GBO: delete job after launch
                    if ( $location.search().deleteJob) {
                        jobStoreService.deleteJob(username, jobName);
                    }
                    if ( $location.search().validation) {
                        // TODO: post message validation summary
                        evBus.broadcast(APP_EVENTS.validationXMLSummary, {status: $location.search().validation});
                    }
                })
            });


        },

        /*
         * 1. Obtain details of the job to be launched from the temporary job store record
         * 2. Use the job model to populate the registry
         */
        _processJob: function(username, jobName, jobIndex) {
            var defer = $q.defer();
            var promise = defer.promise;

            var launchService = this;
            jobStoreService.details(username, jobName).then( function (jobDetails) {
                if (!jobDetails) {
                    throw new Error(i18n.error.launch_job_not_exists + jobName);
                }

                // Obtain the job model and document
                jobBuilderService.getJobModel(jobDetails.jobXml).then(function (jobModel) {
                    var productQuery = {'name' : jobModel.productName };
                    var typeQuery = {'name' : jobModel.typeName };
                    var docQuery = {'name' : jobModel.documentName };
                    configService.getGroupNames(productQuery, typeQuery, docQuery).then(function(groupNames) {
                        jobModel.groupNames = groupNames;
                        var assocIdsOverride = null;

                        if ( jobModel.publicationData && jobModel.publicationData.metadata && jobModel.publicationData.metadata.metadataPair) {
                            angular.forEach(jobModel.publicationData.metadata.metadataPair, function (metaField) {
                                if (metaField.name === 'assocIdsOverride') assocIdsOverride = metaField.value;
                            });
                        }

                        configService.getDocument(productQuery, typeQuery, docQuery, assocIdsOverride).then(function(doc) {
                            // Restore the job into the registry
                            launchService.populateJobModelIntoRegistryAtPosition(jobName, jobModel, doc, jobIndex);
                            $log.debug('Job ' + jobIndex + ' processed');

                            var result = {
                                'jobDetails': jobDetails, 'jobModel': jobModel, 'doc': doc
                            }
                            defer.resolve(result);
                        }, function (reason) {
                            throw new Error(i18n.error.launch_job_doc_not_loaded + reason);
                        });
                    }, function (reason) {
                        throw new Error(i18n.error.launch_job_group_not_loaded + reason);
                    });
                }, function (reason) {
                    throw new Error(i18n.error.launch_job_model_not_loaded + reason);
                });
            });

            return promise;
        },

        _processChildJobs: function(username, jobNames) {
            var defer = $q.defer();
            var promise = defer.promise;

            var launchService = this;
            var promises = [];
            angular.forEach(jobNames, function(jobName, jobIndex) {
                // Load the job XML
                promises.push(launchService._processJob(username, jobName, jobIndex + 1));
            });
            $q.all(promises).then(function() {
                defer.resolve();
            });

            return promise;
        },

        _updateJobModelWithDocContent: function(libraries, options, jobZeroData, stepName, multiJob){
            var jobs = registry.get("jobs");

            var promises = [];
            var effects = null;
            var previewUpdates = {};
            angular.forEach(jobs[0].groupArticleMapping, function (group, groupIndex) {
                var deferred = $q.defer();

                var getStructurePromises = [];
                getStructurePromises[0] = docContentService.getDocContent(options, groupIndex);
                if (groupIndex === 0 ) {
                    getStructurePromises[1] = docContentService.getEffects(options);
                }

                $q.all(getStructurePromises).then(function(results){
                    angular.forEach(jobs, function (job, jobIndex) {
                        if (Array.isArray(results) ){
                            job.docConfig = angular.extend({}, results[0]);
                            if ( results && results.length > 1 ) {
                                effects = angular.extend(results[1][0]);
                            }
                        }else {
                            job.docConfig = angular.extend(results);
                        }

                        if ( effects) {
                            job.docConfig.effects = effects;
                        }
                        angular.forEach(job.groupArticleMapping[groupIndex], function (articleKey) {
                            var updatePreviewsPromises = [];
                            var findItemWithMatchingName = function(itemList, name){
                                var matchingItem = null;
                                angular.forEach(itemList, function(item){
                                    if(item.name === name){
                                        matchingItem = item;
                                    }
                                });
                                return matchingItem;
                            };

                            var extendTextItem = function(itemList, currentItem){
                                var matchingItem = findItemWithMatchingName(itemList, currentItem.name);
                                var dateFormat = currentItem['dateFormat'];
                                if(matchingItem){
                                    angular.extend(currentItem, matchingItem);
                                }
                                if ( dateFormat && dateFormat !== currentItem['dateFormat']){
                                    currentItem['dateFormat'] = dateFormat;
                                }
                            };


                            var extendImageItem = function(itemList, currentItem){
                                // GBO: Need to check the preview url
                                var matchingItem = findItemWithMatchingName(itemList, currentItem.name);
                                var subJob = currentItem['subJob'];
                                angular.extend(currentItem, matchingItem);
                                if (subJob ){
                                    var oldDocId = utilitiesService.getDocumentIdFromSubJob(currentItem['subJob']);
                                    currentItem['subJob'] = subJob;
                                    var newDocId = utilitiesService.getDocumentIdFromSubJob(currentItem['subJob']);
                                    if ( newDocId && oldDocId !== newDocId){
                                        currentItem.previewUrl = undefined;
                                        if ( ebConfig.get('easybuild.web.buildmedia.useDocumentPreview')){
                                            if ( !previewUpdates[articleKey]) {
                                                previewUpdates[articleKey] = [];
                                            }
                                            previewUpdates[articleKey].push(newDocId);
                                        }

                                    }
                                } else {
                                    currentItem['subJob'] = subJob;
                                    if (!currentItem.value || currentItem.value === "" ) {
                                        currentItem.previewUrl = undefined;
                                    }
                                }
                            };

                            var article = registry.get(articleKey);

                            article.articleCount = job.docConfig.articleCount;
                            article.groupType = job.docConfig.groupType;

                            if (article.textItems) {
                                angular.forEach(article.textItems, function (item, pos) {
                                    extendTextItem(job.docConfig.textItems, item);
                                });
                            }
                            if (article.flashTextItems) {
                                angular.forEach(article.flashTextItems, function (item, pos) {
                                    extendTextItem(job.docConfig.flashTextItems, item);
                                });
                            }
                            if (article.imageItems) {
                                angular.forEach(article.imageItems, function (item, pos) {
                                    extendImageItem(job.docConfig.imageItems, item);
                                });
                            }
                            if (article.flashImageItems) {
                                angular.forEach(article.flashImageItems, function (item, pos) {
                                    extendImageItem(job.docConfig.flashImageItems, item);
                                });
                            }
                            if (article.externalMediaItems) {
                                angular.forEach(article.externalMediaItems, function (item, pos) {
                                    var matchingItem = findItemWithMatchingName(job.docConfig.externalMediaItems, item.name);
                                    if(matchingItem){
                                        angular.extend(item, matchingItem);
                                    }
                                });
                            }
                            if ( effects ){
                                article.effects = effects;
                            }
                            registry.put(articleKey, article);
                        });
                    });

                    deferred.resolve();
                }, function(reason) {
                    dialog.showDialog(dialog.TYPE.NOTIFY, '', i18n.error.launch_job_content_invalid);
                    deferred.reject(reason);
                });
                promises.push(deferred.promise);
            });

            $q.all(promises).then(function() {
                var updatePromises = [];
                angular.forEach(jobs, function (job, jobIndex) {
                    if (jobIndex > 0) {
                        var deferred = $q.defer();
                        bundleService.updateSizes(jobIndex, function () {
                            deferred.resolve();
                        });
                        updatePromises.push(deferred.promise);
                    }
                });

                var updatePreviewImageItem = function(imageItem, fullDocId, updatePreviewPromises){
                    var subJob = imageItem['subJob'];

                    if (subJob ){
                        if ( utilitiesService.getDocumentIdFromSubJob(subJob) == fullDocId){
                            var deferred = $q.defer();
                            var docID = fullDocId.split('@@')
                            var productQuery = {'name': docID[0]};
                            var typeQuery = {'name': docID[1]};
                            var docQuery = {'name': docID[2]};

                            configService.getDocument(productQuery, typeQuery, docQuery).then(function (doc) {
                                imageItem.previewUrl = doc.displayimage;
                                deferred.resolve();
                            });
                            updatePreviewPromises.push(deferred.promise);
                        }
                    }
                };

                var updatedPreviewPromises = [];
                $q.all(updatePromises).then(function () {
                    angular.forEach(previewUpdates, function (previewUpdate, articleKey) {
                        var deferred = $q.defer();

                        var updatePreviewPromises = [];
                        var article = registry.get(articleKey);

                        angular.forEach(previewUpdate, function (fullDocId) {
                            if (article.imageItems) {
                                angular.forEach(article.imageItems, function (item, pos) {
                                    updatePreviewImageItem(item, fullDocId, updatePreviewPromises);
                                });
                            }
                            if (article.flashImageItems) {
                                angular.forEach(article.flashImageItems, function (item, pos) {
                                    updatePreviewImageItem(item, fullDocId, updatePreviewPromises);
                                });
                            }
                        });
                        if ( updatePreviewPromises.length > 0 ) {
                            $q.all(updatePreviewPromises).then(function () {
                                registry.put(articleKey, article);
                                deferred.resolve();
                            });
                        }else {
                            deferred.resolve();
                        }

                        updatedPreviewPromises.push(deferred.promise);
                    });

                    $q.all(updatedPreviewPromises).then(function () {
                        registry.put("jobs", jobs);
                        registry.put("libraries", libraries);
                        var launchEvents = registry.get('launchEvents');
                        launchEvents.originalRegistry = registry.getAll();
                        registry.put("launchEvents", launchEvents);

                        handleRedirect(jobZeroData.jobModel.productName, jobZeroData.jobModel.typeName, jobZeroData.jobModel.documentName, stepName, multiJob);
                    });
                });
            });
        },

        _copyImageFit : function(field) {
            if (field.imagefit){
                if ( field.imagefit.fitting && !field.fitting){
                    field.fitting = field.imagefit.fitting;
                }
                if ( field.imagefit.framefitting && !field.framefitting){
                    field.framefitting = field.imagefit.framefitting;
                }
                if ( field.imagefit.halign && !field.halign){
                    field.halign = field.imagefit.halign;
                }
                if ( field.imagefit.valign && !field.valign){
                    field.valign = field.imagefit.valign;
                }
                if ( field.imagefit.clipping !== undefined && field.imagefit.clipping !== null && (field.clipping === undefined || field.clipping === null )){
                    field.clipping = field.imagefit.clipping;
                }
            }
        },

        populateJobModelIntoRegistryAtPosition: function(jobName, jobModel, doc, jobIndex, targetRegistry) {
            if (!targetRegistry) targetRegistry = registry;
            var launchService = this;

            // Read the job model
            var articleDataMap = jobModel.articleDataMap;
            var dataPairs = articleDataMap.articleDataPair;
            if (dataPairs) {
                dataPairs.sort(function (a, b) {//When sorting negative indicates that a should appear before b
                        if (a.groupArticle.groupId != b.groupArticle.groupId) {
                            return a.groupArticle.groupId - b.groupArticle.groupId;
                        }
                        else {
                            return a.groupArticle.articleId - b.groupArticle.articleId;
                        }
                    }
                );
            }

            var groupArticleMapping = [];
            if (dataPairs) groupArticleMapping = new Array(dataPairs[dataPairs.length - 1].groupArticle.groupId);
            for (var i = 0; i < groupArticleMapping.length; i++) {
                groupArticleMapping[i] = [];
            }

            // Add the new article
            var articleKeys = [];
            if (dataPairs) {
                for (var dataPairIndex = 0; dataPairIndex < dataPairs.length; dataPairIndex++) {
                    var dataPair = dataPairs[dataPairIndex];

                    // Convert from web service object to a normal javascript object
                    var articleData = dataPair.articleData
                    var articleKey = 'article_' + Wave2.WcService.createUUID();

                    if (articleData.layoutLibraryName && articleData.layoutLibraryName.length > 0) {
                        articleData.x = articleData.layoutColumnPosition;
                        articleData.y = articleData.layoutRowPosition;
                        articleData.libraryEntryName = articleData.layoutLibraryItemName;
                        articleData.libraryName = articleData.layoutLibraryName;
                        articleData.cols = articleData.layoutColumnSpan;
                        articleData.rows = articleData.layoutRowSpan;
                    }
                    if ( articleData.imageItems && articleData.imageItems.length > 0) {
                        jQuery.each(articleData.imageItems, function (index, field) {
                            launchService._copyImageFit(field);
                        });
                    }
                    if ( articleData.flashImageItems && articleData.flashImageItems.length > 0) {
                        jQuery.each(articleData.flashImageItems, function (index, field) {
                            launchService._copyImageFit(field);
                        });
                    }

                    targetRegistry.put(articleKey, articleData);

                    var groupArticle = dataPair.groupArticle;

                    // Note that ids are integers start at 1. Indexes start at 0
                    var articleId = groupArticle.articleId;
                    var groupId = groupArticle.groupId;
                    if ((groupId === 1) && (articleId > 0)) {
                        articleKeys[articleId - 1] = articleKey;
                    }
                    if ((groupId > 0) && (articleId > 0)) {
                        groupArticleMapping[groupId - 1][articleId - 1] = articleKey;
                    }
                }
            }

            // Append a new job entry to the jobs array
            var jobs = targetRegistry.get('jobs') || [];
            var job = {};
            job['document'] = doc;
            job['articleKeys'] = articleKeys;
            job['groupArticleMapping'] = groupArticleMapping;
            job['effects'] = jobModel.publicationData.effects;
            job['colourChanges'] = jobModel.publicationData.colourChanges;
            angular.forEach(job['colourChanges'], function (colourChangeParam) {
                if ( colourChangeParam.colour) {
                    if ((colourChangeParam.black === null || colourChangeParam.black === undefined) && colourChangeParam.colour.black !== null || colourChangeParam.colour.black !== undefined){
                        colourChangeParam.black = colourChangeParam.colour.black;
                    }
                    if ((colourChangeParam.cyan === null || colourChangeParam.cyan === undefined) && colourChangeParam.colour.cyan !== null || colourChangeParam.colour.cyan !== undefined){
                        colourChangeParam.cyan = colourChangeParam.colour.cyan;
                    }
                    if ((colourChangeParam.yellow === null || colourChangeParam.yellow === undefined) && colourChangeParam.colour.yellow !== null || colourChangeParam.colour.yellow !== undefined){
                        colourChangeParam.yellow = colourChangeParam.colour.yellow;
                    }
                    if ((colourChangeParam.magenta === null || colourChangeParam.magenta === undefined) && colourChangeParam.colour.magenta !== null || colourChangeParam.colour.magenta !== undefined){
                        colourChangeParam.magenta = colourChangeParam.colour.magenta;
                    }
                }
            });
            job['metadata'] = jobModel.publicationData.metadata;

            // Apply optional overrides for job
            if (jobModel.publicationData.channelName
                && jobModel.publicationData.channelName !== ebConfig.get('easybuild.w2pp.channel.preview')) {
                job['channelName'] = jobModel.publicationData.channelName;
            }
            if (jobModel.publicationData.channelPostAction
                && jobModel.publicationData.channelPostAction !== 'preview') {
                job['channelPostAction'] = jobModel.publicationData.channelPostAction;
            }
            if (jobModel.publicationData.templateName) {
                job['templateName'] = jobModel.publicationData.templateName;
            }
            jobs[jobIndex] = job; // Insert at given position
            targetRegistry.put('jobs', jobs);

            // Set the primary selection info if this is the first (primary) job
            if (jobIndex == 0) {
                var size = {};
                if(jobModel.publicationData.width > 0 && jobModel.publicationData.height) {
                    size['name'] = _('build.size_custom');
                    size['width'] = jobModel.publicationData.width;
                    size['height'] = jobModel.publicationData.height;
                }
                else {
                    size['name'] = jobModel.publicationData.scaleName;
                }
                targetRegistry.put('size', size);

                if((jobName.length < 6) || (jobName.substring(0,6) != "LAUNCH")) {
                    var baseJobName = metadataService.getMetaField('baseJobName');
                    targetRegistry.put('jobname', baseJobName || jobName);
                    var joblabel = metadataService.getMetaField('joblabel');
                    if ( !joblabel || joblabel.length === 0 ){
                        metadataService.setMetaField('joblabel', jobName);
                    }
                }

                targetRegistry.put('groups', jobModel.groupNames);

                // Fetch the real document
                targetRegistry.put('document', angular.copy(doc));
            }

        }
    }
}]);
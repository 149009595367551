angular.module('easybuild.page.jobmanager')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebShareWith
 * @description Controls for EasyBuild job sharing
 * @restrict 'A'
 * @scope
 * @param  {expression=} jobDetails Job store data containing share selections
 * @param  {expression=} shareGroupList Available user groups to share with
 * @param  {expression=} shareUserList Available users to share with
 * @param  {expression=} shareGroup Last chosen user group for this job
 * @param  {expression=} shareUser Last chosen user for this job
 * @param  {boolean} hideIfNotOwner Hide this control if you don't own the job
 **/
.directive('ebShareWith', ['userSession', 'authService', function(userSession, authService) {
    return {
        restrict: 'A',
        scope: {
            jobDetails: '=',
            shareGroupList: '=',
            shareUserList: '=',
            shareGroup: '=',
            shareUser: '=',
            hideIfNotOwner: '@'
        },
        link: function (scope, elm) {

            scope.$watch('jobDetails', function(jobDetails) {
                if (jobDetails !== undefined) {
                    if (scope.hideIfNotOwner === 'true' && scope.jobDetails.owner
                        && userSession.username != scope.jobDetails.owner) {
                        // The owner does not match, hide this control
                        elm.hide();
                    }

                    var userGroups = [];
                    if(localStorage.getItem("userGroups")) {
                        userGroups = JSON.parse(localStorage.getItem("userGroups"));
                    }

                    scope.shareGroupList = userGroups;
                    function getUserNames() {
                        for (var userGroupIndex = 0; userGroupIndex < userGroups.length; userGroupIndex++) {
                            var userGroup = userGroups[userGroupIndex];
                            for (var memberIndex = 0; memberIndex < userGroup.members.length; memberIndex++) {
                                if (scope.shareUserList.indexOf(userGroup.members[memberIndex]) == -1) {
                                    scope.shareUserList.push(userGroup.members[memberIndex]);
                                }
                            }
                        }
                    }

                    function setShareDetails(){
                        var currentGroup = scope.shareGroupList.filter(function(item) {
                            return item.name === scope.jobDetails.shareType.groups;
                        });
                        scope.shareGroup = currentGroup.length > 0 ? currentGroup[0] : null;
                        var currentUser = scope.shareUserList.filter(function(item) {
                            return item === scope.jobDetails.shareType.users;
                        });
                        scope.shareUser = currentUser.length > 0 ? currentUser[0] : null;

                        if (scope.jobDetails.shareType.groups
                            && scope.jobDetails.shareType.groups.length === scope.shareGroupList.length) {

                            scope.jobDetails.shareType.type = 'all_groups';
                        }
                    }
                    getUserNames();
                    if ( !scope.shareUserList || scope.shareUserList.length == 0 ){
                        authService.userUserGroups(userSession.username, true).then(function (newGroups) {
                            userGroups = newGroups;
                            getUserNames();
                            if ( scope.shareUserList && scope.shareUserList.length > 0 ) {
                                localStorage.removeItem('userGroups');
                                localStorage.setItem('userGroups', JSON.stringify(newGroups));
                            }
                            setShareDetails();
                        });
                    } else {
                        setShareDetails();
                    }
                }
            });
        }
    }
}]);
angular.module('easybuild.component.eb.action.directive', [])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebAction
 * @description Action button strategy builder
 *              See Wave2 wiki for more details on how to use this directive.
 * @restrict 'A'
 * @scope
 * @param  {array} ebAction Action chain to invoke (see Wiki)
 **/
.directive('ebAction', ['registry', 'actionHandler', function(registry, actionHandler) {
    return {
        restrict: 'A',
        scope: {
            ebAction: '@',
            ebActionJavascript : '&'
        },
        compile: function (element, attr) {

            var isFiring = false;

            var launchEvents = registry.get('launchEvents');
            if (launchEvents) {
                var label = null, href = null;

                var hasNavigationBack = attr['ebAction'].indexOf('redirect-back') > -1 || attr['ebAction'].indexOf('postmessage-back') > -1;
                var hasNavigationFinish = !hasNavigationBack && (attr['ebAction'].indexOf('redirect') > -1 || attr['ebAction'].indexOf('postmessage') > -1);  // Captures *-finish
                if (hasNavigationBack && launchEvents.hasOwnProperty("BackLabel")) {
                    label = launchEvents["BackLabel"];
                }
                else if (hasNavigationFinish && launchEvents.hasOwnProperty('FinishLabel')) {
                    label = launchEvents['FinishLabel'];
                }
                if (label) element.html(label);

                if (attr['ebAction'].indexOf('redirect-back') > -1 && launchEvents.hasOwnProperty("BackURL")) {
                    href = launchEvents["BackURL"];
                }
                else if (attr['ebAction'].indexOf('redirect') > -1 && launchEvents.hasOwnProperty('FinishURL')) { // Captures *-finish
                    // We presume the redirect action will always want to follow the launch URL
                    href = launchEvents['FinishURL'];
                }
                if (href) attr.$set("href", href);
            }

            return {
                'post' : function(scope, element, attrs) {
                    element.on('click', function (ev) {
                        ev.preventDefault();

                        if (isFiring || !scope.ebAction) return;
                        isFiring = true;
                        attrs.$set('disabled', 'disabled');

                        var actions = scope.ebAction.split(',');

                        // Redirect actions have a special property that allows them to inherit the
                        // anchor href if a target is not specified
                        var params = {
                            redirectBack: attrs.href, redirectFinish: attrs.href,
                            javascriptActionIndex : 0
                        };

                        // Add the parameters for each action
                        for (var arg in attrs) {
                            if(arg=="ebActionJavascript"){
                                params["javascript"] = scope.ebActionJavascript;
                            }
                            else if (attrs.hasOwnProperty(arg) && arg.indexOf('ebAction') == 0 && arg !== 'ebAction') {
                                var name = arg.charAt(8).toLowerCase() + arg.substring(9);
                                if (name == 'redirect') name = 'redirectFinish'; // Mutate legacy parameter name
                                if (name == 'postmessage') name = 'postmessageFinish'; // Mutate legacy parameter name

                                params[name] = attrs[arg];
                            }
                        }

                        actionHandler.execute(actions, params).then(function() {
                            // Action chain has finished, reactivate the button
                            isFiring = false;
                            element.removeAttr('disabled');
                        }, function(e) {
                            Wave2.WcService.fireError(e);
                        });
                    });
                }
            }
        }
    }
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebCancelAction
 * @description Navigate back in the browser history when action button clicked
 * @restrict 'A'
 **/
.directive('ebCancelAction', ['$window', function($window) {
    return {
        restrict: 'A',
        link: function(scope, elm) {
            elm.on('click', function () {
                $window.history.back();
                scope.$apply();
            });
        }
    };
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebActionOverlay
 * @description Generic UI component for overlaying a hover menu over an image
 * @restrict 'A'
 * @scope
 **/
.directive('ebActionOverlay', ['APP_EVENTS', function(APP_EVENTS) {
    return {
        restrict: 'A',
        link: function (scope, elm) {
            function scanForElements(node, results) {
                if (!results) results = {};
                else if (results.actionBtn && results.overlayPanel) return results;
                else if (!results.optionBtns) results.optionBtns = [];
                angular.forEach(node.children(), function(child) {
                    child = angular.element(child);
                    if (child.hasClass('eb-action-overlay-button')) results.actionBtn = child;
                    else if (child.hasClass('eb-option-btn')) results.optionBtns.push(child);
                    else if (child.hasClass('eb-action-overlay')) results.overlayPanel = child;

                    // Search children recursively
                    if (child.children()) scanForElements(child, results);
                });

                return results;
            }
            var controls = scanForElements(elm);
            if (controls.overlayPanel) {
                elm.on(APP_EVENTS.actionoverlayClear, function (ev, source) {
                    if (elm === null || elm[0] !== source) {
                        // Hide this overlay, another one is being displayed
                        controls.overlayPanel.hide();
                    }
                });
                angular.forEach(controls.optionBtns, function(optionBtn) {
                    // Hide this overlay after an option button is clicked
                    optionBtn.on('click', function () {
                        angular.element(elm).triggerHandler(APP_EVENTS.actionoverlayClear, null);
                    });
                });
                var closeOtherOverlays = function () {
                    var parent = elm.parent();
                    angular.forEach(parent.children(), function(child) {
                        angular.element(child).triggerHandler(APP_EVENTS.actionoverlayClear, elm);
                    });
                }
                if (controls.actionBtn && scope.touchScreen) {
                    controls.actionBtn.on('click', function() {
                        closeOtherOverlays();
                        controls.overlayPanel.toggle();
                    });
                }
                else {
                    elm.children().children().children().on('mouseenter', function() {
                        closeOtherOverlays();
                        controls.overlayPanel.show()
                    });
                    controls.overlayPanel.on('mouseleave', function() {
                        closeOtherOverlays();
                        controls.overlayPanel.hide();
                    });
                    controls.overlayPanel.children().on('click', function() {
                        closeOtherOverlays();
                        controls.overlayPanel.hide();
                    });
                }
            }
        }
    }
}])

/**
 * @ngdoc directive
 * @name easybuild.directive:ebActionChannels
 * @description Display a button for each channel which the document is configured to use
 * @restrict 'A'
 **/
.directive('ebActionChannels',['configService','registry', function(configService, registry){
    return {
        restrict: 'A',
        template: '<a ng-repeat="channel in channels track by $index" eb-action="generate,notify" eb-action-generate="{{channel.name}}" eb-action-notify="{{channel.msgKey}}" ' +
        'class="btn btn-primary btn-lg btn-block">{{channel.name}}</a>' +
        '<div ng-show="!channels.length" class="text-center">{{i18n.proof.no_channels}}</div>',

        link: function (scope) {
            var product = registry.get('product');
            var type = registry.get('type');
            var document = registry.get('document');

            configService.getChannels(product, type, document).then(function(channels){
                var filteredChannels = [];
                angular.forEach( channels, function(channel, key){
                    if(channel.name != _('proof.ignore_channel')){

                        // Here we remove spaces from the channel name and append to 'proof.sent_for_generation_' in
                        // order to see if we can find an i18n key specific to the channel (no spaces allowed in the i18n keys)
                        var channelWithoutSpaces = channel.name;
                        channelWithoutSpaces = channelWithoutSpaces.toLowerCase();

                        var spaceIndex = channelWithoutSpaces.indexOf(' ');
                        while(spaceIndex != -1){
                            channelWithoutSpaces = channelWithoutSpaces.replace(' ','_');
                            spaceIndex = channelWithoutSpaces.indexOf(' ');
                        }
                        channelWithoutSpaces = channelWithoutSpaces.replace(/[\W]+/g,"");

                        if (_('proof.sent_for_generation_' + channelWithoutSpaces)) {
                            channel.msgKey = 'proof.sent_for_generation_' + channelWithoutSpaces;
                        }
                        else {
                            channel.msgKey = 'proof.sent_for_generation';
                        }

                        filteredChannels.push(channel);
                    }
                });
                scope.channels = filteredChannels;
            });
        }
    }
}
]);
angular.module('easybuild.page.proof', [])

/**
  * Post generation proof page
  */
.controller('ProofCtrl', ['$scope', '$rootScope', '$log', '$compile' , 'GENERATION_TYPE', 'evBus', 'registry', 'buildService',
    'articleService', 'ebConfig', 'bundleService', 'userSession', 'dialog',
    function($scope, $rootScope, $log, $compile , GENERATION_TYPE, evBus, registry, buildService, articleService, ebConfig, bundleService,
             userSession, dialog) {
        if (!serverReady(ebConfig)) return;

        $rootScope.showProductLogo = true;
        $scope.$on("$destroy", function () {
            $rootScope.showProductLogo = false;
        });

        if (!registry.get('jobowner')) {
            registry.put('jobowner', userSession.username);
        }

        // Determine if we are displaying a multi-job from the number of jobs
        // NOT by whether the primary document has multi-job config
        // since the primary job always does...
        let jobs = registry.get("jobs");
        $scope.jobCount = jobs ? jobs.length : 1;

        $scope.selection = {
            product: registry.get('product'),
            type: registry.get('type'),
            document: registry.get('document'),
            size: registry.get('size')
        }

        $scope.onBackAction = function (ev) {
            // Cancel bundled edits if applicable...
            bundleService.cancelAllBundledEdits(ev, true);
        };

        let doGeneration = function () {
            buildService.attachGenerateEvents($scope, registry, GENERATION_TYPE.proof);

            // Clear the last generation
            if (jobs) {
                angular.forEach(jobs, function (job) {
                    delete job.output;
                });
                registry.put('jobs', jobs);
            }

            // Kick off a proof generation
            $scope.$on(Wave2.ArticleManager.JOBS_LOADED_EVENT, function () {
                Wave2.JobGenerator.initialise(registry, articleService.getManager());
                Wave2.JobGenerator.start(registry, ebConfig.get('easybuild.web.proof.currentgrouponly'));
            });
            articleService.initialise();
        }

        let includesMediaEncoderOutput = function(output) {
            let i = output.length;
            while (i--) {
                if (output[i].type === 'media encoder') return true;
            }
            return false;
        }

        // if this is a single job that includes media encoder output ask if
        // they wish to use the preview output to save time, or generate again...
        const isSingleMediaEncoderJob =
            jobs &&
            jobs.length === 1 &&
            jobs[0].output &&
            jobs[0].output.outputFiles &&
            includesMediaEncoderOutput(jobs[0].output.outputFiles);

        if (isSingleMediaEncoderJob) {
            dialog.showDialog(dialog.TYPE.CONFIRM, '', _('proof.do_regeneration?'), {
                onCancelClicked: function () {
                    dialog.closeDialog();
                    // The preview will be shown automatically
                },
                onOkClicked: function () {
                    dialog.closeDialog();
                    doGeneration();
                }
            });
        } else {
            doGeneration();
        }
}]);
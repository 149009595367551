angular.module('easybuild.shared.generation')

/**
 * Manage third-party plugin scripts
 * The handling of this data is delegated to the current formatter
 */
.service('pluginScripts', ['$log', function($log) {

    this.scripts = {};

    /**
     * Add or update a script
     * @param scriptName - the name of the script to add/modify
     * @param type - when the script should be triggered (consult wiki)
     * @param args - a tuple of arguments to pass to script (null means no arguments)
     */
    this.setScript = function(scriptName, type, args) {
        args.__type__ = type;
        this.scripts[scriptName] = args;
    };

    /**
     * Retrieve the arguments for a script
     * @param scriptName - the name of the script to retrieve
     * @returns {*} - the current tuple of arguments
     */
    this.getScript = function(scriptName) {
        return this.scripts[scriptName];
    };

    /**
     * Retrieve all scripts for generation
     * @returns {object} the scripts to pass to the job builder
     */
    this.getScripts = function() {
        return this.scripts;
    };

    /**
     * Remove all active scripts
     */
    this.clearScripts = function() {
        this.scripts = {};
    };

    this.removeScript = function(scriptName) {
        delete this.scripts[scriptName];
    };

    return this;
}]);

require('./build/build.module');
require('./job-manager/job-manager.module');
require('./launch/launch.module');
require('./login/login.module');
require('./sso/sso.module');
require('./pick/pick.controller');
require('./proof/proof.controller');
require('./batch-manager/batch.module');

/**
 * Explicit check required for each controller since the server might be down
 * and the router will not wait to find out
 * @param ebConfig
 * @global
 */
window.serverReady = function(ebConfig) {
    return ebConfig.isInitialised();
}

angular.module('easybuild.page', [
    'easybuild.page.launch',
    'easybuild.page.login',
    'easybuild.page.pick',
    'easybuild.page.build',
    'easybuild.page.proof',
    'easybuild.page.jobmanager',
    'easybuild.page.batch'
])

/**
 * Global application logic, applies to all controllers
 */
.controller('ApplicationCtrl', ['$scope', '$rootScope', '$controller', '$location', '$window', '$log', 'APP_EVENTS',
    'WORKFLOW_CONFIG', 'dialog', 'i18n', 'registry', 'AUTH_EVENTS', 'USER_ROLES', 'authService', 'ebConfig', 'evBus', 'utilitiesService',
    function ($scope, $rootScope, $controller, $location, $window, $log, APP_EVENTS, WORKFLOW_CONFIG, dialog, i18n, registry,
              AUTH_EVENTS, USER_ROLES, authService, ebConfig, evBus, utilitiesService) {
    angular.extend(this, $controller('CustomApplicationCtrl', {$scope: $scope}));

    $rootScope.showProductLogo = false;

    $log.debug("ApplicationCtrl - Starting Easybuild ....");
    $scope.userRoles = USER_ROLES;
    $scope.isAuthenticated = authService.isAuthenticated;
    $scope.isAuthorised = authService.isAuthorised;

    // Provide the messages to all partials
    $scope.i18n = i18n;

    $scope.registry = registry;//provide registry globally we can use say registry.contains in ng-if

    // Determine if we are on a touch screen
    $scope.touchScreen = ('ontouchstart' in document.documentElement);

    $scope.newJob = function () {
        if (!registry.contains('jobs')) {
            // Create a new job immediately
            registry.newJob();
            if ($location.path() === WORKFLOW_CONFIG.defaultJobStartPage) {
                $window.location.reload();
            }
            else {
                $location.path(WORKFLOW_CONFIG.defaultJobStartPage);
            }
        }
        else {
            // Ask the user if they wish to lose their changes first
            dialog.showDialog(dialog.TYPE.CONFIRM, '', _('build.reset_form_confirm'), {
                onCancelClicked: function() {
                    dialog.closeDialog();
                },
                onOkClicked: function() {
                    dialog.closeDialog();
                    registry.newJob();
                    $location.path(WORKFLOW_CONFIG.defaultJobStartPage);
                    $scope.$apply();
                }
            });
        }
    };

    // When the "Logout" button is clicked (or the session expires)
    // reload the page so the login form can appear
    $scope.logout = function () {
        registry.newJob();
        authService.logout();
    };
    $scope.$on(AUTH_EVENTS.logoutSuccess, function() {
        localStorage.removeItem('language');
        localStorage.removeItem('dateformat');
        $window.open(WORKFLOW_CONFIG.defaultLogoutPage, '_self');
    });
    $scope.$on(AUTH_EVENTS.sessionTimeout, function() {
        // Reload the current page so we don't lose your progress
        $window.location.reload();
    });

    $scope.$on(AUTH_EVENTS.forceLogout, function() {
        authService.forceLogout();
    });

    $scope.$on(AUTH_EVENTS.loginTimeout, function() {
        authService.timeout();
    });

    $scope.$on(Wave2.WcService.ERRORED_EVENT, function(ev, msg) {
        if ( utilitiesService.isLightboxBuild()){
            dialog.showDialog(dialog.TYPE.NOTIFY, '', _('error.build_light_launch'), {
                onOkClicked: function() {
                    $window.location = utilitiesService.getNewLocation('lightbox');
                }
            });
        }else {
            if (msg) {
                throw new Error(i18n.error.error_with_message + _(msg));
            } else {
                throw new Error(i18n.error.error_with_no_message);
            }
        }
    });

    if (ebConfig.get('easybuild.web.mediamanager.enabled')) {
        // Media manager show/hide toggle
        $scope.toggleMediaManager = function (toThis) {
            // Display/hide the media manager

            $scope.showMediaManager = angular.isDefined(toThis) ? toThis : !$scope.showMediaManager;

            // Remove any selected field (this function does not select a field)
            if (!angular.isDefined(toThis)) {
                evBus.broadcast(APP_EVENTS.mediaManagerChangeField, null);
            }

            if ($scope.showMediaManager) {
                // Hide the zoom controls
                $scope.$emit(APP_EVENTS.zoomToggle, false);

                // Select the first image/video field if the doccontent WC is available
                if (!angular.isDefined(toThis)) {
                    var firstField = angular.element(document.querySelector('#field_image_1, #field_externalmedia_1'));
                    if (firstField) {
                        var selectButton = firstField.parent().find(".button.select");
                        selectButton.trigger('click');
                    }
                }
            }
        }
    }

    $scope.loadCSS = function(url, type, charset) {
        if (type===undefined) type = 'text/css';
        if (url) {
            var link = document.querySelector("link[href*='"+url+"']");
            if (!link) {
                var heads = document.getElementsByTagName("head");
                if (heads && heads.length) {
                    var head = heads[0];
                    if (head) {
                        link = document.createElement('link');
                        link.setAttribute('rel', 'stylesheet');
                        link.setAttribute('href', url);
                        link.setAttribute('type', type);
                        if (charset) link.setAttribute('charset', charset);
                        head.appendChild(link);
                    }
                }
            }
            return link;
        }
    };

    $scope.loadScript = function(url, type, charset) {
        if (type===undefined) type = 'text/javascript';
        if (url) {
            var script = document.querySelector("script[src*='"+url+"']");
            if (!script) {
                var heads = document.getElementsByTagName("head");
                if (heads && heads.length) {
                    var head = heads[0];
                    if (head) {
                        script = document.createElement('script');
                        script.setAttribute('src', url);
                        script.setAttribute('type', type);
                        if (charset) script.setAttribute('charset', charset);
                        head.appendChild(script);
                    }
                }
            }
            return script;
        }
    };

    // Expose the web config to the HTML page
    $scope.easybuild = ebConfig.getConfigAsObject();

    if ($scope.easybuild && $scope.easybuild.web && $scope.easybuild.web.resize && $scope.easybuild.web.resize.unit ) {
        $scope.easybuild.web.resize.unit.print = utilitiesService.getResizeUnitPrint();
    }

    evBus.on(APP_EVENTS.unitsChanged, function(ev, scope) {
        if (!$scope.easybuild ){
            $scope.easybuild = {};
        }
        if (!$scope.easybuild.web ){
            $scope.easybuild.web = {};
        }
        if (!$scope.easybuild.web.resize ){
            $scope.easybuild.web.resize = {};
        }
        if (!$scope.easybuild.web.resize.unit ){
            $scope.easybuild.web.resize.unit = {};
        }
        $scope.easybuild.web.resize.unit.print = utilitiesService.getResizeUnitPrint();
    });

    evBus.broadcast(APP_EVENTS.applicationLoaded, $scope);
}]);
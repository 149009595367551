angular.module('easybuild.shared.ui-support')

/**
 * @ngdoc directive
 * @name easybuild.directive:ebAffixContainer
 * @description Affix a block container to the top of the page
 * @restrict 'A'
 * @param {string} ebAfficeContainer Ensure it also fills the height of the page if set to "page-fill"
 * @param {string} ebAffixUpdatePreview If true, trigger a preview update event when window is resized
 **/
.directive('ebAffixContainer', ['evBus', function(evBus) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var ebAffixContainer = attrs.ebAffixContainer;
            var ebAffixUpdatePreview = attrs.ebAffixUpdatePreview;

            var windowEl = angular.element(window);

            element.affix({ offset : { top: 1 } });

            windowEl.resize(function() {
                // Ensure preview is not hidden by the bottom nav bar, if present
                var padding = 0;//top nav bar padding (bottom) - in the outer height
                if (angular.element('.navbar-fixed-bottom').outerHeight(true) > 0) {
                    padding += angular.element('.navbar-fixed-bottom').outerHeight(true);
                }
                if (angular.element('.navbar-fixed-top').outerHeight(true) > 0) {
                    padding += angular.element('.navbar-fixed-top').outerHeight(true);
                    padding += angular.element('.navbar-fixed-top').offset().top;
                }

                var elWidthPadding = element.outerWidth(true) - element.width();
                // When the window is resized, remove the preview and resize the container
                element.width(element.parent().width() - elWidthPadding);// 5px padding to split form area from the rest of the page content
                if (ebAffixUpdatePreview === 'true') {
                    evBus.broadcast(Wave2.Preview.REFRESH);
                }

                if (ebAffixContainer === 'page-fill') {
                    var elHeightPadding = element.outerHeight(true) - element.height();
                    // Force the element to be the height of the page
                    element.height(windowEl.height() - (padding) - elHeightPadding);
                }
            });

            setTimeout(function() {
                windowEl.resize();
            }, 100);
        }
    }
}]);
angular.module('easybuild.shared.generation')

.service('docContentService',['metadataService', 'registry' , 'configService', '$q', 'i18n',
    function(metadataService, registry, configService, $q, i18n){
    return {
        getDocContent: function(options, groupIndex, libItem){

            var defer = $q.defer();
            var promise = defer.promise;
            if(!groupIndex) groupIndex = 0;
            var uuid = this._getDocUUID(options);
            var docContent = configService.getDocContent(uuid, groupIndex, libItem, metadataService.getMetaField('assocIdsOverride'));

            var docContentService = this;

            docContent.then(function(docConfig){
                var docConfig = docContentService.filterExternalMediaItems(docConfig);
                docConfig = docContentService.applyDocContentOverrides(docConfig);
                defer.resolve(docConfig);
            }, function(reason) {
                defer.reject(reason);
            });

            return promise;
        },

        getEffects: function(options){
            var uuid = this._getDocUUID(options?options:{});
            var pEffects = configService.getEffects(uuid, metadataService.getMetaField('assocIdsOverride'));
            return pEffects;
        },

        _getDocUUID: function(options){
            var primaryDoc = metadataService.getMetaField('primaryDoc');
            var uuid;
            if(primaryDoc){
                uuid = primaryDoc;
            }
            else if(options.productName && options.typeName && options.documentName){
                uuid = [options.productName, options.typeName, options.documentName].join('@@');
            }
            else {
                var document = registry.get("document");

                if(document){
                    uuid = document.id;
                }
                else {
                    throw new Error(i18n.error.doccontent_select_criteria_missing);
                }
            }
            return uuid;
        },

        applyDocContentOverrides : function(docContent){
            var launchEvents = registry.get('launchEvents');

            if(launchEvents && 'customJson' in launchEvents && 'imageItemsFileUpload' in launchEvents.customJson ){
                var imageItemsFileUpload = launchEvents.customJson.imageItemsFileUpload;
                angular.forEach(docContent.imageItems.concat(docContent.flashImageItems),function(imageItem){
                    imageItem.fileUpload = imageItemsFileUpload;        
                });
            };
            return docContent;
        },

        filterExternalMediaItems: function(docConfig){
            var externalMediaItems = docConfig.externalMediaItems;
            var filteredExternalMediaItems = [];
            angular.forEach(externalMediaItems, function (externalMediaItem){
                if (externalMediaItem.mediaType && externalMediaItem.mediaType != "page") {
                    filteredExternalMediaItems.push(externalMediaItem);
                }
            });
            docConfig.externalMediaItems = filteredExternalMediaItems;

            return docConfig;
        }
    }
}]);
angular.module('easybuild.component.wc.dialog', [])

/**
 * Create/attach to the active dialog handler
 */
.service('dialog', ['$log', 'WORKFLOW_CONFIG', 'i18n', function($log, WORKFLOW_CONFIG, i18n) {
    Wave2.WcService.Dialog.initialise({
        'templates' : {
            'confirmDialogTmpl' : '#confirm_dialog_tmpl',
            'notifyDialogTmpl' : '#notify_dialog_tmpl',
            'errorDialogTmpl' : '#error_dialog_tmpl',
            'progressDialogTmpl' : '#progress_dialog_tmpl',
            'displayImageDialogTmpl' : '#display_image_dialog_tmpl'
        },
        'overlay' : true
    });

    return {

        showDialog: function(type, title, message, options) {
            $log.debug('Showing ' + type.toLowerCase() + ' dialog');
            Wave2.WcService.showDialog.call(this, type, title, message, options);
            this.dialogCounter++;
        },

        showExceptionDialog: function(exception, cause) {
            if (!this.suppressExceptionDialogs) {
                this.suppressExceptionDialogs = true;
                $log.debug('Showing exception dialog for error: ', exception, cause);

                var trace = exception.stack ? exception.stack : null;
                if (trace) trace = trace.replace(/ at /g, '<br/>&nbsp;&nbsp;&nbsp;&nbsp;at ');

                var details = exception;
                if (cause) {
                    details += '<br/><br/>Caused by:<br/>' + cause;
                }
                if (trace) {
                    details += '<br/><br/>Stacktrace:<br/>' + trace;
                }
                var msg = _('error.headline');

                Wave2.WcService.showDialog.call(this, this.TYPE.ERROR, _('error.comms_error'), msg, { details: details });
                this.dialogCounter++;

                var dialogService = this;
                var resetActionButton = angular.element(document.querySelector('.resetEBAction'));
                resetActionButton.click(function() {
                    dialogService.suppressExceptionDialogs = false;
                    // At this level we have no access to services like authService or even $http...
                    // Therefore, drop to the lowest denominator and POST a logout directly with XHR
                    var xhr = new XMLHttpRequest();
                    xhr.open('POST', Wave2.WcService.BASE_SERVER_URL + 'user/logout', false);
                    xhr.onreadystatechange = function() {
                        // Force a window redirect to show the logout navigation page
                        window.open(WORKFLOW_CONFIG.defaultLogoutPage, '_self')
                    };
                    xhr.send();
                });
            }
        },

        closeDialog: function() {
            Wave2.WcService.Dialog.Commands.closeDialogAction.call(this);
            this.dialogCounter--;
        },

        TYPE: Wave2.WcService.Dialog.TYPE,

        dialogCounter: 0,
        suppressExceptionDialogs : false
    };
}]);
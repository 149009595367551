angular.module('easybuild.shared.restrictor', [])
    .directive('ebRestrictor', [
        function() {
            return {
                restrict: 'A',
                scope: {
                    ebRestrictor: '@',
                },
                require: 'ngModel',
                link: function(scope, element, attr, ngModelCtrl) {
                    var pattern = new RegExp(scope.ebRestrictor);

                    function fromUser(text) {
                        if (!text)
                            return text;

                        var transformedInput = text.replace(pattern, '');
                        if (transformedInput !== text) {
                            ngModelCtrl.$setViewValue(transformedInput);
                            ngModelCtrl.$render();
                        }
                        return transformedInput;
                    }
                    ngModelCtrl.$parsers.push(fromUser);
                }
            };
        }
    ]);
angular.module('easybuild.shared.generation')

    .directive('ebPluginScript', ['$log', 'pluginScripts', 'ebConfig', 'registry', 'evBus', 'APP_EVENTS', 'utilitiesService', function($log, pluginScripts, ebConfig, registry, evBus, APP_EVENTS, utilitiesService) {
        return {
            restrict: 'E',
            scope: {
                'script': '@',
                'type': '@',
                'label': '@',
                'args': '@',
                'start': '='
            },
            templateUrl: 'partials/plugin_script.html',
            link: function (scope) {
                scope.enabled = scope.start;
                scope.updateScript = function(noUpdate){
                    if (scope.enabled ){
                        var parsedArgs = {};
                        if (scope.args ){
                            var args = scope.args.split(';');
                            angular.forEach(args, function(arg, index) {
                                var item = arg.split(':');
                                if ( item && item.length == 2){
                                    parsedArgs[item[0]] = item[1];
                                }
                            });
                        }
                        pluginScripts.setScript(scope.script, scope.type, parsedArgs);
                    } else {
                        pluginScripts.removeScript(scope.script);
                    }
                    if ( !noUpdate && utilitiesService.isDynamicPreviewEnabled()) Wave2.JobGenerator.start(registry, ebConfig.get('easybuild.web.preview.currentgrouponly'));
                };
                scope.$on('$destroy', function(){
                    scope.enabled = false;
                    scope.updateScript(true);
                    evBus.off(APP_EVENTS.scriptsOff);
                });
                evBus.off(APP_EVENTS.scriptsOff);
                evBus.on(APP_EVENTS.scriptsOff, function(ev) {
                    scope.enabled = false;
                    scope.updateScript(true);
                });
                scope.updateScript();
            }
        }
    }]);
angular.module('easybuild.page.build')

/**
 * Base build page, where we determine which build page to show and attach generic event handlers
 */
.controller('BuildCtrl', ['$scope', '$rootScope', '$routeParams', '$window' , '$log', '$q', 'registry', 'ebConfig', 'APP_EVENTS', 'GENERATION_TYPE', 'configService', 'jobService',
'galleryService', 'mediaService', 'invokeGenerate', 'pluginScripts', 'buildService', 'evBus', 'dialog', 'articleService', 'bundleService', 'mediaManager', 'i18n','$location', 'jobStoreService',
        'userSession', 'utilitiesService', 'docContentService', '$http', 'metadataService',
                function ($scope, $rootScope, $routeParams, $window, $log, $q, registry, ebConfig, APP_EVENTS, GENERATION_TYPE, configService, jobService,
                          galleryService, mediaService, invokeGenerate, pluginScripts, buildService, evBus, dialog, articleService, bundleService, mediaManager, i18n, $location, jobStoreService,
                          userSession, utilitiesService, docContentService, $http, metadataService) {

    if (!serverReady(ebConfig)) return;

    if ( !registry.get('jobowner') ) {
        registry.put('jobowner', userSession.username);
    }

    $scope.setGroupAndArticleIndices = function(){
        var articleIndex = registry.get("articleIndex");
        if(articleIndex === null){
            articleIndex = 0;
        }
        var groupIndex = registry.get("groupIndex");
        if(groupIndex === null){
            groupIndex = 0;
        }

        $scope.currentGroup = {
            groupIndex : groupIndex,
            articleIndex : articleIndex
        };
    };

    $rootScope.showProductLogo = false;
    $scope.selection = {
        product: registry.get('product'),
        type: registry.get('type'),
        document: registry.get('document'),
        size: registry.get('size')
    }

    $scope.setGroupAndArticleIndices();

    var jobIndex = 0;
    var format = $scope.selection.document.format;

    var job = registry.get("job");
    if(job && job.index && typeof job.index === "number"){
        jobIndex = job.index;
        format = job.document.format;
    }

    $scope.job = job;
    $scope.jobIndex = jobIndex;
    $scope.backDisabled = (typeof job === "object") && (job !== null);
    $scope.downloadType = "pdf";

    $scope.downloadEnabled = (format !== 'flash');
    switch(format){
        case "indd" :
            $scope.downloadType = "pdf";
            break;
        case "html5" :
        case "html" :
            $scope.downloadType = "html5";
            break;
        case "ae" :
            $scope.downloadType = ebConfig.get('easybuild.web.ae.download');
            break;
        default:
            $scope.downloadEnabled = false;
            break;
    }
    buildService.attachGenerateEvents($scope, registry, GENERATION_TYPE.preview);

    // Some helpers to determine build view
    $scope.isBuildType = function(buildType) {
        return $scope.currentGroup && $scope.currentGroup.buildType === buildType;
    };
    $scope.isNotBuildType = function(buildType) {
        return $scope.currentGroup && $scope.currentGroup.buildType !== buildType;
    };

/*    evBus.on(Wave2.FlashCropTool.VideoCropTool.events.VIDEO_CROP_SUBMIT_EVENT, function(ev, data){

        var originalSource = data.source;
        var parts = originalSource.split('/');
        var source = "";
        for (var i=0; i < parts.length;i++) {
            source += decodeURIComponent(parts[i]);
            if (i < parts.length - 1) source += '/';
        }

        var originalDestination = data.destination;
        var parts = originalDestination.split('/');
        var destination = "";
        for (i=0; i < parts.length;i++) {
            destination += decodeURIComponent(parts[i]);
            if (i < parts.length - 1) destination += '/';
        }

        var cropStartSeconds = data.cropStartSeconds;
        var id = data.id;

        mediaService.submitCropVideo(source, destination, cropStartSeconds).then(function(jobToken) {

            var data = {"destination" : originalDestination, "id" : id };
            var callbackEvent = {
                name: Wave2.FlashCropTool.VideoCropTool.events.VIDEO_CROP_COMPLETE_EVENT,
                data: data
            };
            Wave2.JobGenerator.attachRunningJob(jobToken,{formatterMode: true, finishEvent: callbackEvent});

        }, function(reason) {
            throw new Error(i18n.error.video_crop_failed + reason);
        });
    });*/

    $scope.pageValid = true;
    $scope.$on(PageValidator.STATE_CHANGED, function(ev, data){
        var state;
        if(angular.isArray(data)){
            state = data[0];
        }
        else {
            state = data.state;
        }
        switch(state){
            case "failed" :
                $scope.pageValid = false;
                break;
            case "invalid" :
                $scope.pageValid = false;
                break;
            default:
                $scope.pageValid = true;
                break;
        }
    });

    $scope.$on(APP_EVENTS.doccontentValidateFields, function (ev, fieldData, callback, ctx, callbackArgs) {
        // This allows us to attach Easybuild-specific validation.
        // Always return no errors for now.
        callback.call(ctx, [], callbackArgs);
    });

    $scope.onNextAction = function (ev) {
        if (!$scope.pageValid) {
            evBus.broadcast(PageValidator.SHOW_PAGE_VALIDATIONS);
            evBus.broadcast(DoccontentValidator.SHOW_FIELD_VALIDATIONS);
            ev.preventDefault();
        }
    };


    // New window.postMessage method - Browser security friendly
    window.addEventListener("message", function(ev) {
        console.log(ev);
        if ( ev.data.event && ev.data.event.toLowerCase() === 'validate' ) {
            $scope.onNextAction(ev);
            var ret = {'event': ev.data.event, 'Status': $scope.pageValid ? 'Validated' : 'ValidationError'};
            ev.source.postMessage(ret, ev.origin);
        }
    }, false);

    evBus.on(APP_EVENTS.resetForm, function(ev, override) {
        dialog.showDialog(dialog.TYPE.CONFIRM, '', _('build.reset_form_confirm'), {
            onCancelClicked: function() {
                dialog.closeDialog();
            },
            onOkClicked: function() {
                if(registry.contains("launchEvents")){
                    var launchEvents = registry.get("launchEvents");
                    var originalRegistry = registry.get("launchEvents").originalRegistry;

                    //Keep the launchEvents so we can handle another resetForm click 
                    originalRegistry.launchEvents = launchEvents;
                    registry.restore(originalRegistry);
                    $window.location.reload();
                }
                else {
                    registry.remove("undoRegistry");
                    registry.remove("groupIndex");
                    registry.remove("articleIndex");
                    articleService.clearArticles();
                    pluginScripts.clearScripts();
                    $window.location.reload();
                }
            }
        });
    });

    evBus.on(Wave2.DocContent.FIELD_RESET_START_EVENT, function(ev, override) {
        var updateFromArticleData = function(articleData){
            var doCrop = false;
            var fieldDatas = null;
            switch(override.type){
                case 'image':
                    fieldDatas = articleData.imageItems;
                    doCrop = true;
                    break;
                case 'flashimage':
                    fieldDatas = articleData.flashImageItems;
                    doCrop = true;
                    break;
                case 'externalmedia':
                    fieldDatas = articleData.externalMediaItems;
                    break;
                case 'text':
                    fieldDatas = articleData.textItems;
                    break;
                case 'flashtext':
                    fieldDatas = articleData.flashTextItems;
                    break;
            }

            if (fieldDatas != null && fieldDatas.length > 0 ){
                for(var i=0;i<fieldDatas.length;i++){
                    if ( fieldDatas[i].item === override.fieldInfo.item && fieldDatas[i].name === override.fieldInfo.name){
                        evBus.broadcast(Wave2.DocContent.FIELD_RESET_COMPLETE_EVENT, {
                            'type': override.type,
                            'id': override.id,
                            'fieldInfo': override.fieldInfo,
                            'resetData': fieldDatas[i]
                        });
                        break;
                    }
                }
            }
        };

        dialog.showDialog(dialog.TYPE.CONFIRM, '', _('build.reset_item_confirm', override.fieldInfo.name), {
            onCancelClicked: function() {
                dialog.closeDialog();
            },
            onOkClicked: function() {
                if(registry.contains("launchEvents")){
                    var originalRegistry = registry.get("launchEvents").originalRegistry;

                    var jobIndex = Wave2.ArticleManager.getJobIndex();
                    var groupIndex = registry.get("groupIndex") || 0;
                    var articleIndex = registry.get("articleIndex") || 0;
                    var articleKey = originalRegistry.jobs[jobIndex].groupArticleMapping[groupIndex][articleIndex];
                    var articleData = originalRegistry[articleKey];
                    updateFromArticleData(originalRegistry[articleKey]);
                }
                else {
                    if ( override.fieldInfo.buildMedia) {
                        var groupIndex = registry.get("groupIndex") || 0;
                        docContentService.getDocContent({}, groupIndex).then(function (res) {
                            updateFromArticleData(res);
                        }, function() {
                            dialog.showDialog(dialog.TYPE.NOTIFY, '', _('build.reset_item_failed'));
                        });
                    } else {
                        if ( override.fieldInfo.defaultValue) {
                            evBus.broadcast(Wave2.DocContent.FIELD_SELECT_COMPLETE_EVENT, {
                                'type': override.type,
                                'id': override.id,
                                'content': override.fieldInfo.defaultValue,
                                'resetMove': true,
                                'checkboxTicked': override.fieldInfo.checkboxTicked
                            });
                        } else {
                            evBus.broadcast(Wave2.DocContent.FIELD_DELETE_EVENT, {
                                'type': override.type,
                                'id': override.id,
                                'resetMove': true
                            });
                        }
                    }
                }
                dialog.closeDialog();
            }
        });
    });

    $scope.$on(APP_EVENTS.transcodeComplete, function(ev, payload){

        var transcodedVideos = payload.transcodedVideos;
        var mediaItems = [];

        angular.forEach(transcodedVideos, function(transcodedVideo){
            var media = {
                filename : transcodedVideo.substring(transcodedVideo.lastIndexOf("/") + 1),
                type : "externalmedia",
                path : transcodedVideo.replace("cms://","")
            };
            mediaItems.push(media);
        });

        //delete the original file(s) now that the transcode operation(s) have completed
        mediaManager.deleteMedia(mediaItems, function(){
            dialog.closeDialog();
            evBus.broadcast(APP_EVENTS.mediaManagerRefresh, payload);
        });
    });

    $scope.$on(APP_EVENTS.doccontentLoadGalleryTree, function(ev, payload,callback, ctx, callbackArgs ) {
        galleryService.getDirectories(payload['path'], payload['additionalGallery']).then( function(res) {
            if(callback){
                callback.call(ctx || ev, res, callbackArgs);
            }
        }, function(reason) {
            throw new Error(i18n.error.retrieving_gallery + reason);
        });
    });

    $scope.getGroupPreview = function() {
        if (utilitiesService.isDynamicPreviewEnabled()) {
            var jobs = registry.get('jobs');
            var jobIndex = utilitiesService.getJobIndex();

            var pageIndex = utilitiesService.getFirstPageIndex(jobs[jobIndex], Wave2.JobGenerator.groupIndex);

            var previewStatus = undefined;
            if (jobs[jobIndex].pagePreviewMapping) {
                if (jobs[jobIndex].document.documentId === jobs[jobIndex].pagePreviewMapping["documentId"]) {
                    previewStatus = jobs[jobIndex].pagePreviewMapping[pageIndex];
                } else {
                    jobs[jobIndex].pagePreviewMapping = {"documentId": jobs[jobIndex].document.documentId};
                }
                registry.put('jobs', jobs);
            }

            if (previewStatus) {
                Wave2.JobGenerator.cancel();
                evBus.broadcast(Wave2.JobGenerator.JOB_STATUS_CHANGED_EVENT, jobs[jobIndex].pagePreviewMapping[pageIndex]);
            } else {
                Wave2.JobGenerator.start(registry, ebConfig.get('easybuild.web.preview.currentgrouponly'));
            }
        }
    }

    $scope.$on(Wave2.GroupsArticles.GROUP_CHANGED_EVENT, function (ev, groupIndex) {
        $scope.currentGroup.groupIndex = parseInt(groupIndex);

        if (ebConfig.get('easybuild.web.pagepergroup.enabled')) {
            // Update the group index and kick off a generate
            Wave2.JobGenerator.groupIndex = $scope.currentGroup.groupIndex;
            $scope.getGroupPreview();
        }
    });

    $scope.$on(Wave2.ArticleManager.JOBS_LOADED_EVENT, function (ev, articleKey) {
        $scope.firstArticleKey = articleKey;

        var job = registry.get("job");
        var singleJobIndex = 0;
        if(job && job.index && (typeof job.index === "number")){
            singleJobIndex = job.index;
        }

        Wave2.JobGenerator.initialise(registry, articleService.getManager(), {
            "singleJobMode" : true,
            "singleJobIndex" : singleJobIndex
        });

        if (ebConfig.get('easybuild.web.pagepergroup.enabled')) {
            // Tell the job generator how many groups there are (for page-per-group mode)
            Wave2.JobGenerator.groupCount = registry.get('groups').length;
        }
        Wave2.JobGenerator.groupIndex = $scope.currentGroup.groupIndex;

        // Structure is loaded, show the correct page
        var jobs = registry.get('jobs');
        var structure = jobs[0].docConfig;
        if (structure) {
            // Design mode has no structure
            $scope.currentGroup.articleCount = structure.articleCount;
        }
        if (!$scope.currentGroup.buildType) {
            $scope.currentGroup.buildType = (registry.contains('libraries')) ? 'design' : 'form';
                if(registry.contains('libraries')){
                    $scope.navBarBottomMode = "build-design";
                }
                else {
                    $scope.navBarBottomMode = "build-form";
                }
                if(registry.contains("buildMediaAssociation")) {
                    $scope.navBarBottomMode += "-media";
                    // Copy the launchEvents over.
                    var originalRegistry = registry.get("buildMediaAssociation")["originalRegistry"];
                    if (originalRegistry) {
                        var oldLaunchEvents = originalRegistry["launchEvents"];
                        if (oldLaunchEvents) {
                            var launchEvents = registry.get("launchEvents");
                            if (launchEvents) {
                                angular.forEach(oldLaunchEvents, function (value, key) {
                                    if (this[key] === undefined && key !== "jobName" ) {
                                        this[key] = value;
                                    }
                                }, launchEvents);
                            } else {
                                launchEvents = oldLaunchEvents;

                            }
                      //      if ( !launchEvents["buildMediaAssociation"] ){
                      //          launchEvents["buildMediaAssociation"] = registry.get("buildMediaAssociation");
                       //     }
                       //     if ( !launchEvents["originalRegistry"]["buildMediaAssociation"]){
                       //         launchEvents["originalRegistry"]["buildMediaAssociation"] = registry.get("buildMediaAssociation");
                       //     }
                            registry.put("launchEvents", launchEvents);
                        }
                    }
                } else {
                    if (utilitiesService.isLightboxBuild()) {
                        $scope.navBarBottomMode += "-lightbox";
                    }
                }
        }
    });

    $scope.getImageFrameHeightAndWidth = function(ev){
        var imageItem = $scope.getImageItem(ev.currentTarget);
        if(imageItem){
            $scope.frameHeight = imageItem.frameHeight;
            $scope.frameWidth = imageItem.frameWidth;
        }
    };

    $scope.getImageName = function(sourceDomElement){
        return angular.element(sourceDomElement).parents(".image_field").data('name');
    };

    $scope.getImageItem = function(sourceDomElement){
        var fieldName = $scope.getImageName(sourceDomElement);
        var ret = undefined;
        var i = 0;
        var imageItem = undefined;

        var article = Wave2.ArticleManager.getArticle(registry);

        if(article.imageItems) imageItem = article.imageItems.find(function(image){return image.name == fieldName});
        if(!imageItem && article.flashImageItems) imageItem = article.flashImageItems.find(function(image){return image.name == fieldName});

        return imageItem;
    };

    $scope.getImageSubJobXml = function(sourceDomElement){
        var imageItem = $scope.getImageItem(sourceDomElement);

        if(imageItem && imageItem.subJob && imageItem.subJob.builtJobXML ){
            return imageItem.subJob.builtJobXML;
        }
    };

    $scope.needsSubJobPreview = function(sourceDomElement){
        var imageItem = $scope.getImageItem(sourceDomElement);

        if ( imageItem && imageItem.subJob && imageItem.subJob.builtJobXML && !imageItem.value ){
            return true;
        }
        if ( imageItem && imageItem.previewUrl && !ebConfig.get('easybuild.web.buildmedia.useDocumentPreview') ){
            var imageFieldDiv = angular.element(sourceDomElement).parents(".image_field");
            var image = angular.element(imageFieldDiv).find("img.image_preview");

            if ( image[0].src === imageItem.previewUrl) {
                return true;
            }
        }

        return false;
    };

    $scope.buildImage = function(ev) {
        var imageItem = $scope.getImageItem(ev.currentTarget);
        if ( !imageItem || !imageItem.externalData){
            $scope.ev = ev;
            var doc = registry.get("document");

            $scope.getImageFrameHeightAndWidth(ev);

            $scope.buildMediaAssociatedDocuments = [];
            $scope.buildMediaAssociatedDocuments.length = 0;

            buildService.getFilteredBuildMediaAssociatedDocuments($scope.frameHeight, $scope.frameWidth)
                .then(function (buildMediaAssociatedDocuments) {
                    if (buildMediaAssociatedDocuments && buildMediaAssociatedDocuments.length > 0) {
                        $scope.buildMediaPageSize = ebConfig.get('easybuild.web.buildmedia.retrieve.pagesize');
                        var imageType = $(ev.currentTarget).data('type');

                        if (buildMediaAssociatedDocuments.length == 1) {
                            $scope.selectAssociateDoc(buildMediaAssociatedDocuments[0].product +
                                "@@" + buildMediaAssociatedDocuments[0].type + "@@" + buildMediaAssociatedDocuments[0].document, ev);
                            return;
                        }

                        angular.forEach(buildMediaAssociatedDocuments, function (buildMediaAssociatedDocument) {
                            var buildMediaUrl = buildMediaAssociatedDocument.product +
                                "@@" + buildMediaAssociatedDocument.type + "@@" + buildMediaAssociatedDocument.document;

                            if (!buildMediaAssociatedDocument.relativeImagePath || buildMediaAssociatedDocument.relativeImagePath === "") {
                                buildMediaAssociatedDocument.relativeImagePath = 'img/no-doc-preview.png';
                            } else if (buildMediaAssociatedDocument.relativeImagePath.indexOf('/') !== 0) {
                                buildMediaAssociatedDocument.relativeImagePath = buildMediaAssociatedDocument.relativeImagePath;
                            }
                            // HTML5 previews must be passed through the /File servlet to play properly
                            if (buildMediaAssociatedDocument.relativeImagePath.indexOf(Wave2.WcService.BASE_SERVER_URL + 'File/webFile') == -1 && buildMediaAssociatedDocument.relativeImagePath.indexOf('.zip') > 0) {
                                buildMediaAssociatedDocument.relativeImagePath = Wave2.WcService.BASE_SERVER_URL + 'File/webFile' + buildMediaAssociatedDocument.relativeImagePath + '/';
                            }
                            buildMediaAssociatedDocument.url = buildMediaUrl;
                            $scope.buildMediaAssociatedDocuments.push(buildMediaAssociatedDocument);

                        });
                        $scope.headingMessage = i18n.build.select_build_media + imageType;
                        $scope.buildMediaCurrentPage = 1;
                    } else {
                        $scope.headingMessage = i18n.build.no_build_media;
                    }
                }, function (reason) {
                    $scope.headingMessage = reason;
                });

            //jQuery('#buildMediaModal').modal({backdrop: 'static', keyboard: false}); // to stop closing on ESC key and click on remaining area.
            //Below code is to make modal dialog draggable and reset to its original position after move
            if (!$("#buildMediaModal .modal-dialog").is(":ui-draggable")) {
                jQuery("#buildMediaModal .modal-dialog").draggable({
                    handle: ".modal-header"
                });
            }
            jQuery('#buildMediaModal').modal('show');
        }
    };

    $scope.launchSubjob = function(ev, jobxml) {
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(jobxml,"text/xml");
        var jobLabel = "";
        var metafields =  xmlDoc.getElementsByTagName("metafield");

        for (var i=0; i<metafields.length; i++) {
            var metafield = metafields[i];
            if ( metafield.getAttribute('name') === 'joblabel' ) {
                if ( metafield.hasChildNodes()) {
                    jobLabel = metafield.childNodes[0].nodeValue;
                }else{
                    jobLabel = metafield.innerHTML;
                }
                break;
            }
        }

        if ( !jobLabel ||jobLabel.length === 0 ){
            jobLabel = 'SUBJOB' + Wave2.WcService.createUUID();
        }

        var jobDetails = {
            label: jobLabel,
            name: jobLabel,
            owner: registry.get('jobowner'),
            jobXml: jobxml,
            hidden: true
        };
        jobStoreService.store(jobDetails.owner, jobDetails).then(function() {
            $scope.setAssociateDoc(ev, jobLabel);
            $window.location.href = utilitiesService.getNewLocation("launch?StepName=Content&buildMedia=true&deleteJob=true&jobName=" + jobDetails.name + "&jobOwner=" + jobDetails.owner);
        });
    }


    $scope.editImage = function(ev) {
        $scope.ev = ev;
        var doc = registry.get("document");

        var jobxml = $scope.getImageSubJobXml(ev.currentTarget);

        if  (jobxml) {
            $scope.launchSubjob(ev, jobxml);
        }
        else{
            $scope.buildImage(ev);
        }
    };

    $scope.setAssociateDoc = function (ev, subJobLabel) {
        var product = registry.get("product");
        var type = registry.get("type");
        var ppDoc = registry.get("document");

        var originalRegistry = registry.getAll();
        var metadata = originalRegistry.jobs[0].metadata;
        var articleIndex = registry.get("articleIndex") || 0;
        var groupIndex = registry.get("groupIndex") || 0;

        registry.empty();
        var buildMediaAssociation = {
            jobIndex: 0,
            fieldName: $scope.getImageName(ev.currentTarget), //When webcomponents builds the partial it places the image name into the title attribute
            originalRegistry: originalRegistry,
            buildMediaReturnUrl: encodeURI("build/" + product.name + "/" + type.name + "/" + ppDoc.name),
            metadata: metadata,
            articleIndex: articleIndex,
            groupIndex: groupIndex
        };
        if (subJobLabel ){
            buildMediaAssociation['jobLabel'] = subJobLabel;
        }
        registry.put("buildMediaAssociation", buildMediaAssociation);
    };

    $scope.selectAssociateDoc = function (documentId,ev) {
        $http.get(Wave2.WcService.BASE_SERVER_URL + "generatexml?doc=" + documentId.replace("&", "%26")).then(function(res) {
            var parser = new DOMParser();
            var doc = jQuery.parseXML(res.data);
            var $doc = jQuery(doc);
            var buildMediaData = metadataService.getMetadataBlock(utilitiesService.getJobIndex(), 'BuildMediaData');

            if (buildMediaData) {
                var keyValuePairsList = buildMediaData.keyValuePairs;
                if (keyValuePairsList) {
                    jQuery.each(keyValuePairsList, function (index, keyValuePair) {
                        var namePath = '[name=\"' + keyValuePair.name + '\"]';
                        var items = $doc.find('text' + namePath + ', flashtext' + namePath);
                        var index = 0;
                        for (index = 0; index < items.length; index++) {
                            var contents = jQuery(items[index]).find('content');
                            if ( contents.length > 0 ){
                                for (contentsIndex = 0; contentsIndex < contents.length; contentsIndex++) {
                                    contents[contentsIndex].textContent = keyValuePair.value;
                                }
                            } else {
                                var content = doc.createElement("content");
                                content.textContent = keyValuePair.value;
                                items[index].appendChild(content)
                            }
                            items[index].setAttribute('skip', 'false');
                        }

                        var items = $doc.find('image' + namePath + ', flashimage' + namePath + ', externalmedia' + namePath);
                        for (index = 0; index < items.length; index++) {
                            items[index].setAttribute('url', keyValuePair.value);
                            items[index].setAttribute('skip', 'false');
                        }

                        var items = $doc.find('text>content, flashtext>content');
                        var replaceTag = new RegExp('{{' + keyValuePair.name + '}}', 'g');
                        for (index = 0; index < items.length; index++) {
                            var found = items[index].textContent.match(replaceTag);
                            if (found && found.length > 0) {
                                items[index].textContent = items[index].textContent.replace(replaceTag, keyValuePair.value);
                                items[index].parentNode.setAttribute('skip', 'false');
                            }
                        }
                    });
                }
            }

            jQuery('#buildMediaModal').modal('hide');
            $scope.launchSubjob(ev, (new XMLSerializer()).serializeToString(doc));
        }, function() {

        });
    };

    $scope.closeModal = function(){
        jQuery('#buildMediaModal').modal('hide');
    };

    $scope.setupArticleAndGroupNavigation = function(){
        evBus.on(Wave2.GroupsArticles.GROUP_CHANGED_EVENT, function(ev, data){
            registry.put("groupIndex", parseInt(data));
        });

        evBus.on(Wave2.GroupsArticles.ARTICLE_CHANGED_EVENT, function(ev, data){
            registry.put("articleIndex",parseInt(data));
        });

        // Start the articles/groups component
        $scope.groupsArticles = new Wave2.GroupsArticles.UI.PagedNavigator(registry, {
            initialArticleIndex : $scope.currentGroup.articleIndex,
            initialGroupIndex : $scope.currentGroup.groupIndex
        });
        $scope.groupsArticles.initialise();
    };

    evBus.on(Wave2.JobGenerator.JOBS_START_EVENT, function(ev, status){
        var jobs = registry.get('jobs');
        var jobIndex = utilitiesService.getJobIndex();
        if ( !jobs[jobIndex].pagePreviewMapping){
            jobs[jobIndex].pagePreviewMapping = {"documentId": jobs[jobIndex].document.documentId};
        }

        jobs[jobIndex].pagePreviewMapping["jobGroupId"] = Wave2.JobGenerator.groupIndex;
        angular.forEach(utilitiesService.getPageIndexes(jobs[jobIndex], Wave2.JobGenerator.groupIndex), function (pageIndex) {
            jobs[jobIndex].pagePreviewMapping[pageIndex] = undefined;
        });
        registry.put('jobs', jobs);
    });

    evBus.on(Wave2.JobGenerator.JOB_STATUS_CHANGED_EVENT, function(ev, status){
        var outputStatus = status.output.status;
        if (status && status.jobIndex == jobIndex && status.output && outputStatus) {
            if (outputStatus === "FINISHED" ) {
                jobService.getJobXml(status.output.jobToken.jobToken).then(function (result) {
                    var jobs = registry.get('jobs');
                    jobs[status.jobIndex].jobxml = result;
                    registry.put('jobs', jobs);
                    evBus.broadcast(APP_EVENTS.jobXmlUpdated);
                });
                if (ebConfig.get('easybuild.web.pagepergroup.enabled')) {
                    if ( !status.stored && status.output.outputFiles && status.output.outputFiles.length >= 1) {
                        var jobs = registry.get('jobs');
                        if ( !jobs[status.jobIndex].pagePreviewMapping || jobs[status.jobIndex].pagePreviewMapping["documentId"] !== jobs[status.jobIndex].document.documentId){
                            jobs[status.jobIndex].pagePreviewMapping = {"documentId": jobs[status.jobIndex].document.documentId};
                            registry.put('jobs', jobs);
                        }
                        var groupId = jobs[status.jobIndex].pagePreviewMapping["jobGroupId"];
                        if ( groupId !== null && groupId !== undefined) {
                            var pageIndex = utilitiesService.getFirstPageIndex(jobs[status.jobIndex], groupId);
                            jobs[status.jobIndex].pagePreviewMapping["jobGroupId"] = undefined;
                            jobs[status.jobIndex].pagePreviewMapping[pageIndex] = status;
                            jobs[status.jobIndex].pagePreviewMapping[pageIndex].stored = true;
                            registry.put('jobs', jobs);
                        }
                        if ( groupId !== Wave2.JobGenerator.groupIndex){
                            ev.preventDefault();
                            $scope.getGroupPreview();
                        }
                        if ( $scope.isBuildType('form') ) {
                            utilitiesService.updateFormError(jobs[status.jobIndex]);
                        }
                    }
                } else {
                    if ( $scope.isBuildType('form') ) {
                        utilitiesService.updateFormError(status);
                    }
                }
            }
        }
    });

    $scope.setupArticleAndGroupNavigation();

    evBus.on(APP_EVENTS.jobXmlUpdated, function() {
        var jobIndex = Wave2.ArticleManager.getJobIndex();
        var jobs = registry.get('jobs');
        var jobXml = jobs[jobIndex].jobxml;
        if (jobXml) {
            var groupIndex = registry.get("groupIndex") || 0;
            var articleIndex = registry.get("articleIndex") || 0;
            var groupxmlIndex = parseInt(groupIndex) + 1;
            var articlexmlIndex = parseInt(articleIndex) + 1;

            $(jobXml).find('articlegroup').each(function () {
                var articleGroup = $(this);
                var groupId = parseInt(articleGroup.attr('id'));
                articleGroup.find('article').each(function () {
                    var article = $(this);
                    var articleId = parseInt(article.attr('id'));
                    if ((groupId != groupxmlIndex || articleId != articlexmlIndex) && article.parents('subJob').length == 0) {
                        var articleKey = Wave2.ArticleManager.getArticleKey(registry, groupId-1, articleId-1, jobIndex);
                        var articleData = registry.get(articleKey);
                        if (articleData.imageItems) {
                            var updateItem = function (imageItem) {
                                if (!imageItem.value && imageItem.subJob && imageItem.subJob.builtJobXML) {
                                    items = article.find('image[name="' + imageItem.name + '"]');
                                    if (items.length < 1) {
                                        items = article.find('flashimage[name="' + imageItem.name + '"]');
                                    }
                                    if (items.length < 1) {
                                        items = article.find('img[name="' + imageItem.name + '"]');
                                    }
                                    if (items.length >= 1) {
                                        var newUrl = items.attr('url');
                                        if (newUrl) {
                                            imageItem.value = newUrl;
                                            if (!ebConfig.get('easybuild.web.buildmedia.useDocumentPreview')) {
                                                imageItem.previewUrl = "";
                                            }
                                            registry.put(articleKey, articleData);
                                        }
                                    }
                                }
                            };

                            if (articleData.imageItems ) articleData.imageItems.forEach(updateItem);
                            if (articleData.flashImageItems ) articleData.flashImageItems.forEach(updateItem);
                        }
                    }
                });
            });
        }
    });

    // Initialise the article manager and job generator
    articleService.initialise();
}]);
